<div [ngClass]="{bookingDetail: type === types.Booking}" class="row">
  <div class="col-sm-6 box-default" [ngClass]="getBoxClass()" [ngStyle]="{'border-color': getEventColor()}">
    <div class="ribbon-box" [ngClass]="getBgClass()" [ngStyle]="{'border-color': getEventColor()}">
      <div class="circle"></div>
      <div class="ribbon" [ngStyle]="{'border-color': getEventColor(), 'background': getEventColor()}"></div>
    </div>
    <div class="hidden-xs" [ngClass]="getIconClass()"
         [ngStyle]="{'color': getEventColor(), 'border-color': getEventColor()}">
      <span class="regular-icon" [ngClass]="getEventIcon()"></span>
      <span [matTooltip]="'tooltips.removeTriggerFromWorkflow' | translate"
            matTooltipPosition="above"
            matTooltipClass="text-center"
            (click)="confirmRemove()"><i class="fal fa-times"></i></span>
    </div>
    <div class="main-content">
      <div class="box-title-holder">
        <div (click)="onOpen(tabs.Overview)"
             [matTooltip]="'tooltips.workflowTriggerEdit' | translate"
             matTooltipPosition="above"
             matTooltipClass="text-center"
             class="title">{{trigger?.name}}</div>
        <div class="btn-holder">

          <mat-slide-toggle *ngIf="type === types.Booking && trigger.event | isTriggerSkippable"
                            (click)="$event.stopPropagation()"
                            [matTooltip]="trigger.skippedInquiries | isSkippedForInquiry:inquiryId | skippedInquiryTooltipText"
                            matTooltipPosition="above"
                            matTooltipClass="text-center"
                            [checked]="trigger.skippedInquiries | isSkippedForInquiry:inquiryId"
                            (change)="onToggleSkip()"
                            class="mat-slide-secondary">
          </mat-slide-toggle>
          <mat-slide-toggle *ngIf="type === types.Workflow"
                            (click)="$event.stopPropagation()"
                            [matTooltip]="trigger.status | workflowTriggerStatus"
                            matTooltipPosition="above"
                            matTooltipClass="text-center"
                            [checked]="trigger.status === 1"
                            (change)="onToggleStatus()">
          </mat-slide-toggle>
        </div>
      </div>
      <div class="box-content">
        <ul>
          <li>
            {{'tableColumns.action' | translate}}: <strong>{{getHandlerName()}}</strong>
          </li>
          <li *ngIf="trigger?.name?.length > 0">
            {{'tableColumns.event' | translate}}: <strong>{{getEventName()}}</strong>
          </li>
          <li *ngIf="trigger.templateName"
              (click)="onOpen(tabs.Template)"
              [matTooltip]="'tooltips.workflowTriggerTemplateEdit' | translate"
              matTooltipPosition="above"
              matTooltipClass="text-center"
              class="cp">
            {{'tableColumns.template' | translate}}: <strong>{{trigger.templateName}}</strong>
          </li>
          <li *ngIf="trigger.channelName">
            {{'tableColumns.channel' | translate}}: <strong>{{trigger.channelName}}</strong>
          </li>
          <li *ngIf="users.length > 0">
            {{'tableColumns.recipients' | translate}}: <strong
            *ngFor="let user of users;let last = last;">{{user?.firstname}} {{user?.lastname}}<span
            *ngIf="!last">, </span></strong>
          </li>
          <li (click)="onOpen(tabs.Activity)"
              [matTooltip]="'tooltips.viewTriggerActivityLogs' | translate"
              matTooltipPosition="above"
              matTooltipClass="text-center" class="cp">
            {{'tableColumns.lastTrigger' | translate}}: <strong>{{trigger.lastUpdateFormatted}}</strong>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div [ngClass]="getSideBoxClass()" class="col-sm-6 box-template-description">
    <p>{{trigger.templateDescription}}</p>
  </div>
</div>
