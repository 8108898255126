<div class="main-body clearfix">
  <div class="container-fluid">
    <div class="row title-holder">
      <div class="col-sm-12 title">
        {{'pageTitle.accountInfo' | translate}}
      </div>
    </div>
    <div class="content-holder clearfix">
      <div class="tab-content">
        <div class="tab-pane active">
          <div class="clearfix overall-content">
            <div class="panel panel-default">
              <div class="clearfix header-panel">
                <div class="col-xs-8 title">
                  {{'pageElements.yourAccountInfo' | translate}}
                  <span class="account-id"><strong>{{'pageElements.accountId' | translate}}
                    : </strong> <span>{{accountId}}</span></span>
                  <span class="account-id"><strong>{{'pageElements.created' | translate}}
                    : </strong> <span>{{createdFormatted}}</span></span>
                </div>
                <div class="col-xs-4 btn-holder">
                  <div>
                    <button (click)="saveAccountInfo()" [disabled]="infoForm.invalid" type="button"
                            class="btn btn-new left-icon text-uppercase">
                      <span class="far fa-save" aria-hidden="true"></span>
                      {{'buttons.save' | translate}}
                    </button>
                  </div>
                </div>
              </div>
              <div class="panel-body">
                <form [formGroup]="infoForm" class="row">
                  <div class="col-sm-6 box">
                    <div class="row box-title">
                      {{'pageElements.companyInfo' | translate}}
                    </div>
                    <div class="box-body">
                      <div class="clearfix input-holder">
                        <div class="col-sm-10">
                          <mat-form-field>
                            <input matInput [placeholder]="'formControls.name' | translate" formControlName="name"
                                   type="text"
                                   [required]="utility.isRequired(infoForm.get('name'))">
                            <mat-error
                              *ngIf="infoForm.get('name').invalid && infoForm.get('name').touched && infoForm.get('name').errors?.required">
                              <span [innerHtml]="'validations.nameRequired' | translate"></span>
                            </mat-error>
                            <mat-error
                              *ngIf="infoForm.get('name').invalid && infoForm.get('name').touched && infoForm.get('name').errors?.minlength">
                              <span [innerHtml]="'validations.nameAtLeast2' | translate"></span>
                            </mat-error>
                            <mat-hint align="end">{{infoForm.get('name').value.length || 0}}/50</mat-hint>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="clearfix input-holder">
                        <div class="col-sm-10">
                          <mat-form-field>
                            <input matInput [placeholder]="'formControls.phone' | translate" formControlName="phone"
                                   type="text"
                                   [required]="utility.isRequired(infoForm.get('phone'))">
                            <mat-error
                              *ngIf="infoForm.get('phone').invalid && infoForm.get('phone').touched && infoForm.get('phone').errors?.required">
                              <span [innerHtml]="'validations.phoneRequired' | translate"></span>
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="clearfix input-holder">
                        <div class="col-sm-10">
                          <mat-form-field>
                            <input matInput [placeholder]="'formControls.email' | translate" formControlName="email"
                                   type="email"
                                   [required]="utility.isRequired(infoForm.get('email'))">
                            <mat-error
                              *ngIf="infoForm.get('email').invalid && infoForm.get('email').touched && infoForm.get('email').errors?.required">
                              <span [innerHtml]="'validations.emailRequired' | translate"></span>
                            </mat-error>
                            <mat-error
                              *ngIf="infoForm.get('email').invalid && infoForm.get('email').touched && infoForm.get('email').errors?.email">
                              <span [innerHtml]="'validations.emailFormat' | translate"></span>
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="clearfix input-holder">
                        <div class="col-sm-10">
                          <app-select-search [items]="countries"
                                             [ctrl]="infoForm.get('country')"
                                             [multiple]="false"
                                             [hasBlank]="false"
                                             [placeholder]="'formControls.country' | translate"></app-select-search>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 box">
                    <div class="row box-title">
                      {{'pageElements.companyLocation' | translate}}
                    </div>
                    <div class="box-body">
                      <div class="clearfix input-holder">
                        <div class="col-sm-10">
                          <mat-form-field>
                            <input matInput [placeholder]="'formControls.address' | translate" formControlName="address"
                                   type="text">
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="clearfix input-holder">
                        <div class="col-sm-10">
                          <mat-form-field>
                            <input matInput [placeholder]="'formControls.city' | translate" formControlName="city"
                                   type="text">
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="clearfix input-holder">
                        <div class="col-sm-10">
                          <mat-form-field>
                            <input matInput [placeholder]="'formControls.stateProvinceRegion' | translate"
                                   formControlName="state" type="text">
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="clearfix input-holder">
                        <div class="col-sm-6">
                          <mat-form-field>
                            <input matInput [placeholder]="'formControls.postalCode' | translate"
                                   formControlName="zipCode" type="text">
                          </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                          <mat-form-field>
                            <input matInput [placeholder]="'formControls.vatTax' | translate" formControlName="tax"
                                   type="text">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-api-keys-table *ngIf="account" [account]="account"></app-api-keys-table>

  </div>
</div>
