import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {combineLatest, Subscription} from 'rxjs';
import {Notification} from '../../../models/notification';
import * as R from 'ramda';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {ActivityList} from '@automata/components/activity/activity-list/activity-list.abstract';

@Component({
  selector:    'app-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls:   ['./activity-list.component.scss']
})
export class ActivityListComponent extends ActivityList {

  constructor(protected store: Store<fromRoot.State>) {
    super(store);
  }
}
