import {Component} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ConfirmDialogStatus} from '../../../enums/confirm-dialog-status';
import { MatDialogRef } from '@angular/material/dialog';
import {AccountService} from '../../../services/account.service';
import {ApiKey} from '../../../models/api-key';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {AddApiKey} from '../../../actions/accounts.actions';
import {AddApiKeyPayload} from '../../../interfaces/account/add-api-key-payload';
import {AuthService} from '../../../services/auth.service';
import * as R from 'ramda';
import {ToastService} from '@automata/services/toast.service';

@Component({
  selector:    'app-create-api-key-dialog',
  templateUrl: './create-api-key-dialog.component.html',
  styleUrls:   ['./create-api-key-dialog.component.scss']
})
export class CreateApiKeyDialogComponent {

  isCopied = false;

  isCreated = false;

  apiKey: ApiKey;

  name = new FormControl('', [Validators.required]);

  constructor(public dialogRef: MatDialogRef<CreateApiKeyDialogComponent>,
              private store: Store<fromRoot.State>,
              private auth: AuthService,
              private toast: ToastService,
              private accountService: AccountService) {
  }

  create(name: string) {
    this.accountService
      .generateAPIKey(name)
      .then((apiKey) => {
        this.isCreated = true;
        this.apiKey = apiKey;
        this.store.dispatch(AddApiKey({
          key: {
                 account: this.auth.getUser().account,
                 apiKey:  this.apiKey
               } as AddApiKeyPayload
        }));
        this.toast.success('apiKey');
      });
  }

  confirm() {
    this.dialogRef.close(ConfirmDialogStatus.Confirmed);
  }

  cancel() {
    this.dialogRef.close(ConfirmDialogStatus.Canceled);
  }

  close() {
    this.dialogRef.close(ConfirmDialogStatus.Closed);
  }

  hasApiKey(): boolean {
    return !R.isNil(this.apiKey);
  }
}
