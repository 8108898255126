export class LocalStorageKeys {
  static API_TOKEN = 'API_TOKEN';
  static USER_INFO = 'USER_INFO';
  static TOAST = 'TOAST';
  static INQUIRIES_FILTERS = 'INQUIRIES_FILTERS';
  static TRIGGERS_FILTERS = 'TRIGGERS_FILTERS';
  static WORKFLOWS_FILTERS = 'WORKFLOWS_FILTERS';
  static CODES_FILTERS = 'CODES_FILTERS';
  static TABLE_COLUMNS = 'TABLE_COLUMNS';
  static TABLE_CUSTOMIZATION_BADGE = 'TABLE_CUSTOMIZATION_BADGE';
  static LOGOUT = 'LOGOUT';
  static LANGUAGE = 'LANGUAGE';
}
