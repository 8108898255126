import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  AddCode,
  AddCodeComplete,
  DeleteBatchCode,
  DeleteBatchCodeComplete,
  DeleteCode,
  DeleteCodeComplete,
  LoadCodes,
  LoadCodesComplete,
  UpdateCode,
  UpdateCodeComplete
} from '../actions/codes.actions';
import {of} from 'rxjs';
import {catchError, map, switchMap, switchMapTo, tap} from 'rxjs/operators';
import {CodesService} from '../services/codes.service';
import {ActionFailed} from '../actions/utility.actions';
import {ToastService} from '@automata/services/toast.service';

@Injectable()
export class CodesEffects {

  @Effect()
  addCode$ = this.actions$
    .pipe(
      ofType(AddCode),
      switchMap(({request}) => this.codes
        .add(request)
        .pipe(
          tap(() => this.toast.success('codeCreated')),
          map(code => AddCodeComplete({code})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  updateCode$ = this.actions$
    .pipe(
      ofType(UpdateCode),
      switchMap(({request}) => this.codes
        .update(request)
        .pipe(
          tap(() => this.toast.success('codeUpdated')),
          map(code => UpdateCodeComplete({code})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  loadCodes$ = this.actions$
    .pipe(
      ofType(LoadCodes),
      switchMapTo(
        this.codes
          .all()
          .pipe(
            map(codes => LoadCodesComplete({codes})),
            catchError(error => of(ActionFailed({error})))
          )
      )
    );

  @Effect()
  deleteCode$ = this.actions$
    .pipe(
      ofType(DeleteCode),
      switchMap(({id}) => this.codes
        .delete(id)
        .pipe(
          tap(() => this.toast.success('codeDeleted')),
          map(() => DeleteCodeComplete({id})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  deleteBatchCode$ = this.actions$
    .pipe(
      ofType(DeleteBatchCode),
      switchMap(({ids}) => this.codes
        .deleteBatch(ids)
        .pipe(
          tap(() => this.toast.success('codeDeletedBatch')),
          map(() => DeleteBatchCodeComplete({ids})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  constructor(private actions$: Actions,
              private toast: ToastService,
              private codes: CodesService) {
  }
}
