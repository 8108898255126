import {deserialize, Serializable} from '@automata/utility/serializers/serializers';

export class ApiKey extends Serializable<ApiKey> {
  name: string;
  key: string;
  apikey: string;
  created: number;
  access: string;
  internal: boolean;

  static deserialize(data: object): ApiKey {
    return deserialize(ApiKey, data);
  }
}
