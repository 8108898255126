import {Pipe, PipeTransform} from '@angular/core';
import {TriggerHandler} from '../../enums/trigger-handler';

@Pipe({
  name: 'isSMSHandler'
})
export class IsSMSHandlerPipe implements PipeTransform {

  transform(handler: string): boolean {
    return handler === TriggerHandler.SendSMSMessage;
  }

}
