import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'workflowStatusTooltip'
})
export class WorkflowStatusTooltipPipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }


  transform(status: number): string {
    if (status === 0) {
      return this.translate.instant('tooltips.resumeAllTriggersInWorkflow');
    } else {
      return this.translate.instant('tooltips.pauseAllTriggersInWorkflow');
    }
  }

}
