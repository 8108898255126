import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, IAppConfig} from '../app.config';
import {ApiKey} from '../models/api-key';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import * as R from 'ramda';

@Injectable()
export class TokeetService {

  constructor(private http: HttpClient,
              private authService: AuthService,
              @Inject(APP_CONFIG) private config: IAppConfig) {
  }

  createIFTTTUser(secret, apiKey: ApiKey) {
    return this.http.post(`${this.config.iftttUrl}/account/create`, {
      secret:    secret,
      accountId: this.authService.getUser().account,
      apiKey:    apiKey.apikey,
      name:      `${this.authService.getUser().firstname} ${this.authService.getUser().lastname}`
    });
  }

  isSlackConnected(): Observable<boolean> {
    return this.http.get('@api/slack/oauth')
      .pipe(
        map(response => !R.isNil(response))
      );
  }
}
