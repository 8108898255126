<div class="dialog-header">
  <h2 mat-dialog-title>
    <i class="fas fa-tags"></i>
    <span *ngIf="type === types.Template"> {{'pageElements.addTemplateTags' | translate}}</span>
    <span *ngIf="type === types.Trigger"> {{'pageElements.addTriggerTags' | translate}}</span>
    <span *ngIf="type === types.Code"> {{'pageElements.addCustomCodeTags' | translate}}</span>
  </h2>
  <button (click)="close()" class="close" aria-label="Close" type="button">
    <span class="far fa-times"></span>
  </button>
</div>

<div class="modal-body">
  <p [translate]="'pageElements.tagDialogDescription'"
     [translateParams]="{type1: this.getTypeName1(), type2: this.getTypeName2()}"></p>
  <form [formGroup]="form">
    <tag-input [matTooltip]="'tooltips.selectExistingTag' | translate"
               matTooltipPosition="above"
               matTooltipClass="text-center"
               placeholder=""
               [secondaryPlaceholder]="'pageElements.selectTagsPlaceholder' | translate"
               [formControlName]="'tags'"
               [addOnBlur]="true"
               name="tags"
               [ripple]="false">
      <tag-input-dropdown [keepOpen]="false"
                          [minimumTextLength]="0"
                          [limitItemsTo]="6"
                          [autocompleteObservable]="requestAutocomplete"></tag-input-dropdown>
    </tag-input>
  </form>
</div>

<div class="modal-footer text-right">
  <button (click)="close()" type="button" class="btn btn-default left-icon text-uppercase">
    <i class="far fa-check-square" aria-hidden="true"></i>
    {{'buttons.done' | translate}}
  </button>

  <button (click)="submit()" type="button"
          class="btn btn-new left-icon text-uppercase">
    <span class="far fa-save" aria-hidden="true"></span>
    {{'buttons.save' | translate}}
  </button>
</div>
