import {AbstractControl} from '@angular/forms';

export function validateAlphaNum() {
  return (control: AbstractControl) => {
    if (!/^[a-zA-Z0-9]*$/.test(control.value)) {
      return {alphaNum: true};
    }
    return null;
  };
}
