import {Pipe, PipeTransform} from '@angular/core';
import {TriggerEvents} from '@automata/models/trigger-events';

@Pipe({
  name: 'eventAllowsDelay'
})
export class EventAllowsDelayPipe implements PipeTransform {
  transform(event: string): boolean {
    return !TriggerEvents.isTime(event);
  }
}
