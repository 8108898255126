<div [ngSwitch]="valueType">

  <div *ngSwitchCase="valueTypes.InquiryStatus">

    <mat-form-field>
      <mat-select [formControl]="form.get('inquiryStatus')" [placeholder]="'formControls.status' | translate">
        <mat-option [value]="inquiryStatuses.Booked">Booked</mat-option>
        <mat-option [value]="inquiryStatuses.Canceled">Canceled</mat-option>
        <mat-option [value]="inquiryStatuses.Inquiry">Pending</mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <div *ngSwitchCase="valueTypes.Trigger">

    <app-select-search [items]="triggerList"
                       [ctrl]="form.get('trigger')"
                       [multiple]="false"
                       [hasBlank]="false"
                       [placeholder]="'formControls.trigger' | translate"></app-select-search>

  </div>

  <div *ngSwitchCase="valueTypes.TriggerTag">

    <mat-form-field>
      <input matInput
             [placeholder]="'formControls.bookingTag' | translate"
             [formControl]="form.get('bookingTag')"
             [required]="true"
             type="text">
      <mat-error *ngIf="form.get('bookingTag').hasError('required')">
        <span [innerHtml]="'validations.bookingTagRequired' | translate"></span>
      </mat-error>
    </mat-form-field>

  </div>

  <div *ngSwitchCase="valueTypes.Country">
    <app-select-search [items]="countries"
                       [ctrl]="form.get('country')"
                       [multiple]="false"
                       [hasBlank]="false"
                       [placeholder]="'formControls.country' | translate"></app-select-search>
  </div>

  <div *ngSwitchCase="valueTypes.Number">

    <mat-form-field>
      <input matInput [placeholder]="'formControls.number' | translate" [formControl]="form.get('number')"
             [required]="true" type="number" pattern="^-?(0|[1-9]\d*)?$">
      <mat-error *ngIf="form.get('number').hasError('pattern')">
        <span [innerHtml]="'validations.onlyWholeNumbers' | translate"></span>
      </mat-error>
    </mat-form-field>

  </div>

  <div *ngSwitchCase="valueTypes.Nights">

    <mat-form-field>
      <input matInput [placeholder]="'formControls.nights' | translate" [formControl]="form.get('nights')"
             [required]="true" type="number" pattern="^-?(0|[1-9]\d*)?$">
      <mat-error *ngIf="form.get('nights').hasError('pattern')">
        <span [innerHtml]="'validations.onlyWholeNumbers' | translate"></span>
      </mat-error>
    </mat-form-field>

  </div>

  <div *ngSwitchCase="valueTypes.HoursMinutes">

    <div class="row">
      <div class="col-sm-6">
        <mat-form-field>
          <input matInput [placeholder]="'formControls.hours' | translate" [formControl]="form.get('hours')"
                 type="number" pattern="^-?(0|[1-9]\d*)?$">
          <mat-error *ngIf="form.get('hours').hasError('required')">
            <span [innerHtml]="'validations.hoursRequired' | translate"></span>
          </mat-error>
          <mat-error *ngIf="form.get('hours').hasError('minNumber')">
            <span [innerHtml]="'validations.hoursAtLeast0' | translate"></span>
          </mat-error>
          <mat-error *ngIf="form.get('hours').hasError('maxNumber')">
            <span [innerHtml]="'validations.hoursAtMost24' | translate"></span>
          </mat-error>
          <mat-error *ngIf="form.get('hours').hasError('pattern')">
            <span [innerHtml]="'validations.onlyWholeNumbers' | translate"></span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-6">
        <mat-form-field>
          <input matInput [placeholder]="'formControls.minutes' | translate" [formControl]="form.get('minutes')"
                 type="number" pattern="^-?(0|[1-9]\d*)?$">
          <mat-error *ngIf="form.get('minutes').hasError('required')">
            <span [innerHtml]="'validations.minutesRequired' | translate"></span>
          </mat-error>
          <mat-error *ngIf="form.get('minutes').hasError('minNumber')">
            <span [innerHtml]="'validations.minutesAtLeast0' | translate"></span>
          </mat-error>
          <mat-error *ngIf="form.get('minutes').hasError('maxNumber')">
            <span [innerHtml]="'validations.minutesAtMost24' | translate"></span>
          </mat-error>
          <mat-error *ngIf="form.get('minutes').hasError('pattern')">
            <span [innerHtml]="'validations.onlyWholeNumbers' | translate"></span>
          </mat-error>
        </mat-form-field>
      </div>

      <mat-error *ngIf="(form.get('hours').touched || form.get('minutes').touched) && !form.controls.hours.errors && !form.controls.minutes.errors && form.hasError('minimumHoursMinutes')">
        Total time must be greater than or equal to 30 minutes.
      </mat-error>
    </div>

  </div>

  <div *ngSwitchCase="valueTypes.DaysHours">

    <div class="row">
      <div class="col-sm-6">
        <mat-form-field>
          <input matInput [placeholder]="'formControls.days' | translate" [formControl]="form.get('days')"
                 type="number" pattern="^-?(0|[1-9]\d*)?$">
          <mat-error *ngIf="form.get('days').hasError('required')">
            <span [innerHtml]="'validations.daysRequired' | translate"></span>
          </mat-error>
          <mat-error *ngIf="form.get('days').hasError('minNumber')">
            <span [innerHtml]="'validations.daysAtLeast0' | translate"></span>
          </mat-error>
          <mat-error *ngIf="form.get('days').hasError('pattern')">
            <span [innerHtml]="'validations.onlyWholeNumbers' | translate"></span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-6">
        <mat-form-field>
          <input matInput [placeholder]="'formControls.hours' | translate" [formControl]="form.get('hours')"
                 type="number" pattern="^-?(0|[1-9]\d*)?$">
          <mat-error *ngIf="form.get('hours').hasError('required')">
            <span [innerHtml]="'validations.hoursRequired' | translate"></span>
          </mat-error>
          <mat-error *ngIf="form.get('hours').hasError('minNumber')">
            <span [innerHtml]="'validations.hoursAtLeast0' | translate"></span>
          </mat-error>
          <mat-error *ngIf="form.get('hours').hasError('maxNumber')">
            <span [innerHtml]="'validations.hoursAtMost24' | translate"></span>
          </mat-error>
          <mat-error *ngIf="form.get('hours').hasError('pattern')">
            <span [innerHtml]="'validations.onlyWholeNumbers' | translate"></span>
          </mat-error>
        </mat-form-field>
      </div>

      <mat-error *ngIf="(form.get('days').touched || form.get('hours').touched) && !form.controls.days.errors && !form.controls.hours.errors && form.hasError('minimumDaysHours')">
        Total time must be greater than or equal to 1 hour.
      </mat-error>
    </div>

  </div>


</div>
