import {ChannelResponse} from '../interfaces/channel-response';
import {Searchable} from '../interfaces/searchable';
import {deserialize, Serializable} from '@automata/utility/serializers/serializers';
import {Expose} from 'class-transformer';

export class Channel extends Serializable<Channel> implements ChannelResponse, Searchable {

  @Expose({name: 'channelId'})
  id: string;
  logo: string;
  depricated: boolean;
  name: string;
  logo_color: string;
  description: string;
  isconnected: number;
  channelId: string;
  friendlyName: string;
  infoPage: string;
  token: string;
  agent: string;
  enabled: boolean;
  type: string;
  logo_bw: string;

  static deserialize(data: object): Channel {
    return deserialize(Channel, data);
  }

}
