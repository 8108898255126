import {Message} from './message';
import {Expose, Transform, Type} from 'class-transformer';
import {EpochFormatter, EventNameTransformer, HandlerNameTransformer} from '@automata/utility/transformers/transformers';
import {deserialize, Serializable} from '@automata/utility/serializers/serializers';

export class AutomataLogModel extends Serializable<AutomataLogModel> {

  @Expose({name: 'pkey'})
  id: string;

  @Expose({name: 'trigger'})
  triggerId: string;

  @Expose({name: 'timestamp'})
  @Transform(EpochFormatter(), {toClassOnly: true})
  dateFormatted: string;

  @Expose({name: 'event'})
  @Transform(EventNameTransformer(), {toClassOnly: true})
  eventName: string;

  @Expose({name: 'handler'})
  @Transform(HandlerNameTransformer(), {toClassOnly: true})
  handlerName: string;

  triggerName?: string;

  @Type(() => Message)
  message: Message;

  timestamp: number;

  static deserialize(data: object): AutomataLogModel {
    return deserialize(AutomataLogModel, data);
  }
}
