import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  CreateTriggersForWorkflow,
  CreateWorkflow,
  DeleteWorkflow,
  DuplicateWorkflow,
  RemoveWorkflow,
  RenameWorkflow
} from '../actions/workflows.actions';
import {CreateTriggersComplete, DeleteBatchTriggerComplete, LoadAllTriggers, UpdateTriggersComplete} from '../actions/triggers.actions';
import {DeleteBatchTemplateComplete} from '../actions/templates.actions';
import {catchError, concatMap, flatMap, map, mergeMap, switchMap, take, tap, toArray} from 'rxjs/operators';
import {forkJoin, from, Observable, of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {selectTriggers} from '../reducers';
import {Trigger} from '../models/trigger';
import {Workflow} from '../models/workflow';
import {TriggersService} from '../services/triggers.service';
import * as R from 'ramda';
import {ActionFailed} from '../actions/utility.actions';
import {TemplatesService} from '../services/templates.service';
import {ToastService} from '@automata/services/toast.service';
import {TriggersCreateRequest} from '@automata/interfaces/trigger/triggers-create-request';
import {TriggerSaveRequest} from '@automata/interfaces/trigger/trigger-save-request';
import {selectOnce} from '@automata/utility/operators/select-once';
import {TriggerCondition} from '@automata/features/conditions/models/trigger-condition';
import {isSomething} from '@automata/utility/functions/is-something';

@Injectable()
export class WorkflowsEffects {

  removeWorkflow = (triggers: Trigger[], workflow: Workflow) => {
    return R.map(t => {
      let trigger = new Trigger(t);
      trigger.attributes = R.merge(trigger.attributes, {workflows: R.reject(w => R.contains(w, workflow.name), t.attributes.workflows)});
      return trigger;
    }, triggers);
  };

  addWorkflow = (triggers: Trigger[], workflowName: string) => {
    return R.map(t => {
      let trigger = new Trigger(t);
      trigger.attributes = R.merge(trigger.attributes, {workflows: R.append(workflowName, t.attributes.workflows)});
      return trigger;
    }, triggers);
  };

  renameWorkflow = (triggers: Trigger[], workflow: Workflow, workflowName: string) => {
    return R.map(t => {
      let trigger = new Trigger(t);
      trigger.attributes = R.merge(trigger.attributes, {workflows: R.append(workflowName, R.filter((name: string) => name !== workflow.name, t.attributes.workflows))});
      return trigger;
    }, triggers);
  };

  @Effect()
  duplicateWorkflow$ = this.actions$
    .pipe(
      ofType(DuplicateWorkflow),
      switchMap(({payload}) => this.store.pipe(
        selectOnce(selectTriggers),
        map(triggers => ({triggers, payload})))
      ),
      switchMap(({triggers, payload}) => of(payload)
        .pipe(
          flatMap(payload => from(payload.rentalIds)),
          mergeMap(rentalId => {

            const allConditions = R.flatten(R.map((t: Trigger) => t.conditions, payload.workflow.triggers));
            const replacementRecord: Record<string, Trigger> = {};

            R.forEach((c: TriggerCondition) => {
              const t = R.find((x: Trigger) => x.id === c.trigger, payload.workflow.triggers);
              if (isSomething(t)) {
                replacementRecord[t.id] = null;
              }
            }, <any>allConditions);

            return of(payload.workflow.triggers)
              .pipe(
                flatMap(trigger => from(trigger)),
                mergeMap(oldTrigger => this.triggers.duplicateTrigger(oldTrigger, payload.workflow.name, rentalId)
                  .pipe(
                    tap(newTrigger => {
                      if (R.has(oldTrigger.id, replacementRecord)) {
                        replacementRecord[oldTrigger.id] = newTrigger;
                      }
                    })
                  )),
                toArray(),
                switchMap(newTriggers => {

                  let conditionRequests = [];

                  R.forEach((t: Trigger) => {
                    R.forEach((c: TriggerCondition) => {
                      if (R.has(c.trigger, replacementRecord)) {
                        conditionRequests = R.append({
                          triggerId:   t.id,
                          conditionId: c.key,
                          condition:   {...c, trigger: replacementRecord[c.trigger].id}
                        }, conditionRequests);
                      }

                    }, t.conditions);

                  }, <any>newTriggers);

                  return of(conditionRequests)
                    .pipe(
                      flatMap(conditionRequest => from(conditionRequest)),
                      mergeMap(conditionRequest => this.triggers.saveCondition(conditionRequest)),
                      toArray(),
                      map(savedConditions => ({newTriggers, savedConditions}))
                    );
                })
              );

                    }),
                    toArray(),
                    map(response => R.head(response))
                )),
            tap(() => this.toast.success('workflowDuplicated')),
            switchMap(() => of(LoadAllTriggers())),
            catchError(error => of(ActionFailed({error})))
        );

    @Effect()
    createWorkflow$ = this.actions$
        .pipe(
            ofType(CreateWorkflow),
            switchMap(({triggers, rental, name}) => {
                let toUpdate = R.filter((t: Trigger) => t.settings.rental === rental, triggers);
                toUpdate = this.addWorkflow(toUpdate, name);
                const toRecreate = R.filter((t: Trigger) => t.settings.rental !== rental, triggers);
                return this.triggers
                    .recreate({triggers: toRecreate, workflow: name, rental})
                    .pipe(
                        map(triggers => ({created: triggers, toUpdate})),
                        catchError(error => of(ActionFailed({error})))
                    );
            }),
            switchMap((param: {created: Trigger[], toUpdate: Trigger[]}) => {
                return this.triggers
                    .updateBatch(param.toUpdate)
                    .pipe(
                        map(triggers => ({created: param.created, updated: triggers})),
                        catchError(error => of(ActionFailed({error})))
                    );
            }),
            switchMap((param: {created: Trigger[], updated: Trigger[]}) => {
                this.toast.success('workflowCreated');
                return [
                    CreateTriggersComplete({triggers: param.created}),
                    UpdateTriggersComplete({triggers: param.updated})
                ];
            }),
            catchError(error => of(ActionFailed({error})))
        );

    @Effect()
    deleteWorkflow$ = this.actions$
        .pipe(
            ofType(DeleteWorkflow),
            switchMap(({workflow}) => this.store.pipe(
                select(fromRoot.selectTriggersByWorkflow(workflow)),
                take(1),
                map(triggers => ({triggers, workflow}))
            )),
            switchMap((params: {triggers: Trigger[], workflow: Workflow}) => {
                const toDelete = R.filter(t => t.attributes.workflows.length === 1, params.triggers);
                const templatesToDelete = R.uniq(R.map(t => t.template, toDelete));
                const triggersWithMultipleWorkflows = R.filter(t => t.attributes.workflows.length !== 1, params.triggers);
                const toUpdate = this.removeWorkflow(triggersWithMultipleWorkflows, params.workflow);
                return this.triggers.updateBatch(toUpdate)
                    .pipe(
                        map(result => ({updatedTriggers: R.flatten(<any>result), toDelete, templatesToDelete}))
                    );
            }),
            switchMap((params: {updatedTriggers: Trigger[], toDelete: Trigger[], templatesToDelete: string[]}) => {
                return this.triggers
                    .deleteBatch(R.map(t => t.id, params.toDelete))
                    .pipe(
                        map(() => params),
                        catchError(error => of(ActionFailed({error})))
                    );
            }),
            switchMap((params: {updatedTriggers: Trigger[], toDelete: Trigger[], templatesToDelete: string[]}) => {
                return this.templates
                    .deleteBatch(params.templatesToDelete)
                    .pipe(
                        map(deletedTemplates => {
                            return {
                                updatedTriggers:   params.updatedTriggers,
                                toDelete:          params.toDelete,
                                templatesToDelete: R.map(t => t.pkey, <any>deletedTemplates)
                            };
                        })
                    );
            }),
            switchMap((params: {updatedTriggers: Trigger[], toDelete: Trigger[], templatesToDelete: string[]}) => {
                this.toast.success('workflowDeleted');
                return [
                    DeleteBatchTriggerComplete({ids: R.map(t => t.id, params.toDelete)}),
                    UpdateTriggersComplete({triggers: params.updatedTriggers}),
                    DeleteBatchTemplateComplete({ids: params.templatesToDelete})
                ];
            }),
            catchError(error => of(ActionFailed({error})))
        );

    @Effect()
    removeWorkflow$ = this.actions$
        .pipe(
            ofType(RemoveWorkflow),
            switchMap(({workflow}) => this.store.pipe(
                select(fromRoot.selectTriggersByWorkflow(workflow)),
                take(1),
                map((triggers) => ({triggers, workflow}))
            )),
            switchMap((payload: {triggers: Trigger[], workflow: Workflow}) => {
                const toUpdate = this.removeWorkflow(payload.triggers, payload.workflow);
                return this.triggers.updateBatch(toUpdate);
            }),
            map(result => R.flatten(<any>result)),
            switchMap((triggers: Trigger[]) => {
                this.toast.success('workflowRemoved');
                return of(UpdateTriggersComplete({triggers}));
            }),
            catchError(error => of(ActionFailed({error})))
        );

    @Effect()
    renameWorkflow$ = this.actions$
        .pipe(
            ofType(RenameWorkflow),
            switchMap(({workflow, name}) => this.store.pipe(
                select(fromRoot.selectTriggersByWorkflow(workflow)),
                take(1),
                map(triggers => ({triggers, workflow, name}))
            )),
            switchMap(({triggers, workflow, name}) => {
                const toUpdate = this.renameWorkflow(triggers, workflow, name);
                return this.triggers.updateBatch(toUpdate);
            }),
            map(result => <Trigger[]>R.flatten(<any>result)),
            switchMap((triggers: Trigger[]) => {
                this.toast.success('workflowRenamed');
                return of(UpdateTriggersComplete({triggers}));
            }),
            catchError(error => of(ActionFailed({error})))
        );

  @Effect()
  createTriggersToWorkflow = this.actions$
    .pipe(
      ofType(CreateTriggersForWorkflow),
      map(({triggers, workflow}) => {

        let triggersToUpdate = R.filter((t: Trigger) => t.rentalId === workflow.rentalId, triggers);
        let triggersToCreate = R.filter((t: Trigger) => t.rentalId !== workflow.rentalId, triggers);

        let triggersCreateRequests: TriggersCreateRequest[] = <any>R.map((t: Trigger) => t.toCreateMulti(), triggersToCreate);
        R.forEach(request => {
          let workflows = R.map(R.compose(R.toLower, R.join('_'), R.split(' '), R.trim))([workflow.name]);
          request.attributes = R.merge(request.attributes, {workflows});
          request.rentals = [workflow.rentalId];
        }, triggersCreateRequests);

        let triggersUpdateRequests: TriggerSaveRequest[] = <any>R.map((t: Trigger) => t.toUpdate(), triggersToUpdate);
        R.forEach(request => {
          let workflows = R.map(R.compose(R.toLower, R.join('_'), R.split(' '), R.trim))([workflow.name]);
          request.attributes = R.merge(request.attributes, {workflows});
        }, triggersUpdateRequests);

                return {
                    updateRequests: triggersUpdateRequests,
                    createRequests: triggersCreateRequests
                };
            }),
            switchMap((payload: {updateRequests: TriggerSaveRequest[], createRequests: TriggersCreateRequest[]}) => {
                return this.createTriggers(payload.createRequests)
                    .pipe(
                        map(createdTriggers => ({createdTriggers, updateRequests: payload.updateRequests})),
                        catchError(error => of(ActionFailed({error})))
                    );
            }),
            switchMap((payload: {updateRequests: TriggerSaveRequest[], createdTriggers: Trigger[]}) => {
                return this.updateTriggers(payload.updateRequests)
                    .pipe(
                        map(updatedTriggers => ({updatedTriggers, createdTriggers: payload.createdTriggers})),
                        catchError(error => of(ActionFailed({error})))
                    );
            }),
            tap(() => this.toast.success('triggersToWorkflows')),
            switchMap((payload: {updatedTriggers: Trigger[], createdTriggers: Trigger[]}) => {
                return [
                    CreateTriggersComplete({triggers: payload.createdTriggers}),
                    UpdateTriggersComplete({triggers: payload.updatedTriggers})
                ];
            }),
            catchError(error => of(ActionFailed({error})))
        );

  createTriggers(requests: TriggersCreateRequest[]): Observable<Trigger[]> {
    if (R.isEmpty(requests)) {
      return of([]);
    }
    let createdTriggers: Trigger[] = [];
    return forkJoin(
      from(requests)
        .pipe(
          concatMap((request) => this.triggers
            .createMany(request)
            .pipe(
              map(triggers => {
                createdTriggers = R.concat(triggers, createdTriggers);
                return createdTriggers;
              })
            ))
        )
    )
      .pipe(
        map(triggers => <any>R.flatten(triggers))
      );
  }

  updateTriggers(requests: TriggerSaveRequest[]): Observable<Trigger[]> {
    if (R.isEmpty(requests)) {
      return of([]);
    }
    let updatedTriggers: Trigger[] = [];
    return forkJoin(
      from(requests)
        .pipe(
          concatMap((triggerUpdateRequest: TriggerSaveRequest) => {
            return this.triggers.update(triggerUpdateRequest)
              .pipe(
                map(trigger => {
                  updatedTriggers = R.append(trigger, updatedTriggers);
                  return updatedTriggers;
                })
              );
          })
        )
    )
      .pipe(
        map(triggers => <any>R.flatten(triggers))
      );
  }

  constructor(private actions$: Actions,
              private store: Store<fromRoot.State>,
              private triggers: TriggersService,
              private toast: ToastService,
              private templates: TemplatesService) {
  }
}
