import {Routes} from '@angular/router';
import {WorkflowsComponent} from './app/containers/workflows/workflows.component';
import {BookingsPageComponent} from '@automata/containers/bookings-page/bookings-page.component';
import {TemplatesComponent} from './app/containers/templates/templates.component';
import {TriggersComponent} from './app/containers/triggers/triggers.component';
import {LoginComponent} from './app/containers/login/login.component';
import {AuthGuard} from './app/guards/auth.guard';
import {LoginGuard} from './app/guards/login.guard';
import {IntegrationsComponent} from './app/containers/integrations/integrations.component';
import {ActivityComponent} from './app/containers/activity/activity.component';
import {TemplatesGuard} from './app/guards/templates.guard';
import {TriggersGuard} from './app/guards/triggers.guard';
import {RentalsGuard} from './app/guards/rentals.guard';
import {ChannelsGuard} from './app/guards/channels.guard';
import {UsersGuard} from './app/guards/users.guard';
import {BookingGuard} from '@automata/containers/bookings-page/store/booking.guard';
import {ActivitiesGuard} from './app/guards/activities.guard';
import {AccountComponent} from './app/containers/account/account.component';
import {AccountGuard} from './app/guards/account.guard';
import {ApiKeysGuard} from './app/guards/api-keys-guard';
import {AuthorizeComponent} from './app/containers/authorize/authorize.component';
import {NotificationsGuard} from './app/guards/notifications.guard';
import {AdminGuard} from './app/guards/admin.guard';
import {CodesComponent} from './app/containers/codes/codes.component';
import {CodesGuard} from './app/guards/codes.guard';
import {SampleGuard} from './app/guards/samples.guard';
import {BillingComponent} from './app/containers/billing/billing.component';
import {ContractsGuard} from './app/guards/contracts.guard';
import {SubscriptionsGuard} from '@automata/guards/subscriptions.guard';

export const routes: Routes = [
  {
    path:       '',
    pathMatch:  'full',
    redirectTo: '/activity'
  },
  {
    path:        'workflows',
    component:   WorkflowsComponent,
    canActivate: [AuthGuard, TriggersGuard, SubscriptionsGuard, BookingGuard, RentalsGuard, ChannelsGuard, UsersGuard, TemplatesGuard, CodesGuard, SampleGuard, ContractsGuard]
  },
  {
    path:        'triggers',
    component:   TriggersComponent,
    canActivate: [AuthGuard, TriggersGuard, SubscriptionsGuard, BookingGuard, TemplatesGuard, RentalsGuard, ChannelsGuard, UsersGuard, ActivitiesGuard, CodesGuard, SampleGuard, ContractsGuard]
  },
  {
    path:        'codes',
    component:   CodesComponent,
    canActivate: [AuthGuard, CodesGuard, SubscriptionsGuard, RentalsGuard, UsersGuard]
  },
  {
    path:        'bookings',
    component:   BookingsPageComponent,
    canActivate: [AuthGuard, BookingGuard, SubscriptionsGuard, TemplatesGuard, UsersGuard, TriggersGuard, RentalsGuard, ChannelsGuard, CodesGuard, ContractsGuard]
  },
  {
    path:        'integrations',
    component:   IntegrationsComponent,
    canActivate: [AuthGuard, AccountGuard, ApiKeysGuard]
  },
  {
    path:        'activity',
    component:   ActivityComponent,
    canActivate: [AuthGuard, RentalsGuard, SubscriptionsGuard, ActivitiesGuard, NotificationsGuard, TemplatesGuard, ChannelsGuard, CodesGuard, TriggersGuard, BookingGuard, ContractsGuard, UsersGuard]
  },
  {
    path:        'billing',
    component:   BillingComponent,
    canActivate: [AuthGuard]
  },
  {
    path:        'account',
    component:   AccountComponent,
    canActivate: [AuthGuard, AdminGuard, AccountGuard]
  },
  {
    path:      'ifttt/oauth2/authorize',
    component: AuthorizeComponent
  },
  {
    path:        'login',
    component:   LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path:        'templates',
    component:   TemplatesComponent,
    canActivate: [AuthGuard, TemplatesGuard, SubscriptionsGuard, TriggersGuard, BookingGuard, CodesGuard, SampleGuard, ContractsGuard]
  },
  // {
  //   path:     'wizard',
  //   canLoad:  [AuthGuard],
  //   children: [
  //     {
  //       path:        'landing',
  //       component:   WizardLandingComponent,
  //       canActivate: [TriggersGuard, WizardLandingGuard, TemplatesGuard, ContractsGuard, RentalsGuard, ChannelsGuard, UsersGuard, CodesGuard]
  //     },
  //     {
  //       path:        'existing',
  //       component:   WizardExistingComponent,
  //       canActivate: [RentalsGuard, ActivitiesGuard, NotificationsGuard, TemplatesGuard, ChannelsGuard, CodesGuard, TriggersGuard, InquiriesGuard, ContractsGuard, UsersGuard]
  //     },
  //     {
  //       path:        'activity/:id',
  //       component:   WizardActivityComponent,
  //       canActivate: [TriggersGuard, TemplatesGuard, ContractsGuard, RentalsGuard, ChannelsGuard, UsersGuard, CodesGuard]
  //     },
  //     {
  //       path:        'steps',
  //       component:   WizardComponent,
  //       canActivate: [RentalsGuard, ChannelsGuard, UsersGuard, TemplatesGuard, ContractsGuard, TriggersGuard, CodesGuard, SampleGuard],
  //       children:    [
  //         {
  //           path:      'event',
  //           component: WizardStepEventComponent
  //         },
  //         {
  //           path:      'rental',
  //           component: WizardStepRentalComponent
  //         },
  //         {
  //           path:      'handler',
  //           component: WizardStepHandlerComponent
  //         },
  //         {
  //           path:      'template',
  //           component: WizardStepTemplateComponent
  //         },
  //         {
  //           path:      'subject',
  //           component: WizardStepSubjectComponent
  //         },
  //         {
  //           path:      'body',
  //           component: WizardStepBodyComponent
  //         },
  //         {
  //           path:      'delivery',
  //           component: WizardStepDeliveryComponent
  //         },
  //         {
  //           path:      'recipients',
  //           component: WizardStepRecipientsComponent
  //         }
  //       ]
  //     }
  //   ]
  // }
];
