<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <button (click)="close()" class="cstm-close" data-dismiss="modal" aria-label="Close">
        <span class="far fa-times"></span>
      </button>

      <div class="header-box">
        <div>
          <h5 class="modal-title">
            {{'pageElements.createWorkflow' | translate}}
          </h5>
        </div>
        <div>
          <button [disabled]="form.invalid" (click)="createWorkflow()" type="button"
                  class="btn btn-cstm-info left-icon text-uppercase">
            <span class="far fa-plus"></span> {{'buttons.create' | translate}}
          </button>
        </div>
      </div>
    </div>
    <div class="modal-body work-detail-modal-content">
      <form [formGroup]="form">
        <div class="row">
          <div class="col-sm-4">
            <app-select-search [items]="triggers$ | async"
                               [ctrl]="form.get('triggers')"
                               [multiple]="true"
                               [hasBlank]="true"
                               [placeholder]="'formControls.selectTriggers2' | translate"></app-select-search>
          </div>
          <div class="col-sm-4">
            <app-select-search [items]="rentals$ | async"
                               [ctrl]="form.get('rental')"
                               [multiple]="false"
                               [hasBlank]="false"
                               [placeholder]="'formControls.selectARental' | translate"></app-select-search>
          </div>
          <div class="col-sm-4">
            <mat-form-field>
              <input matInput [placeholder]="'formControls.nameYourWorkflow' | translate"
                     formControlName="name" [required]="true">
              <mat-error *ngIf="form.get('name').hasError('required')">
                <span [innerHtml]="'validations.nameRequired' | translate"></span>
              </mat-error>
              <mat-error *ngIf="form.get('name').hasError('maxlength')">
                <span [innerHtml]="'validations.nameAtMost50' | translate"></span>
              </mat-error>
              <mat-hint align="end">{{form.get('name').value.length || 0}}/50</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </form>

      <div class="row">
        <div class="col-sm-8 col-sm-offset-2 workflow-holder">
          <app-workflow-detail-trigger *ngFor="let trigger of selected;let isOdd = odd;"
                                       [type]="type"
                                       [workflowName]="'Workflow Name'"
                                       [trigger]="trigger"
                                       [isOdd]="isOdd"
                                       (open)="onOpen($event)"></app-workflow-detail-trigger>
        </div>
      </div>
    </div>
  </div>
</div>
