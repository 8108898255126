import {Pipe, PipeTransform} from '@angular/core';
import {Notification} from '../models/notification';
import * as R from 'ramda';

@Pipe({
  name: 'notificationBoxClass'
})
export class NotificationBoxClassPipe implements PipeTransform {

  transform(item: Notification, args?: any): string {
    if (R.isNil(item)) {
      return '';
    }
    if (item.link_type === 'inquiry') {
      return 'feed-box-inquiry';
    } else if (item.link_type === 'invoice' && item.action === 'paid') {
      return 'feed-box-paid';
    } else if (item.link_type === 'booking' && item.action === 'confirmed') {
      return 'feed-box-booked';
    } else if (item.link_type === 'booking' && item.action === 'cancelled') {
      return 'feed-box-cancelled';
    } else if (item.link_type === 'contract' && item.action === 'signed') {
      return 'feed-box-signed';
    } else if (item.link_type === 'contract' && item.action === 'rejected') {
      return 'feed-box-rejected';
    } else {
      return 'feed-box-all';
    }
  }

}
