export enum TriggerHandler {
  SendEmailMessage = 'Handlers::EmailHandler',
  SendContract = 'Handlers::ContractHandler',
  SendDocument = 'Handlers::DocumentHandler',
  SendForm = 'Handlers::FormHandler',
  SendSMSMessage = 'Handlers::SMSHandler',
  SendHTTPPost = 'Handlers::WebhookHandler',
  SendPushNotification = 'Handlers::MobilePushHandler',
  SendFollowUp = 'Handlers::FollowupHandler',
  RemoveSendFollowUp = 'Handlers::RemoveFollowupHandler',
  AddTagHandler = 'Handlers::AddTagHandler',
  RemoveTagHandler = 'Handlers::RemoveTagHandler',
}
