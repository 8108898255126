import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {TemplateType} from '../../../enums/template-type';
import {FormControl, FormGroup} from '@angular/forms';
import {forEach, isEmpty, isNil, path} from 'ramda';
import {Counter} from '../../../../lib/quill/modules/counter';
import {Validations} from '../../../models/validations';
import {AttachmentResponse} from '../../../interfaces/attachment-response';
import {MatDialog} from '@angular/material/dialog';
import {AttachmentDialogComponent} from '../attachment-dialog/attachment-dialog.component';
import {DictionaryDialogComponent} from '../dictionary-dialog/dictionary-dialog.component';
import {isSomething} from '../../../utility/functions/is-something';
import {distinctUntilChanged} from 'rxjs/operators';
import {UtilityService} from '../../../services/utility.service';
import {isHTML} from '../../../utility/functions/is-html';
import {PreviewTemplateDialogService} from '../preview-template/preview-template-dialog.service';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

declare const require: any;
declare const document: any;

const get = require('lodash/get');
const random = require('lodash/random');

let Quill = require('quill');

let IndentStyle = require('../../../../lib/quill/attributors/indent/index');

Quill.register('modules/counter', Counter);
const icons = Quill.import('ui/icons');

let AlignStyle = Quill.import('attributors/style/align');
let BackgroundStyle = Quill.import('attributors/style/background');
let ColorStyle = Quill.import('attributors/style/color');
let DirectionStyle = Quill.import('attributors/style/direction');
let FontStyle = Quill.import('attributors/style/font');
let SizeStyle = Quill.import('attributors/style/size');
let LinkFormat = Quill.import('formats/link');

Quill.register(AlignStyle, true);
Quill.register(BackgroundStyle, true);
Quill.register(ColorStyle, true);
Quill.register(DirectionStyle, true);
Quill.register(FontStyle, true);
Quill.register(SizeStyle, true);
Quill.register(IndentStyle, true);
Quill.register(LinkFormat, true);


icons['undo'] = `<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon><path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path></svg>`;
icons['redo'] = `<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon><path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path></svg>`;
icons['dictionary'] = `<i class="tippy fas fa-book dictionary-icon" aria-hidden="true"></i>`;
icons['attachment'] = `<i class="tippy fas fa-paperclip attachment-icon" aria-hidden="true"></i>`;

@Component({
  selector:    'app-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls:   ['./template-editor.component.scss']
})
export class TemplateEditorComponent extends OnDestroyMixin implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  editorId = `editor-${random(1000, 9999)}`;

  charLabelId = `charLabel-${random(1000, 9999)}`;
  charCountId = `charCount-${random(1000, 9999)}`;
  wordLabelId = `wordLabel-${random(1000, 9999)}`;
  wordCountId = `wordCount-${random(1000, 9999)}`;
  limitLabelId = `limitLabel-${random(1000, 9999)}`;
  limitCountId = `limitCount-${random(1000, 9999)}`;

  @Input() type: FormControl;
  @Input() subject: FormControl;
  @Input() form: FormGroup;
  @Input() content: string;

  @Input() showCount = true;
  @Input() showNote = true;

  @ViewChild('editor', {static: true}) editor;
  plainText = '';

  types = TemplateType;

  quill: any;

  isTouched = false;

  lastRange: any;

  isPlainTextMode = false;
  forcePlainTextMode = false;

  constructor(public dialog: MatDialog,
              private elRef: ElementRef,
              private previewTemplateDialogService: PreviewTemplateDialogService,
              private utility: UtilityService) {
    super()
  }

  ngOnInit() {

    /**
     * Initiates editors for the first time and determines if plain text mode is active and exclusive (SMS type is plain text only)
     *
     */
    if (isSomething(this.content)) {
      this.plainText = this.content;
      this.isPlainTextMode = this.type.value === TemplateType.Payload || this.type.value === TemplateType.PushNotification || !isHTML(this.content);
      this.maybeForcePlainText(this.type.value);
      this.initEditor(this.type.value, this.content);
    } else {
      this.isPlainTextMode = this.type.value === TemplateType.Payload || this.type.value === TemplateType.PushNotification;
      this.maybeForcePlainText(this.type.value);
      this.initEditor(this.type.value);
    }

    this.type.valueChanges
      .pipe(
        distinctUntilChanged(),
        untilComponentDestroyed(this)
      )
      .subscribe((type) => {
        this.plainText = this.form.get('body').value;
        if (!this.isPlainTextMode) {
          this.isPlainTextMode = this.type.value === TemplateType.Payload || this.type.value === TemplateType.PushNotification;
        }
        this.maybeForcePlainText(this.type.value);
        this.initEditor(type, this.form.get('body').value);
      });
  }

  ngAfterViewInit() {
    this.editor.setTheme('textmate');
  }

  ngOnDestroy() {
    if (this.quill) {
      this.quill.off('text-change', this.onRichTextChange);
      this.quill.off('selection-change', this.blurHandler.bind(this));
    }
    this.quill = null;
    super.ngOnDestroy()
  }

  ngOnChanges(changes: SimpleChanges) {

    const content: string = path(['content', 'currentValue'], changes);

    if (isSomething(content)) {
      this.plainText = content;
      this.initEditor(this.type.value, content);
    }
  }

  maybeForcePlainText(type: TemplateType) {
    this.forcePlainTextMode = type === TemplateType.PushNotification;
  }

  togglePlainTextMode() {
    if (this.isPlainTextMode) {
      let paste = this.quill.clipboard.convert(this.plainText || '');
      this.quill.setContents(paste);
    } else {
      this.plainText = this.quill.root.innerHTML;
    }
    this.isPlainTextMode = !this.isPlainTextMode;
  }

  openDictionary() {
    this.lastRange = this.quill.getSelection();

    let dialogRef = this.dialog.open(DictionaryDialogComponent, {
      width:         '880px',
      height:        '470px',
      backdropClass: ''
    });

    dialogRef.afterClosed().pipe(untilComponentDestroyed(this)).subscribe((tag) => {
      this.addTag(tag);
    });
  }

  openUpload() {
    this.lastRange = this.quill.getSelection();
    let dialogRef = this.dialog.open(AttachmentDialogComponent, {
      width:         '600px',
      backdropClass: ''
    });

    dialogRef.afterClosed().pipe(untilComponentDestroyed(this)).subscribe((result: AttachmentResponse[]) => {
      this.insertLink(result);
    });
  }

  openImageUpload() {
    this.lastRange = this.quill.getSelection();
    let dialogRef = this.dialog.open(AttachmentDialogComponent, {
      width:         '600px',
      backdropClass: ''
    });

    dialogRef.afterClosed().pipe(untilComponentDestroyed(this)).subscribe((result: AttachmentResponse[]) => {
      this.insertLink(result);
    });
  }

  insertLink(attachmentResponse: AttachmentResponse[]) {

    let attachments = '';
    let body = '';

    forEach((file) => {
      attachments += `<p>&#128449;&nbsp;&nbsp;<a href="${file.url}?guest_id=*|GUEST:guest_id|*" target="_blank" id="${file.pkey}" class="attachment">${file.name}</a></p>`;
    }, attachmentResponse || []);

    if (attachments.length > 0) {
      body += `<div class="attachments">${attachments}</div>`;
    }

    this.quill.clipboard.dangerouslyPasteHTML(this.lastRange.index + 1, body);
  }

  addTag(tag: string) {
    if (isEmpty(tag) || isNil(tag)) {
      return;
    }
    if (this.isPlainTextMode) {
      this.plainText += tag;
      this.editor.getEditor().focus();
    } else {
      let range = this.lastRange;
      if (range) {
        if (range.length == 0) {
          this.quill.insertText(range.index, tag);
        } else {
          this.quill.deleteText(range.index, range.length);
          this.quill.insertText(range.index, tag);
        }
      } else {
        this.quill.insertText(this.quill.getLength(), tag);
      }
      this.quill.focus();
      if (range) {
        this.quill.setSelection(range.index + tag.length, 0);
      }
    }
  }

  focusEditor(event) {
    if (this.quill && get(event, 'target.classList').contains('ql-container')) {
      this.quill.focus();
    }
  }

  initEditor(type: TemplateType, content = '') {
    this.cleanEditorElements();
    setTimeout(() => {
      const editorElement = document.getElementById(this.editorId);
      if (!isSomething(editorElement)) {
        return;
      }
      this.quill = new Quill(`#${this.editorId}`, {
        theme:   'snow',
        modules: this.getEditorModules(type)
      });
      if (this.quill.clipboard) {
        let paste = this.quill.clipboard.convert(content || '');
        this.quill.setContents(paste);
      }
      this.form.patchValue({body: content || ''});
      this.isTouched = false;

      if (this.quill && this.quill.container) {
        this.quill.on('text-change', this.onRichTextChange.bind(this));
        this.quill.on('selection-change', this.blurHandler.bind(this));
      }
      this.utility.showTippyTooltipsOnTemplateEditor();
    }, 0);
  }

  onPlainTextChange(text) {
    this.form.patchValue({body: text});
  }

  onRichTextChange() {
    const html = this.quill.root.innerHTML;
    this.form.patchValue({body: html});
  }

  blurHandler(range, oldRange, source) {
    if (range === null && oldRange !== null) {
      this.isTouched = true;
    }
  }

  getEditorModules(type: TemplateType) {

    let modules = {
      toolbar: this.getEditorToolbarOptions(type)
    };

    modules['counter'] = {
      charLabel:  `#${this.charLabelId}`,
      charCount:  `#${this.charCountId}`,
      wordLabel:  `#${this.wordLabelId}`,
      wordCount:  `#${this.wordCountId}`,
      limitLabel: `#${this.limitLabelId}`,
      limitCount: `#${this.limitCountId}`,
      limit:      type === TemplateType.PushNotification ? Validations.SMSLimit : null
    };

    return modules;
  }

  getEditorToolbarOptions(type: TemplateType) {
    const handlers = {
      dictionary: () => {
        this.openDictionary();
      },
      undo:       () => {
        (<any>this.quill).history.undo();
      },
      redo:       () => {
        (<any>this.quill).history.redo();
      }
    };

    if (type === this.types.Email || type === this.types.Payload) {
      handlers['attachment'] = () => {
        this.openUpload();
      };
    }

    switch (type) {
      case TemplateType.Email:
      case TemplateType.Payload:
        return {
          container: [
            [
              {
                'header': [
                  1,
                  2,
                  3,
                  4,
                  false
                ]
              }
            ],
            [
              'bold',
              'italic',
              'underline',
              'strike',
              {'list': 'ordered'},
              {'list': 'bullet'}
            ],

            [
              {'indent': '-1'},
              {'indent': '+1'}
            ],
            [{'direction': 'rtl'}],

            [{'align': []}],

            ['clean'],
            [
              'undo',
              'redo'
            ],
            [
              'dictionary',
              'attachment'
            ],
            [
              'link',
              'image'
            ]
          ],
          handlers:  handlers
        };
      case TemplateType.PushNotification:
        return {
          container: [
            [
              'undo',
              'redo'
            ],
            ['clean'],
            ['dictionary']
          ],
          handlers:  handlers
        };
    }
  }

  cleanEditorElements() {
    const elements = this.elRef.nativeElement.getElementsByClassName('ql-toolbar');
    setTimeout(() => {
      while (elements.length > 0) {
        if (elements[0].parentNode) {
          elements[0].parentNode.removeChild(elements[0]);
        }
      }
    }, 0);
  }

  onPreviewEmail() {
    this.previewTemplateDialogService.openPreview(this.form.get('body').value, this.subject.value, this.type.value)
  }
}
