<div class="main-body clearfix">
  <div class="container-fluid">
    <!-- Title & buttons -->
    <div class="d-flex justify-content-between title-holder">
      <div class="title">
        {{'pageTitle.triggers' | translate}}
        <span [matTooltip]="'pageTooltip.triggers' | translate"
              matTooltipPosition="above"
              matTooltipClass="text-center">
                    <i class="far info-icon fa-question-circle"></i>
                </span>
        <app-tutorial-opener [type]="'triggers'"></app-tutorial-opener>
      </div>
      <app-inactive-subscription></app-inactive-subscription>
      <div class="btn-holder">
        <div dropdown class="btn-group">
          <mat-slide-toggle
            [matTooltip]="skipped ? ('tooltips.accountResume' | translate) : ('tooltips.accountPause' | translate)"
            matTooltipPosition="above"
            matTooltipClass="text-center"
            [checked]="!skipped"
            (change)="toggleTriggers()"
            class="pull-left mat-slide-secondary global-toggle">{{skipped ? ('pageElements.accountPaused' | translate) : ('pageElements.accountActive' | translate)}}
          </mat-slide-toggle>

          <button dropdownToggle type="button"
                  class="btn btn-cstm-info right-icon text-uppercase dropdown-toggle">
            {{'buttons.actions' | translate}}
            <i class="far dropdown-icon fa-angle-down" aria-hidden="true"></i>
          </button>
          <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
            <li role="menuitem">
              <button *ngIf="selected | isSomething" (click)="addToWorkflow()" class="dropdown-item">
                {{'buttons.addToWorkflow' | translate}}
              </button>
              <span *ngIf="!(selected | isSomething)"
                    [matTooltip]="'tooltips.firstSelectTemplates' | translate"
                    matTooltipPosition="above"
                    matTooltipClass="text-center">
                                <button [disabled]="true"
                                        class="dropdown-item">
                                    {{'buttons.addToWorkflow' | translate}}
                                </button>
                            </span>
            </li>
          </ul>
        </div>

        <button *ngIf="isAnySelected()" (click)="confirmDelete()" type="button"
                class="btn btn-cst-danger left-icon text-uppercase">
          <span class="far fa-trash-alt"></span> {{'buttons.delete' | translate}}
        </button>
        <div *ngIf="!isAnySelected()"
             [matTooltip]="'tooltips.noTriggersSelected' | translate"
             matTooltipPosition="above"
             matTooltipClass="text-center"
             class="delete-button-wrapper">
          <button [disabled]="true" (click)="confirmDelete()" type="button"
                  class="btn btn-cst-danger left-icon text-uppercase">
            <span class="far fa-trash-alt"></span> {{'buttons.delete' | translate}}
          </button>
        </div>
        <div dropdown class="btn-group">
          <button dropdownToggle type="button"
                  class="btn btn-cstm-info left-icon text-uppercase dropdown-toggle">
            <span class="far fa-plus"></span> {{'buttons.new' | translate}}
          </button>
          <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
            <li role="menuitem">
              <a [matTooltip]="'tooltips.triggerCommonSamples' | translate"
                 matTooltipPosition="left"
                 matTooltipClass="text-center"
                 class="dropdown-item" (click)="openSamples()">
                {{'buttons.startWithSample' | translate}}
              </a>
              <a [matTooltip]="'tooltips.triggerFromScratch' | translate"
                 matTooltipPosition="left"
                 matTooltipClass="text-center"
                 class="dropdown-item" (click)="openNew()">
                {{'buttons.startWithBlank' | translate}}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Content Holder -->
    <div class="content-holder clearfix">
      <!-- input fields -->
      <div class="clearfix triggers-page input-holder-top">
        <div class="col-sm-9">
          <div class="row">
            <div class="col-sm-2">
              <app-select-search [matTooltip]="'tooltips.triggerRentalFilter' | translate"
                                 matTooltipPosition="above"
                                 matTooltipClass="text-center"
                                 [items]="rentals$ | async"
                                 [ctrl]="rentalsCtrl"
                                 [placeholder]="'formControls.rentals' | translate"></app-select-search>
            </div>
            <div class="col-sm-2">
              <app-select-search [matTooltip]="'tooltips.triggerChannelFilter' | translate"
                                 matTooltipPosition="above"
                                 matTooltipClass="text-center"
                                 bindValue="token"
                                 bindLabel="friendlyName"
                                 [items]="channels$ | async"
                                 [ctrl]="channelsCtrl"
                                 [placeholder]="'formControls.channels' | translate"></app-select-search>
            </div>
            <div class="col-sm-2">
              <app-select-search [matTooltip]="'tooltips.triggerTemplateFilter' | translate"
                                 matTooltipPosition="above"
                                 matTooltipClass="text-center"
                                 [items]="templates$ | async"
                                 [ctrl]="templatesCtrl"
                                 [placeholder]="'formControls.templates' | translate"></app-select-search>
            </div>
            <div class="col-sm-2">
              <app-select-search [matTooltip]="'tooltips.triggerEventFilter' | translate"
                                 matTooltipPosition="above"
                                 matTooltipClass="text-center"
                                 bindValue="value"
                                 bindLabel="name"
                                 [items]="events"
                                 [ctrl]="eventsCtrl"
                                 [placeholder]="'formControls.events' | translate"></app-select-search>
            </div>
            <div class="col-sm-2">
              <app-select-search [matTooltip]="'tooltips.triggerActionFilter' | translate"
                                 matTooltipPosition="above"
                                 matTooltipClass="text-center"
                                 bindValue="value"
                                 bindLabel="name"
                                 [items]="actions"
                                 [ctrl]="actionsCtrl"
                                 [placeholder]="'formControls.actions' | translate"></app-select-search>
            </div>
            <div class="col-sm-2">
              <mat-form-field>
                <mat-select [matTooltip]="'tooltips.triggerStatusFilter' | translate"
                            matTooltipPosition="above"
                            matTooltipClass="text-center"
                            [formControl]="statusCtrl" [placeholder]="'formControls.status' | translate">
                  <mat-option *ngFor="let status of statuses" [value]="status.key">
                    {{ status.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-sm-2">
              <app-select-search *ngIf="(tags$ | async)?.length"
                                 [matTooltip]="'tooltips.triggerTagFilter' | translate"
                                 matTooltipPosition="above"
                                 matTooltipClass="text-center"
                                 [items]="tags$ | async"
                                 [ctrl]="tagsCtrl"
                                 [placeholder]="'formControls.tags' | translate"></app-select-search>
            </div>
          </div>
        </div>
        <div class="col-sm-3 edit-align">
          <mat-form-field>
            <input matInput (keyup)="filter$.next($event.target.value)"
                   [placeholder]="'formControls.searchHere' | translate"
                   type="search">
            <span matPrefix>
              <i class="far fa-search"></i>
            </span>
          </mat-form-field>
        </div>
      </div>

      <app-trigger-table [data]="filteredTableData$"
                         [filter]="filter$"
                         (page)="onPagination($event)"
                         (select)="onSelection($event)"
                         (remove)="confirmRemove($event)"
                         (duplicate)="onDuplicate($event)"
                         (edit)="onEdit($event)"
                         (pause)="onPause($event)"
                         (resume)="onResume($event)"
                         (archive)="onArchive($event)"
                         (unarchive)="onUnArchive($event)"></app-trigger-table>

    </div>
  </div>
</div>
