import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormControl} from '@angular/forms';
import * as R from 'ramda';
import {TableColumn} from '../../../interfaces/table-column';
import {TableManagementResult} from '../../../interfaces/table-management-result';
import {TableUtilityService} from '../../../services/table-utility.service';
import {TableManagementDialogParams} from '../../../interfaces/table-management-dialog-params';
import {TableType} from '../../../enums/table-type';

@Component({
  selector:    'app-table-management-dialog',
  templateUrl: './table-management-dialog.component.html',
  styleUrls:   ['./table-management-dialog.component.scss']
})
export class TableManagementDialogComponent<T> implements OnInit {

  columns: TableColumn[];
  type: TableType;

  columnsCtrl = new FormControl();

  constructor(public dialogRef: MatDialogRef<TableManagementDialogComponent<T>>,
              private tableService: TableUtilityService,
              @Inject(MAT_DIALOG_DATA) public data: TableManagementDialogParams) {
  }

  ngOnInit() {
    this.columns = this.data.columns;
    this.type = this.data.type;

    this.ensureFirstAndLastColumnsAreCorrect();

    const existingSelection = R.map((c: any) => c.name, R.filter(c => c.excluded, this.columns));

    this.columnsCtrl.patchValue(existingSelection);
  }

  ensureFirstAndLastColumnsAreCorrect() {
    const selectItem = R.find(c => c.name === 'select', this.columns);
    if (!R.isNil(selectItem)) {
      this.columns = R.reject(c => c.name === 'select', this.columns);
      this.columns = R.prepend(selectItem, this.columns);
    }

    const editItem = R.find(c => c.name === 'edit', this.columns);
    if (!R.isNil(editItem)) {
      this.columns = R.reject(c => c.name === 'edit', this.columns);
      this.columns = R.append(editItem, this.columns);
    }
  }

  close() {
    this.dialogRef.close();
  }

  reset() {
    this.columns = this.tableService.availableColumns[this.type];
    const toSelect = R.map(((col: any) => col.name), R.filter((c) => c.excluded, this.columns));
    this.columnsCtrl.patchValue(toSelect);
  }

  submit() {
    const selection = this.columnsCtrl.value || [];

    const available = R.map(c => ({...c, excluded: R.contains(c.name, selection)}), this.columns);
    const displayed = R.map((c: TableColumn) => c.name, R.filter((a: TableColumn) => !a.excluded, available));

    const result = {
      available: available,
      displayed: displayed
    } as TableManagementResult;

    this.dialogRef.close(result);
  }
}
