import {Component, HostBinding, OnInit} from '@angular/core';
import {PlanService} from '@automata/services/plan.service';
import {Store} from '@ngrx/store';
import {isAutomataActive} from '@automata/reducers';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-inactive-subscription',
  template: `
                  <div class="alert alert-warning">
                      <i class="fal fa-exclamation-circle"></i><strong>You are not currently subscribed to Automata. Triggers will not fire until you subscribe.</strong></div>
              `,
  styles:   [
    `
            :host {
                display: block;
            }

            .alert {
                margin: 0;
            }

            strong {
                font-size: 11px;
            }

            .svg-inline--fa {
                margin-right: 10px;
                font-size: 16px;
            }
        `
  ]
})
export class InactiveSubscriptionComponent extends OnDestroyMixin implements OnInit {

  @HostBinding('class.hidden') isHidden = true;

  constructor(private planService: PlanService,
              private store: Store<any>) {
    super()
  }

  ngOnInit() {
    this.store.pipe(
      isAutomataActive,
      untilComponentDestroyed(this)
    )
      .subscribe(isActive => {
        this.isHidden = isActive;
      });
  }
}
