import {SignatureTemplateResponseItem} from '../interfaces/signature-template-response';
import * as moment from 'moment';
import {SignatureTemplateType} from '../types/signature-template-type';

export class SignatureTemplate {

  name: string;
  description: string;
  userId: string;
  account: number;
  id: string;
  updatedFormatted: string;
  createdFormatted: string;
  type: SignatureTemplateType;

  constructor(template?: SignatureTemplate) {
    if (template) {
      Object.assign(<SignatureTemplate>this, template);
    }
  }

  fromResponse(response: SignatureTemplateResponseItem) {
    this.name = response.name;
    this.description = response.description;
    this.userId = response.user_id;
    this.account = parseFloat(response.account);
    this.id = response.template_id;
    this.updatedFormatted = response.updated ? moment.unix(response.updated).format('DD - MMM - YYYY | h:mm A') : '';
    this.createdFormatted = response.created ? moment.unix(response.created).format('DD - MMM - YYYY | h:mm A') : '';
    this.type = response.type;
  }

}
