import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutSidebarComponent} from '@automata/layout/sidebar/sidebar.component';

const modules = [
  CommonModule,
];

export const components = [
  LayoutSidebarComponent
];

export const dialogs = [];

export const services = [];

export const pipes = [

];

export const directives = [];

export const declarations = [
  ...pipes,
  ...dialogs,
  ...components,
  ...directives
];

@NgModule({
  imports: [
    ...modules
  ],
  declarations,
  providers:       [
    ...services
  ],
  entryComponents: [
    ...dialogs
  ],
  exports:         [
    ...declarations,
    ...modules
  ]
})
export class LayoutSidebarModule {
}
