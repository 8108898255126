import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, map, switchMapTo} from 'rxjs/operators';
import {ActionFailed} from '../actions/utility.actions';
import {PlanService} from '@automata/services/plan.service';
import {LoadSubscriptions, LoadSubscriptionsComplete} from '@automata/actions/subscription.actions';

@Injectable()
export class SubscriptionsEffects {

  @Effect()
  loadSubscriptions$ = this.actions$
    .pipe(
      ofType(LoadSubscriptions),
      switchMapTo(
        this.plans
          .all()
          .pipe(
            map(subscriptions => LoadSubscriptionsComplete({subscriptions})),
            catchError(error => of(ActionFailed({error})))
          )
      )
    );

  constructor(private actions$: Actions,
              private plans: PlanService) {
  }
}
