import {Pipe, PipeTransform} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '@automata/reducers';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Pipe({
  name: 'triggerName'
})
export class TriggerNamePipe implements PipeTransform {

  transform(triggerId: string): Observable<string> {
    return this.store.pipe(
      select(fromRoot.selectTriggerById(triggerId)),
      map(trigger => trigger.name)
    );
  }

  constructor(private store: Store<fromRoot.State>) {
  }
}
