import {Injectable} from '@angular/core';
import {take} from 'rxjs/operators';
import {PreviewTemplateDialogComponent} from '@automata/components/template/preview-template/preview-template-dialog.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {TemplateType} from '@automata/enums/template-type';

@Injectable()
export class PreviewTemplateDialogService {

  constructor(private dialog: MatDialog) {
  }

  public openPreview(content: string, subject: string, type: TemplateType) {

    const defaultConfig: MatDialogConfig = {
      width:      '750px',
      height:     `500px`,
      data:       {content, subject, type},
      panelClass: [
        'confirm-modal',
        'modal-confirmation'
      ]
    };

    return this.dialog.open(PreviewTemplateDialogComponent, defaultConfig).afterClosed()
      .pipe(
        take(1)
      )
      .subscribe();
  }
}
