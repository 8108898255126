import {ApiKey} from './api-key';
import {deserialize, Serializable} from '@automata/utility/serializers/serializers';
import {Expose, Type} from 'class-transformer';
import {Address} from '@automata/models/address';

export class AccountDetails extends Serializable<AccountDetails> {
  @Expose({name: 'account'})
  id: number;

  account: number;
  pkey: string;

  @Type(() => Address)
  address: Address;

  created: number;
  email: string;
  name: string;
  phone: string;

  @Expose({name: 'vat_tin'})
  vatTin: number;

  apiKeys: ApiKey[];

  static deserialize(data: object): AccountDetails {
    return deserialize(AccountDetails, data);
  }
}
