import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {ActionFailed} from '../actions/utility.actions';
import {tap} from 'rxjs/operators';
import {ToastService} from '@automata/services/toast.service';

@Injectable()
export class UtilityEffects {

  @Effect({dispatch: false})
  actionFailed$ = this.actions$
    .pipe(
      ofType(ActionFailed),
      tap(({error}) => this.toast.error(null, error))
    );

  constructor(private actions$: Actions,
              private toast: ToastService) {
  }
}
