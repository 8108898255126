import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DialogService} from '../../../services/dialog.service';
import * as fromRoot from '../../../reducers';
import {select, Store} from '@ngrx/store';
import {TriggerDialogComponent} from '../../trigger/trigger-dialog/trigger-dialog.component';
import {Workflow} from '../../../models/workflow';
import * as R from 'ramda';
import {
  ArchiveTrigger,
  DeleteTrigger,
  PauseTrigger,
  PauseTriggers,
  ResumeTrigger,
  ResumeTriggers,
  UnArchiveTrigger
} from '../../../actions/triggers.actions';
import {BehaviorSubject, Subscription} from 'rxjs';
import {delay} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {TriggerEvents} from '../../../models/trigger-events';
import {Trigger} from '../../../models/trigger';
import {TriggersService} from '../../../services/triggers.service';
import {WorkflowDetailType} from '../../../enums/workflow-detail-type';
import {TriggerSample} from '@automata/models/samples/trigger-sample';
import {CreateWorkflow} from '@automata/actions/samples.actions';
import {TableType} from '@automata/enums/table-type';
import {CreateTriggersForWorkflow} from '@automata/actions/workflows.actions';
import { AmplitudeService } from '@automata/services/amplitude.service';

@Component({
  selector:    'app-workflow-dialog',
  templateUrl: './workflow-dialog.component.html',
  styleUrls:   ['./workflow-dialog.component.scss']
})
export class WorkflowDialogComponent implements OnInit, OnDestroy {

  triggers: Trigger[];
  workflow: Workflow;
  workflowSub: Subscription;

  isList = false;

  tableData$ = new BehaviorSubject<Trigger[]>([]);

  workflowsCtrl = new FormControl('');
  workflowChangeSub: Subscription;

  type = WorkflowDetailType.Workflow;

  tableType = TableType.WorkflowTriggers;

  workflows$ = this.store.pipe(select(fromRoot.selectFormWorkflows));

  constructor(public dialogRef: MatDialogRef<WorkflowDialogComponent>,
              private dialogService: DialogService,
              private triggersService: TriggersService,
              private store: Store<fromRoot.State>,
              private amplitudeService: AmplitudeService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {

    this.workflowsCtrl.patchValue(this.data.workflowId);

    this.workflowSub = this.store
      .select(fromRoot.selectWorkflowById(this.data.workflowId))
      .pipe(
        delay(10)
      )
      .subscribe((workflow: Workflow) => {
        if (R.isNil(workflow)) {
          this.dialogRef.close();
        } else {
          this.workflow = workflow;
          const sortedTriggers = R.sort((a, b) => TriggerEvents.getWeight(a) - TriggerEvents.getWeight(b), workflow.triggers);
          this.triggers = sortedTriggers;
          this.tableData$.next(<Trigger[]>R.map(t => new Trigger(t), sortedTriggers));
        }
      });

    this.workflowChangeSub = this.workflowsCtrl
      .valueChanges
      .subscribe((workflowId) => {
        this.close();
        console.log(`Editing workflow ${workflowId}`);
        this.dialogService.openSide(WorkflowDialogComponent, {
          data: {
            workflowId
          }
        });
      });
  }

  ngOnDestroy() {
    this.workflowSub.unsubscribe();
    this.workflowChangeSub.unsubscribe();
  }

  openSamples() {
    this.dialogService.openTriggerSamples(true).subscribe((sample: TriggerSample) => {
      this.store.dispatch(CreateWorkflow({
        payload: {
          triggers: [sample],
          rentalId: this.workflow.rentalId,
          name:     this.workflow.name
        }
      }));
    });
  }

  addExistingTrigger() {
    this.dialogService.openExistingTriggers(this.workflow)
      .subscribe((triggers: Trigger[]) => {
        this.store.dispatch(CreateTriggersForWorkflow({triggers, workflow: this.workflow}));
      });
  }

  toggleWorkflow() {
    if (this.workflow.status) {
      this.store.dispatch(PauseTriggers({triggers: this.triggers}));
    } else {
      this.store.dispatch(ResumeTriggers({triggers: this.triggers}));
    }
  }

  toggleView() {
    this.isList = !this.isList;
  }

  createTrigger() {
    console.log(`Creating trigger for workflow ${this.workflow.id}`);
    this.dialogService.openSide(TriggerDialogComponent, {data: {workflow: this.workflow}});
  }

  close() {
    this.dialogRef.close();
  }

  onOpen(event) {
    console.log(`Editing trigger ${event.trigger.id}`);
    this.dialogService.openSide(TriggerDialogComponent, {
      data: {
        triggerId: event.trigger.id,
        activeTab: event.tab
      }
    });
  }

  confirmRemove(trigger: Trigger) {
    this.dialogService.openConfirm().subscribe(() => {
      this.onConfirmRemove(trigger);
    });
  }

  onConfirmRemove(trigger: Trigger) {
    this.store.dispatch(DeleteTrigger({id: trigger.id}));
  }

  onDuplicate(trigger: Trigger) {
    console.log(`Duplicating trigger ${trigger.id}`);
    this.dialogService.openSide(TriggerDialogComponent, {
      data: {
        triggerId:   trigger.id,
        isDuplicate: true
      }
    });
  }

  onEdit(trigger: Trigger) {
    console.log(`Editing trigger ${trigger.id}`);
    this.dialogService.openSide(TriggerDialogComponent, {data: {triggerId: trigger.id}});
  }

  onPause(trigger: Trigger) {
    this.store.dispatch(PauseTrigger({id: trigger.id}));
  }

  onResume(trigger: Trigger) {
    this.store.dispatch(ResumeTrigger({id: trigger.id}));
  }

  onArchive(trigger: Trigger) {
    this.store.dispatch(ArchiveTrigger({id: trigger.id}));
  }

  onUnArchive(trigger: Trigger) {
    this.store.dispatch(UnArchiveTrigger({id: trigger.id}));
  }
}
