<div class="row">

  <div [ngClass]="{hidden: isPlainTextMode}" class="col-sm-12">
    <div [attr.id]="editorId" (click)="focusEditor()"></div>
  </div>

  <div [ngClass]="{hidden: !isPlainTextMode}" class="col-sm-12">
    <ace-editor [(text)]="plainText" (textChanged)="onPlainTextChange($event)" [mode]="'text'" #editor
                style="height:300px;"></ace-editor>
  </div>

  <div *ngIf="(control.hasError('required') || control.hasError('isEmptyTrimmed')) && isTouched"
       class="col-sm-12 mt15">
    <p class="mat-error mb0"><strong>{{'pageElements.cantBeEmpty' | translate}}</strong></p>
  </div>

  <div *ngIf="!customCodes" class="col-sm-12">
    <button (click)="togglePlainTextMode()" class="btn btn-default mt15 mb15">
            <span [matTooltip]="'tooltips.editTemplateAsPlainText' | translate"
                  matTooltipPosition="above"
                  matTooltipClass="text-center"
                  [ngClass]="{hidden: isPlainTextMode}">{{'buttons.usePlainText' | translate}}</span>
      <span [matTooltip]="'tooltips.editTemplateAsRichText' | translate"
            matTooltipPosition="above"
            matTooltipClass="text-center"
            [ngClass]="{hidden: !isPlainTextMode}">{{'buttons.useRichText' | translate}}</span>
    </button>
  </div>

  <div *ngIf="customCodes" class="col-sm-12">
    <button (click)="togglePlainTextMode()" class="btn btn-default mt15 mb15">
            <span
              [matTooltip]="'tooltips.editTemplateCodesAsPlainText' | translate"
              matTooltipPosition="above"
              matTooltipClass="text-center"
              [ngClass]="{hidden: isPlainTextMode}">{{'buttons.usePlainText' | translate}}</span>
      <span
        [matTooltip]="'tooltips.editTemplateCodesAsRichText' | translate"
        matTooltipPosition="above"
        matTooltipClass="text-center"
        [ngClass]="{hidden: !isPlainTextMode}">{{'buttons.useRichText' | translate}}</span>
    </button>
  </div>

  <div [ngClass]="{hidden: isPlainTextMode}" class="col-sm-12 text-counter">
    <strong [attr.id]="wordCountId"></strong> <span [attr.id]="wordLabelId"></span>, <strong
    [attr.id]="charCountId"></strong> <span [attr.id]="charLabelId"></span>
  </div>

  <div [ngClass]="{hidden: !isPlainTextMode}" class="col-sm-12 text-counter">
    <strong>{{plainText | wordCount}}</strong><span> Word<span
    [ngClass]="{hidden: (plainText | wordCount) === 1}">s</span>, </span><strong>{{plainText.length}}</strong><span> Character<span
    [ngClass]="{hidden: plainText.length === 1}">s</span></span>
  </div>
</div>
