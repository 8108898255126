import {Component, OnInit} from '@angular/core';
import {UtilityService} from '../../services/utility.service';
import {AuthService} from '../../services/auth.service';
import {User} from '../../models/user';
import {DialogService} from '../../services/dialog.service';
import {ToastService} from '@automata/services/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorage} from '@automata/services/storage.service';
import {LocalStorageKeys} from '@automata/models/local-storage-keys';
import {Language} from '@automata/enums/language';
import {ToastIconClasses} from '@automata/utility/models/toast-icon-classes';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {userRoleToFriendlyString} from '@automata/utility/functions/user-role-to-string';

declare const window: any;

@Component({
  selector:    'app-header',
  templateUrl: './header.component.html',
  styleUrls:   ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  user: User;
  isAdmin = false;

  language: Language;
  languages = Language;

  constructor(public utility: UtilityService,
              private auth: AuthService,
              private toast: ToastService,
              private storage: LocalStorage,
              private router: Router,
              private store: Store<any>,
              private translate: TranslateService,
              private dialogService: DialogService) {
  }

  ngOnInit() {
    this.user = this.auth.getUser();
    this.isAdmin = this.auth.isAdmin();
    this.language = this.storage.get(LocalStorageKeys.LANGUAGE, Language.English);
  }

  role() {
    const user = this.auth.getUser();
    // console.log(user);
    if (user) {
      return userRoleToFriendlyString(user.roles);
    } else {
      return '';
    }
  }

  useLanguage(language: Language) {
    this.translate.use(language);
    this.language = language;
    this.storage.set(LocalStorageKeys.LANGUAGE, language);
  }

  changePassword() {
    this.dialogService.openChangePassword(this.auth.getUser());
  }

  logout() {
    this.auth.logout();
    this.utility.showToastAfterRefresh(this.translate.instant('toast.success.loggedOut'), 'Success', ToastIconClasses.Success);
    window.location.href = '/login';
  }
}
