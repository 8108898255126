import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {isSomething} from '../../../utility/functions/is-something';
import {UtilityService} from '../../../services/utility.service';
import {SimpleCounter} from '../../../../lib/quill/modules/simple-counter';

declare const require: any;

const random = require('lodash/random');

let Quill = require('quill');

let IndentStyle = require('../../../../lib/quill/attributors/indent/index');

Quill.register('modules/simple-counter', SimpleCounter);
const icons = Quill.import('ui/icons');

let AlignStyle = Quill.import('attributors/style/align');
let BackgroundStyle = Quill.import('attributors/style/background');
let ColorStyle = Quill.import('attributors/style/color');
let DirectionStyle = Quill.import('attributors/style/direction');
let FontStyle = Quill.import('attributors/style/font');
let SizeStyle = Quill.import('attributors/style/size');

Quill.register(AlignStyle, true);
Quill.register(BackgroundStyle, true);
Quill.register(ColorStyle, true);
Quill.register(DirectionStyle, true);
Quill.register(FontStyle, true);
Quill.register(SizeStyle, true);
Quill.register(IndentStyle, true);


icons['undo'] = `<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon><path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path></svg>`;
icons['redo'] = `<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon><path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path></svg>`;

@Component({
  selector:    'app-editor',
  templateUrl: './editor.component.html',
  styleUrls:   ['./editor.component.scss']
})
export class EditorComponent implements OnInit, AfterViewInit, OnDestroy {

  editorId = `editor-${random(1000, 9999)}`;

  charLabelId = `charLabel-${random(1000, 9999)}`;
  charCountId = `charCount-${random(1000, 9999)}`;
  wordLabelId = `wordLabel-${random(1000, 9999)}`;
  wordCountId = `wordCount-${random(1000, 9999)}`;

  @Input() control: FormControl;
  @Input() content: string;

  @Input() customCodes = false;

  @ViewChild('editor', {static: true}) editor;

  plainText = '';

  quill: any;

  isTouched = false;

  isPlainTextMode = false;

  plainBlurHandler = () => {
    this.isTouched = true;
  };

  constructor(public dialog: MatDialog,
              private elRef: ElementRef,
              private utility: UtilityService) {
  }

  ngOnInit() {
    if (isSomething(this.content)) {
      this.plainText = this.content;
      this.initEditor(this.content);
    } else {
      this.initEditor();
    }
    this.editor.getEditor().on('blur', this.plainBlurHandler);
  }

  ngAfterViewInit() {
    this.editor.setTheme('textmate');
  }

  ngOnDestroy() {
    this.quill.off('text-change', this.onRichTextChange);
    this.quill.off('selection-change', this.blurHandler.bind(this));
    this.quill = null;
    this.editor.getEditor().off('blur', this.plainBlurHandler);
  }

  togglePlainTextMode() {
    this.isTouched = false;
    if (this.isPlainTextMode) {
      let paste = this.quill.clipboard.convert(this.plainText || '');
      this.quill.setContents(paste);
    } else {
      this.plainText = this.quill.root.innerHTML;
    }
    this.isPlainTextMode = !this.isPlainTextMode;
  }

  focusEditor() {
    if (this.quill) {
      this.quill.focus();
    }
  }

  initEditor(content = '') {
    this.cleanEditorElements();
    setTimeout(() => {
      this.quill = new Quill(`#${this.editorId}`, {
        theme:   'snow',
        modules: this.getEditorModules()
      });
      let paste = this.quill.clipboard.convert(content || '');
      this.quill.setContents(paste);
      this.control.patchValue(content || '');
      this.isTouched = false;

      this.quill.on('text-change', this.onRichTextChange.bind(this));
      this.quill.on('selection-change', this.blurHandler.bind(this));
      this.utility.showTippyTooltipsOnTemplateEditor();
    }, 0);
  }

  onPlainTextChange(text) {
    this.control.patchValue(text);
  }

  onRichTextChange() {
    const html = this.quill.root.innerHTML;
    this.control.patchValue(html);
  }

  blurHandler(range, oldRange, source) {
    if (range === null && oldRange !== null) {
      this.isTouched = true;
    }
  }

  getEditorModules() {

    let modules = {
      toolbar: {
        container: [
          [
            {
              'header': [
                1,
                2,
                3,
                4,
                false
              ]
            }
          ],
          [
            'bold',
            'italic',
            'underline',
            'strike',
            {'list': 'ordered'},
            {'list': 'bullet'}
          ],

          [
            {'indent': '-1'},
            {'indent': '+1'}
          ],
          [{'direction': 'rtl'}],

          [{'align': []}],

          ['clean'],
          [
            'undo',
            'redo'
          ],
          [
            'link',
            'image'
          ]
        ],
        handlers:  {
          undo: () => {
            (<any>this.quill).history.undo();
          },
          redo: () => {
            (<any>this.quill).history.redo();
          }
        }
      }
    };

    modules['simple-counter'] = {
      charLabel: `#${this.charLabelId}`,
      charCount: `#${this.charCountId}`,
      wordLabel: `#${this.wordLabelId}`,
      wordCount: `#${this.wordCountId}`
    };

    return modules;
  }

  cleanEditorElements() {
    let elements = this.elRef.nativeElement.getElementsByClassName('ql-toolbar');
    setTimeout(() => {
      while (elements.length > 0) {
        if (elements[0].parentNode) {
          elements[0].parentNode.removeChild(elements[0]);
        }
      }
    }, 0);
  }
}
