import {Expose, Transform} from 'class-transformer';
import {EpochFormatter} from '@automata/utility/transformers/transformers';
import {deserialize, Serializable} from '@automata/utility/serializers/serializers';

export class Message extends Serializable<Message> {

  @Expose({name: 'pkey'})
  id: string;

  account: number;

  @Expose({name: 'created'})
  @Transform(EpochFormatter(), {toClassOnly: true})
  createdFormatted: string;

  from: string;

  @Expose({name: 'guest_id'})
  guestId: string;

  @Expose({name: 'guest_name'})
  guestName: string;

  @Expose({name: 'inquiry_id'})
  inquiryId: string;

  @Expose({name: 'message_id'})
  messageId: string;

  @Expose({name: 'message_txt'})
  text: string;

  translated: any;

  name: string;
  subject: string;

  static deserialize(data: object): Message {
    return deserialize(Message, data);
  }
}
