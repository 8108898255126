import {deserialize, Serializable} from '@automata/utility/serializers/serializers';
import {Expose} from 'class-transformer';

export class AutomataNotification extends Serializable<AutomataNotification> {

  @Expose({name: 'pkey'})
  id: string;

  @Expose({name: 'trigger'})
  triggerId: string;

  event: string;

  @Expose({name: 'guest'})
  guestId: string;

  @Expose({name: 'guest_name'})
  guestName: string;

  @Expose({name: 'template'})
  templateId: string;

  @Expose({name: 'channel'})
  channelToken: string;

  @Expose({name: 'channel_name'})
  channelName: string;

  @Expose({name: 'event_msgs'})
  eventMessage: string;

  handler: string;
  success: number;
  sent: number;
  account: number;
  object: any;
  service: string; // should be 'automata' always
  sentChartFormatted: string;
  inquiryId: string;

  @Expose({name: 'trigger_name'})
  triggerName: string;

  static deserialize(data: object): AutomataNotification {
    return deserialize(AutomataNotification, data);
  }
}
