import {AfterContentInit, Component, ContentChild, OnDestroy} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {TableUtilityService} from '@automata/services/table-utility.service';
import {Subscription} from 'rxjs';
import {delay} from 'rxjs/operators';

@Component({
  selector:    'app-translated-pagination',
  templateUrl: './translated-pagination.component.html',
  styleUrls:   ['./translated-pagination.component.scss']
})
export class TranslatedPaginationComponent implements AfterContentInit, OnDestroy {

  @ContentChild(MatPaginator, {static: true}) paginator: MatPaginator;

  translateSub: Subscription;

  constructor(private tableService: TableUtilityService) {
  }

  ngAfterContentInit() {
    this.translateSub = this.tableService.paginationTranslations()
      .pipe(
        delay(10)
      )
      .subscribe(translation => {
        this.paginator._intl.itemsPerPageLabel = translation.perPage;
        this.paginator._intl.nextPageLabel = translation.nextPage;
        this.paginator._intl.previousPageLabel = translation.prevPage;
        this.paginator._intl.firstPageLabel = translation.firstPage;
        this.paginator._intl.lastPageLabel = translation.lastPage;
        this.paginator._intl.changes.next();
      });
  }

  ngOnDestroy() {
    this.translateSub.unsubscribe();
  }
}
