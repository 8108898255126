<div class="dialog-header">
  <h2 mat-dialog-title>
    <i class="fas fa-book"></i> {{'pageElements.renameWorkflow' | translate}}
  </h2>
  <button (click)="close()" class="close" aria-label="Close" type="button">
    <span class="far fa-times"></span>
  </button>
</div>

<div class="row">
  <form [formGroup]="form">
    <div class="col-sm-offset-2 col-sm-8">
      <mat-form-field>
        <input matInput [placeholder]="'formControls.nameYourWorkflow' | translate" formControlName="name"
               [required]="true">
        <mat-error *ngIf="form.get('name').hasError('required')">
          <span [innerHtml]="'validations.nameRequired' | translate"></span>
        </mat-error>
        <mat-error *ngIf="form.get('name').hasError('maxlength')">
          <span [innerHtml]="'validations.nameAtMost50' | translate"></span>
        </mat-error>
        <mat-error *ngIf="form.get('name').hasError('minlength')">
          <span [innerHtml]="'validations.nameAtLeast3' | translate"></span>
        </mat-error>
        <mat-hint align="end">{{form.get('name').value.length || 0}}/50</mat-hint>
      </mat-form-field>
    </div>
  </form>
</div>

<div class="text-right">
  <button (click)="close()" class="btn btn-default left-icon mr16 text-uppercase" type="button">
    <i class="far fa-check-square"></i> {{'buttons.cancel' | translate}}
  </button>
  <button (click)="save()" [disabled]="form.invalid" class="btn btn-new left-icon text-uppercase" type="button">
    <i class="far fa-save"></i> {{'buttons.save' | translate}}
  </button>
</div>
