<div class="main-body clearfix">
  <div class="container-fluid">
    <div class="content-holder clearfix">
      <div class="chart clearfix">
        <div class="empty-chart">
          <i class="far fa-money-check-alt"></i>
          <p>{{'pageElements.billingDesc1' | translate}}</p>
          <br>
          <p>{{'pageElements.billingDesc2' | translate}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
