import {Injectable} from '@angular/core';
import {LocalStorageKeys} from '../models/local-storage-keys';
import {InquiriesFilters} from '../interfaces/inquiry/inquiries-filters';
import {TriggersFilters} from '../interfaces/trigger/triggers-filters';
import {WorkflowFilters} from '../interfaces/workflow-filters';
import {CodeFilters} from '../interfaces/code/code-filters';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '@automata/reducers';
import {delay, map, tap} from 'rxjs/operators';
import {TagsType} from '@automata/enums/tags-type';
import {FormControl, FormGroup} from '@angular/forms';
import * as R from 'ramda';
import {allTags} from '@automata/selectors/tags.selectors';

@Injectable()
export class FiltersService {

  storeInquiries(filters: InquiriesFilters): void {
    localStorage.setItem(LocalStorageKeys.INQUIRIES_FILTERS, JSON.stringify({...filters}));
  }

  storeTriggers(filters: TriggersFilters): void {
    localStorage.setItem(LocalStorageKeys.TRIGGERS_FILTERS, JSON.stringify({...filters}));
  }

  storeWorkflows(filters: WorkflowFilters): void {
    localStorage.setItem(LocalStorageKeys.WORKFLOWS_FILTERS, JSON.stringify({...filters}));
  }

  storeCodes(filters: CodeFilters): void {
    localStorage.setItem(LocalStorageKeys.CODES_FILTERS, JSON.stringify({...filters}));
  }

  getInquiries(): InquiriesFilters {
    let data = {
      rentals:  [],
      channels: [],
      events:   [],
      status:   '',
      arrival:  '',
      tags:     []
    } as InquiriesFilters;

    try {
      data = JSON.parse(localStorage.getItem(LocalStorageKeys.INQUIRIES_FILTERS)) || data;
    } catch (e) {
      console.log('error');
    }

    return data;
  }

  getTriggers(): TriggersFilters {
    let data = {
      rentals:   [],
      channels:  [],
      templates: [],
      events:    [],
      status:    '',
      tags:      []
    } as TriggersFilters;

    try {
      data = JSON.parse(localStorage.getItem(LocalStorageKeys.TRIGGERS_FILTERS)) || data;
    } catch (e) {
      console.log('error');
    }

    return data;
  }

  getWorkflows(): WorkflowFilters {
    let data = {
      rentals:  [],
      channels: [],
      events:   []
    } as WorkflowFilters;

    try {
      data = JSON.parse(localStorage.getItem(LocalStorageKeys.WORKFLOWS_FILTERS)) || data;
    } catch (e) {
      console.log('error');
    }

    return data;
  }

  getCodes(): CodeFilters {
    let data = {
      rentals: [],
      tags:    []
    } as CodeFilters;

    try {
      data = JSON.parse(localStorage.getItem(LocalStorageKeys.CODES_FILTERS)) || data;
    } catch (e) {
      console.log('error');
    }

    return data;
  }

  registerTagsFilter(tagsType: TagsType, tagsCtrl: FormControl): Observable<{id: string, name: string}[]> {
    return this.store.pipe(select(allTags(tagsType)))
      .pipe(
        delay(10),
        map(tags => R.map(t => ({id: t, name: t}), tags)),
        tap(tags => {
          const selectedTags = tagsCtrl.value;
          R.forEach(selectedTag => {
            if (!R.find(t => t.id === selectedTag, tags)) {
              tagsCtrl.patchValue(R.reject(t => t === selectedTag, selectedTags));
            }
          }, selectedTags);
        })
      );
  }

  registerTagsFormFilter(tagsType: TagsType, tagsForm: FormGroup): Observable<{id: string, name: string}[]> {
    return this.store.pipe(select(allTags(tagsType)))
      .pipe(
        delay(10),
        map(tags => R.map(t => ({id: t, name: t}), tags)),
        tap(tags => {
          const selectedTags = tagsForm.get('tags').value;
          R.forEach(selectedTag => {
            if (!R.find(t => t.id === selectedTag, tags)) {
              tagsForm.patchValue({tags: R.reject(t => t === selectedTag, selectedTags)});
            }
          }, selectedTags);
        })
      );
  }

  constructor(private store: Store<fromRoot.State>) {
  }

}
