import {Pipe, PipeTransform} from '@angular/core';
import {TriggerHandler} from '../../enums/trigger-handler';

@Pipe({
  name: 'allowsDelay'
})
export class AllowsDelayPipe implements PipeTransform {
  transform(handler: string): boolean {

    return handler === TriggerHandler.SendEmailMessage;

  }
}
