<div class="dialog-header">
  <h2 mat-dialog-title>
    <i class="fas fa-book"></i> {{'pageElements.duplicateWorkflow' | translate}}
  </h2>
  <button (click)="close()" class="close" aria-label="Close" type="button">
    <span class="far fa-times"></span>
  </button>
</div>

<div class="row">
  <div class="col-sm-12">
    <p class="guide-text">{{'pageElements.duplicateWorkflowGuide' | translate}}</p>
  </div>
  <div class="col-sm-offset-2 col-sm-8">
    <app-select-search *ngIf="rentals$ | async"
                       [matTooltip]="'tooltips.selectDestinationRental' | translate"
                       matTooltipPosition="above"
                       matTooltipClass="text-center"
                       [items]="rentals$ | async"
                       [ctrl]="rentalCtrl"
                       [hasBlank]="false"
                       [multiple]="true"
                       [placeholder]="'formControls.rentals' | translate"></app-select-search>
  </div>
</div>

<div class="text-right">
  <button (click)="close()" class="btn btn-default left-icon mr10 text-uppercase" type="button">
    <i class="far fa-check-square"></i> {{'buttons.cancel' | translate}}
  </button>
  <button (click)="save()" [disabled]="rentalCtrl.invalid" class="btn btn-new left-icon text-uppercase" type="button">
    <i class="far fa-save"></i> {{'buttons.save' | translate}}
  </button>
</div>
