import {Pipe, PipeTransform} from '@angular/core';
import {TriggerHandler} from '../../enums/trigger-handler';

@Pipe({
  name: 'isBookingTagHandler'
})
export class IsBookingTagHandlerPipe implements PipeTransform {

  transform(handler: string): boolean {
    return handler === TriggerHandler.AddTagHandler || handler === TriggerHandler.RemoveTagHandler;
  }

}
