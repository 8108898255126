import {Injectable} from '@angular/core';
import {take} from 'rxjs/operators';
import {RemoveTemplateDialogComponent} from '@automata/containers/templates/remove-template/remove-template-dialog.component';
import {Template} from '@automata/models/template';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

@Injectable()
export class RemoveTemplateDialogService {

  constructor(private dialog: MatDialog) {
  }

  public removeTemplate(template: Template) {

    let defaultConfig: MatDialogConfig = {
      width:      '600px',
      height:     `268px`,
      data:       {template},
      panelClass: [
        'confirm-modal',
        'modal-confirmation'
      ]
    };

    return this.dialog.open(RemoveTemplateDialogComponent, defaultConfig).afterClosed()
      .pipe(
        take(1)
      );
  }
}
