<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <button (click)="close()" class="cstm-close" data-dismiss="modal" aria-label="Close">
        <span class="far fa-times"></span>
      </button>

      <div class="header-box">
        <div>
          <h5 class="modal-title">
            {{'pageElements.createWorkflowFromSample' | translate}}
          </h5>
        </div>
      </div>
    </div>

    <div class="modal-body work-detail-modal-content">
      <mat-horizontal-stepper [linear]="true" #stepper>
        <mat-step [stepControl]="workflowGroup">
          <div class="row">
            <div class="col-md-offset-3 col-md-6">
              <form [formGroup]="workflowGroup">
                <ng-template matStepLabel>{{'pageElements.chooseAWorkflow' | translate}}</ng-template>
                <app-select-search [items]="workflows$ | asyncSchedule | async"
                                   [ctrl]="workflowGroup.get('workflow')"
                                   [multiple]="false"
                                   [hasBlank]="false"
                                   [placeholder]="'formControls.selectASampleWorkflow' | translate"></app-select-search>
                <div class="mt5">
                  <button matStepperNext [disabled]="workflowGroup.invalid"
                          class="btn btn-cstm-info left-icon text-uppercase">
                    <i class="fal fa-arrow-right"></i> {{'buttons.next' | translate}}
                  </button>
                </div>
                <p *ngIf="!workflow" class="guide-text">
                  {{'pageElements.createWorkflowFromSampleGuide' | translate}}
                </p>
                <div *ngIf="workflow">
                  <hr>
                  <p [innerHtml]="workflow.description | safeHtml"></p>
                  <hr>
                </div>
              </form>
            </div>
          </div>
          <div *ngIf="workflow" class="row">
            <div class="col-md-offset-1 col-md-10">
              <div>
                <h5 class="triggers-heading">{{'pageElements.triggersWithinWorkflow' | translate}}</h5>
                <div class="trigger-boxes">
                  <div *ngFor="let trigger of triggers"
                       [matTooltip]="trigger.templateDescription"
                       matTooltipPosition="above"
                       matTooltipClass="text-center"
                       (click)="toggleSample(trigger)"
                       class="trigger-box">
                    <mat-checkbox [matTooltip]="'tooltips.selectTriggerForWorkflow' | translate"
                                  matTooltipPosition="above"
                                  matTooltipClass="text-center"
                                  (click)="$event.stopPropagation()"
                                  (change)="toggleSample(trigger)"
                                  [checked]="trigger.isChecked"></mat-checkbox>
                    <i [ngClass]="trigger.icon" class="fal fa-2x"></i>
                    <h5>{{trigger.name}}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-step>
        <mat-step [stepControl]="rentalGroup">
          <div class="row">
            <div class="col-md-offset-3 col-md-6">
              <form [formGroup]="rentalGroup">
                <ng-template
                  matStepLabel>{{'pageElements.chooseWorkflowRental' | translate}}</ng-template>
                <app-select-search [items]="rentals$ | async"
                                   [ctrl]="rentalGroup.get('rental')"
                                   [multiple]="false"
                                   [hasBlank]="false"
                                   [placeholder]="'formControls.selectARental' | translate"></app-select-search>
                <div class="mt5">
                  <button matStepperPrevious class="btn btn-default left-icon text-uppercase mr10">
                    <i class="fal fa-arrow-left"></i> {{'buttons.back' | translate}}
                  </button>
                  <button matStepperNext [disabled]="rentalGroup.invalid"
                          class="btn btn-cstm-info left-icon text-uppercase">
                    <i class="fal fa-arrow-right"></i> {{'buttons.next' | translate}}
                  </button>
                </div>
              </form>
              <p class="guide-text">
                {{'pageElements.chooseWorkflowRentalGuide' | translate}}
              </p>
            </div>
          </div>
        </mat-step>
        <mat-step>
          <div class="row">
            <div class="col-md-offset-3 col-md-6">
              <form [formGroup]="nameGroup">
                <ng-template matStepLabel>{{'pageElements.nameWorkflow' | translate}}</ng-template>
                <mat-form-field>
                  <input matInput [placeholder]="'formControls.nameYourWorkflow' | translate"
                         formControlName="name"
                         [required]="true">
                  <mat-error *ngIf="nameGroup.get('name').hasError('required')">
                    <span [innerHtml]="'validations.nameRequired' | translate"></span>
                  </mat-error>
                  <mat-error *ngIf="nameGroup.get('name').hasError('maxlength')">
                    <span [innerHtml]="'validations.nameAtMost50' | translate"></span>
                  </mat-error>
                  <mat-hint align="end">{{nameGroup.get('name').value.length || 0}}/50</mat-hint>
                </mat-form-field>
                <div class="mt5">
                  <button matStepperPrevious class="btn btn-default left-icon text-uppercase mr10">
                    <i class="fal fa-arrow-left"></i> {{'buttons.back' | translate}}
                  </button>
                  <button (click)="stepper.reset()"
                          class="btn btn-default left-icon text-uppercase mr10">
                    <i class="fal fa-sync"></i> {{'buttons.reset' | translate}}
                  </button>
                  <button (click)="finish()" [disabled]="nameGroup.invalid"
                          class="btn btn-new left-icon text-uppercase">
                    <i class="fal fa-save"></i> {{'buttons.create' | translate}}
                  </button>
                </div>
              </form>
              <p class="guide-text">
                {{'pageElements.nameWorkflowGuide' | translate}}
              </p>
            </div>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</div>
