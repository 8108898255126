import {Pipe, PipeTransform} from '@angular/core';
import * as R from 'ramda';

@Pipe({
  name: 'listOfStrings'
})
export class ListOfStringsPipe implements PipeTransform {

  transform(strings: string[]): string {
    return R.join(', ', strings);
  }

}
