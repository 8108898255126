import * as R from 'ramda';

export const capitalizeWorkflow = R.map(R.compose(
  R.join(''),
  R.juxt([R.compose(R.toUpper, R.head), R.tail])
));

export const readWorkflows = (attributes: {workflows: string[]}) => {
  let workflows = R.pathOr([], ['workflows'], attributes);
  workflows = R.map(R.pipe(R.when(R.is(Number), R.toString)))(workflows);
  return R.map(R.compose(R.join(' '), capitalizeWorkflow, <any>R.split('_'), R.trim))(workflows);
};
