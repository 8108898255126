import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {flatMap, map, toArray} from 'rxjs/operators';
import {NotificationResponse} from '../interfaces/notification-response';
import {Notification} from '../models/notification';
import {from} from 'rxjs';

@Injectable()
export class NotificationsService {

  constructor(private http: HttpClient) {
  }

  get() {
    return this.http.get<NotificationResponse[]>(`@api/notification/all`)
      .pipe(
        flatMap(response => from(response)),
        map(response => new Notification(response)),
        toArray()
      );
  }

}
