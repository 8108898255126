import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {TagsDialogComponent} from '../../tags/tags-dialog/tags-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Workflow} from '../../../models/workflow';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {RenameWorkflow} from '@automata/actions/workflows.actions';

@Component({
  selector:    'app-workflow-rename-dialog',
  templateUrl: './workflow-rename-dialog.component.html',
  styleUrls:   ['./workflow-rename-dialog.component.scss']
})
export class WorkflowRenameDialogComponent implements OnInit {

  form = this.fb.group({
    name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]]
  });

  constructor(private fb: FormBuilder,
              private store: Store<fromRoot.State>,
              public dialogRef: MatDialogRef<TagsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {workflow: Workflow}) {
  }

  ngOnInit() {
    this.form.setValue({
      name: this.data.workflow.name
    });
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const form = this.form.getRawValue();
    this.store.dispatch(RenameWorkflow({workflow: this.data.workflow, name: form.name}));
    this.dialogRef.close();
  }

}
