import {ITemplateSample} from '../../interfaces/template/template-sample';
import {SampleType} from '../../enums/sample-type';
import {TemplateType} from '../../enums/template-type';
import {deserialize, Serializable} from '@automata/utility/serializers/serializers';

declare const require: any;

const uuid = require('uuid/v4');

export class TemplateSample extends Serializable<TemplateSample> implements ITemplateSample {
  id = uuid();
  kind = SampleType.Template;

  body: string;
  description: string;
  key: string;
  name: string;
  subject: string;
  type: TemplateType;

  icon: string;

  static deserialize(data: object): TemplateSample {
    return deserialize(TemplateSample, data);
  }
}
