import {Expose} from 'class-transformer';

export class Address {
  address: string;
  city: string;
  country: string;

  @Expose({name: 'country_code'})
  countryCode: string;

  @Expose({name: 'postal_code'})
  postalCode: number;
  state: string;
}
