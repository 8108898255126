export enum TableType {
  Templates,
  Bookings,
  Triggers,
  ApiKeys,
  Workflows,
  WorkflowTriggers,
  FailTriggers,
  SuccessTriggers,
  WizardExisting,
  TriggerLog,
  Conditions,
  PayloadHttpHeaders,
  TemplateLanguages,
  Codes,
  BookingJourney
}
