<div class="modal-header">
  <h5 class="modal-title">Delete Template</h5>
  <button (click)="close()" type="button" class="close">
    <span><i class="fal fa-times"></i></span>
  </button>
</div>
<div class="modal-body">
  <p>
    You are attempting to delete a template that is assigned to one or more triggers. If you click the Delete Associated Triggers button below, all triggers assigned to this template will be deleted. This will allow you to then delete the template. We strongly recommend reviewing the triggers associated with this template before you delete, to ensure that deletion will not disrupt any of your intended messaging. Once the trigger is deleted, it can not be recovered. It will have to be recreated again.
  </p>
</div>

<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-default left-icon text-uppercase">
    <i class="far fa-check-square" aria-hidden="true"></i>
    {{'buttons.cancel' | translate}}
  </button>
  <button *ngIf="triggers.length > 0"
          (click)="onDeleteTriggers()"
          type="button"
          class="btn btn-cst-danger left-icon text-uppercase">
    <span class="far fa-trash-alt"></span> Delete Associated Triggers
  </button>
  <button [disabled]="triggers.length > 0"
          (click)="onDeleteTemplate()" type="button"
          class="btn btn-cst-danger left-icon text-uppercase">
    <span class="far fa-trash-alt"></span> Delete Template
  </button>
</div>
