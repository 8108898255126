import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ConfirmDialogStatus} from '@automata/enums/confirm-dialog-status';

export interface AlertDialogParams {
  title: string
  body: string
}

@Component({
  selector:    'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls:   ['./alert-dialog.component.scss']
})
export class AlertDialogComponent {

  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AlertDialogParams) {
  }

  close() {
    this.dialogRef.close(ConfirmDialogStatus.Closed);
  }

}
