import {AccountDetails} from '../models/account-details';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as R from 'ramda';
import {ApiKey} from '../models/api-key';
import * as AccountActions from '../actions/accounts.actions';
import {createReducer, on} from '@ngrx/store';

export interface State extends EntityState<AccountDetails> {
  // additional entities state properties
  selectedAccountId: string | null
  isLoaded: boolean
  isApiKeysLoaded: boolean,
  triggersSkipped: boolean
}

export const adapter: EntityAdapter<AccountDetails> = createEntityAdapter<AccountDetails>({
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedAccountId: null,
  isLoaded:          false,
  isApiKeysLoaded:   false,
  triggersSkipped:   false
});

export const reducer = createReducer(
  initialState,
  on(AccountActions.LoadAccountComplete, (state, {account}) => adapter.addOne(account, {...state, isLoaded: true})),
  on(AccountActions.AddApiKey, (state, {key}) => {
    return adapter.updateOne({
      id:      key.account,
      changes: {
        apiKeys: <any>R.append(key.apiKey, <ApiKey[]>state.entities[key.account].apiKeys)
      }
    }, state);
  }),
  on(AccountActions.DeleteApiKeyComplete, (state, {key}) => {
    return adapter.updateOne({
      id:      key.account,
      changes: {
        apiKeys: <any>R.filter((k: any) => k.key !== key.key, <any>state.entities[key.account].apiKeys)
      }
    }, state);
  }),
  on(AccountActions.SaveAccountComplete, (state, {account}) => {
    return adapter.updateOne({
      id:      account.id,
      changes: account
    }, state);
  }),
  on(AccountActions.LoadApiKeysComplete, (state, {keys}) => {
    return adapter.updateOne({
      id:      keys.id,
      changes: {
        apiKeys: keys.keys
      }
    }, {...state, isApiKeysLoaded: true});
  }),
  on(AccountActions.GetSkipTriggersComplete, (state, {isSkipped}) => ({...state, triggersSkipped: isSkipped})),
  on(AccountActions.SkipTriggersComplete, (state) => ({...state, triggersSkipped: true})),
  on(AccountActions.UnSkipTriggersComplete, (state) => ({...state, triggersSkipped: false}))
);

export const {selectEntities} = adapter.getSelectors();
