import {Pipe, PipeTransform} from '@angular/core';
import * as R from 'ramda';

@Pipe({
  name: 'isNil'
})
export class IsNilPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return R.isNil(value);
  }

}
