import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {Channel} from '../models/channel';
import {ChannelResponse} from '../interfaces/channel-response';
import {filter, flatMap, map, toArray} from 'rxjs/operators';
import * as R from 'ramda';

@Injectable()
export class ChannelsService {

  constructor(private http: HttpClient) {
  }

  all(): Observable<Channel[]> {
    return this.http.get<ChannelResponse[]>(`@api/channels/`)
      .pipe(
        map(channels => R.filter(c => c.token !== 'airbnb', channels)),
        map(channels => R.map(c => {
          if (c.token === 'airbnbapiv2') {
            return {
              ...c,
              token:        'airbnb',
              name:         'airbnb',
              friendlyName: 'Airbnb API'
            };
          } else {
            return c;
          }
        }, channels)),
        flatMap(channels => from(channels)),
        filter(channel => channel.type === 'auto'),
        map(channel => Channel.deserialize(channel)),
        toArray()
      );
  }
}
