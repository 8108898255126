import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {TemplateTranslation} from '../../../models/template-translation';
import {Observable} from 'rxjs';
import {TableType} from '../../../enums/table-type';
import {DialogService} from '../../../services/dialog.service';
import * as R from 'ramda';
import {path} from 'ramda';
import {Template} from '../../../models/template';
import {TemplateType} from '../../../enums/template-type';
import {TemplateLanguageDialogParams} from '../../../interfaces/template/template-language-dialog-params';
import {TemplateTranslateDialogComponent} from '../template-translate-dialog/template-translate-dialog.component';
import {FormControl} from '@angular/forms';
import {TableUtilityService} from '../../../services/table-utility.service';
import {localeCompareSort} from '@automata/utility/functions/locale-compare-sort';
import {TranslateService} from '@ngx-translate/core';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

@Component({
  selector:    'app-translations-table',
  templateUrl: './template-translations-table.component.html',
  styleUrls:   ['./template-translations-table.component.scss']
})
export class TemplateTranslationsTableComponent extends OnDestroyMixin implements OnInit, OnChanges {

  @Input() template: Template;
  @Input() type: TemplateType;
  @Input() languages: Partial<TemplateTranslation>[];
  @Input() content: FormControl;
  @Input() subject: FormControl;

  @Input() translateControl: EventEmitter<boolean>;

  models: TemplateTranslation[];

  @Output() change = new EventEmitter<TemplateTranslation[]>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns = [
    'languageName',
    'text',
    'edit'
  ];

  dataSource = new MatTableDataSource<TemplateTranslation>();

  isEmptyTable$: Observable<boolean>;

  tableType = TableType.TemplateLanguages;

  constructor(private dialogService: DialogService,
              private translate: TranslateService,
              private tableService: TableUtilityService) {
    super()
  }

  ngOnInit() {
    this.models = R.map(t => new TemplateTranslation(t), this.languages);
    setTimeout(() => this.dataSource.data = this.models || [], 0);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortData = localeCompareSort;

    this.isEmptyTable$ = this.tableService.isEmptyTable(this.dataSource);

    this.translateControl.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.onAdd();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const languages: TemplateTranslation[] = path(['languages', 'currentValue'], changes) || [];

    this.models = R.map(t => new TemplateTranslation(t), languages);
    setTimeout(() => this.dataSource.data = this.models || [], 0);
  }

  onAdd() {

    let dialogRef = this.dialogService.openSide(TemplateTranslateDialogComponent, {
      data: {
              template:      new Template({body: this.content.value}),
              templateType:  this.type,
              languageModel: new TemplateTranslation({text: this.content.value, subject: this.subject.value})
            } as TemplateLanguageDialogParams
    });

    dialogRef.afterClosed().pipe(untilComponentDestroyed(this)).subscribe((translation) => {
      if (!R.isNil(translation)) {
        this.models = R.append(new TemplateTranslation(translation), this.models);
        this.dataSource.data = this.models;
        this.change.emit(this.models);
      }
    });
  }

  onEdit(translation: TemplateTranslation) {
    let dialogRef = this.dialogService.openSide(TemplateTranslateDialogComponent, {
      data: {
              template:      new Template({body: translation.text}),
              templateType:  this.type,
              languageModel: translation
            } as TemplateLanguageDialogParams
    });

    dialogRef.afterClosed().pipe(untilComponentDestroyed(this)).subscribe((translation) => {
      if (!R.isNil(translation)) {
        this.models = R.filter((h) => h.id !== translation.id, this.models);
        this.models = R.append(new TemplateTranslation(translation), this.models);
        this.dataSource.data = this.models;
        this.change.emit(this.models);
      }
    });
  }

  onRemove(header: TemplateTranslation) {
    this.models = R.filter((h) => h.id !== header.id, this.models);
    this.dataSource.data = this.models;
    this.change.emit(this.models);
  }
}
