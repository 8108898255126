import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable} from 'rxjs';
import * as R from 'ramda';
import {map} from 'rxjs/operators';
import {Code} from '../models/code';
import {CodeResponse} from '../interfaces/code/code-response';
import {CodeAddRequest} from '../interfaces/code/code-add-request';
import {CodeUpdateRequest} from '../interfaces/code/code-update-request';

@Injectable()
export class CodesService {

  constructor(private http: HttpClient) {
  }

  get(id: string): Observable<Code> {
    return this.http.get<CodeResponse>(`@api/customcode/${id}`)
      .pipe(
        map(response => {
          let code = new Code();
          code.fromResponse(response);
          return code;
        })
      );
  }

  all(): Observable<Code[]> {
    return this.http.get<CodeResponse[]>(`@api/customcode/all/`)
      .pipe(
        map((response) => R.map((r) => {
          let code = new Code();
          code.fromResponse(r);
          return code;
        }, response))
      );
  }

  delete(codeId) {
    return this.http.delete(`@api/customcode/${codeId}`);
  };

  deleteBatch(ids: string[]): Observable<string[]> {
    return forkJoin(<any>R.map((id) => this.http.delete(`@api/customcode/${id}`), ids)) as any;
  };

  add(request: CodeAddRequest): Observable<Code> {
    return this.http.post<CodeResponse>(`@api/customcode/`, request)
      .pipe(
        map(response => {
          let code = new Code();
          code.fromResponse(response);
          return code;
        })
      );
  }

  update(request: CodeUpdateRequest) {
    return this.http.put<CodeResponse>(`@api/customcode/${request.pkey}`, request)
      .pipe(
        map(response => {
          let code = new Code();
          code.fromResponse(response);
          return code;
        })
      );
  }
}
