import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {TriggersService} from '../services/triggers.service';
import {of} from 'rxjs';
import * as fromRoot from '../reducers';
import {TemplatesService} from '../services/templates.service';
import {catchError, concatMap, map, switchMap, switchMapTo, tap} from 'rxjs/operators';
import {DialogService} from '../services/dialog.service';
import {TriggerDialogComponent} from '../components/trigger/trigger-dialog/trigger-dialog.component';
import {BookingService} from '../containers/bookings-page/store/booking.service';
import {
  AddWorkflowsToTriggers,
  AddWorkflowsToTriggersComplete,
  ArchiveTrigger,
  ArchiveTriggerComplete,
  CreateCondition,
  CreateConditionComplete,
  CreateTrigger,
  CreateTriggerComplete,
  CreateTriggers,
  CreateTriggersComplete,
  DeleteBatchTrigger,
  DeleteBatchTriggerComplete,
  DeleteCondition,
  DeleteConditionComplete,
  DeleteTrigger,
  DeleteTriggerComplete,
  LoadAllTriggers,
  LoadAllTriggersComplete,
  LoadTriggerDetails,
  LoadTriggerDetailsComplete,
  LoadTriggers,
  LoadTriggersComplete,
  LoadTriggerSkippedBookings,
  LoadTriggerSkippedBookingsComplete,
  OpenTrigger,
  OpenTriggerComplete,
  PauseTrigger,
  PauseTriggerComplete,
  PauseTriggers,
  PauseTriggersComplete,
  RemoveFromWorkflow,
  RemoveFromWorkflowComplete,
  ResumeTrigger,
  ResumeTriggerComplete,
  ResumeTriggers,
  ResumeTriggersComplete,
  SaveCondition,
  SaveConditionComplete,
  SaveTrigger,
  SaveTriggerComplete,
  TriggerSkipInquiry,
  TriggerSkipInquiryComplete,
  TriggerUnSkipInquiry,
  TriggerUnSkipInquiryComplete,
  UnArchiveTrigger,
  UnArchiveTriggerComplete
} from '@automata/actions/triggers.actions';
import {ToastService} from '@automata/services/toast.service';
import {ActionFailed} from '@automata/actions/utility.actions';

@Injectable()
export class TriggersEffects {

  @Effect()
  openTrigger$ = this.actions$
    .pipe(
      ofType(OpenTrigger),
      tap(({id}) => {
        console.log(`Editing trigger ${id}`);
        this.dialogService.openSide(TriggerDialogComponent, {data: {triggerId: id}});
      }),
      switchMap(() => of(OpenTriggerComplete()))
    );

  @Effect()
  pauseMultiple$ = this.actions$
    .pipe(
      ofType(PauseTriggers),
      switchMap(({triggers}) => this.triggers
        .pauseTriggers(triggers)
        .pipe(
          tap(() => this.toast.success('triggersPaused')),
          map(triggers => PauseTriggersComplete({triggers})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  resumeMultiple$ = this.actions$
    .pipe(
      ofType(ResumeTriggers),
      switchMap(({triggers}) => this.triggers
        .resumeTriggers(triggers)
        .pipe(
          tap(() => this.toast.success('triggersResumed')),
          map((response) => ResumeTriggersComplete({triggers: response})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  addWorkflowsToTriggers$ = this.actions$
    .pipe(
      ofType(AddWorkflowsToTriggers),
      switchMap(({params}) => this.triggers
        .addWorkflowsToTriggers(params)
        .pipe(
          tap(() => this.toast.success('workflowsAddedToTriggers')),
          map(triggers => AddWorkflowsToTriggersComplete({triggers})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  loadTriggers$ = this.actions$
    .pipe(
      ofType(LoadTriggers),
      switchMapTo(
        this.triggers.getAll()
          .pipe(
            map(({automata, legacy}) => LoadTriggersComplete({automata, legacy})),
            catchError(error => of(ActionFailed({error})))
          )
      )
    );

  @Effect()
  loadAll$ = this.actions$
    .pipe(
      ofType(LoadAllTriggers),
      switchMapTo(
        this.triggers
          .getAll()
          .pipe(
            map(({automata, legacy}) => LoadAllTriggersComplete({automata, legacy})),
            catchError(error => of(ActionFailed({error})))
          )
      )
    );

  @Effect()
  createCondition$ = this.actions$
    .pipe(
      ofType(CreateCondition),
      switchMap(({request}) => this.triggers
        .createCondition(request)
        .pipe(
          tap(() => this.toast.success('conditionCreated')),
          map(condition => CreateConditionComplete({
            response: {
              triggerId: request.triggerId,
              condition: condition
            }
          })),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  saveCondition$ = this.actions$
    .pipe(
      ofType(SaveCondition),
      switchMap(({request}) => this.triggers
        .saveCondition(request)
        .pipe(
          tap(() => this.toast.success('conditionSaved')),
          map(condition => SaveConditionComplete({
            response: {
              triggerId: request.triggerId,
              condition: condition
            }
          })),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  deleteCondition$ = this.actions$
    .pipe(
      ofType(DeleteCondition),
      switchMap(({request}) => this.triggers
        .deleteCondition(request)
        .pipe(
          tap(() => this.toast.success('conditionDeleted')),
          map(() => DeleteConditionComplete({response: request})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  loadTriggerDetails$ = this.actions$
    .pipe(
      ofType(LoadTriggerDetails),
      switchMap(({trigger}) => this.triggers
        .get(trigger.id)
        .pipe(
          map(trigger => LoadTriggerDetailsComplete({trigger})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  pauseTrigger$ = this.actions$
    .pipe(
      ofType(PauseTrigger),
      switchMap(({id}) => this.triggers
        .pause(id)
        .pipe(
          map(trigger => PauseTriggerComplete({trigger})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  saveTrigger$ = this.actions$
    .pipe(
      ofType(SaveTrigger),
      switchMap(({request}) => this.triggers
        .update(request)
        .pipe(
          tap(() => this.toast.success('triggerSaved')),
          map(trigger => SaveTriggerComplete({trigger})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  createTriggers$ = this.actions$
    .pipe(
      ofType(CreateTriggers),
      switchMap(({request}) => this.triggers
        .createMany(request)
        .pipe(
          tap(() => this.toast.success('triggersCreated')),
          map(triggers => CreateTriggersComplete({triggers})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  createTrigger$ = this.actions$
    .pipe(
      ofType(CreateTrigger),
      switchMap(({request}) => this.triggers
        .create(request)
        .pipe(
          tap(() => this.toast.success('triggerCreated')),
          map(trigger => CreateTriggerComplete({trigger})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  resumeTrigger$ = this.actions$
    .pipe(
      ofType(ResumeTrigger),
      switchMap(({id}) => this.triggers
        .resume(id)
        .pipe(
          map(trigger => ResumeTriggerComplete({trigger})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  archiveTrigger$ = this.actions$
    .pipe(
      ofType(ArchiveTrigger),
      switchMap(({id}) => this.triggers
        .archive(id)
        .pipe(
          tap(() => this.toast.success('triggerArchived')),
          map(trigger => ArchiveTriggerComplete({trigger})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  unArchiveTrigger$ = this.actions$
    .pipe(
      ofType(UnArchiveTrigger),
      switchMap(({id}) => this.triggers
        .unarchive(id)
        .pipe(
          tap(() => this.toast.success('triggerUnarchived')),
          map((trigger) => UnArchiveTriggerComplete({trigger})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  deleteTrigger$ = this.actions$
    .pipe(
      ofType(DeleteTrigger),
      switchMap(({id}) => this.triggers
        .delete(id)
        .pipe(
          tap(() => this.toast.success('triggerDeleted')),
          map(() => DeleteTriggerComplete({id})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  deleteBatchTrigger$ = this.actions$
    .pipe(
      ofType(DeleteBatchTrigger),
      switchMap(({ids, silent}) => this.triggers
        .deleteBatch(ids)
        .pipe(
          tap(() => {
            if (!silent) {
              this.toast.success('triggersDeleted')
            }
          }),
          map(() => DeleteBatchTriggerComplete({ids})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  skipBooking$ = this.actions$
    .pipe(
      ofType(TriggerSkipInquiry),
      concatMap(({request}) => this.inquiries
        .skipTrigger(request)
        .pipe(
          tap(() => {
            if (!request.silent) {
              this.toast.success('triggerBookingSkip');
            }
          }),
          map(response => TriggerSkipInquiryComplete({response})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  unSkipBooking$ = this.actions$
    .pipe(
      ofType(TriggerUnSkipInquiry),
      concatMap(({request}) => this.inquiries
        .unSkipTrigger(request)
        .pipe(
          tap(() => {
            if (!request.silent) {
              this.toast.success('triggerBookingResume');
            }
          }),
          map(response => TriggerUnSkipInquiryComplete({response})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  loadSkippedBookings$ = this.actions$
    .pipe(
      ofType(LoadTriggerSkippedBookings),
      switchMap(({ids}) => this.triggers
        .loadSkippedBookingTriggers(ids)
        .pipe(
          map(response => LoadTriggerSkippedBookingsComplete({responses: response})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  removeFromWorkflow$ = this.actions$
    .pipe(
      ofType(RemoveFromWorkflow),
      switchMap(({request}) => this.triggers
        .removeFromWorkflow(request)
        .pipe(
          map(response => RemoveFromWorkflowComplete({trigger: response})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  constructor(private actions$: Actions,
              private store: Store<fromRoot.State>,
              private inquiries: BookingService,
              private templates: TemplatesService,
              private toast: ToastService,
              private dialogService: DialogService,
              private triggers: TriggersService) {
  }
}
