import {TriggerResponse} from '../interfaces/trigger/trigger-response';
import * as moment from 'moment';
import {TriggerSettings} from '../interfaces/trigger/trigger-settings';
import {TriggerAttributes} from '../interfaces/trigger/trigger-attributes';
import {TriggerService} from '../types/trigger-service';
import {TriggerCondition} from '../features/conditions/models/trigger-condition';
import * as R from 'ramda';
import {Taggable} from '../interfaces/taggable';
import {TriggerEvents} from './trigger-events';
import {TriggerHandlers} from './trigger-handlers';
import {TriggerSaveRequest} from '../interfaces/trigger/trigger-save-request';
import {readWorkflows} from '../utility/functions/read-workflows';
import {getTriggerName} from '../utility/functions/get-trigger-name';
import {TriggersCreateRequest} from '@automata/interfaces/trigger/triggers-create-request';
import {TriggerHandler} from '@automata/enums/trigger-handler';
import {TruncatePipe} from '@automata/pipes/truncate.pipe';

export class Trigger implements Taggable {
  attributes: TriggerAttributes;
  tagsFormatted = '';
  settings: TriggerSettings;

  template: string;
  templateId: string;
  templateName: string;
  templateDescription: string;

  status: number;

  handler: TriggerHandler;
  handlerName: string;

  users: string[];
  archived: number;
  pkey: string;

  timeevent: string;

  event: string;
  eventId: string;
  eventName: string;

  lastupdate: number;
  lastUpdate: Date;
  lastUpdateFormatted: string;
  lastUpdateFormattedShort: string;

  service: TriggerService = 'automata';
  account: number;

  created: number;
  createdFormatted: string;
  createdFormattedLong: string;

  hasDetails: boolean;
  pendingDetails: boolean;

  id: string;
  name: string;
  nameShort: string;

  rentalName: string;

  recipientsNames: string;
  channelName: string;
  channelToken: string;
  rentalId: string;

  skipped = 0;

  conditions: TriggerCondition[] = [];
  conditionsDescriptions: string;

  skippedInquiries: string[] = [];

  constructor(trigger?: Trigger) {
    if (trigger) {
      Object.assign(<Trigger>this, trigger);
    }
  }

  toUpdate(): TriggerSaveRequest {
    let workflows = R.map(R.compose(R.toLower, R.join('_'), R.split(' '), R.trim))(this.attributes.workflows);

    const attributes = R.merge(this.attributes, {workflows});

    // @todo - check if timeevent is required on backend
    return <TriggerSaveRequest>{
      id:          this.id,
      event:       this.event,
      timeevent:   this.event,
      days:        this.settings.days,
      hours:       this.settings.hours,
      status:      this.status,
      channel:     this.settings.channel,
      rental:      this.settings.rental,
      not_rentals: this.settings.not_rentals,
      handler:     this.handler,
      service:     'automata',
      attributes:  attributes,
      users:       <string[]>this.settings.users,
      template:    this.settings.template
    };
  }

  toCreateMulti(): TriggersCreateRequest {

    let workflows = R.map(R.compose(R.toLower, R.join('_'), R.split(' '), R.trim))(this.attributes.workflows);

    const attributes = R.merge(this.attributes, {workflows});

    return {
      event:      this.event,
      timeevent:  this.timeevent,
      days:       this.settings.days,
      hours:      this.settings.hours,
      status:     this.status,
      handler:    this.handler,
      service:    'automata',
      conditions: this.conditions || [],
      rentals:    [this.rentalId],
      attributes: {
        name:        this.name,
        tags:        attributes.tags,
        delayed:     attributes.delayed,
        workflows:   attributes.workflows,
        bookingTags: attributes.bookingTags
      },
      channels:   [this.channelToken],
      users:      this.users,
      template:   this.template
    } as TriggersCreateRequest;
  }

  fromResponse(response: TriggerResponse) {
    this.settings = response.settings;

    this.template = response.template;
    this.templateId = response.template;

    this.status = response.status;

    this.handler = response.handler;
    this.handlerName = TriggerHandlers.getHandlerName(response.handler);

    this.users = response.users;
    this.archived = response.archived;
    this.pkey = response.pkey;
    this.service = response.service;

    this.event = response.event;
    this.eventId = response.event;
    this.eventName = TriggerEvents.getEventName(response.event);

    this.created = response.created;

    this.lastupdate = response.lastupdate;
    this.lastUpdate = moment.unix(response.lastupdate).toDate();

    this.account = response.account;
    this.attributes = response.attributes;
    this.conditions = <any>R.map(c => new TriggerCondition(c), response.conditions || []);

    this.id = response.pkey;

    const friendlyId = response.pkey.toUpperCase().substr(response.pkey.length - 5);
    let name = getTriggerName(response);
    this.name = name ? `${name} - ${friendlyId}` : friendlyId;
    this.nameShort = new TruncatePipe().transform(this.name, 25);

    this.createdFormatted = moment.unix(response.created).format('DD - MMM - YYYY');
    this.createdFormattedLong = moment.unix(response.created).format('DD - MMM - YYYY | h:mm A');

    this.lastUpdateFormattedShort = response.lastupdate === 0 ? 'Never' : moment.unix(response.lastupdate).format('DD - MMM - YYYY');
    this.lastUpdateFormatted = response.lastupdate === 0 ? 'Never' : moment.unix(response.lastupdate).format('DD - MMM - YYYY | h:mm A');

    if (response.settings) {
      this.channelToken = response.settings.channel;
      this.rentalId = response.settings.rental;
    }

    if (!this.attributes) {
      this.attributes = {tags: [], handler: '', delayed: 0, workflows: [], bookingTags: []} as TriggerAttributes;
    }

    if (!R.is(Array, this.attributes.tags)) {
      this.attributes = R.merge(this.attributes, {tags: []});
    }

    if (!R.is(Array, this.attributes.workflows)) {
      this.attributes = R.merge(this.attributes, {workflows: []});
    }

    if (!R.is(Array, this.attributes.bookingTags)) {
      this.attributes = R.merge(this.attributes, {bookingTags: []});
    }

    this.attributes = {
      ...this.attributes,
      tags: R.map(R.pipe(R.when(R.is(Number), R.toString)))(this.attributes.tags)
    };
    this.tagsFormatted = this.attributes.tags.join(', ');

    this.attributes.workflows = readWorkflows(this.attributes);
  }
}
