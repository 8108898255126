import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {LoadChannels, LoadChannelsComplete} from '../actions/channels.actions';
import {ChannelsService} from '../services/channels.service';
import {of} from 'rxjs';
import {catchError, map, switchMapTo} from 'rxjs/operators';
import {ActionFailed} from '../actions/utility.actions';

@Injectable()
export class ChannelsEffects {

  @Effect()
  loadChannels$ = this.actions$
    .pipe(
      ofType(LoadChannels),
      switchMapTo(
        this.channels
          .all()
          .pipe(
            map(channels => LoadChannelsComplete({channels})),
            catchError(error => of(ActionFailed({error})))
          )
      )
    );

  constructor(private actions$: Actions,
              private channels: ChannelsService) {
  }
}
