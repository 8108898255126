<app-activity-table-controls [heading]="'pageTitle.successTriggers' | translate" (search)="applyFilter($event)"
                             [filter]="filter$"
                             [notifications]="notifications$"></app-activity-table-controls>

<!-- Content Holder -->
<div class="content-holder clearfix">

  <mat-table #table matSort [dataSource]="dataSource" class="table-without-select"
             [ngClass]="{minHeight: !(isEmptyTable$ | async)}">
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.date' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
                <span [matTooltip]="element.dateFormattedLong"
                      matTooltipPosition="above"
                      matTooltipClass="text-center">{{element.dateFormatted}}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="guestName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.guest' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element" (click)="openBookingDetails(element);$event.stopPropagation()">
        <span *ngIf="!!element.inquiry" [tooltip]="tolTemplate">
          {{element.guestName || '.....'}}
        </span>
        <span *ngIf="!element.inquiry">
          {{element.guestName || '.....'}}
        </span>
        <ng-template #tolTemplate>
          <div class="tal arrive-depart-tooltip">
            <strong>{{'tooltips.arrive' | translate}}</strong> {{element.inquiry?.guest_arrive | epoch:'DD - MMM - YYYY'}}
            <br>
            <strong>{{'tooltips.depart' | translate}}</strong> {{element.inquiry?.guest_depart | epoch:'DD - MMM - YYYY'}}
          </div>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="eventName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.event' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"><span [ngStyle]="{background: element.eventColor}"
                                                class="event-color"></span> {{element.eventName}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="templateName">
      <mat-header-cell *matHeaderCellDef
                       mat-sort-header> {{'tableColumns.template' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.templateName || '...'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="channelName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.channel' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.channelName || '...'}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openTrigger(row)"
             [ngClass]="{hasNoTrigger: !row.hasTrigger}"></mat-row>
  </mat-table>

  <app-translated-pagination>
    <mat-paginator #paginator [ngClass]="{hidden: (isEmptyTable$ | async)}"
                   [length]="dataSource?.data?.length"
                   [pageSize]="5" [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
                   [showFirstLastButtons]="true">
    </mat-paginator>
  </app-translated-pagination>

  <app-empty-table *ngIf="(isEmptyTable$ | async)" [type]="tableType"></app-empty-table>
</div>
