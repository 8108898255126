import {Component, Inject, OnInit} from '@angular/core';
import {Template} from '@automata/models/template';
import {select, Store} from '@ngrx/store';
import {DeleteTemplate, DeleteTemplateComplete} from '@automata/actions/templates.actions';
import {selectAllTriggers} from '@automata/reducers';
import {Trigger} from '@automata/models/trigger';
import {DeleteBatchTrigger} from '@automata/actions/triggers.actions';
import {Actions, ofType} from '@ngrx/effects';
import {tap} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

@Component({
  selector:    'app-remove-template-dialog',
  templateUrl: './remove-template-dialog.component.html',
  styleUrls:   ['./remove-template-dialog.component.scss']
})
export class RemoveTemplateDialogComponent extends OnDestroyMixin implements OnInit {

  triggers: Trigger[] = []

  constructor(public dialogRef: MatDialogRef<RemoveTemplateDialogComponent>,
              private store: Store<any>,
              private actions$: Actions,
              @Inject(MAT_DIALOG_DATA) public data: {template: Template}) {
    super()
  }

  ngOnInit() {
    this.actions$
      .pipe(
        ofType(DeleteTemplateComplete),
        tap(() => {
          this.close()
        }),
        untilComponentDestroyed(this)
      )
      .subscribe()

    this.store.pipe(select(selectAllTriggers), untilComponentDestroyed(this)).subscribe(triggers => {
      this.triggers = triggers.filter(t => t.template === this.data.template.id)
    })
  }

  close() {
    this.dialogRef.close();
  }

  onDeleteTriggers() {
    const ids = this.triggers.map(t => t.id)
    this.store.dispatch(DeleteBatchTrigger({ids}));
  }

  onDeleteTemplate() {
    this.store.dispatch(DeleteTemplate({id: this.data.template.id}));
  }

}
