import {UserRoleValue} from '../types/user-role-value';
import {UserAddressInfo} from '../interfaces/user/user-address-info';
import {UserSubscription} from '../interfaces/user/user-subscription';
import {CardInfo} from '../interfaces/user/card-info';
import {Expose} from 'class-transformer';
import {deserialize, Serializable} from '@automata/utility/serializers/serializers';

export class User extends Serializable<User> {

  @Expose({name: 'pkey'})
  id: string;
  token: string;

  firstname: string;
  lastname: string;
  account: number;
  email: string[];
  roles: UserRoleValue[];
  settings: any;
  lastlogin: number;
  created: number;
  archived: number;
  address: UserAddressInfo;
  primaryemail: string;
  username: string;
  resettoken: string;
  phone: string;
  subscriptions: UserSubscription[];
  card_info: CardInfo;
  network: number;

  static deserialize(data: object): User {
    return deserialize(User, data);
  }
}
