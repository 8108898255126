import {Pipe, PipeTransform} from '@angular/core';
import {isSomething} from '../utility/functions/is-something';

@Pipe({
  name: 'notificationGuestImage'
})
export class NotificationGuestImagePipe implements PipeTransform {

  defaultImage = 'assets/activity/user.png';

  transform(guestImage: string): string {
    if (isSomething(guestImage)) {
      return guestImage;
    } else {
      return this.defaultImage;
    }
  }

}
