import {LoadUsersComplete} from '../actions/users.actions';
import {User} from '../models/user';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';

export interface State extends EntityState<User> {
  selectedUserId: string | null
  isLoaded: boolean
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({
  sortComparer: (a: User, b: User) => a.firstname.toString().localeCompare(b.firstname)
});

export const initialState: State = adapter.getInitialState({
  selectedUserId: null,
  isLoaded:       false
});

export const reducer = createReducer(
  initialState,
  on(LoadUsersComplete, (state, {users}) => adapter.addMany(users, {
    ...state,
    isLoaded: true
  }))
);

export const {selectEntities, selectAll} = adapter.getSelectors();
