<div class="cells-placeholder"
     [ngClass]="item | notificationBoxClass">
  <div class="avater">
    <img [src]="item.guest?.pic_url | notificationGuestImage" alt="">
  </div>
  <div class="content">
    <div class="cell-line label-holder">
      <div class="ellips-text">
        <!--[ngStyle]="{background: item.event | eventColor}"-->
        <span class="label">{{item.link_type | uppercase}} {{item.action | uppercase}}</span>
      </div>
      <span class="time">{{item.date | timeAgo}}</span>
    </div>
    <div class="cell-line">
      <div class="ellips-text">
        <span>{{item.guest?.name}}</span><span
        [ngClass]="{hidden: item.guest?.name | isNil}"> - </span><span>{{item.rental?.name}}</span>
      </div>
    </div>
  </div>
</div>
