import {Serializable} from '@automata/utility/serializers/serializers';
import {Expose, plainToClass, Type} from 'class-transformer';
import {Address} from '@automata/models/address';
import * as R from 'ramda';

export class Rental extends Serializable<Rental> {

  id: string;
  pkey: string;

  @Expose({name: 'payment_instructions'})
  paymentInstructions: string;

  @Expose({name: 'size_metric'})
  sizeMetric: string;

  @Expose({name: 'sleep_min'})
  sleepMin: number;

  @Expose({name: 'sleep_max'})
  sleepMax: number;

  @Expose({name: 'display_name'})
  displayName: string;

  @Expose({name: 'payment_terms'})
  paymentTerms: string;

  @Expose({name: 'restricted_users'})
  restrictedUsers: string[];

  @Type(() => Address)
  address: Address;

  attributes?: object;

  detail: { [id: string]: boolean };

  instructions: {
    checkin: string,
    directions: string,
    checkout: string,
    rules: string
  };

  specifics: {
    sec_code: number | string,
    special_inst: string,
    key_pickup: number | string,
    wifi_pass: number | string;
  };

  lastupdate: string;
  tags: string[];
  created: number;
  account: number;
  size: number;
  description: string;
  archived: number;
  bathrooms: number;
  phone: number;
  name: string;
  email: string;
  type: string;
  bedrooms: number;

  custom1: string;
  custom2: string;
  custom3: string;
  custom4: string;
  custom5: string;

  currency;
  checkin;
  checkout;
  color: string;

  descriptionView: string;
  cityView: string;
  countryView: string;

  static deserialize(data: any): Rental {
    const rental: Rental = plainToClass<Rental, Rental>(Rental, data);

    rental.id = data.pkey;
    rental.pkey = data.pkey;

    rental.descriptionView = `${rental.bedrooms || 0} Bedrooms, ${rental.bathrooms || 0} Bathrooms ${rental.type || ''}`;
    rental.cityView = R.pathOr('', ['address', 'city'], rental);
    rental.countryView = R.pathOr('', ['address', 'country'], rental);
    rental.bedrooms = parseInt(`${rental.bedrooms}`, 10);
    rental.bathrooms = parseInt(`${rental.bathrooms}`, 10);
    rental.sleepMin = parseInt(`${rental.sleepMin}`, 10);
    rental.sleepMax = parseInt(`${rental.sleepMax}`, 10);

    rental.custom1 = R.pipe(R.when(R.is(Number), R.toString))(rental.custom1);
    rental.custom2 = R.pipe(R.when(R.is(Number), R.toString))(rental.custom2);
    rental.custom3 = R.pipe(R.when(R.is(Number), R.toString))(rental.custom3);
    rental.custom4 = R.pipe(R.when(R.is(Number), R.toString))(rental.custom4);
    rental.custom5 = R.pipe(R.when(R.is(Number), R.toString))(rental.custom5);

    rental.instructions = rental.instructions || <any>{};

    rental.instructions.checkin = R.pipe(R.when(R.is(Number), R.toString))(rental.instructions.checkin);
    rental.instructions.directions = R.pipe(R.when(R.is(Number), R.toString))(rental.instructions.directions);
    rental.instructions.checkout = R.pipe(R.when(R.is(Number), R.toString))(rental.instructions.checkout);
    rental.instructions.rules = R.pipe(R.when(R.is(Number), R.toString))(rental.instructions.rules);

    rental.specifics = rental.specifics || <any>{};

    rental.specifics.sec_code = R.pipe(R.when(R.is(Number), R.toString))(rental.specifics.sec_code);
    rental.specifics.special_inst = R.pipe(R.when(R.is(Number), R.toString))(rental.specifics.special_inst);
    rental.specifics.key_pickup = R.pipe(R.when(R.is(Number), R.toString))(rental.specifics.key_pickup);
    rental.specifics.wifi_pass = R.pipe(R.when(R.is(Number), R.toString))(rental.specifics.wifi_pass);

    rental.description = R.pipe(R.when(R.is(Number), R.toString))(rental.description);

    return rental;
  }

}
