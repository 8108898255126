import {Pipe, PipeTransform} from '@angular/core';
import * as R from 'ramda';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'workflowTriggerStatus'
})
export class WorkflowTriggerStatusPipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }

  transform(status: number): string {
    if ((R.is(Number, status) && status === 0) || (R.is(Boolean, status) && !status)) {
      return this.translate.instant('tooltips.triggerIsDisabled');
    } else {
      return this.translate.instant('tooltips.triggerIsEnabled');
    }
  }

}
