import {Component, Input, OnInit} from '@angular/core';
import {Trigger} from '../../../models/trigger';

@Component({
  selector:    'app-trigger-tooltip',
  templateUrl: './trigger-tooltip.component.html',
  styleUrls:   ['./trigger-tooltip.component.scss']
})
export class TriggerTooltipComponent implements OnInit {

  @Input() trigger: Trigger;

  constructor() {
  }

  ngOnInit() {
  }

}
