<div class="dialog-header">
  <h2 mat-dialog-title>
    <i class="fal fa-user-secret"></i> {{'pageElements.changePassword' | translate}}
  </h2>
  <button (click)="close()" class="close" aria-label="Close" type="button">
    <span class="far fa-times"></span>
  </button>
</div>

<div class="row center-row">
  <div class="col-sm-12">
    <form [formGroup]="form">
      <mat-form-field>
        <input matInput [placeholder]="'formControls.newPassword' | translate" formControlName="password1"
               type="password">
        <mat-error
          *ngIf="form.get('password1').hasError('required')">
          <span [innerHtml]="'validations.passwordRequired' | translate"></span>
        </mat-error>
        <mat-error
          *ngIf="form.get('password1').hasError('minNumber')">
          <span [innerHtml]="'validations.passwordAtLeast8' | translate"></span>
        </mat-error>
        <mat-error
          *ngIf="form.get('password1').hasError('maxNumber')">
          <span [innerHtml]="'validations.passwordAtMost32' | translate"></span>
        </mat-error>
        <mat-error
          *ngIf="form.get('password1').hasError('pattern')">
          <span [innerHtml]="'validations.passwordNoSpaces' | translate"></span>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput [placeholder]="'formControls.repeatPassword' | translate" formControlName="password2"
               type="password">
        <mat-error
          *ngIf="form.get('password2').hasError('required')">
          <span [innerHtml]="'validations.passwordRequired' | translate"></span>
        </mat-error>
        <mat-error
          *ngIf="form.get('password2').hasError('minlength')">
          <span [innerHtml]="'validations.passwordAtLeast8' | translate"></span>
        </mat-error>
        <mat-error
          *ngIf="form.get('password2').hasError('maxlength')">
          <span [innerHtml]="'validations.passwordAtMost32' | translate"></span>
        </mat-error>
        <mat-error
          *ngIf="form.get('password2').hasError('pattern')">
          <span [innerHtml]="'validations.passwordNoSpaces' | translate"></span>
        </mat-error>
        <mat-error
          *ngIf="form.get('password2').errors?.passwordMatch">
          <span [innerHtml]="'validations.passwordNoMatch' | translate"></span>
        </mat-error>
      </mat-form-field>
    </form>
  </div>
</div>

<div class="text-right">
  <button (click)="close()" class="btn btn-default left-icon mr10 text-uppercase" type="button">
    <i class="far fa-check-square"></i> {{'buttons.cancel' | translate}}
  </button>
  <button (click)="save()" [disabled]="form.invalid" class="btn btn-new left-icon text-uppercase" type="button">
    <i class="far fa-save"></i> {{'buttons.save' | translate}}
  </button>
</div>
