<form [formGroup]="loginForm" (ngSubmit)="login.emit(loginForm.value)" class="login-form">
  <div class="clearfix">

    <mat-form-field class="prepend-icon">
      <mat-icon>
        <i class="far fa-envelope"></i>
      </mat-icon>
      <input matInput placeholder="Email" formControlName="email">
      <mat-error *ngIf="email.invalid && email.touched && email.errors?.email">
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="email.invalid && email.touched && email.errors?.required">
        Email is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>
  <div class="clearfix">

    <mat-form-field class="prepend-icon">
      <mat-icon>
        <i class="far fa-lock"></i>
      </mat-icon>
      <input matInput placeholder="Password" type="password" formControlName="password">
      <mat-error *ngIf="password.invalid && password.touched && password.errors?.required">
        Password is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="password.invalid && password.touched && password.errors?.minlength">
        Password must be at least 8 characters long
      </mat-error>
    </mat-form-field>
  </div>
  <div class="row form-group checkbox-holder">
    <div class="col-xs-7">
      <mat-checkbox formControlName="keepAlive">Keep me signed in</mat-checkbox>
    </div>
    <div class="col-xs-5">
      <a href="https://app.tokeet.com/forgot" class="forgot-link">Forgot Password?</a>
    </div>
  </div>

  <div class="text-center pt10 mt5 border-top">
    <i class="fas fa-user-plus"></i> Don't have an account? <a class="link" href="https://register.tokeet.com/">Signup
    for free.</a>
  </div>

  <div class="form-group login-btn-holder">
    <button class="btn btn-cst-danger text-uppercase" type="submit">
      sign in <i class="far fa-arrow-right" aria-hidden="true"></i>
    </button>
  </div>
</form>
