import {concat, Observable, of, OperatorFunction, throwError} from 'rxjs';
import {catchError, delay, retryWhen, shareReplay, take} from 'rxjs/operators';

export function retryConfig(errors, entity, retryCount = 4) {
  return concat(
    errors.pipe(delay(1000), take(retryCount)),
    throwError({error: `There was an error loading ${entity} (after ${retryCount} retries)`})
  );
}

export function retryEntity<T>(entityName: string, toast: any, retryCount?: number): OperatorFunction<T, T> {
  return function(source$: Observable<T>): Observable<T> {
    return source$.pipe(
      shareReplay(),
      retryWhen(errors => retryConfig(errors, entityName)),
      catchError(error => {
        toast.error(error.error);
        return of(<any>[]);
      })
    );
  };
}
