import {Pipe, PipeTransform} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '@automata/reducers';
import {map, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {isSignatureActive, selectTriggerById} from '@automata/reducers';
import {TriggerHandler} from '@automata/enums/trigger-handler';

@Pipe({
  name: 'triggerUnsupported'
})
export class TriggerUnsupportedPipe implements PipeTransform {

  transform(triggerId: string): Observable<boolean> {
    return this.store.pipe(
      isSignatureActive,
      switchMap(isActive => this.store.pipe(
        select(fromRoot.selectTriggerById(triggerId)),
        map(trigger => {
          return !isActive && (trigger?.handler === TriggerHandler.SendContract ||
          trigger?.handler === TriggerHandler.SendForm ||
          trigger?.handler === TriggerHandler.SendDocument);
        })
        )
      )
    );
  }

  constructor(private store: Store<fromRoot.State>) {
  }
}
