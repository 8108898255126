import {createAction, props} from '@ngrx/store';
import {Code} from '@automata/models/code';
import {CodeAddRequest} from '@automata/interfaces/code/code-add-request';
import {CodeUpdateRequest} from '@automata/interfaces/code/code-update-request';

export const LoadCodes = createAction('[Codes] Load Start');
export const LoadCodesComplete = createAction('[Codes] Load Complete', props<{codes: Code[]}>());

export const AddCode = createAction('[Codes] Add Start', props<{request: CodeAddRequest}>());
export const AddCodeComplete = createAction('[Codes] Add Complete', props<{code: Code}>());

export const UpdateCode = createAction('[Codes] Update Start', props<{request: CodeUpdateRequest}>());
export const UpdateCodeComplete = createAction('[Codes] Update Complete', props<{code: Code}>());

export const DeleteCode = createAction('[Codes] Delete Code Start', props<{id: string}>());
export const DeleteCodeComplete = createAction('[Codes] Delete Code Complete', props<{id: string}>());

export const DeleteBatchCode = createAction('[Codes] Delete Batch Start', props<{ids: string[]}>());
export const DeleteBatchCodeComplete = createAction('[Codes] Delete Batch Complete', props<{ids: string[]}>());
