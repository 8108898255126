import {Component, Input} from '@angular/core';
import {Notification} from '../../../../models/notification';

@Component({
  selector:    'app-activity-list-item',
  templateUrl: './activity-list-item.component.html',
  styleUrls:   ['./activity-list-item.component.scss']
})
export class ActivityListItemComponent {

  @Input() item: Notification;

}
