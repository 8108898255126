import {Component, EventEmitter, Output} from '@angular/core';
import {TableUtilityService} from '../../../services/table-utility.service';

@Component({
  selector:    'app-column-edit-control',
  templateUrl: './column-edit-control.component.html',
  styleUrls:   ['./column-edit-control.component.scss']
})
export class ColumnEditControlComponent {

  @Output() select = new EventEmitter<boolean>();

  isBadgeVisible = this.tableService.isCustomizationBadgeVisible();

  constructor(private tableService: TableUtilityService) {

  }

  onClick() {
    this.isBadgeVisible = false;
    this.tableService.hideCustomizationBadge();
    this.select.emit(true);
  }

}
