export enum ConditionValueFormType {
  DaysHours,
  Nights,
  Number,
  Country,
  None,
  Trigger,
  TriggerTag,
  InquiryStatus,
  HoursMinutes
}
