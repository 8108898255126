import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {TableType} from '../../../enums/table-type';
import {BehaviorSubject, combineLatest, of, Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {TriggerNotificationTableModel} from '../../../models/trigger-notification-table-model';
import * as R from 'ramda';
import {isSomething} from '../../../utility/functions/is-something';
import {distinctUntilChanged, filter, map, switchMap, take, tap} from 'rxjs/operators';
import {Trigger} from '../../../models/trigger';
import {DialogService} from '../../../services/dialog.service';
import {TriggerDialogComponent} from '../../trigger/trigger-dialog/trigger-dialog.component';
import {TableUtilityService} from '../../../services/table-utility.service';
import {BookingDialogComponent} from '../../bookings/booking-dialog/booking-dialog.component';
import {ToastService} from '@automata/services/toast.service';
import {localeCompareSort} from '@automata/utility/functions/locale-compare-sort';
import {TabTypes} from '@automata/enums/tab-types';
import {TranslateService} from '@ngx-translate/core';
import {selectInquiryByGuestId} from '@automata/containers/bookings-page/store/booking.selectors';

@Component({
  selector:    'app-triggers-fail-table',
  templateUrl: './triggers-fail-table.component.html',
  styleUrls:   ['./triggers-fail-table.component.scss']
})
export class TriggersFailTableComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns = [
    'date',
    'guestName',
    'eventName',
    'templateName',
    'channelName',
    'options'
  ];

  dataSource = new MatTableDataSource<TriggerNotificationTableModel>();

  tableType = TableType.FailTriggers;

  notificationsSub: Subscription;
  bookingDetailsSub: Subscription;

  searchFilter = '';
  filter$ = new BehaviorSubject('');

  notifications$ = this.store.pipe(select(fromRoot.selectTriggerNotificationTableModel(false)));

  hasMore$ = this.store.pipe(select(fromRoot.selectHasMoreActivities));
  isEmptyTable$ = this.tableService.isEmptyTable(this.dataSource);

  constructor(private store: Store<fromRoot.State>,
              private dialogService: DialogService,
              private translate: TranslateService,
              private tableService: TableUtilityService,
              private toast: ToastService) {
  }

  ngOnInit() {
    this.dataSource.data = [];
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortData = localeCompareSort;

    this.notificationsSub = combineLatest(
      this.notifications$,
      this.filter$
    )
      .pipe(
        map(([activities, filter]) => {
          if (isSomething(filter)) {
            return R.filter(a => a.event === filter, activities);
          } else {
            return activities;
          }
        })
      )
      .subscribe(activities => {
        this.paginator.firstPage();
        setTimeout(() => this.dataSource.data = activities, 0);
      });
  }

  ngOnDestroy() {
    this.notificationsSub.unsubscribe();
    if (this.bookingDetailsSub) {
      this.bookingDetailsSub.unsubscribe();
    }
  }

  openTrigger(notification: TriggerNotificationTableModel, activeTab = TabTypes.Activity) {
    of(notification.triggerId)
      .pipe(
        filter(id => isSomething(id)),
        distinctUntilChanged(),
        switchMap(id => this.store.pipe(select(fromRoot.selectTriggerById(id)))),
        take(1),
        tap((trigger) => {
          if (R.isNil(trigger)) {
            this.toast.warning('triggerNoLonger');
          }
        }),
        filter(trigger => isSomething(trigger))
      )
      .subscribe((trigger: Trigger) => {
        console.log(`Editing trigger ${trigger.id}`);
        this.dialogService.openSide(TriggerDialogComponent, {data: {triggerId: trigger.id, activeTab}});
      });
  }

  onTriggerDetails(notification: TriggerNotificationTableModel) {
    this.openTrigger(notification, TabTypes.Overview);
  }

  onEventLog(notification: TriggerNotificationTableModel) {
    this.dialogService.openAlert({
      title: `Trigger Error`,
      body:  notification.eventMessage
    });
  }

  openBookingDetails(model: TriggerNotificationTableModel) {
    const guestId = model.guest;

    this.bookingDetailsSub = this.store.pipe(select(selectInquiryByGuestId(guestId)))
      .pipe(
        take(1)
      )
      .subscribe(inquiry => {
        if (isSomething(inquiry) && isSomething(inquiry.associatedTriggers)) {
          console.log(`Editing booking detail for inquiry ${inquiry.pkey}`);
          this.dialogService.openSide(BookingDialogComponent, {data: {inquiry}});
        } else {
          this.toast.info('noTriggersMatchBooking');
        }
      });
  }

  applyFilter(term: string) {
    this.paginator.firstPage();
    this.searchFilter = term;
    this.dataSource.filter = term.trim().toLowerCase();
  }
}
