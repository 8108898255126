import {Pipe, PipeTransform} from '@angular/core';
import {Trigger} from '../models/trigger';
import * as R from 'ramda';

@Pipe({
  name: 'listOfTriggers'
})
export class ListOfTriggersPipe implements PipeTransform {

  transform(triggers: Trigger[]): string {
    return R.join(', ', R.map((t: Trigger) => t.name, triggers));
  }

}
