import {IActivityType} from '../interfaces/activity-type';
import {ActivityType} from '../enums/activity-type';
import * as R from 'ramda';
import {TriggerHandler} from '../enums/trigger-handler';

export class ActivityTypes {
  static types: IActivityType[] = [
    {
      name:    'Emails',
      altName: 'Emails',
      type:    ActivityType.Email,
      color:   '#ffc600',
      i18n:    'activityType.email'
    },
    {
      name:    'SMS',
      altName: 'SMS',
      type:    ActivityType.SMS,
      color:   '#ff781c',
      i18n:    'activityType.sms'
    },
    {
      name:    'HTTP',
      altName: 'HTTP',
      type:    ActivityType.Http,
      color:   '#bf5ffe',
      i18n:    'activityType.http'
    },
    {
      name:    'Notifications',
      altName: 'Notificaciones',
      type:    ActivityType.Notifications,
      color:   '#02f140',
      i18n:    'activityType.notification'
    },
    {
      name:    'Follow Up',
      altName: 'Seguir',
      type:    ActivityType.FollowUp,
      color:   '#3adaf7',
      i18n:    'activityType.followUp'
    },
    {
      name:    'Contracts',
      altName: 'Contratos',
      type:    ActivityType.Contracts,
      color:   '#1840ee',
      i18n:    'activityType.contracts'
    },
    {
      name:    'Booking Tags',
      altName: 'Etiquetas de reserva',
      type:    ActivityType.BookingTags,
      color:   '#38a4ee',
      i18n:    'activityType.bookingTags'
    }
  ];

  static getColorByName(name: string) {
    let foundType = R.find(R.propEq('name', name), ActivityTypes.types);
    if (!foundType) {
      foundType = R.find(R.propEq('altName', name), ActivityTypes.types);
    }
    return !R.isNil(foundType) ? foundType.color : '';
  }

  static getHandlerNameByCode(code: string) {
    switch (code) {
      case TriggerHandler.SendEmailMessage:
        return 'activityType.email';
      case TriggerHandler.SendSMSMessage:
        return 'activityType.sms';
      case TriggerHandler.SendHTTPPost:
        return 'activityType.http';
      case TriggerHandler.SendPushNotification:
        return 'activityType.notification';
      case TriggerHandler.SendFollowUp:
      case TriggerHandler.RemoveSendFollowUp:
        return 'activityType.followUp';
      case 'Handlers::GuestFormHandler':
        return 'activityType.guestForm';
      case TriggerHandler.SendContract:
      case TriggerHandler.SendDocument:
      case TriggerHandler.SendForm:
        return 'activityType.contracts';
      case TriggerHandler.AddTagHandler:
      case TriggerHandler.RemoveTagHandler:
        return 'activityType.bookingTags';
    }
  }
}
