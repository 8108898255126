import {Inject, Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ToastIconClasses} from '@automata/utility/models/toast-icon-classes';
import {take} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {HttpErrorResponse} from '@angular/common/http';
import {APP_CONFIG, IAppConfig} from '@automata/app.config';
import {compact, get, isObject, isString} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastr: ToastrService,
              @Inject(APP_CONFIG) private config: IAppConfig,
              private translateService: TranslateService) {
  }

  success(key: string) {
    const type = 'toast.title.success';
    const translationKey = `toast.success.${key}`;
    this.translateService.get([translationKey, type]).pipe(take(1))
      .subscribe(result => {
        this.toastr.show(result[translationKey], result[type], {toastClass: ToastIconClasses.Success});
      });
  }

  warning(key: string) {
    const type = 'toast.title.warning';
    const translationKey = `toast.warning.${key}`;
    this.translateService.get([translationKey, type]).pipe(take(1))
      .subscribe(result => {
        this.toastr.show(result[translationKey], result[type], {toastClass: ToastIconClasses.Warning});
      });
  }

  info(key: string) {
    const type = 'toast.title.info';
    const translationKey = `toast.info.${key}`;
    this.translateService.get([translationKey, type]).pipe(take(1))
      .subscribe(result => {
        this.toastr.show(result[translationKey], result[type], {toastClass: ToastIconClasses.Info});
      });
  }

  error(key?: string, error?: any) {
    if (key) {
      const type = 'toast.title.error';
      const translationKey = `toast.error.${key}`;
      this.translateService.get([translationKey, type]).pipe(take(1))
        .subscribe(result => {
          this.toastr.show(result[translationKey], result[type], {toastClass: ToastIconClasses.Error});
        });
    } else if (error) {
      const errorMessage = this.getErrorMessage(error);

      let message = '';

      if (errorMessage) {
        message = errorMessage;
      } else {
        message = compact([message, this.getErrorMessage(error)]).join('<br>');
      }
      this.toastr.show(message, 'Error', {toastClass: ToastIconClasses.Error});
    } else {
      this.toastr.show('Something went wrong', 'Error', {toastClass: ToastIconClasses.Error, enableHtml: true});
    }
  }

  private getErrorMessage(error: any): string {
    if (error instanceof HttpErrorResponse) {
      const response: HttpErrorResponse = error as HttpErrorResponse;

      if (response.url && response.url.startsWith(this.config.apiUrl)) {
        const textError = get(response, 'error.error');

        if (textError && isString(textError)) {
          return textError;
        } else if (isObject(textError)) {
          return get(textError, 'message');
        } else {
          return get(error, 'error');
        }
      }
    } else if (isString(error)) {
      return error;
    } else if (isObject(error)) {
      return get(error, 'error');
    }
    return null;
  }
}
