import {createFeatureSelector, createSelector, select, Store} from '@ngrx/store';
import * as R from 'ramda';
import {Rental} from '@automata/models/rental';
import {Trigger} from '@automata/models/trigger';
import {TriggerEvents} from '@automata/models/trigger-events';
import {Workflow} from '@automata/models/workflow';
import {AutomataLogModel} from '@automata/models/automata-log-model';
import {combineLatest} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {isSomething} from '@automata/utility/functions/is-something';
import {selectAllRentals, selectTriggerEntities, selectTriggersWithTemplates, selectWorkflows} from '@automata/reducers';
import {selectAll, selectEntities, State} from '@automata/containers/bookings-page/store/booking.reducer';
import {BookingView} from '@automata/containers/bookings-page/store/booking.model';

declare const require: any;

const startsWith = require('lodash/startsWith');

export const bookingState = createFeatureSelector<State>('bookings');

export const selectBookingsLoaded = createSelector(bookingState, (state: State) => state.isLoaded);
export const selectAllBookings = createSelector(bookingState, selectAll);
export const selectBookingEntities = createSelector(bookingState, selectEntities);
export const selectHasMoreBookings = createSelector(bookingState, (state: State) => state.hasMore);

export const selectBookingsWithRentalsAndWorkFlows = createSelector(
  selectAllBookings, selectAllRentals, selectWorkflows, selectTriggerEntities, selectTriggersWithTemplates,
  (bookings, rentals, workflows, triggerEntities, triggers) => {
    return R.map((booking: BookingView) => {
      booking = {...booking};
      const rental: Rental = R.find(
        R.propEq('id', booking.rental_id),
        rentals
      );
      if (!R.isNil(rental)) {
        booking.rental = rental;
      }

      let triggersWithRentalAndChannelOrInvoice = R.filter((t: Trigger) => (TriggerEvents.allowsRental(t.event) && TriggerEvents.allowsChannel(t.event) || startsWith(t.event, 'invoice')), triggers);

      let inquiryRentalId = booking.rental ? booking.rental.id : null;

      let combinedTriggers = R.filter((t: Trigger) => {
        return (t.settings.rental === inquiryRentalId || !t.settings.rental) && (hasSameChannel(t.settings.channel, booking.inquiry_source) || !t.settings.channel);
      }, triggersWithRentalAndChannelOrInvoice);

      if (!R.isNil(inquiryRentalId)) {
        combinedTriggers = R.filter((t: Trigger) => !R.contains(inquiryRentalId, t.settings.not_rentals || []), combinedTriggers);
      }

      booking.associatedTriggers = combinedTriggers;

      booking.workflow = R.find((w: Workflow) => w.rentalId === booking.rental_id, workflows);

      booking.automataLogModels = <AutomataLogModel[]>R.map((log: AutomataLogModel) => {
        let logModel = new AutomataLogModel(log);
        logModel.triggerName = triggerEntities[log.triggerId] ? triggerEntities[log.triggerId].name : '';
        return logModel;
      }, booking.automataLogModels || []);

      const lastTriggerId = R.path(['automata_last_trigger', 'trigger'], booking);
      const lastTrigger = R.find(t => t.pkey === lastTriggerId, triggers);
      booking.lastTriggerName = R.pathOr('', ['name'], lastTrigger);

      return booking;
    }, bookings);
  }
);

export const hasSameChannel = (source: string, target: string) => {
  const re = new RegExp('booking', 'i');
  const isTargetBooking = re.test((target + '').toLowerCase());
  const isSourceBooking = re.test((source + '').toLowerCase());
  if (isTargetBooking && isSourceBooking) {
    return true;
  } else {
    return source === target;
  }
};

export const selectBookingById = (id: string) => createSelector(selectBookingsWithRentalsAndWorkFlows, (bookings) => {
  return bookings.find(b => b.pkey === id);
});

export const selectInquiryByGuestId = (id: string) => createSelector(selectBookingsWithRentalsAndWorkFlows, bookings => {
  return bookings.find(b => b.guest_id === id);
});

export const getBookingLogs = (id: string) => (store: Store<any>) => {
  return combineLatest([
    store.pipe(select(selectBookingEntities)),
    store.pipe(select(selectTriggerEntities))
  ]).pipe(
    map(([bookings, triggers]) => {
      let booking = bookings[id];
      if (booking && isSomething(booking.automataLogModels)) {
        booking = {
          ...booking,
          automataLogModels: booking.automataLogModels.map(log => ({
            ...log,
            triggerName: triggers[log.triggerId] ? triggers[log.triggerId].name : ''
          }) as AutomataLogModel)
        };
      }
      return booking;
    }),
    filter(booking => isSomething(booking))
  );
};
