<eco-fab-speed-dial [(open)]="open" [direction]="direction" [animationMode]="animationMode">

  <eco-fab-speed-dial-trigger [spin]="spin">
    <button [matTooltip]="'tooltips.getHelp' | translate" matTooltipPosition="left" matTooltipClass="text-center"
            mat-fab>
      <mat-icon>
        <i class="far fa-question-circle"></i>
      </mat-icon>
    </button>
  </eco-fab-speed-dial-trigger>

  <eco-fab-speed-dial-actions [ngClass]="{moveBtns: !open}">
    <button [matTooltip]="'tooltips.scheduleTraining' | translate" matTooltipPosition="left"
            matTooltipClass="text-center" mat-mini-fab (click)="goToScheduleTraining()" class="action">
      <mat-icon>
        <i class="icon far fa-calendar-alt"></i>
      </mat-icon>
    </button>
    <button matTooltip="Automata Portal" matTooltipPosition="left" matTooltipClass="text-center"
            mat-mini-fab (click)="goToPortal()">
      <mat-icon>
        <i class="far fa-lightbulb-on"></i>
      </mat-icon>
    </button>
    <button [matTooltip]="'tooltips.helpCenter' | translate" matTooltipPosition="left" matTooltipClass="text-center"
            mat-mini-fab (click)="goToHelp()">
      <mat-icon>
        <i class="far fa-book"></i>
      </mat-icon>
    </button>
    <button [matTooltip]="'tooltips.liveChat' | translate" matTooltipPosition="left" matTooltipClass="text-center"
            mat-mini-fab (click)="openChat()">
      <mat-icon>
        <i class="far fa-comments"></i>
      </mat-icon>
    </button>
  </eco-fab-speed-dial-actions>

</eco-fab-speed-dial>
