<div class="main-body integrations-page clearfix">
  <div class="container-fluid">
    <!-- Title & buttons -->
    <div class="row title-holder">
      <div class="col-xs-12 title">
        {{'pageTitle.integrations' | translate}}
        <span [matTooltip]="'pageTooltip.integrations' | translate"
              matTooltipPosition="above"
              matTooltipClass="text-center">
                <i class="far info-icon fa-question-circle"></i>
            </span>
      </div>
    </div>

    <!-- Content Holder -->
    <div class="content-holder clearfix">
      <div class="col-sm-12 content-header">
        {{'pageElements.intergrationsOptions' | translate}}
      </div>
      <div class="col-sm-12 content-body">
        <div class="box-holder">
          <div (click)="openInfo(integrations.Webhooks)" class="box">
            <div class="img-holder">
              <img src="assets/integrations/webhooks.png" alt="">
            </div>
            <h4 class="title">Webhooks</h4>
          </div>
        </div>
        <div class="box-holder">
          <div (click)="openInfo(integrations.Email)" class="box">
            <div class="img-holder">
              <img src="assets/integrations/email.png" alt="">
            </div>
            <h4 class="title">Email</h4>
          </div>
        </div>
        <div class="box-holder">
          <div (click)="openInfo(integrations.SMS)" class="box">
            <div class="img-holder">
              <img src="assets/integrations/sms.png" alt="">
            </div>
            <h4 class="title">SMS</h4>
          </div>
        </div>
        <div class="box-holder">
          <div class="box">
            <div class="img-holder">
              <img src="assets/integrations/delay.png" alt="">
            </div>
            <h4 class="title">Delay</h4>
          </div>
        </div>
        <div class="box-holder">
          <div class="box">
            <div class="img-holder">
              <img src="assets/integrations/formatter.png" alt="">
            </div>
            <h4 class="title">Formatter</h4>
          </div>
        </div>
        <div class="box-holder">
          <div (click)="openSlackInfo()" class="box">
            <div class="img-holder">
              <img src="assets/integrations/slack.png" alt="">
            </div>
            <h4 class="title">Slack</h4>
          </div>
        </div>
        <!--div class="box-holder">
            <div class="box">

                    <div class="img-holder">
                        <img src="assets/integrations/icontact.png" alt="">
                    </div>
                    <h4 class="title">iContact Pro</h4>

            </div>
        </div>
        <div class="box-holder">
            <div class="box">

                    <div class="img-holder">
                        <img src="assets/integrations/infusionsoft.png" alt="">
                    </div>
                    <h4 class="title">Infusionsoft</h4>

            </div>
        </div>
        <div class="box-holder">
            <div class="box">

                    <div class="img-holder">
                        <img src="assets/integrations/mandrill.png" alt="">
                    </div>
                    <h4 class="title">Mandrill</h4>

            </div>
        </div -->
        <div class="box-holder">
          <div class="box">
            <a (click)="openInfo(integrations.Tokeet)">
              <div class="img-holder">
                <img src="assets/integrations/tokeet.png" alt="">
              </div>
              <h4 class="title">Tokeet</h4>
            </a>
          </div>
        </div>
        <!--div class="box-holder">
            <div class="box">

                    <div class="img-holder">
                        <img src="assets/integrations/xero.png" alt="">
                    </div>
                    <h4 class="title">Xero</h4>

            </div>
        </div-->
        <!--<div class="box-holder">-->
        <!--<div class="box">-->

        <!--<div class="img-holder">-->
        <!--<img src="assets/integrations/zapier.png" alt="">-->
        <!--</div>-->
        <!--<h4 class="title">Zapier</h4>-->

        <!--</div>-->
        <!--</div>-->
        <!--<div (click)="openIFTTT()" class="box-holder">-->
        <!--<div class="box">-->
        <!--<div class="img-holder">-->
        <!--<img src="assets/integrations/ifttt.svg" alt="">-->
        <!--</div>-->
        <!--<h4 class="title">IFTTT</h4>-->
        <!--</div>-->
        <!--</div>-->
        <div (click)="openInfo(integrations.Signature)" class="box-holder">
          <div class="box">
            <div class="img-holder">
              <img src="assets/integrations/signature.png" alt="">
            </div>
            <h4 class="title">Signature</h4>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
