import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {APP_CONFIG, IAppConfig} from '../app.config';

const PREFIX_API = '@api/';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(@Inject(APP_CONFIG) private config: IAppConfig) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.startsWith(PREFIX_API)) {
      const url = this.config.apiUrl + request.url.slice(PREFIX_API.length - 1);

      request = request.clone({url});
    }

    return next.handle(request);
  }
}
