import * as R from 'ramda';

export const USER_ROLES = {
    admin:      {
        name:  'ADMIN',
        value: 1
    },
    manager:    {
        name:  'MANAGER',
        value: 1.1
    },
    accounting: {
        name:  'ACCOUNTING',
        value: 1.2
    },
    staff:      {
        name:  'STAFF',
        value: 1.5
    },
    owner:      {
        name:  'OWNER',
        value: 1.6
    },
    guest:      {
        name:  'GUEST',
        value: 2
    }
};

export enum RoleKeys {
    ADMIN = 'ADMIN',
    MANAGER = 'MANAGER',
    ACCOUNTING = 'ACCOUNTING',
    OWNER = 'OWNER',
    STAFF = 'STAFF',
    GUEST = 'GUEST',
}

export function userRoleToString(roles: number[]) {
    roles = R.filter(role => role > 0, roles || []);
    roles.sort((a, b) => a - b);
    let highestRole;
    R.forEachObjIndexed((role) => {
        if (role.value === roles[0]) {
            highestRole = role;
        }
    }, USER_ROLES);
    highestRole = highestRole || USER_ROLES.guest;
    return highestRole.name;
}

export function userRoleToFriendlyString(roles: number[]) {
    const role = userRoleToString(roles);
    switch (role) {
        case RoleKeys.ADMIN:
            return 'Administrator';
        case RoleKeys.MANAGER:
            return 'Property Manager';
        case RoleKeys.ACCOUNTING:
            return 'Accountant';
        case RoleKeys.OWNER:
            return 'Property Owner';
        case RoleKeys.STAFF:
            return 'House Staff';
        case RoleKeys.GUEST:
            return 'Guest';
    }
}
