import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, IAppConfig} from '../app.config';
import {AuthService} from './auth.service';
import {SignatureTemplateResponse, SignatureTemplateResponseItem} from '../interfaces/signature-template-response';
import {forkJoin, from, Observable} from 'rxjs';
import {SignatureTemplate} from '../models/signature-template';
import {concatMap, map} from 'rxjs/operators';
import * as R from 'ramda';
import {SignatureTemplateType} from '../types/signature-template-type';
import {sortAscend} from '../utility/functions/sort-by-name';

@Injectable()
export class ContractsService {

  constructor(private http: HttpClient,
              private auth: AuthService,
              @Inject(APP_CONFIG) private config: IAppConfig) {
  }

  getContracts(): Observable<SignatureTemplate[]> {
    const templateTypes: SignatureTemplateType[] = ['contract', 'form', 'document'];

    let retrievedTemplates: SignatureTemplate[] = [];

    return <any>forkJoin(
      from(templateTypes)
        .pipe(
          concatMap((templateType) => {
            return this.http.get<SignatureTemplateResponse>(`@api/template/signature/${templateType}/all/`)
              .pipe(
                map(response => {
                  const templates = R.map((r: SignatureTemplateResponseItem) => {
                    let item = new SignatureTemplate();
                    item.fromResponse(r);
                    return item;
                  }, R.pathOr([], ['items'], response));
                  retrievedTemplates = R.concat(templates, retrievedTemplates);
                  return retrievedTemplates;
                })
              );
          })
        )
    )
      .pipe(
        map((templates) => sortAscend('name')(R.flatten(templates)))
      );
  }
}
