import {ConditionType} from '../enums/condition-type';
import {InvoiceStatus} from '../enums/invoice-status';

export const LESS_THAN = {
  comp:  '<',
  label: 'less than'
};

export const MORE_THAN = {
  comp:  '>',
  label: 'more than'
};

export const EXACTLY = {
  comp:  '=',
  label: 'exactly'
};

export const EQUALS = {
  comp:  '=',
  label: 'equals'
};

export const IS = {
  comp:  '=',
  label: 'is'
};

export const IS_NOT = {
  comp:  '!=',
  label: 'is not'
};

export const UNPAID = {
  value: InvoiceStatus.Unpaid,
  comp:  '=',
  label: 'is unpaid'
};

export const PAID = {
  value: InvoiceStatus.Paid,
  comp:  '=',
  label: 'is paid'
};

export const HAS_FIRED = {
  value: 1,
  comp:  '=',
  label: 'has fired'
};

export const HAS_NOT_FIRED = {
  value: 0,
  comp:  '=',
  label: 'has not fired'
};

export const EXISTS = {
  comp:  'defined',
  label: 'exists'
};

export const DOES_NOT_EXIST = {
  comp:  'undefined',
  label: 'does not exist'
};

export const AVAILABLE = {
  value: 1,
  comp:  '=',
  label: 'available'
};

export const UNAVAILABLE = {
  value: 0,
  comp:  '=',
  label: 'unavailable'
};

export const INCLUDE = {
  comp:  '=',
  label: 'include'
};

export const DOES_NOT_INCLUDE = {
  comp:  '!=',
  label: 'does not include'
};

export const comparatorMapping = {
  [ConditionType.UNTIL_CHECKIN]:         [LESS_THAN, MORE_THAN, EXACTLY],
  [ConditionType.SINCE_CHECKIN]:         [LESS_THAN, MORE_THAN, EXACTLY],
  [ConditionType.UNTIL_CHECKOUT]:        [LESS_THAN, MORE_THAN, EXACTLY],
  [ConditionType.SINCE_CHECKOUT]:        [LESS_THAN, MORE_THAN, EXACTLY],
  [ConditionType.STAY_LENGTH]:           [LESS_THAN, MORE_THAN, EQUALS],
  [ConditionType.GUEST_COUNT]:           [LESS_THAN, MORE_THAN, EQUALS],
  [ConditionType.GUEST_COUNTRY]:         [IS, IS_NOT],
  [ConditionType.GUEST_PHONE]:           [EXISTS, DOES_NOT_EXIST],
  [ConditionType.GUEST_EMAIL]:           [EXISTS, DOES_NOT_EXIST],
  [ConditionType.GUEST_LASTNAME]:        [EXISTS, DOES_NOT_EXIST],
  [ConditionType.BOOKING_TOTAL]:         [LESS_THAN, MORE_THAN],
  [ConditionType.INVOICE_TOTAL]:         [LESS_THAN, MORE_THAN],
  [ConditionType.BOOKINGS_STATUS]:       [PAID, UNPAID],
  [ConditionType.INQUIRY_STATUS]:        [IS, IS_NOT],
  [ConditionType.TRIGGER_CONDITION]:     [HAS_FIRED, HAS_NOT_FIRED],
  [ConditionType.GUEST_BOOKINGS]:        [LESS_THAN, MORE_THAN, EXACTLY],
  [ConditionType.AVAILABLE_DAYS_AFTER]:  [EQUALS],
  [ConditionType.AVAILABLE_DAYS_BEFORE]: [EQUALS],
  [ConditionType.RENTAL_AVAILABILITY]:   [AVAILABLE, UNAVAILABLE],
  [ConditionType.BOOKING_TAGS]:          [INCLUDE, DOES_NOT_INCLUDE],
  [ConditionType.EARLY_CHECKIN]:         [MORE_THAN],
  [ConditionType.EARLY_CHECKOUT]:        [MORE_THAN],
  [ConditionType.LATE_CHECKIN]:          [MORE_THAN],
  [ConditionType.LATE_CHECKOUT]:         [MORE_THAN],
};

export const typeDescriptionMapping = {
  [ConditionType.UNTIL_CHECKIN]:         'Less than 2 days and 1 hour until checkin',
  [ConditionType.SINCE_CHECKIN]:         'More than 5 days and 0 hours since checkin',
  [ConditionType.UNTIL_CHECKOUT]:        'Exactly 1 day and 12 hours until checkout',
  [ConditionType.SINCE_CHECKOUT]:        'More than 14 days and 0 hours since checkout',
  [ConditionType.STAY_LENGTH]:           'Length of stay equals 7 nights',
  [ConditionType.GUEST_COUNT]:           'Number of guests more than 4',
  [ConditionType.GUEST_COUNTRY]:         'Guest country is Australia',
  [ConditionType.GUEST_PHONE]:           'Guest phone exists',
  [ConditionType.GUEST_EMAIL]:           'Guest email exists',
  [ConditionType.GUEST_LASTNAME]:        'Guest last name exists',
  [ConditionType.BOOKING_TOTAL]:         'Booking total more than 1000',
  [ConditionType.INVOICE_TOTAL]:         'Invoice total more than 1000',
  [ConditionType.BOOKINGS_STATUS]:       'Booking is paid',
  [ConditionType.INQUIRY_STATUS]:        'Inquiry is booked',
  [ConditionType.TRIGGER_CONDITION]:     'Trigger has not fired 45ABC',
  [ConditionType.GUEST_BOOKINGS]:        'Guest has more than 10 bookings',
  [ConditionType.AVAILABLE_DAYS_AFTER]:  '1 night(s) available after booking',
  [ConditionType.AVAILABLE_DAYS_BEFORE]: '3 night(s) available before booking',
  [ConditionType.RENTAL_AVAILABILITY]:   'Rental is available',
  [ConditionType.BOOKING_TAGS]:          `Booking tags include 'SpecialRequest'`,
  [ConditionType.EARLY_CHECKIN]:         'Checkin is early by 3 hours and 30 minutes or more',
  [ConditionType.EARLY_CHECKOUT]:        'Checkout is early by 2 hours and 15 minutes or more',
  [ConditionType.LATE_CHECKIN]:          'Checkin is late by 4 hours and 30 minutes or more',
  [ConditionType.LATE_CHECKOUT]:         'Checkout is late by 2 hours and 45 minutes or more',
};

