import {Directive, OnInit} from '@angular/core';
import {combineLatest} from 'rxjs';
import {Notification} from '@automata/models/notification';
import {FormControl} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '@automata/reducers';
import {map, startWith} from 'rxjs/operators';
import * as R from 'ramda';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

@Directive()
export abstract class ActivityList extends OnDestroyMixin implements OnInit {

  notifications: Notification[];

  type = new FormControl('all');

  types = [
    {
      value: 'all',
      name:  'Everything',
      i18n:  'activityItem.all',
      color: '#ddd'
    },
    {
      value: 'inquiry',
      name:  'Inquiry',
      i18n:  'activityItem.inquiry',
      color: '#5bc0de'
    },
    {
      value: 'booked',
      name:  'Booking',
      i18n:  'activityItem.booked',
      color: '#337ab7'
    },
    {
      value: 'cancelled',
      name:  'Cancellation',
      i18n:  'activityItem.cancelled',
      color: '#d9534f'
    },
    {
      value: 'paid',
      name:  'Paid',
      i18n:  'activityItem.paid',
      color: '#5cb85c'
    },
    {
      value: 'signed',
      name:  'Signed',
      i18n:  'activityItem.signed',
      color: '#00c78c'
    },
    {
      value: 'rejected',
      name:  'Rejection',
      i18n:  'activityItem.rejected',
      color: '#ffa500'
    }
  ];

  constructor(protected store: Store<fromRoot.State>) {
    super()
  }

  ngOnInit() {

    combineLatest(
      this.store.pipe(select(fromRoot.selectNotificationsWithRentals)),
      this.type.valueChanges.pipe(startWith('all'))
    )
      .pipe(
        map(([notifications, filter]) => this.filter(notifications, filter)),
        map((notifications: Notification[]) => R.sort((a, b) => b.date - a.date, notifications || [])),
        untilComponentDestroyed(this)
      )
      .subscribe(notifications => {
        this.notifications = notifications;
      });
  }

  filter(notifications: Notification[], filter) {
    if (filter === 'all') {
      return notifications;
    } else if (filter === 'inquiry') {
      return R.filter(a => 'inquiry' === a.link_type && 'created' === a.action, notifications);
    } else if (filter === 'paid') {
      return R.filter(a => 'invoice' === a.link_type && 'paid' === a.action, notifications);
    } else if (filter === 'booked') {
      return R.filter(a => 'booking' === a.link_type && 'confirmed' === a.action, notifications);
    } else if (filter === 'cancelled') {
      return R.filter(a => 'booking' === a.link_type && 'cancelled' === a.action, notifications);
    } else if (filter === 'signed') {
      return R.filter(a => 'contract' === a.link_type && 'signed' === a.action, notifications);
    } else if (filter === 'rejected') {
      return R.filter(a => 'contract' === a.link_type && 'rejected' === a.action, notifications);
    }
  }
}
