<div class="header-block">
  <h2 mat-dialog-title><i class="fal fa-envelope"></i> {{'pageElements.message' | translate}}</h2>
  <button (click)="close()" class="close" aria-label="Close" type="button">
    <span class="far fa-times"></span>
  </button>
</div>

<mat-dialog-content>

  <div class="tal message-box">
    <strong>{{'tooltips.from' | translate}}:</strong> {{message?.from}}
    <br>
    <strong>{{'tooltips.guest' | translate}}:</strong> {{message?.guestName}}
    <br>
    <strong>{{'tooltips.subject' | translate}}:</strong> {{message?.subject}}
    <br>
    <strong>{{'tooltips.body' | translate}}:</strong>
    <p [innerHTML]="message?.text | safeHtml"></p>
  </div>

</mat-dialog-content>
