import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  EditBooking,
  EditBookingComplete,
  LoadBookings,
  LoadBookingsComplete, LoadBookingLogs, LoadBookingLogsComplete,
  LoadBookingMessages,
  LoadBookingMessagesComplete,
  LoadIsSkipBooking,
  LoadIsSkipBookingComplete,
  SearchBookings,
  SearchBookingsComplete,
  SkipBooking,
  SkipBookingComplete,
  UnSkipBooking,
  UnSkipBookingComplete
} from './booking.actions';
import {BookingService} from './booking.service';
import {of} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {ActionFailed} from '../../../actions/utility.actions';
import {ToastService} from '@automata/services/toast.service';

@Injectable()
export class BookingEffects {

  @Effect()
  loadBookings$ = this.actions$
    .pipe(
      ofType(LoadBookings),
      switchMap(() => this.bookingService
        .getInquires(100)
        .pipe(
          map(({inquiries, skip}) => LoadBookingsComplete({
            response: {
              limit:  100,
              offset: skip,
              result: inquiries
            }
          })),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  searchBookings$ = this.actions$
    .pipe(
      ofType(SearchBookings),
      switchMap(({filters, search}) => this.bookingService
        .searchInquires(filters, search)
        .pipe(
          map(inquiries => SearchBookingsComplete({inquiries})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  loadIsSkipBooking$ = this.actions$
    .pipe(
      ofType(LoadIsSkipBooking),
      switchMap(({id}) => this.bookingService
        .getInquirySkip(id)
        .pipe(
          map((skipped) => LoadIsSkipBookingComplete({response: {id, skipped}})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  loadBookingMessages$ = this.actions$
    .pipe(
      ofType(LoadBookingMessages),
      switchMap(({id}) => this.bookingService
        .getMessages(id)
        .pipe(
          map((messages) => LoadBookingMessagesComplete({inquiryId: id, messages})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  loadBookingLogs$ = this.actions$
    .pipe(
      ofType(LoadBookingLogs),
      switchMap(({id}) => this.bookingService
        .getLogs(id)
        .pipe(
          map(logs => LoadBookingLogsComplete({id, logs})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  editBooking$ = this.actions$
    .pipe(
      ofType(EditBooking),
      switchMap(({request}) => this.bookingService
        .update(request)
        .pipe(
          tap(() => this.toast.success('bookingUpdated')),
          map(inquiry => EditBookingComplete({inquiry})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  skipBooking$ = this.actions$
    .pipe(
      ofType(SkipBooking),
      switchMap(({id}) => this.bookingService
        .skipInquiry(id)
        .pipe(
          map(() => SkipBookingComplete({id})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  unSkipBooking$ = this.actions$
    .pipe(
      ofType(UnSkipBooking),
      switchMap(({id}) => this.bookingService
        .unSkipInquiry(id)
        .pipe(
          map(() => UnSkipBookingComplete({id})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  constructor(private actions$: Actions,
              private bookingService: BookingService,
              private toast: ToastService) {
  }
}
