import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'skippedInquiryTooltipText'
})
export class SkippedInquiryTooltipTextPipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }

  transform(isSkipped: boolean): string {
    if (isSkipped) {
      return this.translate.instant('tooltips.triggerSkipped');
    } else {
      return this.translate.instant('tooltips.triggerEnabled');
    }
  }
}
