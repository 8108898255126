import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {combineLatest, Subscription} from 'rxjs';
import {TableType} from '../../../enums/table-type';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {TriggerNotificationTableModel} from '../../../models/trigger-notification-table-model';
import {ClearActivities, LoadActivities} from '../../../actions/activities.actions';
import {delay, map} from 'rxjs/operators';
import {Trigger} from '../../../models/trigger';
import {TableUtilityService} from '../../../services/table-utility.service';
import {localeCompareSort} from '@automata/utility/functions/locale-compare-sort';
import {DialogService} from '@automata/services/dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {selectAllBookings} from '@automata/containers/bookings-page/store/booking.selectors';
import * as R from 'ramda';
import {isSomething} from '@automata/utility/functions/is-something';

@Component({
  selector:    'app-trigger-logs',
  templateUrl: './trigger-logs.component.html',
  styleUrls:   ['./trigger-logs.component.scss']
})
export class TriggerLogsComponent implements OnInit, OnDestroy {

  @Input() triggerId: string;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns = [
    'date',
    'guestName',
    'eventName',
    'templateName',
    'channelName'
  ];

  dataSource = new MatTableDataSource<TriggerNotificationTableModel>();

  isEmptyTable$ = this.tableService.isEmptyTable(this.dataSource);

  tableType = TableType.TriggerLog;

  logsSub: Subscription;
  triggerSub: Subscription;

  trigger: Trigger;

  hasMore$ = this.store.pipe(select(fromRoot.selectHasMoreActivities));

  constructor(private store: Store<fromRoot.State>,
              private dialogService: DialogService,
              private translate: TranslateService,
              private tableService: TableUtilityService) {

  }

  ngOnInit() {
    this.dataSource.data = [];
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortData = localeCompareSort;

    this.logsSub = combineLatest(
      [
        this.store.pipe(select(fromRoot.selectActivitiesByTriggerId(this.triggerId))),
        this.store.pipe(select(selectAllBookings))
      ]
    ).pipe(
      map(([activities, bookings]) => {
        return R.map(a => {
          const inquiry = R.find(b => b.inquiry_id === a.inquiryId, bookings)
          return {...a, inquiry}
        }, activities)
      })
    ).subscribe(activities => {
      this.dataSource.data = activities;
    })

    this.triggerSub = this.store
      .pipe(
        select(fromRoot.selectTriggerById(this.triggerId))
      )
      .subscribe(trigger => {
        this.trigger = trigger;
      });
  }

  ngOnDestroy() {
    this.logsSub.unsubscribe();
    this.triggerSub.unsubscribe();
  }

  onEventLog(notification: TriggerNotificationTableModel) {
    if (!notification.eventMessage) {
      return;
    }
    this.dialogService.openAlert({
      title: `Trigger Error`,
      body:  notification.eventMessage
    });
  }

  applyFilter(term: string) {
    this.paginator.firstPage();
    this.dataSource.filter = term.trim().toLowerCase();
  }

  refreshLogs() {
    this.store.dispatch(ClearActivities());
    this.store.dispatch(LoadActivities());
  }
}
