<div class="main-body templates-page clearfix">
  <div class="container-fluid">
    <!-- Title & buttons -->
    <div class="row title-holder">
      <div class="col-xs-3 title">
        {{'pageTitle.customCodes' | translate}}
        <span [matTooltip]="'pageTooltip.customCodes' | translate"
              matTooltipPosition="above"
              matTooltipClass="text-center">
                    <i class="far info-icon fa-question-circle"></i>
                </span>
        <app-tutorial-opener [type]="'codes'"></app-tutorial-opener>
      </div>
      <div class="col-xs-6">
        <app-inactive-subscription></app-inactive-subscription>
      </div>
      <div class="col-xs-3 btn-holder">
        <button *ngIf="isAnySelected()" (click)="confirmDelete()" type="button"
                class="btn btn-cst-danger left-icon text-uppercase">
          <span class="far fa-trash-alt"></span> {{'buttons.delete' | translate}}
        </button>
        <div *ngIf="!isAnySelected()"
             [matTooltip]="'tooltips.noCodesSelected' | translate"
             matTooltipPosition="above"
             matTooltipClass="text-center"
             class="delete-button-wrapper">
          <button [disabled]="true" type="button" class="btn btn-cst-danger left-icon text-uppercase">
            <span class="far fa-trash-alt"></span> {{'buttons.delete' | translate}}
          </button>
        </div>
        <button (click)="openNew()"
                [matTooltip]="'tooltips.newCustomDictionaryCode' | translate"
                matTooltipPosition="above"
                matTooltipClass="text-center"
                type="button" class="btn btn-cstm-info left-icon text-uppercase">
          <span class="far fa-plus"></span> {{'buttons.new' | translate}}
        </button>
      </div>
    </div>

    <app-code-table (select)="onSelectChange($event)"
                    (page)="onPageChange($event)"
                    (render)="onRenderedData($event)"
    ></app-code-table>
  </div>
</div>
