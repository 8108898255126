<div class="dialog-header">
  <h2 mat-dialog-title>
    <ng-container *ngIf="type === 'activity'">
      <i class="far fa-video"></i> Recent Activity Walkthrough
    </ng-container>
    <ng-container *ngIf="type === 'workflows'">
      <i class="far fa-video"></i> Workflows Walkthrough
    </ng-container>
    <ng-container *ngIf="type === 'triggers'">
      <i class="far fa-video"></i> Triggers Walkthrough
    </ng-container>
    <ng-container *ngIf="type === 'templates'">
      <i class="far fa-video"></i> Templates Walkthrough
    </ng-container>
    <ng-container *ngIf="type === 'bookings'">
      <i class="far fa-video"></i> Bookings Walkthrough
    </ng-container>
    <ng-container *ngIf="type === 'codes'">
      <i class="far fa-video"></i> Custom Codes Walkthrough
    </ng-container>
  </h2>
  <button (click)="close()" class="close" aria-label="Close" type="button">
    <span class="far fa-times"></span>
  </button>
</div>

<div class="loading-video">
  <i class="fas fa-spinner fa-spin"></i>
</div>

<div class="modal-body">

  <ng-container *ngIf="type === 'activity'">
    <div style="position: relative; padding-bottom: 62.5%; height: 0;">
      <div class='embed-container'>
        <iframe src='https://www.youtube.com/embed/repiFTr8LFw?rel=0&autoplay=1' frameborder='0'
                allowfullscreen></iframe>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="type === 'workflows'">
    <div style="position: relative; padding-bottom: 62.5%; height: 0;">
      <div class='embed-container'>
        <iframe src='https://www.youtube.com/embed/8aI3Odq5i5s?rel=0&autoplay=1' frameborder='0'
                allowfullscreen></iframe>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="type === 'triggers'">
    <div style="position: relative; padding-bottom: 62.5%; height: 0;">
      <div class='embed-container'>
        <iframe src='https://www.youtube.com/embed/1HoKXagfA0k?rel=0&autoplay=1' frameborder='0'
                allowfullscreen></iframe>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="type === 'templates'">
    <div style="position: relative; padding-bottom: 62.5%; height: 0;">
      <div class='embed-container'>
        <iframe src='https://www.youtube.com/embed/84P_Gbk67Yg?rel=0&autoplay=1' frameborder='0'
                allowfullscreen></iframe>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="type === 'bookings'">
    <div style="position: relative; padding-bottom: 62.5%; height: 0;">
      <div class='embed-container'>
        <iframe src='https://www.youtube.com/embed/ISStglYJXXE?rel=0&autoplay=1' frameborder='0'
                allowfullscreen></iframe>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="type === 'codes'">
    <div style="position: relative; padding-bottom: 62.5%; height: 0;">
      <div class='embed-container'>
        <iframe src='https://www.youtube.com/embed/x0mHiC9e7_c?rel=0&autoplay=1' frameborder='0'
                allowfullscreen></iframe>
      </div>
    </div>
  </ng-container>

</div>
