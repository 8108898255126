<mat-table #table matSort matSortActive="updated" matSortDirection="desc" [dataSource]="dataSource">

  <ng-container matColumnDef="updated">
    <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.created' | translate}}</mat-header-cell>
    <mat-cell
      *matCellDef="let element"> {{element.updatedFormatted ? element.updatedFormatted : trigger?.createdFormattedLong}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="description">
    <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.description' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span *ngIf="!element.trigger">{{element.description}}</span>
      <span *ngIf="element.trigger">Trigger '{{element.trigger | triggerName | async}}' {{element.label}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="edit">
    <mat-header-cell *matHeaderCellDef> {{'tableColumns.edit' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div dropdown container="body">
        <div dropdownToggle (click)="$event.stopPropagation()"
             class="text-center elipse-btn">
          <i class="far fa-ellipsis-v-alt"></i>
        </div>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
          <li role="menuitem">
            <button class="dropdown-item"
                    (click)="onEdit(element)">{{'buttons.edit' | translate}}</button>
          </li>
          <li role="menuitem">
            <button class="dropdown-item"
                    (click)="confirmRemove(element)">{{'buttons.delete' | translate}}</button>
          </li>
        </ul>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onEdit(row)"></mat-row>
</mat-table>

<app-translated-pagination>
  <mat-paginator #paginator
                 [ngClass]="{hidden: (isEmptyTable$ | async)}"
                 [length]="dataSource?.data?.length"
                 [pageSize]="10"
                 [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
                 [showFirstLastButtons]="true">
  </mat-paginator>
</app-translated-pagination>

<app-empty-table *ngIf="(isEmptyTable$ | async)" [type]="tableType"></app-empty-table>
