import {Observable, of} from 'rxjs';
import {CanActivate, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {catchError, filter, map, switchMap, switchMapTo, take, tap} from 'rxjs/operators';
import * as R from 'ramda';
import * as TriggersActions from '@automata/actions/triggers.actions';
import {LoadTriggers} from '@automata/actions/triggers.actions';

@Injectable()
export class WizardLandingGuard implements CanActivate {

  constructor(private store: Store<fromRoot.State>,
              private router: Router) {
  }

  public canActivate(): Observable<boolean> {
    return this.checkTriggers()
      .pipe(
        switchMapTo(of(true)),
        catchError(() => of(false))
      );
  }

  checkTriggers(): Observable<boolean> {
    return this.store.pipe(
      select(fromRoot.selectTriggersLoaded),
      tap(isLoaded => {
        if (!isLoaded) {
          this.store.dispatch(LoadTriggers());
        }
      }),
      filter(isLoaded => isLoaded),
      take(1),
      switchMap(() => this.store.pipe(
        select(fromRoot.selectAllTriggers),
        map(triggers => {
          if (!R.isEmpty(triggers)) {
            this.router.navigate(['wizard', 'existing']);
            return false;
          }
          return true;
        })
      ))
    )
  }
}
