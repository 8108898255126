import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {ToastService} from '@automata/services/toast.service';
import {Observable} from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService,
              private toast: ToastService,
              private router: Router) {

  }

  canActivate() {
    if (!this.authService.isAuthenticated()) {
      this.toast.error('sessionExpired');
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate();
  }
}
