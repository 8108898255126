import {Pipe, PipeTransform} from '@angular/core';
import * as R from 'ramda';

@Pipe({
  name: 'fieldOrPath'
})
export class FieldOrPathPipe implements PipeTransform {

  transform(value: any, fieldOrPath): any {
    return R.path(R.split('.', fieldOrPath), value);
  }
}
