<div [ngSwitch]="type">

  <div *ngSwitchCase="types.Templates" class="empty-table-box">
    <i class="far fa-file-alt fa-5x"></i>

    <h5>{{'emptyTable.templateName' | translate}}</h5>

    <p>{{'emptyTable.templateDesc' | translate}}</p>
  </div>

  <div *ngSwitchCase="types.Triggers" class="empty-table-box">
    <i class="far fa-bell"></i>

    <h5>{{'emptyTable.triggersName' | translate}}</h5>

    <p>{{'emptyTable.triggersDesc' | translate}}</p>
  </div>

  <div *ngSwitchCase="types.Bookings" class="empty-table-box">
    <img src="assets/no-email.svg" class="empty-bookings-icon" alt="Email icon photo">

    <h5>{{'emptyTable.bookingsTitle' | translate}}</h5>

    <p>{{'emptyTable.bookingsDesc' | translate}}</p>
  </div>

  <div *ngSwitchCase="types.ApiKeys" class="empty-table-box">
    <i class="far fa-key fa-5x"></i>

    <h5>{{'emptyTable.apiKeysTitle' | translate}}</h5>

    <p>{{'emptyTable.apiKeysDesc' | translate}}</p>
  </div>

  <div *ngSwitchCase="types.Codes" class="empty-table-box">
    <i class="far fa-book fa-5x"></i>

    <h5>{{'emptyTable.codesTitle' | translate}}</h5>

    <p>{{'emptyTable.codesDesc' | translate}}</p>
  </div>

  <div *ngSwitchCase="types.Workflows" class="empty-table-box">
    <i class="far fa-sitemap"></i>

    <h5>{{'emptyTable.workflowsTitle' | translate}}</h5>

    <p>{{'emptyTable.workflowsDesc' | translate}}</p>
  </div>

  <div *ngSwitchCase="types.FailTriggers" class="empty-table-box failed-trigger-box">
    <i class="far fa-bolt fa-5x" aria-hidden="true"></i>

    <h5>{{'emptyTable.failTriggersTitle' | translate}}</h5>

    <p>{{'emptyTable.failTriggersDesc' | translate}}</p>
  </div>

  <div *ngSwitchCase="types.SuccessTriggers" class="empty-table-box success-trigger-box">
    <i class="far fa-bolt fa-5x" aria-hidden="true"></i>

    <h5>{{'emptyTable.successTriggersTitle' | translate}}</h5>

    <p>{{'emptyTable.successTriggersDesc' | translate}}</p>
  </div>

  <div *ngSwitchCase="types.WizardExisting" class="empty-table-box wizard-existing-box">
    <i class="far fa-bolt fa-5x" aria-hidden="true"></i>

    <h5>{{'emptyTable.wizardExistingTitle' | translate}}</h5>

    <p>{{'emptyTable.wizardExistingDesc' | translate}}</p>
  </div>

  <div *ngSwitchCase="types.TriggerLog" class="empty-table-box">
    <i class="far fa-bolt fa-5x" aria-hidden="true"></i>

    <h5>{{'emptyTable.triggerLogsTitle' | translate}}</h5>

    <p>{{'emptyTable.triggerLogsDesc' | translate}}</p>
  </div>

  <div *ngSwitchCase="types.Conditions" class="empty-table-box">
    <i class="far fa-bolt fa-5x" aria-hidden="true"></i>

    <h5>{{'emptyTable.triggerConditionsTitle' | translate}}</h5>

    <p>{{'emptyTable.triggerConditionsDesc' | translate}}</p>
  </div>

  <div *ngSwitchCase="types.PayloadHttpHeaders" class="empty-table-box payload-table">
    <i class="fal fa-globe fa-5x" aria-hidden="true"></i>

    <p>{{'emptyTable.payloadDesc' | translate}}</p>
  </div>

  <div *ngSwitchCase="types.TemplateLanguages" class="empty-table-box template-languages">
    <i class="far fa-language fa-5x"></i>

    <p>{{'emptyTable.templateLanguagesDesc' | translate}}</p>
  </div>

  <div *ngSwitchCase="types.BookingJourney" class="empty-table-box">
    <i class="far fa-bolt fa-5x" aria-hidden="true"></i>

    <h5>{{'emptyTable.journeyTitle' | translate}}</h5>

    <p>{{'emptyTable.journeyDesc' | translate}}</p>
  </div>

  <div *ngSwitchDefault class="default-table-box">
    <h5>{{'emptyTable.noData' | translate}}</h5>
  </div>

</div>
