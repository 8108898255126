export const environment = {
  sentryDns: 'https://5a9b938ff3c244ef9ed0935d07113c29@sentry.io/1249660',
  environment: 'production',
  wootricToken: 'NPS-a11df413',
  production: true,
  apiUrl: 'https://api.tokeet.com',
  signatureApiUrl: 'https://api.usesignature.com',
  samplesUrl: 'https://app.useautomata.com/assets',
  iftttUrl: 'https://ifttt.tokeet.com',
  intercomAppId: 'ziijopdr',
  features: {
    hometogo: true,
  },
};
