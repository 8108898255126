import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'epoch'
})
export class EpochPipe implements PipeTransform {

  transform(value: any, format = 'DD - MMM - YYYY | h:mm A'): string {
    return moment.unix(value).format(format);
  }

}
