import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  AddTemplate,
  AddTemplateComplete,
  AddTemplateSample,
  AddTemplateSampleComplete,
  DeleteBatchTemplate,
  DeleteBatchTemplateComplete,
  DeleteTemplate,
  DeleteTemplateComplete,
  EditTemplate,
  EditTemplateComplete,
  LoadTemplates,
  LoadTemplatesComplete
} from '../actions/templates.actions';
import {TemplatesService} from '../services/templates.service';
import {of} from 'rxjs';
import {ActionFailed} from '../actions/utility.actions';
import {catchError, map, switchMap, switchMapTo, tap} from 'rxjs/operators';
import {ToastService} from '@automata/services/toast.service';
import * as R from 'ramda';

@Injectable()
export class TemplatesEffects {

  @Effect()
  loadTemplates$ = this.actions$
    .pipe(
      ofType(LoadTemplates),
      switchMapTo(
        this.templates
          .all()
          .pipe(
            map(templates => LoadTemplatesComplete({templates})),
            catchError(error => of(ActionFailed({error})))
          )
      )
    );

  @Effect()
  deleteTemplate$ = this.actions$
    .pipe(
      ofType(DeleteTemplate),
      switchMap(({id}) => this.templates
        .delete(id)
        .pipe(
          tap(() => this.toast.success('templateDeleted')),
          map(() => DeleteTemplateComplete({id})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  addTemplate$ = this.actions$
    .pipe(
      ofType(AddTemplate),
      switchMap(({request}) => this.templates
        .add(request)
        .pipe(
          tap(() => this.toast.success('templateSaved')),
          map(template => AddTemplateComplete({template})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  addTemplateSample$ = this.actions$
    .pipe(
      ofType(AddTemplateSample),
      switchMap(({request}) => this.templates
        .add(request)
        .pipe(
          tap(() => this.toast.success('templateSaved')),
          map(template => AddTemplateSampleComplete({template})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  editTemplate$ = this.actions$
    .pipe(
      ofType(EditTemplate),
      switchMap(({request}) => this.templates
        .update(request)
        .pipe(
          tap(() => this.toast.success('templateSaved')),
          map(response => EditTemplateComplete({template: response})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  deleteBatchTemplate$ = this.actions$
    .pipe(
      ofType(DeleteBatchTemplate),
      switchMap(({ids}) => this.templates
        .deleteBatch(ids)
        .pipe(
          tap(() => this.toast.success('templatesDeleted')),
          map(deletedTemplates => DeleteBatchTemplateComplete({ids: R.map(t => t.pkey, <any>deletedTemplates)})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  constructor(private actions$: Actions,
              private toast: ToastService,
              private templates: TemplatesService) {
  }
}
