import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {ConditionValueFormType} from '../../enums/condition-value-form-type';
import {validateMinNumber} from '@automata/validators/min-number.validator';
import {ConditionService} from '../../services/condition.service';
import {Trigger} from '@automata/models/trigger';
import * as R from 'ramda';
import {map, startWith, tap} from 'rxjs/operators';
import {validateMaxNumber} from '@automata/validators/max-number.validator';
import {FormsService} from '@automata/services/forms.service';
import {InquiryStatus} from '../../enums/inquiry-status';
import {ConditionType} from '@automata/features/conditions/enums/condition-type';
import {countriesList} from '@automata/models/country-list';

const MinimumHoursMinutesValidator: ValidatorFn = (fg: FormGroup) => {
  const hours = fg.get('hours').value;
  const minutes = fg.get('minutes').value;
  return hours > 0 || minutes >= 30
    ? null
    : { minimumHoursMinutes: true };
};

const MinimumDaysHoursValidator: ValidatorFn = (fg: FormGroup) => {
  const days = fg.get('days').value;
  const hours = fg.get('hours').value;
  return days === 0 && hours === 0
    ? { minimumDaysHours: true }
    : null
};

@Component({
  selector:    'app-condition-value',
  templateUrl: './condition-value.component.html',
  styleUrls:   ['./condition-value.component.scss']
})
export class ConditionValueComponent implements OnInit, OnDestroy {

  @Input() type: FormControl|ConditionType;
  @Input() triggers: Trigger[];
  @Input() form: FormGroup;

  valueType = ConditionValueFormType.None;
  valueTypes = ConditionValueFormType;

  countries = countriesList;

  triggerList: any[];

  inquiryStatuses = InquiryStatus;

  constructor(private fb: FormBuilder,
              private forms: FormsService,
              private conditionService: ConditionService) {
  }

  ngOnInit() {

    const typeValue = R.is(String, this.type) ? this.type : (<FormControl>this.type).value;

    this.valueType = this.conditionService.resolveFormType(typeValue);

    this.triggerList = <any>R.map(r => {
      return {
        id:   r.id,
        name: r.name
      };
    }, this.triggers);

    if (typeof this.type === 'string') {
      const valueType = this.conditionService.resolveFormType(this.type);
      this.setValueType(valueType);
      this.valueType = valueType;
    } else {
      this.type
        .valueChanges
        .pipe(
          startWith(this.type.value),
          map(type => this.conditionService.resolveFormType(type)),
          tap(valueType => this.setValueType(valueType))
        )
        .subscribe(valueType => {
          this.valueType = valueType;
        });
    }
  }

  ngOnDestroy() {
  }

  private setValueType(valueType) {
    this.forms.resetAllValidators(this.form);
    if (valueType === ConditionValueFormType.HoursMinutes) {
      this.form.setValidators([MinimumHoursMinutesValidator])
    } else if (valueType === ConditionValueFormType.DaysHours) {
      this.form.setValidators([MinimumDaysHoursValidator])
    } else {
      this.form.setValidators([])
    }
    this.form.updateValueAndValidity();
    switch (valueType) {
      case ConditionValueFormType.HoursMinutes:
        this.form.get('hours').setValidators([Validators.required, validateMinNumber(0), validateMaxNumber(24)]);
        this.form.get('hours').updateValueAndValidity();
        this.form.get('minutes').setValidators([Validators.required, validateMinNumber(0)]);
        this.form.get('minutes').updateValueAndValidity();
        break;
      case ConditionValueFormType.DaysHours:
        this.form.get('days').setValidators([Validators.required, validateMinNumber(0)]);
        this.form.get('days').updateValueAndValidity();
        this.form.get('hours').setValidators([Validators.required, validateMinNumber(0), validateMaxNumber(24)]);
        this.form.get('hours').updateValueAndValidity();
        break;
      case ConditionValueFormType.Nights:
        this.form.get('nights').setValidators([Validators.required, validateMinNumber(0)]);
        this.form.get('nights').updateValueAndValidity();
        break;
      case ConditionValueFormType.Number:
        this.form.get('number').setValidators([Validators.required, validateMinNumber(0)]);
        this.form.get('number').updateValueAndValidity();
        break;
      case ConditionValueFormType.Country:
        this.form.get('country').setValidators([Validators.required]);
        this.form.get('country').updateValueAndValidity();
        break;
      case ConditionValueFormType.Trigger:
        this.form.get('trigger').setValidators([Validators.required]);
        this.form.get('trigger').updateValueAndValidity();
        break;
      case ConditionValueFormType.TriggerTag:
        this.form.get('bookingTag').setValidators([Validators.required]);
        this.form.get('bookingTag').updateValueAndValidity();
        break;
      case ConditionValueFormType.InquiryStatus:
        this.form.get('inquiryStatus').setValidators([Validators.required]);
        this.form.get('inquiryStatus').updateValueAndValidity();
        break;
    }
  }
}
