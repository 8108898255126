import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {Rental} from '../models/rental';
import {RentalResponse} from '../interfaces/rental-response';
import {flatMap, map, toArray} from 'rxjs/operators';
import {RentalSettings, RentalUpdateRequest} from '@automata/interfaces/rental-settings';
import {getCountryCode} from '@automata/models/country-list';

@Injectable()
export class RentalsService {

  constructor(private http: HttpClient) {
  }

  all(): Observable<Rental[]> {
    return this.http.get<RentalResponse[]>(`@api/rental/all/`)
      .pipe(
        flatMap(response => from(response)),
        map((response) => Rental.deserialize(response)),
        toArray()
      );
  }

  updateSettings(settings: RentalSettings, rental: Rental): Observable<Rental> {
    const update: RentalUpdateRequest = {
      instructions:         {
        checkin:    settings.checkin_instructions,
        checkout:   settings.checkout_instructions,
        directions: settings.directions,
        rules:      settings.house_rules
      },
      specifics:            {
        wifi_pass:    settings.wifi,
        key_pickup:   settings.keypickup,
        special_inst: settings.special,
        sec_code:     settings.security
      },
      custom1:              settings.custom1,
      custom2:              settings.custom2,
      custom3:              settings.custom3,
      custom4:              settings.custom4,
      custom5:              settings.custom5,
      payment_instructions: settings.payment_instructions,
      payment_terms:        settings.terms,
      description:          settings.description,
      address:              {
        address:      settings.address,
        city:         settings.city,
        state:        settings.state,
        country:      settings.country,
        country_code: getCountryCode(settings.country),
        zip:          <any>rental.address.postalCode
      },
      name:                 settings.name,
      email:                settings.email,
      phone:                settings.phone
    };

    return this.http.put<RentalResponse>(`@api/rental/update/${settings.id}`, update)
      .pipe(
        map(response => Rental.deserialize(response))
      );
  }

}
