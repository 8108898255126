import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as R from 'ramda';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers/index';
import {TagsType} from '../../../enums/tags-type';
import {TranslateService} from '@ngx-translate/core';
import { allTags } from '@automata/selectors/tags.selectors';
import {AmplitudeService} from '@automata/services/amplitude.service';

@Component({
  selector:    'app-template-tags-dialog',
  templateUrl: './tags-dialog.component.html',
  styleUrls:   ['./tags-dialog.component.scss']
})
export class TagsDialogComponent implements OnInit {

  form = this.fb.group({
    tags: [['']]
  });

  allTags$: Observable<string[]>;

  types = TagsType;
  type: TagsType;

  public requestAutocomplete = (text: string): Observable<string[]> => {
    return this.allTags$;
  };

  constructor(private fb: FormBuilder,
              private amplitudeService: AmplitudeService,
              private store: Store<fromRoot.State>,
              private translate: TranslateService,
              public dialogRef: MatDialogRef<TagsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.type = this.data.type;
    this.allTags$ = this.store.pipe(select(allTags(this.type)));
    this.form.patchValue({tags: R.map(t => ({display: t, value: t}), this.data.tags)});
  }

  submit() {
    const form = this.form.getRawValue();
    const tags = R.map(R.pipe(R.when(R.is(Number), R.toString), R.view(R.lensProp('value'))))(form.tags);
    this.amplitudeService.logEvent('add-template-tags')
    this.dialogRef.close({submit: true, tags: tags});
  }

  close() {
    this.dialogRef.close();
  }

  getTypeName1() {
    switch (this.type) {
      case this.types.Template:
        return this.translate.instant('pageElements.template');
      case this.types.Trigger:
        return this.translate.instant('pageElements.trigger');
      case this.types.Code:
        return this.translate.instant('pageElements.customCode');
      case this.types.Inquiry:
        return this.translate.instant('pageElements.booking');
    }
  }

  getTypeName2() {
    switch (this.type) {
      case this.types.Template:
        return this.translate.instant('pageElements.templates');
      case this.types.Trigger:
        return this.translate.instant('pageElements.triggers');
      case this.types.Code:
        return this.translate.instant('pageElements.customCodes');
      case this.types.Inquiry:
        return this.translate.instant('pageElements.bookings');
    }
  }
}
