<div class="row">
  <div class="col-xs-12">
    <div class="clearfix panel-holder">

      <div class="cst-panel">

        <div class="cst-panel-header">
          <span>{{'pageElements.activityFeed' | translate}}</span>

          <mat-form-field>
            <mat-select [placeholder]="'formControls.filter' | translate" [formControl]="type">
              <mat-option *ngFor="let type of types" [value]="type.value"
                          class="activity-feed-event-option">
                                <span [ngStyle]="{background: type.color}"
                                      class="activity-feed-event-color"></span> {{type.i18n | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Content -->
        <div class="cst-panel-content">

          <app-activity-list-item *ngFor="let item of notifications" [item]="item"></app-activity-list-item>

          <div *ngIf="notifications.length === 0" class="empty-notifications">
            <i class="far fa-rss fa-5x"></i>
            <br><br>
            {{'pageElements.activityFeedDesc' | translate}}
          </div>

        </div>

        <div class="cst-panel-bottom"></div>
      </div>
    </div>
  </div>
</div>
