<div class="main-body activity-page clearfix">
  <div class="container-fluid">
    <!-- Title & buttons -->
    <div class="row title-holder">
      <div class="col-xs-3 title">
        {{'pageTitle.recentActivity' | translate}}
        <a href="javascript:Appcues.show('-LOetKiUymzSwo7gm4c9')"
           [matTooltip]="'pageTooltip.recentActivity' | translate"
           matTooltipPosition="above"
           matTooltipClass="text-center">
          <i class="far info-icon fa-question-circle"></i>
        </a>
        <app-tutorial-opener [type]="'activity'"></app-tutorial-opener>
      </div>
      <div class="col-xs-6">
        <app-inactive-subscription></app-inactive-subscription>
      </div>
    </div>

    <!-- Chart Holder -->
    <div class="content-holder clearfix">
      <app-activity-chart></app-activity-chart>
    </div>

    <br>

    <div class="row">
      <div class="col-md-8">

        <app-triggers-success-table></app-triggers-success-table>

        <br>

        <app-triggers-fail-table></app-triggers-fail-table>

      </div>

      <div class="col-md-4">
        <app-activity-list></app-activity-list>
      </div>
    </div>

  </div>
</div>
