import {createAction, props} from '@ngrx/store';
import {InquiriesLoaded} from '@automata/interfaces/inquiry/inquiries-loaded';
import {InquiryEditRequest} from '@automata/interfaces/inquiry/inquiry-edit-request';
import {Message} from '@automata/models/message';
import {InquiriesFilters} from '@automata/interfaces/inquiry/inquiries-filters';
import {AutomataLogModel} from '@automata/models/automata-log-model';
import {BookingView} from '@automata/containers/bookings-page/store/booking.model';

export const LoadBookings = createAction('[Bookings] Load Start');
export const LoadBookingsComplete = createAction('[Bookings] Load Complete', props<{response: InquiriesLoaded}>());

export const SearchBookings = createAction('[Bookings] Search Start', props<{filters: InquiriesFilters, search: string}>());
export const SearchBookingsComplete = createAction('[Bookings] Search Complete', props<{inquiries: BookingView[]}>());

export const SkipBooking = createAction('[Booking] Skip Start', props<{id: string}>());
export const SkipBookingComplete = createAction('[Booking] Skip Complete', props<{id: string}>());

export const LoadIsSkipBooking = createAction('[Booking] LoadSkip Start', props<{id: string}>());
export const LoadIsSkipBookingComplete = createAction('[Booking] LoadSkip Complete', props<{response: {id: string, skipped: boolean}}>());

export const UnSkipBooking = createAction('[Booking] UnSkip Start', props<{id: string}>());
export const UnSkipBookingComplete = createAction('[Booking] UnSkip Complete', props<{id: string}>());

export const EditBooking = createAction('[Booking] Edit Start', props<{request: InquiryEditRequest}>());
export const EditBookingComplete = createAction('[Booking] Edit Complete', props<{inquiry: BookingView}>());

export const LoadBookingMessages = createAction('[Booking] Load Messages Start', props<{id: string}>());
export const LoadBookingMessagesComplete = createAction('[Booking] Load Messages Complete', props<{inquiryId: string, messages: Message[]}>());

export const LoadBookingLogs = createAction('[Booking] Load Logs Start', props<{id: string}>());
export const LoadBookingLogsComplete = createAction('[Booking] Load Logs Complete', props<{id: string, logs: AutomataLogModel[]}>());
