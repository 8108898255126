import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'workflowDetailGlobalTooltip'
})
export class WorkflowDetailGlobalTooltipPipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }

  transform(status: number): string {
    if (status === 0) {
      return this.translate.instant('tooltips.triggersDisabledWorkflow');
    } else {
      return this.translate.instant('tooltips.triggersEnabledWorkflow');
    }
  }
}
