<div class="content-holder mt20 clearfix">
  <div class="tab-content">
    <div class="tab-pane active">
      <div class="clearfix overall-content">
        <div class="panel panel-default">
          <div class="clearfix header-panel">
            <div class="col-xs-8 title">
              {{'pageElements.apiKeys' | translate}}
            </div>
            <div class="col-xs-4 btn-holder">
              <div>
                <button (click)="createKey()" type="button"
                        class="btn btn-cstm-info left-icon text-uppercase">
                  <span class="far fa-plus" aria-hidden="true"></span>
                  {{'buttons.create' | translate}}
                </button>
              </div>
            </div>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-xs-12">
                <mat-table #table matSort matSortActive="created" matSortDirection="desc"
                           [dataSource]="dataSource">

                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef
                                     mat-sort-header> {{'tableColumns.name' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.name}}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="key">
                    <mat-header-cell
                      *matHeaderCellDef> {{'tableColumns.key' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.apikey}}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="access">
                    <mat-header-cell *matHeaderCellDef
                                     mat-sort-header> {{'tableColumns.access' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.access}}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="created">
                    <mat-header-cell *matHeaderCellDef
                                     mat-sort-header> {{'tableColumns.created' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.created | epoch}}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="edit">
                    <mat-header-cell
                      *matHeaderCellDef> {{'tableColumns.edit' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <div dropdown>
                        <div dropdownToggle class="text-center elipse-btn">
                          <i class="far fa-ellipsis-v-alt"></i>
                        </div>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right"
                            role="menu">
                          <li role="menuitem">
                            <button *ngIf="!element.internal" class="dropdown-item"
                                    (click)="confirmRemove(element)">
                              {{'buttons.delete' | translate}}
                            </button>
                            <span *ngIf="element.internal"
                                  [matTooltip]="'tooltips.cannotDeleteInternalApiKey' | translate"
                                  matTooltipPosition="above"
                                  matTooltipClass="text-center">
                                                            <button [disabled]="true" class="dropdown-item">
                                                                {{'buttons.delete' | translate}}
                                                            </button>
                                                        </span>
                          </li>
                        </ul>

                      </div>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>

                <app-translated-pagination>
                  <mat-paginator #paginator
                                 [ngClass]="{hidden: (isEmptyTable$ | async) && isLoaded}"
                                 [length]="dataSource?.data?.length"
                                 [pageSize]="10"
                                 [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
                                 [showFirstLastButtons]="true">
                  </mat-paginator>
                </app-translated-pagination>

                <app-empty-table *ngIf="(isEmptyTable$ | async) && isLoaded"
                                 [type]="tableType"></app-empty-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
