<h2 mat-dialog-title><i class="fas fa-book"></i> {{'buttons.dataDictionary' | translate}}</h2>
<button (click)="close()" class="close" aria-label="Close" type="button">
  <span class="far fa-times"></span>
</button>
<mat-dialog-content>
  <mat-tab-group [color]="'accent'">
    <mat-tab label="Guests">
      <div *ngFor="let guest of dictionary.guests" class="col-sm-4 item-col">
        <button (click)="addTag(guest)" type="button" class="item">{{guest}}</button>
      </div>
    </mat-tab>
    <mat-tab label="Inquiries">
      <div *ngFor="let inquiry of dictionary.inquiries" class="col-sm-4 item-col">
        <button (click)="addTag(inquiry)" type="button" class="item">{{inquiry}}</button>
      </div>
    </mat-tab>
    <mat-tab label="Rentals">
      <div *ngFor="let rental of dictionary.rentals" class="col-sm-4 item-col">
        <button (click)="addTag(rental)" type="button" class="item">{{rental}}</button>
      </div>
    </mat-tab>
    <mat-tab label="Custom">
      <div *ngFor="let code of codes" class="col-sm-4 item-col">
        <button (click)="addTag(code)" type="button" class="item">{{code}}</button>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span matTooltip="Each time a template is sent, with a discount data token in it, a new discount code will be generated. This code can be used one time. Past discount codes sent to the guest will not be invalidated."
              matTooltipClass="text-center"
              matTooltipPosition="above">
          <i class="fal fa-question-circle label-tooltip"></i>
        </span> Discounts
      </ng-template>
      <div *ngFor="let item of dictionary.discounts" class="col-sm-4 item-col">
        <button (click)="addTag(item)"
                [matTooltip]="discountTooltips[item]"
                matTooltipClass="text-center"
                matTooltipPosition="above" type="button" class="item">{{item}}</button>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
