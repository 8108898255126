import {Pipe, PipeTransform} from '@angular/core';
import {TriggerHandler} from '../../enums/trigger-handler';

@Pipe({
  name: 'isAddBookingTagHandler'
})
export class IsAddBookingTagHandlerPipe implements PipeTransform {

  transform(handler: string): boolean {
    return handler === TriggerHandler.AddTagHandler;
  }
}
