import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor() {
  }

  resetAllValidators(form: FormGroup) {
    let controls = form.controls;
    for (let key in controls) {
      if (controls.hasOwnProperty(key)) {
        let control = controls[key];
        control.setValidators([]);
        control.updateValueAndValidity();
      }
    }
  }
}
