<form [formGroup]="form">
  <div class="row">

    <div [ngClass]="{hidden: !isPlainTextMode}" class="col-sm-12">
      <button (click)="openDictionary()" class="dictionary-button" type="button">
        {{'buttons.dataDictionary' | translate}} <i class="fas fa-book dictionary-icon"></i>
      </button>
    </div>

    <div [ngClass]="{hidden: isPlainTextMode}" class="col-sm-12">
      <div [attr.id]="editorId" (click)="focusEditor($event)"></div>
    </div>

    <div [ngClass]="{hidden: !isPlainTextMode}" class="col-sm-12">
      <ace-editor [(text)]="plainText" (textChanged)="onPlainTextChange($event)" [mode]="'text'" #editor
                  style="height:300px;"></ace-editor>
    </div>

    <div class="col-sm-12">
      <button [ngClass]="{hidden: forcePlainTextMode}" (click)="togglePlainTextMode()" class="btn-cst-pale left-icon template-control-btn">
        <span
          [matTooltip]="'tooltips.editTemplateAsPlainText' | translate"
          matTooltipPosition="above"
          matTooltipClass="text-center"
          [ngClass]="{hidden: isPlainTextMode}"><i class="fal fa-file-alt"></i> {{'buttons.usePlainText' | translate}}</span>
        <span
          [matTooltip]="'tooltips.editTemplateAsRichText' | translate"
          matTooltipPosition="above"
          matTooltipClass="text-center"
          [ngClass]="{hidden: !isPlainTextMode}"><i class="fal fa-file-code"></i> {{'buttons.useRichText' | translate}}</span>
      </button>
      <button (click)="onPreviewEmail()"
              matTooltip="Click here to preview your template to see what it will look like when sent to a recipient."
              matTooltipPosition="above"
              matTooltipClass="text-center"
              class="btn-cst-pale left-icon">
        <i class="fal fa-eye"></i> Preview
      </button>
    </div>

    <div *ngIf="form.get('body').hasError('required') && isTouched" class="col-sm-12">
      <p class="editor-error">{{'pageElements.templateValidation' | translate}}</p>
    </div>

    <ng-container *ngIf="showCount">

      <div [ngClass]="{hidden: type.value !== types.PushNotification || isPlainTextMode}"
           class="col-sm-12 text-counter">
        <strong [attr.id]="limitCountId"></strong> <span [attr.id]="limitLabelId"></span>
      </div>

      <div [ngClass]="{hidden: type.value === types.PushNotification || isPlainTextMode}"
           class="col-sm-12 text-counter">
        <strong [attr.id]="wordCountId"></strong> <span [attr.id]="wordLabelId"></span>, <strong
        [attr.id]="charCountId"></strong> <span [attr.id]="charLabelId"></span>
      </div>

      <div [ngClass]="{hidden: !isPlainTextMode}" class="col-sm-12 text-counter">
        <strong>{{plainText | wordCount}}</strong><span> Word<span
        [ngClass]="{hidden: (plainText | wordCount) === 1}">s</span>, </span><strong>{{plainText.length}}</strong><span> Character<span
        [ngClass]="{hidden: plainText.length === 1}">s</span></span>
      </div>

    </ng-container>

    <div *ngIf="showNote" class="col-sm-12">
      <p class="dictionary-guide">
        <strong>{{'pageElements.note' | translate}}: </strong>{{'pageElements.insertDataTokens' | translate}} <i
        class="fas fa-book"></i> {{'pageElements.dataTokensIcons' | translate}}
      </p>
    </div>
  </div>
</form>
