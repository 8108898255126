import {Trigger} from './trigger';

export class Workflow {
  id: string;
  triggers: Trigger[] = [];

  rentalId: string;
  rentalName: string;

  name: string;

  lastUpdated: number;
  lastUpdatedFormatted: string;

  lastTrigger: number;

  status: number;

  templatesCount: number;
  templateNames: string[] = [];
}
