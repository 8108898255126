import {
  EditBookingComplete,
  LoadBookingsComplete, LoadBookingLogsComplete,
  LoadBookingMessagesComplete,
  LoadIsSkipBookingComplete,
  SearchBookingsComplete,
  SkipBookingComplete,
  UnSkipBookingComplete
} from './booking.actions';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {BookingView} from './booking.model';
import * as R from 'ramda';
import {AutomataLogModel} from '../../../models/automata-log-model';
import {Action, ActionReducerMap, createReducer, on} from '@ngrx/store';
import {InjectionToken} from '@angular/core';

export interface State extends EntityState<BookingView> {
  hasMore: boolean
  limit: number
  offset: number
  selectedInquiryId: string | null
  isLoaded: boolean
}

export const adapter: EntityAdapter<BookingView> = createEntityAdapter<BookingView>({
  sortComparer: false,
  selectId: (booking: BookingView) => booking.pkey
});

export const initialState: State = adapter.getInitialState({
  hasMore:           true,
  limit:             0,
  offset:            0,
  selectedInquiryId: null,
  isLoaded:          false
});

export const reducer = createReducer(
  initialState,
  on(LoadBookingsComplete, (state, {response}) => {
    const limit = response.limit;
    const offset = response.offset;
    const loadResult = <BookingView[]>response.result;

    return adapter.addMany(R.take(limit, loadResult), {
      ...state,
      isLoaded: true,
      limit:    limit,
      offset:   offset,
      hasMore:  loadResult.length > limit
    });
  }),
  on(SearchBookingsComplete, (state, {inquiries}) => adapter.upsertMany(inquiries, state)),
  on(LoadBookingMessagesComplete, (state, {inquiryId, messages}) => {
    const inquiry = state.entities[inquiryId];
    return adapter.updateOne(<any>{
      id:      inquiryId,
      changes: {
        automataLogModels: R.map((log: AutomataLogModel) => {
          let model = log.clone(log);
          model.message = R.find(R.propEq('id', model.id), messages);
          return model;
        }, inquiry.automataLogModels || [])
      }
    }, state);
  }),
  on(LoadBookingLogsComplete, (state, {id, logs}) => adapter.updateOne({
    id,
    changes: {automataLogModels: logs}
  }, state)),
  on(EditBookingComplete, (state, {inquiry}) => adapter.updateOne(<any>{
    id:      inquiry.pkey,
    changes: {...inquiry}
  }, state)),
  on(SkipBookingComplete, (state, {id}) => adapter.updateOne(<any>{
    id,
    changes: {
      skip: 1
    }
  }, state)),
  on(UnSkipBookingComplete, (state, {id}) => adapter.updateOne(<any>{
    id,
    changes: {
      skip: 0
    }
  }, state)),
  on(LoadIsSkipBookingComplete, (state, {response}) => adapter.updateOne({
    id:      response.id,
    changes: {
      skip: response.skipped
    }
  }, state))
);

export function bookingReducer(
  state: State | undefined,
  action: Action) {
  return reducer(state, action);
}

export const {selectIds, selectAll, selectEntities} = adapter.getSelectors();
