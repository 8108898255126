import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogService} from '../../../services/dialog.service';
import * as fromRoot from '../../../reducers';
import {select, Store} from '@ngrx/store';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {delay, map, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import * as R from 'ramda';
import {SampleType} from '../../../enums/sample-type';
import {WorkflowSample} from '../../../models/samples/workflow-sample';
import {Rental} from '../../../models/rental';
import {Sample} from '../../../interfaces/sample';
import {TriggerSample} from '../../../models/samples/trigger-sample';
import {CreateWorkflowPayload} from '../../../interfaces/payloads/create-workflow-payload';
import {CreateWorkflow} from '../../../actions/samples.actions';
import {ToastService} from '@automata/services/toast.service';
import {AmplitudeService} from '@automata/services/amplitude.service';

@Component({
  selector:    'app-workflow-wizard',
  templateUrl: './workflow-wizard.component.html',
  styleUrls:   ['./workflow-wizard.component.scss']
})
export class WorkflowWizardComponent implements OnInit {

  workflowGroup: FormGroup;
  rentalGroup: FormGroup;
  nameGroup: FormGroup;

  workflows$: Observable<Sample[]>;
  rentals$: Observable<Rental[]>;

  workflow: WorkflowSample;
  triggers: TriggerSample[];

  constructor(public dialogRef: MatDialogRef<WorkflowWizardComponent>,
              private dialogService: DialogService,
              private fb: FormBuilder,
              private amplitudeService: AmplitudeService,
              private toast: ToastService,
              private store: Store<fromRoot.State>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {

    this.rentals$ = this.store.pipe(select(fromRoot.selectAllRentals));

    this.workflows$ = this.store
      .select(fromRoot.selectSamplesByType(SampleType.Workflow))
      .pipe(
        delay(10)
      );

    this.workflowGroup = this.fb.group({
      workflow: ['', [Validators.required]]
    });

    this.rentalGroup = this.fb.group({
      rental: ['', [Validators.required]]
    });

    this.nameGroup = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(50)]]
    });

    this.workflowGroup.get('workflow').valueChanges
      .pipe(
        switchMap(id => this.workflows$
          .pipe(
            map(workflows => workflows.find(w => w.id === id))
          )),
        switchMap(workflow => {
          if (R.isNil(workflow)) {
            return of({triggers: [], workflow: null});
          } else {
            return this.store.pipe(
              select(fromRoot.selectTriggerSamplesByWorkflow(workflow as WorkflowSample)),
              map(triggers => ({triggers, workflow}))
            );
          }
        })
      )
      .subscribe(result => {
        this.workflow = result.workflow;
        this.triggers = R.clone(<TriggerSample[]>result.triggers);
      });
  }

  toggleSample(sample: TriggerSample) {
    sample.isChecked = !sample.isChecked;
  }

  close() {
    this.dialogRef.close();
  }

  finish() {
    const triggerSamples = R.filter((t: TriggerSample) => t.isChecked, <any>this.triggers);
    const rentalId = this.rentalGroup.get('rental').value;
    const workflowName = this.nameGroup.get('name').value;

    if (R.isEmpty(workflowName.trim())) {
      this.toast.error('toast.error.workflowNameEmpty');
      return;
    }

    const request = {
      triggers: triggerSamples,
      rentalId: rentalId,
      name:     workflowName
    } as CreateWorkflowPayload;

    this.store.dispatch(CreateWorkflow({payload: request}));
    this.amplitudeService.logEvent('workflow-sample-complete')
    this.close();
  }
}
