import {Pipe, PipeTransform} from '@angular/core';
import {TriggerHandler} from '../../enums/trigger-handler';

@Pipe({
  name: 'allowsPostUrl'
})
export class AllowsPostUrlPipe implements PipeTransform {
  transform(handler: string): boolean {
    return handler === TriggerHandler.SendHTTPPost;
  }
}
