import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {IntegrationInfo} from '../../../interfaces/integration-info';

@Component({
  selector:    'app-integration-info-dialog',
  templateUrl: './integration-info-dialog.component.html',
  styleUrls:   ['./integration-info-dialog.component.scss']
})
export class IntegrationInfoDialogComponent {

  constructor(public dialogRef: MatDialogRef<IntegrationInfoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: IntegrationInfo) {
  }

  close() {
    this.dialogRef.close();
  }

}
