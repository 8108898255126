import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {UtilityService} from '../../services/utility.service';
import {countriesList} from '../../models/country-list';
import {AuthService} from '../../services/auth.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {Subscription} from 'rxjs';
import {AccountDetails} from '../../models/account-details';
import {SaveAccount} from '../../actions/accounts.actions';
import {AccountDetailsForm} from '../../interfaces/account/account-details-form';
import {filter} from 'rxjs/operators';
import * as R from 'ramda';
import * as moment from 'moment';
import {Address} from '@automata/models/address';

@Component({
  selector:    'app-account',
  templateUrl: './account.component.html',
  styleUrls:   ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {

  infoForm = this.fb.group({
    name:    ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
    phone:   ['', [Validators.required]],
    email:   ['', [Validators.required, Validators.email]],
    country: [''],
    address: [''],
    city:    [''],
    state:   [''],
    zipCode: [''],
    tax:     ['']
  });

  countries = countriesList;

  accountId: number;
  createdFormatted: string;
  account: AccountDetails;
  accountSub: Subscription;

  constructor(private fb: FormBuilder,
              private auth: AuthService,
              private store: Store<fromRoot.State>,
              public utility: UtilityService) {
  }

  ngOnInit() {

    this.accountId = this.auth.getUser().account;
    this.createdFormatted = moment.unix(this.auth.getUser().created).format('DD - MMM - YYYY | h:mm A');

    this.accountSub = this.store
      .select(fromRoot.selectAccountById(this.accountId))
      .pipe(
        filter((account) => !R.isNil(account))
      )
      .subscribe((account: AccountDetails) => {
        this.account = account;
        this.setInfoFormValues(account);
      });
  }

  ngOnDestroy() {
    this.accountSub.unsubscribe();
  }

  setInfoFormValues(account: AccountDetails) {
    const address = account.address ? account.address : new Address();
    this.infoForm.setValue({
      name:    R.defaultTo('', account.name),
      phone:   R.defaultTo('', account.phone),
      email:   R.defaultTo('', account.email),
      country: R.defaultTo('', address.countryCode),
      address: R.defaultTo('', address.address),
      city:    R.defaultTo('', address.city),
      state:   R.defaultTo('', address.state),
      zipCode: R.defaultTo('', address.postalCode),
      tax:     R.defaultTo('', account.vatTin)
    });
  }

  saveAccountInfo() {
    const formValue = this.infoForm.getRawValue() as AccountDetailsForm;
    this.store.dispatch(SaveAccount({form: formValue}));
  }
}
