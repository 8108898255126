<div class="modal-header">
  <button (click)="close()" type="button" class="close" aria-label="Close"><span
    aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">{{data.title}}</h4>
</div>
<div class="modal-body">
  <p [innerHtml]="data.body"></p>
  <mat-checkbox (change)="toggleChecked()"
                [checked]="isChecked">
    <span [innerHtml]="'pageElements.workflowDeleteConfirm' | translate"></span>
  </mat-checkbox>
</div>
<div class="modal-footer">
  <button (click)="cancel()" type="button" class="btn btn-default text-uppercase">
    {{data.cancelText}}
  </button>
  <button (click)="confirm()" type="button" class="btn btn-cstm-info text-uppercase">
    {{data.confirmText}}
  </button>
</div>
