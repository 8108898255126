import {ITriggerSample} from '../../interfaces/trigger-sample';
import {SampleType} from '../../enums/sample-type';
import {TriggerTimeEvent} from '../../interfaces/trigger-time-event';
import {TriggerConditionModel} from '../../interfaces/trigger/trigger-condition';
import {deserialize, Serializable} from '@automata/utility/serializers/serializers';

declare const require: any;

const uuid = require('uuid/v4');

export class TriggerSample extends Serializable<TriggerSample> implements ITriggerSample {
  id = uuid();
  kind = SampleType.Trigger;

  key: string;
  name: string;
  event: string;
  timeevent: TriggerTimeEvent;

  handler: string;

  icon: string;
  template: string;

  sendToGuest: boolean;

  templateDescription: string;

  isChecked = true;

  conditions: TriggerConditionModel[];

  static deserialize(data: object): TriggerSample {
    return deserialize(TriggerSample, data);
  }
}
