import {Observable, of} from 'rxjs';
import {CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {selectSubscriptions} from '../reducers';
import {catchError, filter, map, switchMapTo, take, tap} from 'rxjs/operators';
import * as R from 'ramda';
import {LoadSubscriptions} from '@automata/actions/subscription.actions';

@Injectable()
export class SubscriptionsGuard implements CanActivate {

  constructor(private store: Store<fromRoot.State>) {
  }

  public canActivate(): Observable<boolean> {
    return this.checkSubscriptions()
      .pipe(
        switchMapTo(of(true)),
        catchError(() => of(false))
      );
  }

  checkSubscriptions(): Observable<boolean> {
    return this.store.pipe(
      select(selectSubscriptions),
      map(subscriptions => !R.isEmpty(subscriptions)),
      tap(isLoaded => {
        if (!isLoaded) {
          this.store.dispatch(LoadSubscriptions());
        }
      }),
      filter(isLoaded => isLoaded),
      take(1)
    );
  }
}
