import {Observable, of} from 'rxjs';
import {CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {LoadRentals} from '../actions/rentals.actions';
import {catchError, filter, switchMapTo, take, tap} from 'rxjs/operators';

@Injectable()
export class RentalsGuard implements CanActivate {

  constructor(private store: Store<fromRoot.State>) {
  }

  public canActivate(): Observable<boolean> {
    return this.checkRentals()
      .pipe(
        switchMapTo(of(true)),
        catchError(() => of(false))
      );
  }

  checkRentals(): Observable<boolean> {
    return this.store.pipe(
      select(fromRoot.selectRentalsLoaded),
      tap(isLoaded => {
        if (!isLoaded) {
          this.store.dispatch(LoadRentals());
        }
      }),
      filter(isLoaded => isLoaded),
      take(1)
    );
  }
}
