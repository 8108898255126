import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {debounceTime, delay, distinctUntilChanged, map, startWith} from 'rxjs/operators';
import * as R from 'ramda';
import {Arrivals} from '../../models/arrivals';
import {EditBooking, LoadBookings, SearchBookings} from './store/booking.actions';
import {DialogService} from '../../services/dialog.service';
import {FiltersService} from '../../services/filters.service';
import {TableUtilityService} from '../../services/table-utility.service';
import {TableType} from '../../enums/table-type';
import {localeCompareSort} from '../../utility/functions/locale-compare-sort';
import {BookingDialogComponent} from '../../components/bookings/booking-dialog/booking-dialog.component';
import {isSomething} from '../../utility/functions/is-something';
import {TagsType} from '../../enums/tags-type';
import {ToastService} from '@automata/services/toast.service';
import {InquiriesFilters} from '@automata/interfaces/inquiry/inquiries-filters';
import {TranslateService} from '@ngx-translate/core';
import {selectBookingsWithRentalsAndWorkFlows, selectHasMoreBookings} from '@automata/containers/bookings-page/store/booking.selectors';
import {BookingView} from '@automata/containers/bookings-page/store/booking.model';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

declare const require: any;

const fuzzysearch = require('fuzzysearch');

@Component({
  selector:    'app-bookings',
  templateUrl: './bookings-page.component.html',
  styleUrls:   ['./bookings-page.component.scss']
})
export class BookingsPageComponent extends OnDestroyMixin implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  search = new EventEmitter<string>();

  tableData = new BehaviorSubject<BookingView[]>([]);

  rentals$ = this.store.pipe(select(fromRoot.selectAllRentals));
  channels$ = this.store.pipe(select(fromRoot.selectChannelsWithDefaults));

  hasMore = false;

  statuses = [
    {
      name:  'All Status',
      key:   '',
      value: 0
    },
    {
      name:  'Unread',
      key:   'read',
      value: 0
    },
    {
      name:  'Booked',
      key:   'booked',
      value: 1
    },
    {
      name:  'Mark for follow-up',
      key:   'followup',
      value: 1
    },
    {
      name:  'Archived',
      key:   'archived',
      value: 1
    },
    {
      name:  'Linked',
      key:   'linked',
      value: 1
    }
  ];
  arrivals = Arrivals.arrivals;

  form = this.fb.group({
    rentals:  [this.filters.getInquiries().rentals],
    channels: [this.filters.getInquiries().channels],
    events:   [this.filters.getInquiries().events],
    status:   [this.filters.getInquiries().status],
    arrival:  [this.filters.getInquiries().arrival],
    tags:     [this.filters.getInquiries().tags]
  });

  displayedColumns = this.tableService.getDisplayedColumns(TableType.Bookings);

  dataSource = new MatTableDataSource<BookingView>();

  tableType = TableType.Bookings;

  isLoaded = false;

  isEmptyTable$ = this.tableService.isEmptyTable(this.dataSource);

  tags$ = this.filters.registerTagsFormFilter(TagsType.Inquiry, this.form);

  constructor(private auth: AuthService,
              private store: Store<fromRoot.State>,
              private filters: FiltersService,
              private toast: ToastService,
              private fb: FormBuilder,
              private translate: TranslateService,
              private tableService: TableUtilityService,
              private dialogService: DialogService) {
    super()
  }

  ngOnInit() {

    const filters = this.filters.getInquiries();

    this.dataSource.data = [];
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortData = localeCompareSort;

    this.search.pipe(
      distinctUntilChanged(),
      debounceTime(200),
      untilComponentDestroyed(this)
    )
      .subscribe(term => {
        this.paginator.firstPage();
        const search = term.trim().toLowerCase();
        this.dataSource.filter = search;
        this.store.dispatch(SearchBookings({filters: this.form.value, search}));
      });

    this.store
      .pipe(
        select(selectBookingsWithRentalsAndWorkFlows),
        untilComponentDestroyed(this)
      )
      .subscribe(bookings => {
        this.tableData.next(bookings);
      });

    this.form.valueChanges
      .pipe(
        startWith({...filters}),
        distinctUntilChanged(),
        untilComponentDestroyed(this)
      )
      .subscribe(filters => {
        this.store.dispatch(SearchBookings({filters, search: this.dataSource.filter}));
      });

    combineLatest(
      this.tableData,
      this.form.valueChanges.pipe(startWith({...filters}))
    )
      .pipe(
        map(data => {
          let [inquiries, activeFilters] = <any>data;

          const filters = {
            rentals:  activeFilters.rentals,
            channels: activeFilters.channels,
            events:   activeFilters.events,
            status:   activeFilters.status,
            arrival:  activeFilters.arrival,
            tags:     activeFilters.tags
          } as InquiriesFilters;

          this.filters.storeInquiries(filters);

          if (isSomething(activeFilters.rentals)) {
            inquiries = R.filter((i: BookingView) => R.contains(i.rental_id, activeFilters.rentals), inquiries);
          }
          if (isSomething(activeFilters.channels)) {

            let results: BookingView[] = [];

            R.forEach((channel: string) => {
              R.forEach((i: BookingView) => {

                if (isSomething(i.inquiry_source) && R.is(String, channel)) {

                  const channelSearch = channel.split('.')[0].toLowerCase();
                  const bookingsSearch = i.inquiry_source.toLowerCase();

                  if (fuzzysearch(channelSearch, bookingsSearch)) {
                    results = R.append(i, <any>results);
                  }
                }

              }, <BookingView[]>inquiries);
            }, <string[]>activeFilters.channels);

            inquiries = results;
          }
          if (isSomething(activeFilters.status)) {
            switch (activeFilters.status) {
              case 'read':  // actually is Unread
                inquiries = R.filter(
                  (i: BookingView) => i.read === 0,
                  inquiries
                );
                break;
              case 'booked':
                inquiries = R.filter(
                  (i: BookingView) => i.booked > 0,
                  inquiries
                );
                break;
              case 'followup':
                inquiries = R.filter(
                  (i: BookingView) => isSomething(i.followup) && i.followup !== 0,
                  inquiries
                );
                break;
              case 'archived':
                inquiries = R.filter(
                  (i: BookingView) => i.archived === 1,
                  inquiries
                );
                break;
              case 'linked':
                inquiries = R.filter(
                  (i: BookingView) =>
                    i.archived !== 1 &&
                    !R.isEmpty(i.links) &&
                    !R.isNil(i.links),
                  inquiries
                );
                break;
            }
          }

          if (isSomething(activeFilters.arrival)) {
            inquiries = R.filter(
              (i: BookingView) => i.arriveMonth === activeFilters.arrival,
              inquiries
            );
          }

          if (isSomething(activeFilters.tags)) {
            inquiries = R.filter((inquiry: BookingView) => R.any(R.equals(true), <any>R.map(tag => R.contains(<any>tag, <any>inquiry.attributes.tags), activeFilters.tags)), inquiries);
          }

          return inquiries;
        }),
        delay(10),
        untilComponentDestroyed(this)
      )
      .subscribe(inquiries => {
        this.isLoaded = true;
        this.paginator.firstPage();
        this.dataSource.data = inquiries;
      });

    this.store.pipe(
      select(selectHasMoreBookings),
      untilComponentDestroyed(this)
    )
      .subscribe(hasMore => this.hasMore = hasMore);

    this.paginator.page
      .pipe(
        delay(50),
        untilComponentDestroyed(this)
      )
      .subscribe(() => {
        if (!this.paginator.hasNextPage() && this.hasMore && this.tableData.value.length === this.dataSource.data.length) {
          this.loadMore();
        }
      });
  }

  onEdit(inquiry: BookingView) {
    if (this.hasWorkflow(inquiry)) {
      console.log(`Editing booking detail for inquiry ${inquiry.pkey}`);
      this.dialogService.openSide(BookingDialogComponent, {data: {inquiry}});
    } else {
      this.toast.info('noTriggersMatchBooking');
    }
  }

  hasWorkflow(inquiry: BookingView): boolean {
    return isSomething(inquiry) && isSomething(inquiry.associatedTriggers);
  }

  onManageTags(inquiry: BookingView) {
    this.dialogService
      .openTags(inquiry.attributes.tags, TagsType.Inquiry)
      .subscribe((result) => {
        this.store.dispatch(EditBooking({
          request: {
            id:         inquiry.pkey,
            attributes: {...inquiry.attributes, tags: result.tags}
          }
        }));
      });
  }

  loadMore() {
    this.store.dispatch(LoadBookings());
  }

  tableManagement() {
    this.dialogService.openTableManagement(this.tableType)
      .subscribe(columns => {
        this.displayedColumns = columns;
      });
  }
}
