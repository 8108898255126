import {Pipe, PipeTransform} from '@angular/core';
import * as R from 'ramda';
import {startsWith} from 'lodash';

@Pipe({
  name: 'isChannelAirbnb'
})
export class IsChannelAirbnbPipe implements PipeTransform {

  transform(channels: string[] | string): boolean {
    if (!R.isNil(channels) && R.is(Array, channels)) {
      return !R.isEmpty(channels) && R.all(c => startsWith(c, 'airbnb'), R.reject(R.isNil)(<string[]>channels));
    } else {
      return startsWith((<string>channels) || '', 'airbnb');
    }
  }
}
