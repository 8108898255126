export class Storage {

  constructor(private storage: any, private prefix: string = null) {
  }

  get(key: string, defaultValue: any = null) {
    key = this.toKey(key);
    try {
      const value = this.storage.getItem(key);

      if (value === null) return defaultValue;

      return JSON.parse(value);
    } catch (error) {
      return defaultValue;
    }
  }

  has(key: string) {
    key = this.toKey(key);
    return !!this.get(key);
  }

  set(key: string, value: any = null) {
    key = this.toKey(key);
    value = JSON.stringify(value);
    this.storage.setItem(key, value);
  }

  remove(key: string) {
    key = this.toKey(key);
    this.storage.removeItem(key);
  }

  private toKey(key): string {
    return this.prefix ? `${this.prefix}:${key}` : key;
  }

}
