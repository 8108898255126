import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {AddTriggerSample, CreateWorkflow, LoadSamples, LoadSamplesComplete} from '../actions/samples.actions';
import {ActionFailed} from '../actions/utility.actions';
import {forkJoin, from, of} from 'rxjs';
import {catchError, concatMap, filter, map, switchMap, switchMapTo, take, tap} from 'rxjs/operators';
import {SamplesService} from '../services/samples.service';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import * as R from 'ramda';
import {TemplatesService} from '../services/templates.service';
import {SampleType} from '../enums/sample-type';
import {Template} from '../models/template';
import {TemplateSample} from '../models/samples/template-sample';
import {TemplateSampleCreationResult, TemplateSampleCreationResults} from '../interfaces/template-sample-creation-result';
import {TriggersService} from '../services/triggers.service';
import * as TriggersActions from '../actions/triggers.actions';
import {AddTemplateComplete, CreateTemplatesComplete} from '../actions/templates.actions';
import {TriggersCreateRequest} from '../interfaces/trigger/triggers-create-request';
import {AuthService} from '../services/auth.service';
import {TriggerSample} from '../models/samples/trigger-sample';
import {Trigger} from '../models/trigger';
import {ToastService} from '@automata/services/toast.service';
import {isSomething} from '@automata/utility/functions/is-something';
import {CreateTriggersComplete} from '../actions/triggers.actions';

@Injectable()
export class SamplesEffects {

  @Effect()
  loadSamples$ = this.actions$
    .pipe(
      ofType(LoadSamples),
      switchMapTo(
        this.samples.samples()
          .pipe(
            map(samples => LoadSamplesComplete({samples})),
            catchError(error => of(ActionFailed({error})))
          )
      )
    );

  @Effect()
  createTrigger$ = this.actions$
    .pipe(
      ofType(AddTriggerSample),
      switchMap(({sample}) => this.store.pipe(
        select(fromRoot.selectTemplateSampleByKey(sample.template)),
        take(1),
        filter(templateSample => isSomething(templateSample)),
        switchMap(templateSample => this.templates.addSample(<TemplateSample>templateSample)
          .pipe(
            map(template => ({template, triggerSample: sample}))
          ))
        )
      ),
      switchMap((params: {template: Template, triggerSample: TriggerSample}) => {

        let users: string[] = [this.auth.getUser().id];

        if (params.triggerSample.sendToGuest) {
          users = R.append('111', <any>users);
        }

        let request = {
          event:      params.triggerSample.event,
          timeevent:  params.triggerSample.timeevent.event,
          days:       params.triggerSample.timeevent.days,
          hours:      params.triggerSample.timeevent.hours,
          status:     1,
          handler:    params.triggerSample.handler,
          service:    'automata',
          channels:   [''],
          rentals:    [''],
          conditions: params.triggerSample.conditions,
          attributes: {
            name:      params.triggerSample.name,
            tags:      [],
            delayed:   0,
            workflows: []
          },
          users:      users,
          template:   params.template.pkey
        } as TriggersCreateRequest;

        return this.triggers.createMany(request)
          .pipe(
            map(triggers => ({template: params.template, triggers}))
          );
      }),
      switchMap((result: {template: Template, triggers: Trigger[]}) => {
        return [
          AddTemplateComplete({template: result.template}),
          CreateTriggersComplete({triggers: result.triggers})
        ];
      })
    );

  @Effect()
  createWorkflow$ = this.actions$
    .pipe(
      ofType(CreateWorkflow),
      switchMap(({payload}) => {
        return this.store
          .select(fromRoot.selectSamplesByType(SampleType.Template))
          .pipe(
            map(templateSamples => R.filter(s => R.contains(s.key, R.map(t => t.template, <any>payload.triggers)), templateSamples)),
            map(templateSamples => ({templateSamples, param: payload}))
          );
      }),
      switchMap(templateSamplesAndParam => {
        let templates = [];
        return forkJoin(
          from(templateSamplesAndParam.templateSamples)
            .pipe(
              concatMap((sample) => this.templates
                .addSample(<TemplateSample>sample)
                .pipe(
                  map(template => {
                    templates = R.append(({template, sampleKey: sample.key}), templates);
                    return templates;
                  })
                ))
            )
        )
          .pipe(
            map(templates => R.flatten(templates)),
            map(templates => ({templates, param: templateSamplesAndParam.param}))
          );
      }),
      switchMap((templateCreationResults: TemplateSampleCreationResults) => {

        let triggerRequests: TriggersCreateRequest[] = [];

        R.forEach(triggerSample => {

          const triggersTemplate: Template = (<TemplateSampleCreationResult>R.find((createdTemplate) => createdTemplate.sampleKey === triggerSample.template, <any>templateCreationResults.templates)).template;

          let users: string[] = [this.auth.getUser().id];

          if (triggerSample.sendToGuest) {
            users = R.append('111', <any>users);
          }

          let request = {
            event:      triggerSample.event,
            timeevent:  triggerSample.timeevent.event,
            days:       triggerSample.timeevent.days,
            hours:      triggerSample.timeevent.hours,
            status:     1,
            handler:    triggerSample.handler,
            service:    'automata',
            channels:   [''],
            rentals:    [templateCreationResults.param.rentalId],
            conditions: triggerSample.conditions,
            attributes: {
              name:      triggerSample.name,
              tags:      [],
              delayed:   0,
              workflows: R.map(R.compose(R.toLower, R.join('_'), R.split(' '), R.trim))([templateCreationResults.param.name])
            },
            users:      users,
            template:   triggersTemplate.pkey
          } as TriggersCreateRequest;

          // @todo - add type if one day samples support signature templates and SendContract handler

          triggerRequests = R.append(request, triggerRequests);

        }, templateCreationResults.param.triggers);

        return of(({requests: triggerRequests, templates: R.map(t => t.template, templateCreationResults.templates)} as any));
      }),
      switchMap(preparedRequest => {

        let triggers: any[] = [];
        return forkJoin(
          from(preparedRequest.requests)
            .pipe(
              concatMap((request: any) => this.triggers
                .createMany(request)
                .pipe(
                  map(trigger => {
                    triggers = R.append(trigger, triggers);
                    return triggers;
                  })
                ))
            )
        )
          .pipe(
            map(triggers => ({triggers: R.flatten(triggers), templates: preparedRequest.templates})),
            tap(() => this.toast.success('workflowCreated'))
          );
      }),
      switchMap((result: any) => {
        return [
          CreateTemplatesComplete({templates: result.templates}),
          CreateTriggersComplete({triggers: result.triggers})
        ];
      })
    );

  constructor(private actions$: Actions,
              private toast: ToastService,
              private auth: AuthService,
              private templates: TemplatesService,
              private triggers: TriggersService,
              private store: Store<fromRoot.State>,
              private samples: SamplesService) {
  }
}
