import {Component, Input, OnInit} from '@angular/core';
import {TableType} from '../../enums/table-type';

@Component({
  selector:    'app-empty-table',
  templateUrl: './empty-table.component.html',
  styleUrls:   ['./empty-table.component.scss']
})
export class EmptyTableComponent implements OnInit {

  @Input() type?: TableType;

  types = TableType;

  ngOnInit() {

  }

}
