<div class="content-holder clearfix">
  <!-- input fields -->
  <div class="clearfix workflows-page input-holder-top">
    <div class="col-sm-9">
      <div class="row">
        <div class="col-sm-2">
          <app-select-search *ngIf="(rentals$ | async)?.length"
                             [matTooltip]="'tooltips.codeRentalFilter' | translate"
                             matTooltipPosition="above"
                             matTooltipClass="text-center"
                             [items]="rentals$ | async"
                             [ctrl]="rentalCtrl"
                             [placeholder]="'formControls.rentals' | translate"></app-select-search>
        </div>
        <div class="col-sm-2">
          <app-select-search *ngIf="(tags$ | async)?.length"
                             [matTooltip]="'tooltips.codeTagsFilter' | translate"
                             matTooltipPosition="above"
                             matTooltipClass="text-center"
                             [items]="tags$ | async"
                             [ctrl]="tagsCtrl"
                             [placeholder]="'formControls.tags' | translate"></app-select-search>
        </div>
      </div>
    </div>
    <div class="col-sm-3 edit-align">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)"
               [placeholder]="'formControls.searchHere' | translate"
               type="search">
        <span matPrefix>
              <i class="far fa-search"></i>
            </span>
      </mat-form-field>
    </div>
  </div>

  <div class="table-responsive">
    <mat-table #table matSort matSortActive="created" matSortDirection="desc" [dataSource]="dataSource"
               [ngClass]="{removeWidth: isEmptyTable && isLoaded}">

      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="created">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.created' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.createdFormattedShort}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="creatorName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.creator' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.creatorName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.name' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.description' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.description | truncate:50}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="tagsFormatted">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.tags' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
                            <span
                              [matTooltip]="element.tagsFormatted"
                              matTooltipPosition="above"
                              matTooltipClass="text-center">
                            {{element.tagsFormatted | truncate:20}}
                            </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="body">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.value' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> <span
          [matTooltip]="element.body | stripHtml | truncate:100"
          matTooltipPosition="above"
          matTooltipClass="text-center break-tooltip">
                            {{element.body | stripHtml | truncate:30}}
                            </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rentalNames">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.rental' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rentalNames | truncate:35}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef>
          <app-column-edit-control (select)="tableManagement()"></app-column-edit-control>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div dropdown container="body">
            <div dropdownToggle (click)="$event.stopPropagation()" class="text-center elipse-btn">
              <i class="far fa-ellipsis-v-alt"></i>
            </div>
            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
              <li role="menuitem">
                <button (click)="onDetails(element)"
                        class="dropdown-item">
                  {{'buttons.edit' | translate}}
                </button>
                <button (click)="onRemove(element)"
                        class="dropdown-item">
                  {{'buttons.delete' | translate}}
                </button>
              </li>
            </ul>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onDetails(row)"></mat-row>
    </mat-table>
  </div>

  <app-translated-pagination>
    <mat-paginator #paginator [ngClass]="{hidden: isEmptyTable && isLoaded}"
                   [length]="dataSource?.data?.length"
                   [pageSize]="15"
                   [pageSizeOptions]="[5, 10, 20, 50, 75, 100]" [showFirstLastButtons]="true">
    </mat-paginator>
  </app-translated-pagination>

  <app-empty-table *ngIf="isEmptyTable && isLoaded" [type]="tableType"></app-empty-table>

</div>
