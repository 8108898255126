import {LoadChannelsComplete} from '../actions/channels.actions';
import {Channel} from '../models/channel';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';

export interface State extends EntityState<Channel> {
  // additional entities state properties
  selectedChannelId: string | null
  isLoaded: boolean
}

export const adapter: EntityAdapter<Channel> = createEntityAdapter<Channel>({
  sortComparer: (a: Channel, b: Channel) => a.friendlyName.toString().localeCompare(b.friendlyName)
});

export const initialState: State = adapter.getInitialState({
  selectedChannelId: null,
  isLoaded:          false
});

export const reducer = createReducer(
  initialState,
  on(LoadChannelsComplete, (state, {channels}) => adapter.addMany(channels, {
    ...state,
    isLoaded: true
  }))
);

export const {selectAll} = adapter.getSelectors();
