import {TemplateResponse} from '../interfaces/template/template-response';
import * as moment from 'moment';
import {TemplateType} from '../enums/template-type';
import {Trigger} from './trigger';
import * as R from 'ramda';
import {PayloadMethod} from '../enums/payload-method';
import {PayloadHttpHeader} from './payload-http-header';
import {TemplateTranslation} from './template-translation';
import {TemplateAttributes} from '../interfaces/template/template-attributes';
import {Taggable} from '../interfaces/taggable';
import {split, toUpper} from 'lodash';

export class Template implements TemplateResponse, Taggable {

  subject: string;
  modified: number;
  modifiedFormatted: string;

  id: string;
  pkey: string;
  account: number;
  archived: number;
  body: string;
  created: number;
  description: string;
  name: string;
  friendlyName: string;
  type: TemplateType;
  typeName: string;
  createdFormatted: string;
  triggers: Trigger[];
  lastused: number;
  lastUsedFormatted: string;
  tagsFormatted = '';

  attributes: TemplateAttributes;

  method: PayloadMethod;
  headers: Partial<PayloadHttpHeader>[];
  languages: Partial<TemplateTranslation>[];

  constructor(template: Partial<Template>, triggers?: Trigger[]) {
    this.id = template.pkey;
    this.account = template.account;
    this.archived = template.archived;
    this.body = template.body;
    this.created = template.created;
    this.description = template.description;
    this.name = split(template.name, ' - ')[0];
    const friendlyId = this.id ? toUpper(this.id.substring(0, 5)) : '';
    this.friendlyName = this.name ? `${this.name} - ${friendlyId}` : friendlyId;
    this.pkey = template.pkey;
    this.type = template.type;
    this.typeName = TemplateType[this.type];
    this.createdFormatted = moment.unix(template.created).format('DD - MMM - YYYY');
    this.triggers = triggers || [];

    this.type = parseInt(<any>template.type, 10);

    this.method = template.method;
    this.headers = template.headers;

    this.subject = template.subject;
    this.modified = template.modified;
    this.modifiedFormatted = template.modified ? moment.unix(template.modified).format('DD - MMM - YYYY | h:mm A') : '';
    // @todo - template and trigger last used/updated is a bit messy
    let lastUsedTrigger: Trigger = R.last(R.sort((a: Trigger, b: Trigger) => a.lastupdate - b.lastupdate, <any>this.triggers));
    this.lastUsedFormatted = R.isNil(lastUsedTrigger) ? 'Never' : lastUsedTrigger.lastUpdateFormatted;
    this.lastused = R.isNil(lastUsedTrigger) ? 0 : lastUsedTrigger.lastupdate;
    this.languages = template.languages;
    this.attributes = template.attributes;
    if (!this.attributes) {
      this.attributes = {tags: []} as TemplateAttributes;
    }
    if (this.attributes && !this.attributes.tags) {
      this.attributes = {...this.attributes, tags: []};
    }
    if (this.attributes && this.attributes.tags) {
      this.attributes = {...this.attributes, tags: R.map(R.pipe(R.when(R.is(Number), R.toString)))(this.attributes.tags)};
    }
    if (template.attributes && R.is(Array, template.attributes.tags)) {
      this.tagsFormatted = template.attributes.tags.join(', ');
    }
  }
}
