import {Component, Inject, OnInit} from '@angular/core';
import {UtilityService} from './services/utility.service';
import {AuthService} from './services/auth.service';
import {APP_CONFIG, IAppConfig} from './app.config';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import * as R from 'ramda';
import {HttpClient} from '@angular/common/http';
import {LocalStorageKeys} from './models/local-storage-keys';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorage} from '@automata/services/storage.service';
import {Language} from '@automata/enums/language';
import {DelightedService} from '@automata/services/delighted.service';
import * as Sentry from '@sentry/browser';
import {environment} from '../environments/environment';
import {Store} from '@ngrx/store';
import {SamplesService} from '@automata/services/samples.service';
import {AmplitudeService} from '@automata/services/amplitude.service';
import {filter} from 'rxjs/operators';

declare const window: any;

@Component({
  selector: 'app-root',
  template: `
                <ng-progress></ng-progress>
                <app-header
                        *ngIf="!utility.isRouteActive('login') && !utility.isRouteActive('ifttt/oauth2/authorize')"></app-header>
                <router-outlet></router-outlet>
                <app-intercom-widget *ngIf="!utility.isRouteActive('login')"></app-intercom-widget>
            `,
  styles:   [
    `:host {
          display: block;
      }
    `
  ]
})
export class AppComponent implements OnInit {

  isAmplitudeBooted = false;
  isIntercomBooted = false;
  isAppcuesBooted = false;
  storageHandler = (event) => {
    if (event.key === LocalStorageKeys.LOGOUT && event.newValue === 'true') {
      window.location.href = '/login';
    }
  };

  constructor(private auth: AuthService,
              private router: Router,
              private wootricService: DelightedService,
              @Inject(APP_CONFIG) private config: IAppConfig,
              private http: HttpClient,
              private storage: LocalStorage,
              private samplesService: SamplesService,
              private translate: TranslateService,
              private store: Store<any>,
              private amplitudeService: AmplitudeService,
              public utility: UtilityService) {
    this.translate.setDefaultLang(this.storage.get(LocalStorageKeys.LANGUAGE, Language.English));

    if (window.Cypress) {
      // @ts-ignore
      window.__appStore__ = store;
    }
  }

  ngOnInit() {

    this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)).subscribe(() => {
      this.amplitudeService.logPageView()
    })

    window.addEventListener('storage', this.storageHandler, false);

    if (this.auth.isAuthenticated()) {
      this.wootricService.run(this.auth.getUser());
    }

    this.utility.showPendingToasts();

    this.router
      .events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          const user = this.auth.getUser();
          if (!R.isNil(user)) {
            if (!this.isAmplitudeBooted) {
              this.amplitudeService.logUser(user);
              this.isAmplitudeBooted = true;
            }
            if (this.isIntercomBooted) {
              if (environment.environment === 'production') {
                window.Intercom('update', this.auth.getIntercomUpdate(user));
              }
            } else {
              Sentry.configureScope((scope) => {
                const id = R.pathOr('', ['account'], user);
                const email = R.pathOr('', ['email'], user);
                scope.setUser({email, id});
              });
              if (environment.environment === 'production') {
                window.Intercom('boot', this.auth.getIntercom(user));
              }
              this.isIntercomBooted = true;
            }

            // This is for the Appcues tutorial software.
            if (this.isAppcuesBooted) {
              window.Appcues?.start();
            } else {
              window.Appcues?.identify(user.id, this.auth.getAppcues(user));
              window.Appcues?.start();
              this.isAppcuesBooted = true;
            }

          } else {
            if (environment.environment === 'production') {
              window.Intercom('shutdown');
            }
          }
        }
      });
  }

}
