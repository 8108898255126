import {NgModule} from '@angular/core';
import {LayoutMainModule} from '@automata/layout/main/main.module';
import {LayoutPageModule} from '@automata/layout/page/page.module';
import {LayoutSidebarButtonModule} from '@automata/layout/sidebar/sidebar-button/sidebar-button.module';
import {LayoutSidebarModule} from '@automata/layout/sidebar/sidebar.module';

const modules = [
  LayoutMainModule,
  LayoutSidebarButtonModule,
  LayoutPageModule,
  LayoutSidebarModule
];

export const components = [];

export const dialogs = [];

export const services = [];

export const pipes = [

];

export const directives = [];

export const declarations = [
  ...pipes,
  ...dialogs,
  ...components,
  ...directives
];

@NgModule({
  imports:         [
    ...modules
  ],
  declarations,
  providers:       [
    ...services
  ],
  entryComponents: [
    ...dialogs
  ],
  exports:         [
    ...declarations,
    ...modules
  ]
})
export class LayoutModule {
}
