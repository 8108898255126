<div class="modal-header">
  <button (click)="close()" type="button" class="close">
    <span class="far fa-times"></span>
  </button>
  <h4 class="modal-title">{{'buttons.update' | translate}} / {{'buttons.saveAsNew' | translate}}</h4>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <mat-form-field>
      <input matInput [placeholder]="'formControls.name' | translate" formControlName="name">
      <mat-error *ngIf="form.get('name').hasError('required')">
        <span [innerHtml]="'validations.nameRequired' | translate"></span>
      </mat-error>
      <mat-error *ngIf="form.get('name').hasError('maxlength')">
        <span [innerHtml]="'validations.nameAtMost50' | translate"></span>
      </mat-error>
      <mat-error *ngIf="form.get('name').hasError('pattern')">
        <span [innerHtml]="'validations.nameNoSpecialNorMinus' | translate"></span>
      </mat-error>
      <mat-hint align="end">{{form.get('name').value?.length || 0}}/50</mat-hint>
    </mat-form-field>
  </form>
</div>
<div class="modal-footer">
  <button (click)="saveAsNew(form)" type="button" class="btn btn-default text-uppercase">
    {{'buttons.saveAsNew' | translate}}
  </button>
  <button (click)="update(form)" type="button" class="btn btn-cstm-info text-uppercase">
    {{'buttons.update' | translate}}
  </button>
</div>
