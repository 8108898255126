<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header btn-holder">
      <div class="col-sm-7">
        <h5 class="modal-title">
          {{'buttons.addTranslation' | translate}}
        </h5>
      </div>
      <div class="col-sm-5 text-right">
        <button tabindex="-1" (click)="close()" type="button" class="btn btn-default left-icon text-uppercase">
          <i class="far fa-check-square" aria-hidden="true"></i>
          {{'buttons.done' | translate}}
        </button>

        <button (click)="submit()" [disabled]="form.invalid" type="button"
                class="btn btn-new left-icon text-uppercase">
          <span class="far fa-save" aria-hidden="true"></span>
          {{'buttons.save' | translate}}
        </button>
      </div>
      <button (click)="close()" class="cstm-close" data-dismiss="modal" aria-label="Close" type="button">
        <span class="far fa-times"></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-sm-12">
          <alert type="info">
            {{'pageElements.translateDesc' | translate}}
          </alert>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <form [formGroup]="form" (ngSubmit)="submit()">
            <div class="row">
              <div class="col-sm-4">
                <app-select-search [items]="languages"
                                   [ctrl]="form.get('language')"
                                   [multiple]="false"
                                   [hasBlank]="false"
                                   [placeholder]="'formControls.selectLanguage' | translate"></app-select-search>
              </div>
            </div>

            <div [ngClass]="{hidden: form.get('type').value !== types.Email}" class="row">
              <div class="col-sm-4">
                <mat-form-field>
                  <input matInput [placeholder]="'formControls.subject' | translate"
                         formControlName="subject">
                  <mat-error *ngIf="form.get('subject').hasError('maxlength')">
                    <span [innerHtml]="'validations.subjectAtMost75' | translate"></span>
                  </mat-error>
                  <mat-hint align="end">{{form.get('subject').value.length || 0}}/75</mat-hint>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <app-template-editor [content]="template?.body"
                                     [type]="form.get('type')"
                                     [form]="form.get('content')"></app-template-editor>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
