<div class="table-responsive">
  <mat-table #table matSort [trackBy]="trackById" matSortActive="created" matSortDirection="desc"
             [dataSource]="dataSource" [ngClass]="{'withoutSelect': !hasSelect}">

    <ng-container *ngIf="hasSelect" matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.name' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
            <span [matTooltip]="element.name"
                  matTooltipPosition="above"
                  matTooltipClass="text-center" class="text-truncate">{{element.name | truncate:35}}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="created">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.created' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.createdFormatted}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="eventName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.event' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"><span class="text-truncate">{{element.eventName}}</span></mat-cell>
    </ng-container>

    <ng-container matColumnDef="handlerName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.action' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"><span class="text-truncate">{{element.handlerName}}</span></mat-cell>
    </ng-container>

    <ng-container matColumnDef="templateName">
      <mat-header-cell *matHeaderCellDef
                       mat-sort-header> {{'tableColumns.template' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
                <span
                  [matTooltip]="element.templateName"
                  matTooltipPosition="above"
                  matTooltipClass="text-center" class="text-truncate">{{element.templateName}}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="lastUpdate">
      <mat-header-cell *matHeaderCellDef
                       mat-sort-header> {{'tableColumns.lastTriggered' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element" class="text-center">
            <span [matTooltip]="element.lastUpdateFormatted"
                  matTooltipPosition="above"
                  matTooltipClass="text-center">
                {{element.lastUpdateFormattedShort}}
            </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="skippedInquiries">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.skipped' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-slide-toggle *ngIf="element.eventId | isTriggerSkippable"
                          [matTooltip]="element.skippedInquiries | isSkippedForInquiry:inquiryId | skippedInquiryTooltipText"
                          matTooltipPosition="above"
                          matTooltipClass="text-center"
                          (click)="$event.stopPropagation()"
                          [id]="element.id" [checked]="element.skippedInquiries | isSkippedForInquiry:inquiryId"
                          (change)="onTogglePause(element)"
                          class="mat-slide-secondary"></mat-slide-toggle>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef> {{'tableColumns.edit' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div dropdown container="body">
          <div dropdownToggle (click)="$event.stopPropagation()" class="text-center elipse-btn">
            <i class="far fa-ellipsis-v-alt"></i>
          </div>
          <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
            <li role="menuitem">
              <a class="dropdown-item" (click)="edit.emit(element)">{{'buttons.edit' | translate}}</a>
            </li>
            <li role="menuitem">
              <a class="dropdown-item" (click)="duplicate.emit(element)">
                {{'buttons.duplicate' | translate}}
              </a>
            </li>
            <li *ngIf="element.eventId | isTriggerSkippable" role="menuitem">
              <a class="dropdown-item" (click)="onTogglePause(element)">
                                <span
                                  *ngIf="!(element.skippedInquiries | isSkippedForInquiry:inquiryId)">{{'buttons.skip' | translate}}</span>
                <span
                  *ngIf="(element.skippedInquiries | isSkippedForInquiry:inquiryId)">{{'buttons.notskip' | translate}}</span>
              </a>
            </li>
            <li role="menuitem">
              <a class="dropdown-item" (click)="onToggleArchive(element)">
                <span *ngIf="element.archived === 0">{{'buttons.archive' | translate}}</span>
                <span *ngIf="element.archived === 1">{{'buttons.unarchive' | translate}}</span>
              </a>
            </li>
            <li role="menuitem">
              <a class="dropdown-item" (click)="remove.emit(element)">{{'buttons.delete' | translate}}</a>
            </li>
          </ul>

        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="edit.emit(row)"></mat-row>
  </mat-table>

  <app-translated-pagination>
    <mat-paginator #paginator
                   [ngClass]="{hidden: isEmptyTable && isLoaded}"
                   [length]="dataSource?.data?.length"
                   [pageSize]="20"
                   [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
                   [showFirstLastButtons]="true">
    </mat-paginator>
  </app-translated-pagination>

  <app-empty-table *ngIf="isEmptyTable && isLoaded" [type]="tableType"></app-empty-table>
</div>
