<div class="dialog-header">
  <h2 mat-dialog-title>
    <i class="fal fa-columns"></i> {{'pageElements.customizeColumns' | translate}}
  </h2>
  <button (click)="close()" class="close" aria-label="Close" type="button">
    <span class="far fa-times"></span>
  </button>
</div>

<div class="modal-body">

  <p>{{'pageElements.customizeDesc' | translate}}</p>

  <mat-button-toggle-group ngxDroppable [model]="columns" [multiple]="true" [formControl]="columnsCtrl"
                           aria-label="Font Style">

    <mat-button-toggle *ngFor="let item of columns"
                       ngxDraggable
                       [checked]="item.excluded"
                       [disabled]="item.restricted"
                       [moves]="!item.restricted"
                       [model]="item" [value]="item.name">{{item.i18n | translate}}</mat-button-toggle>

  </mat-button-toggle-group>

</div>

<div class="modal-footer text-right">
  <button (click)="close()" type="button" class="btn btn-default left-icon text-uppercase">
    <i class="far fa-check-square" aria-hidden="true"></i>
    {{'buttons.done' | translate}}
  </button>

  <button (click)="reset()" type="button" class="btn btn-default left-icon text-uppercase">
    <i class="fal fa-sync"></i>
    {{'buttons.reset' | translate}}
  </button>

  <button (click)="submit()" type="button"
          class="btn btn-new left-icon text-uppercase">
    <span class="far fa-save" aria-hidden="true"></span>
    {{'buttons.save' | translate}}
  </button>
</div>
