import {LoadContractsComplete} from '../actions/contracts.actions';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {SignatureTemplate} from '../models/signature-template';
import {createReducer, on} from '@ngrx/store';

export interface State extends EntityState<SignatureTemplate> {
  selectedContractId: string | null
  isLoaded: boolean
}

export const adapter: EntityAdapter<SignatureTemplate> = createEntityAdapter<SignatureTemplate>({
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedContractId: null,
  isLoaded:           false
});

export const reducer = createReducer(
  initialState,
  on(LoadContractsComplete, (state, {templates}) => adapter.addMany(templates, {
    ...state,
    isLoaded: true
  }))
);

export const {selectAll} = adapter.getSelectors();
