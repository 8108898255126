<div class="content-holder clearfix table-responsive">
  <mat-table #table matSort [dataSource]="dataSource" class="table-without-select">

    <ng-container matColumnDef="dateFormatted">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.date' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span>{{element.dateFormatted}}</span>
        <div *ngIf="element.message?.delivery_status === 'delivered'"
             matTooltip="Message Delivered"
             matTooltipPosition="above"
             matTooltipClass="text-center" class="delivery-icon">
        <i class="fal fa-shipping-fast"></i>
      </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="eventName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.event' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="text-truncate">
          {{element.eventName}}
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="triggerName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.trigger' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"><span class="text-truncate">{{element.triggerName}}</span></mat-cell>
    </ng-container>

    <ng-container matColumnDef="handlerName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.handler' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.handlerName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="message.text">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.message' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <ng-template #tolTemplate>
          <div class="tal">
            <strong>{{'tooltips.from' | translate}}:</strong> {{element.message?.from}}
            <br>
            <strong>{{'tooltips.guest' | translate}}:</strong> {{element.message?.guestName}}
            <br>
            <strong>{{'tooltips.subject' | translate}}:</strong> {{element.message?.subject}}
            <br>
            <strong>{{'tooltips.body' | translate}}:</strong> {{element.message?.text | stripHtml | truncate:250}}
          </div>
        </ng-template>
        <div *ngIf="!!element.message?.translated"
          matTooltip="This message was translated"
              matTooltipPosition="above"
              matTooltipClass="text-center"
              class="translation-icon">
          <i class="fal fa-globe"></i>
        </div>
        <ng-container *ngIf="element.message?.text?.length > 0">
          <span [tooltip]="tolTemplate" placement="left" class="text-truncate">
            {{element.message?.text | stripHtml | truncate:80}}
          </span>
        </ng-container>
        <ng-container *ngIf="element.message?.text?.length === 0 && element.handler === emailHandler">
          This message was only sent to a Tokeet user.
        </ng-container>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openMessage(row.message)"></mat-row>
  </mat-table>

  <app-translated-pagination>
    <mat-paginator #paginator [ngClass]="{hidden: (isEmptyTable$ | async)}"
                   [length]="dataSource?.data?.length"
                   [pageSize]="5" [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
                   [showFirstLastButtons]="true">
    </mat-paginator>
  </app-translated-pagination>

  <app-empty-table *ngIf="(isEmptyTable$ | async)" [type]="tableType"></app-empty-table>
</div>
