import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {User} from '@automata/models/user';
import {userRoleToString} from '@automata/utility/functions/user-role-to-string';
import * as moment from 'moment';
import {HttpClient} from '@angular/common/http';
import * as R from 'ramda'
import {isSomething} from '@automata/utility/functions/is-something';

declare const window: any;

export interface SubscriptionStatus {
  price: number
  trial_end: number
  until: number
  start: number
  rentals: number
  timestamp: number
  users: number
  status: string
  trial_start: number
  plan_id: string
  product: string
  plan_name: string
  delinquent: boolean
  products: string[]
  quantity: number
}

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {

  constructor(private http: HttpClient) {
  }

  status() {
    const url = '@api/subscription/status';

    return this.http.get<SubscriptionStatus>(url);
  }

  logUser(user: User) {
    if (environment.environment !== 'production' || !isSomething(user)) {
      return
    }

    window.amplitude?.getInstance()?.setUserId(user.id);

    this.status().subscribe((status) => {

      const payload = {
        user_id: user.id,
        user_email: user.primaryemail,
        user_name: `${user.firstname} ${user.lastname}`,
        user_phone: user.phone,
        user_role: userRoleToString(user.roles),
        user_created_at: user.created,
        account: user.account,
        subscription: status?.plan_name,
        subscription_status:
          status.status === 'inactive' ? (status.delinquent ? 'past_due' : 'inactive') : status.status,
        subscription_cycle_start_at: status.start,
        subscription_cycle_end_at: status.until,
        subscription_updated_at: status.timestamp,
        trial: status?.trial_end > moment().unix(),
        trial_end_at: status.trial_end,
        rental_count: R.pathOr(0, ['rentals'], status),
        user_count: R.pathOr(0, ['users'], status),
      }

      try {
        const identify = new window.amplitude.Identify()

        R.forEach((key) => {
          identify.set(key, payload[key])
        }, R.keys(payload))

        window.amplitude?.getInstance()?.identify(identify)
      } catch (e) {
        console.log('error logging to amplitude')
      }
    });
  }

  logEvent(event: string) {
    console.log(event);
    if (environment.environment === 'production') {
      window.amplitude?.getInstance()?.logEvent(event);
    }
  }

  logPageView() {
    if (environment.environment === 'production') {
      window.amplitude?.getInstance()?.logEvent('pageview', {url: window.location.pathname});
    }
  }
}
