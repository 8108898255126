import {Pipe, PipeTransform} from '@angular/core';
import {TriggerEvents} from '@automata/models/trigger-events';

@Pipe({
  name: 'isInquiry'
})
export class IsInquiryPipe implements PipeTransform {

  transform(event: string): boolean {
    return TriggerEvents.isInquiry(event);
  }
}
