<div class="container">
  <div class="login-content">
    <div class="login-form-layout">
      <h1 class="logo text-center">
        <a href="#">
          <img src="assets/logo.png">
        </a>
      </h1>

      <app-login-form *ngIf="!isAuthenticated" (login)="onLogin($event)"></app-login-form>

      <div [ngClass]="{hidden: !isAuthenticated}" class="authorization-box">
        <h1>{{'pageElements.authorizeIFFF' | translate}}</h1>

        <div class="content-holder clearfix">

          <div class="requirements-box clearfix">
            <img src="assets/integrations/ifttt.svg" alt="">

            <h5>{{'pageElements.IFFFDesc' | translate}}</h5>
          </div>

          <div class="authorize-box">
            <button (click)="allow()" class="btn btn-new text-uppercase"
                    type="button">{{'buttons.authorize' | translate}}</button>
            <button (click)="deny()" class="btn btn-cst-danger text-uppercase"
                    type="button">{{'buttons.deny' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
