import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {BookingEffects} from '@automata/containers/bookings-page/store/booking.effects';
import * as fromInquiry from '@automata/containers/bookings-page/store/booking.reducer'

@NgModule({
  imports:      [
    CommonModule,
    StoreModule.forFeature('bookings', fromInquiry.bookingReducer),
    EffectsModule.forFeature([BookingEffects])
  ],
  declarations: [],
})
export class BookingStoreModule {
}
