export class TemplateDataDictionary {
  static rentals = [
    '*|RENTAL:NAME|*',
    '*|RENTAL:PHONE|*',
    '*|RENTAL:EMAIL|*',
    '*|RENTAL:ADDRESS|*',
    '*|RENTAL:CITY|*',
    '*|RENTAL:STATE|*',
    '*|RENTAL:COUNTRY|*',
    '*|RENTAL:POSTAL_CODE|*',
    '*|RENTAL:DESCRIPTION|*',
    '*|RENTAL:TERMS|*',
    '*|RENTAL:PAYMENT_INSTRUCTIONS|*',
    '*|RENTAL:WIFI|*',
    '*|RENTAL:KEYPICKUP|*',
    '*|RENTAL:SECURITY|*',
    '*|RENTAL:SPECIAL|*',
    '*|RENTAL:WEBSITES|*',
    '*|RENTAL:CUSTOM1|*',
    '*|RENTAL:CUSTOM2|*',
    '*|RENTAL:CUSTOM3|*',
    '*|RENTAL:CUSTOM4|*',
    '*|RENTAL:CUSTOM5|*',
    '*|RENTAL:CHECKIN_INSTRUCTIONS|*',
    '*|RENTAL:CHECKOUT_INSTRUCTIONS|*',
    '*|RENTAL:HOUSE_RULES|*',
    '*|RENTAL:DIRECTIONS|*',
  ];

  static inquiries = [
    '*|INQUIRY:INQUIRY_ID|*',
    '*|INQUIRY:ARRIVE|*',
    '*|INQUIRY:DEPART|*',
    '*|INQUIRY:CHECK_IN|*',
    '*|INQUIRY:CHECK_OUT|*',
    '*|INQUIRY:COST|*',
    '*|INQUIRY:NIGHTS|*',
    '*|INQUIRY:ADULTS|*',
    '*|INQUIRY:CHILDREN|*',
    '*|INQUIRY:BOOK_DATE|*',
    '*|INQUIRY:GUEST_PORTAL|*',
    '*|INQUIRY:CHANNEL|*'
  ];

  static guests = [
    '*|GUEST:NAME|*',
    '*|GUEST:FNAME|*',
    '*|GUEST:EMAIL|*',
    '*|GUEST:PHONE|*',
    '*|GUEST:ADDRESS|*',
    '*|GUEST:CITY|*',
    '*|GUEST:COUNTRY|*',
    '*|GUEST:POSTAL_CODE|*',
    '*|GUEST:SOURCE|*',
    '*|GUEST:BIRTHDAY|*',
    '*|GUEST:SPOUSE|*',
    '*|GUEST:HOBBY|*',
    '*|GUEST:RAND4|*',
    '*|GUEST:RAND6|*',
  ];

  static contracts = [
    '*|CONTRACT:SIGNATURE|*',
    '*|CONTRACT:COUNTERSIGN|*',
    '*|CONTRACT:DATE|*'
  ];

  static discounts = [
    '*|DISCOUNT:5|*',
    '*|DISCOUNT:10|*',
    '*|DISCOUNT:15|*',
    '*|DISCOUNT:20|*'
  ];

  static all = TemplateDataDictionary.rentals
    .concat(TemplateDataDictionary.inquiries)
    .concat(TemplateDataDictionary.guests)
    .concat(TemplateDataDictionary.contracts)
    .concat(TemplateDataDictionary.discounts);
}
