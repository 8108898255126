import {FormGroup} from '@angular/forms';

export function SaveForm() {
  return function(target: any, propertyName: string, descriptor: TypedPropertyDescriptor<Function>) {
    let method = descriptor.value;

    descriptor.value = function() {

      const form: FormGroup = arguments[0];

      if (form.invalid) {
        let controls = form.controls;
        for (let key in controls) {
          if (controls.hasOwnProperty(key)) {
            let control = controls[key];
            control.markAsTouched();
          }
        }
        return null;
      }

      return method.apply(this, arguments);
    };
  };
}
