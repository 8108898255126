export class Counter {

  quill: any;
  options: any;

  limitLabel: any;
  limitCount: any;

  charLabel: any;
  charCount: any;

  wordLabel: any;
  wordCount: any;

  limit: number;

  constructor(quill, options) {
    this.quill = quill;
    this.options = options;

    this.charLabel = document.querySelector(options.charLabel);
    this.charCount = document.querySelector(options.charCount);

    this.limitLabel = document.querySelector(options.limitLabel);
    this.limitCount = document.querySelector(options.limitCount);

    this.wordLabel = document.querySelector(options.wordLabel);
    this.wordCount = document.querySelector(options.wordCount);

    this.limit = options.limit;
    quill.on('text-change', this.update.bind(this));
    this.update();  // Account for initial contents
  }

  calculateCharactersFromLimit() {
    let text = this.quill.getText();
    return this.limit - (text.length - 1); // editor return at least 1, because of /n
  }

  calculateWordCount() {
    let text = this.quill.getText();
    text = text.trim();
    // Splitting empty text returns a non-empty array
    return text.length > 0 ? text.split(/\s+/).length : 0;
  }

  calculateCharacterCount() {
    let text = this.quill.getText();
    return text.length - 1;
  }

  update() {
    if (this.charCount == null ||
      this.charLabel == null ||
      this.wordCount == null ||
      this.wordLabel == null) {
      return;
    }

    if (this.limit === null) {
      let wordCount = this.calculateWordCount();
      let charCount = this.calculateCharacterCount();

      let wordLabel = ' Word';
      let charLabel = ' Character';

      if (Math.abs(charCount) !== 1) {
        charLabel += 's';
      }

      if (Math.abs(wordCount) !== 1) {
        wordLabel += 's';
      }

      this.charCount.innerText = Math.abs(charCount);
      this.charLabel.innerText = charLabel;

      this.wordCount.innerText = Math.abs(wordCount);
      this.wordLabel.innerText = wordLabel;

    } else {
      let count = this.calculateCharactersFromLimit();
      let label = ' Character';
      if (Math.abs(count) !== 1) {
        label += 's';
      }
      this.limitCount.innerText = Math.abs(count);
      if (count < 0) {
        this.limitLabel.style.color = '#ff5331';
        this.limitCount.style.color = '#ff5331';
        this.limitCount.style.fontWeight = 'bold';
        this.limitLabel.innerText = `${label} over the limit`;
      } else {
        this.limitLabel.style.color = 'inherit';
        this.limitCount.style.color = 'inherit';
        this.limitCount.style.fontWeight = 'normal';
        this.limitLabel.innerText = `${label} remaining`;
      }
    }
  }
}
