import {Injectable} from '@angular/core';
import {take} from 'rxjs/operators';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SendSampleDialogComponent} from '@automata/components/template/preview-template/send-sample/send-sample-dialog.component';
import {TemplateType} from '@automata/enums/template-type';

@Injectable()
export class SendSampleDialogService {

  constructor(private dialog: MatDialog) {
  }

  public openSendSample(content: string, subject: string, type: TemplateType) {

    const defaultConfig: MatDialogConfig = {
      width:      '500px',
      height:     type === TemplateType.PushNotification ? '285px' : '255px',
      data:       {content, subject, type},
      panelClass: [
        'confirm-modal',
        'modal-confirmation'
      ]
    };

    return this.dialog.open(SendSampleDialogComponent, defaultConfig).afterClosed()
      .pipe(
        take(1)
      )
      .subscribe();
  }
}
