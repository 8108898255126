import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {LoadNotifications, LoadNotificationsComplete} from '../actions/notifications.actions';
import {NotificationsService} from '../services/notifications.service';
import {of} from 'rxjs';
import {catchError, map, switchMap, switchMapTo} from 'rxjs/operators';
import {GuestService} from '../services/guest.service';
import * as R from 'ramda';
import {GuestResponse} from '../interfaces/guest-response';
import {Notification} from '../models/notification';
import {ActionFailed} from '../actions/utility.actions';

@Injectable()
export class NotificationsEffects {

  @Effect()
  loadNotifications$ = this.actions$
    .pipe(
      ofType(LoadNotifications),
      switchMapTo(
        this.notifications
          .get()
          .pipe(
            map((notifications) => {
              const guestIds = R.uniq(R.reject(R.isNil)(R.map(n => n.guest_id, notifications)));
              return {notifications, guestIds};
            }),
            switchMap((param) => {
              return this.guestService
                .getMany(param.guestIds)
                .pipe(
                  map(guests => {
                    return R.map((n: Notification) => {
                      const guest: GuestResponse = R.find(g => g.pkey === n.guest_id, guests);
                      if (!R.isNil(guest)) {
                        n.guest = guest;
                      }
                      return n;
                    }, param.notifications);
                  })
                );
            }),
            map(notifications => LoadNotificationsComplete({notifications})),
            catchError(error => of(ActionFailed({error})))
          )
      )
    );

  constructor(private actions$: Actions,
              private guestService: GuestService,
              private notifications: NotificationsService) {
  }
}
