import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, Validators} from '@angular/forms';
import {PayloadHttpHeader} from '../../../models/payload-http-header';

@Component({
  selector:    'app-payload-headers-dialog',
  templateUrl: './payload-headers-dialog.component.html',
  styleUrls:   ['./payload-headers-dialog.component.scss']
})
export class PayloadHeadersDialogComponent implements OnInit {

  form = this.fb.group({
    id:    ['', [Validators.required]],
    name:  [
      '',
      [Validators.required]
    ],
    value: [
      '',
      [Validators.required]
    ]
  });

  constructor(public dialogRef: MatDialogRef<PayloadHeadersDialogComponent>,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: PayloadHttpHeader) {
  }

  ngOnInit() {
    this.form.setValue({
      id:    this.data.id,
      name:  this.data.name,
      value: this.data.value
    });
  }

  submit() {
    this.dialogRef.close(this.form.getRawValue());
  }

  close() {
    this.dialogRef.close();
  }

}
