import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {TableType} from '../../../enums/table-type';
import {Subscription} from 'rxjs';
import {PayloadHttpHeader} from '../../../models/payload-http-header';
import * as R from 'ramda';
import {DialogService} from '../../../services/dialog.service';
import {TableUtilityService} from '../../../services/table-utility.service';
import {localeCompareSort} from '@automata/utility/functions/locale-compare-sort';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector:    'app-http-headers-table',
  templateUrl: './http-headers-table.component.html',
  styleUrls:   ['./http-headers-table.component.scss']
})
export class HttpHeadersTableComponent implements OnInit, OnDestroy {

  @Input() headers: Partial<PayloadHttpHeader>[];

  models: PayloadHttpHeader[];

  @Input() payloadControl: EventEmitter<boolean>;

  @Output() change = new EventEmitter<PayloadHttpHeader[]>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns = [
    'name',
    'value',
    'edit'
  ];

  dataSource = new MatTableDataSource<PayloadHttpHeader>();

  isEmptyTable$ = this.tableService.isEmptyTable(this.dataSource);
  payloadControlSub: Subscription;

  tableType = TableType.PayloadHttpHeaders;

  constructor(private dialogService: DialogService,
              private translate: TranslateService,
              private tableService: TableUtilityService) {
  }

  ngOnInit() {
    this.models = R.map(h => new PayloadHttpHeader(h), this.headers);
    setTimeout(() => this.dataSource.data = this.models || [], 0);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortData = localeCompareSort;

    this.payloadControlSub = this.payloadControl.subscribe(() => {
      this.onAdd();
    });
  }

  ngOnDestroy() {
    this.payloadControlSub.unsubscribe();
  }

  onAdd() {
    this.dialogService.openPayloadHeaders(new PayloadHttpHeader())
      .subscribe((result) => {
        this.models = R.append(new PayloadHttpHeader(result), this.models);
        this.dataSource.data = this.models;
        this.change.emit(this.models);
      });
  }

  onEdit(header: PayloadHttpHeader) {
    this.dialogService.openPayloadHeaders(header)
      .subscribe((result) => {
        this.models = R.filter((h) => h.id !== result.id, this.models);
        this.models = R.append(new PayloadHttpHeader(result), this.models);
        this.dataSource.data = this.models;
        this.change.emit(this.models);
      });
  }

  onRemove(header: PayloadHttpHeader) {
    this.models = R.filter((h) => h.id !== header.id, this.models);
    this.dataSource.data = this.models;
    this.change.emit(this.models);
  }
}
