import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {ToastService} from '@automata/services/toast.service';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private authService: AuthService,
              private toast: ToastService,
              private router: Router) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.isAdmin()) {
      this.toast.error('unauthorized');
      this.router.navigate(['activity']);
      return false;
    }
    return true;
  }
}
