import {LoadSamplesComplete} from '../actions/samples.actions';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Sample} from '../interfaces/sample';
import {createReducer, on} from '@ngrx/store';

export interface State extends EntityState<Sample> {
  // additional entities state properties
  selectedSampleId: string | null
  isLoaded: boolean
}

export const adapter: EntityAdapter<Sample> = createEntityAdapter<Sample>({
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedSampleId: null,
  isLoaded:         false
});

export const reducer = createReducer(
  initialState,
  on(LoadSamplesComplete, (state, {samples}) => adapter.addMany(samples, {
    ...state,
    isLoaded: true
  }))
);

export const {selectEntities, selectAll} = adapter.getSelectors();
