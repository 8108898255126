import {Component, Inject, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {WorkflowDuplicateDialogParam} from '../../../interfaces/dialog-params/workflow-duplicate-dialog-param';
import {Observable} from 'rxjs';
import {Rental} from '../../../models/rental';
import {FormControl, Validators} from '@angular/forms';
import {map} from 'rxjs/operators';
import * as R from 'ramda';
import {DuplicateWorkflow} from '@automata/actions/workflows.actions';

@Component({
  selector:    'app-workflow-duplicate-dialog',
  templateUrl: './workflow-duplicate-dialog.component.html',
  styleUrls:   ['./workflow-duplicate-dialog.component.scss']
})
export class WorkflowDuplicateDialogComponent implements OnInit {

  rentals$: Observable<Partial<Rental>[]>;

  rentalCtrl = new FormControl([], [Validators.required]);

  constructor(private store: Store<fromRoot.State>,
              public dialogRef: MatDialogRef<WorkflowDuplicateDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: WorkflowDuplicateDialogParam) {
  }

  ngOnInit() {
    this.rentals$ = this.store.pipe(
      select(fromRoot.selectAllRentals),
      map(rentals => R.filter(r => r.id !== this.data.workflow.rentalId, rentals))
    );
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.store.dispatch(DuplicateWorkflow({payload: {rentalIds: this.rentalCtrl.value, workflow: this.data.workflow}}));
    this.dialogRef.close();
  }
}
