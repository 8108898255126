export enum ConditionType {
  UNTIL_CHECKIN = 'until_checkin',
  SINCE_CHECKIN = 'since_checkin',
  UNTIL_CHECKOUT = 'until_checkout',
  SINCE_CHECKOUT = 'since_checkout',
  STAY_LENGTH = 'stay_length',
  GUEST_COUNT = 'guest_count',
  GUEST_COUNTRY = 'guest_country',
  GUEST_PHONE = 'guest_phone',
  GUEST_EMAIL = 'guest_email',
  GUEST_LASTNAME = 'guest_lastname',
  BOOKING_TOTAL = 'booking_total',
  INVOICE_TOTAL = 'invoice_total',
  BOOKINGS_STATUS = 'booking_status',
  INQUIRY_STATUS = 'inquiry_status',
  TRIGGER_CONDITION = 'trigger_condition',
  GUEST_BOOKINGS = 'guest_bookings',
  AVAILABLE_DAYS_BEFORE = 'open_days_before',
  AVAILABLE_DAYS_AFTER = 'open_days_after',
  RENTAL_AVAILABILITY = 'rental_availability',
  BOOKING_TAGS = 'booking_tags',
  EARLY_CHECKIN = 'early_checkin',
  EARLY_CHECKOUT = 'early_checkout',
  LATE_CHECKIN = 'late_checkin',
  LATE_CHECKOUT = 'late_checkout',
}
