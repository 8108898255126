import {TableUtilityService} from '@automata/services/table-utility.service';
import {select, Store} from '@ngrx/store';
import {CodeDialogComponent} from '@automata/components/codes/code-dialog/code-dialog.component';
import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {TagsType} from '@automata/enums/tags-type';
import {FiltersService} from '@automata/services/filters.service';
import {localeCompareSort} from '@automata/utility/functions/locale-compare-sort';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {CodeFilters} from '@automata/interfaces/code/code-filters';
import {isSomething} from '@automata/utility/functions/is-something';
import {DialogService} from '@automata/services/dialog.service';
import {TableType} from '@automata/enums/table-type';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Code} from '@automata/models/code';
import {DeleteCode} from '@automata/actions/codes.actions';
import {FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import * as fromRoot from '../../../reducers';
import {SelectionModel} from '@angular/cdk/collections';
import {map, startWith} from 'rxjs/operators';
import * as R from 'ramda';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

@Component({
  selector:    'app-code-table',
  templateUrl: './code-table.component.html',
  styleUrls:   ['./code-table.component.scss']
})
export class CodeTableComponent extends OnDestroyMixin implements OnInit {

  @Output() render = new EventEmitter<any>();
  @Output() select = new EventEmitter<SelectionModel<Code>>();
  @Output() page = new EventEmitter<PageEvent>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns = this.tableService.getDisplayedColumns(TableType.Codes);

  selection = new SelectionModel<Code>(true, []);
  dataSource = new MatTableDataSource<Code>();

  tableData = new BehaviorSubject<Code[]>([]);

  rentals$ = this.store.pipe(select(fromRoot.selectAllRentals));

  rentalCtrl = new FormControl(this.filters.getCodes().rentals);
  tagsCtrl = new FormControl(this.filters.getCodes().tags);

  isLoaded = false;
  isEmptyTable = false;

  tableType = TableType.Codes;

  tags$ = this.filters.registerTagsFilter(TagsType.Code, this.tagsCtrl);

  renderedData: any[];

  constructor(private store: Store<fromRoot.State>,
              private tableService: TableUtilityService,
              private dialogService: DialogService,
              private translate: TranslateService,
              private filters: FiltersService) {
    super()
  }

  ngOnInit() {

    this.dataSource.data = [];
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortData = localeCompareSort;

    // material table has no exposed API for getting what's rendered so we access private property
    this.dataSource['_renderData'].pipe(untilComponentDestroyed(this)).subscribe((rows) => {
      this.renderedData = rows;
      if (isSomething(rows)) {
        this.render.emit(rows);
      }
      this.isEmptyTable = rows.length === 0;
    });

    const filters = this.filters.getCodes();

    combineLatest(
      this.tableData,
      this.rentalCtrl.valueChanges.pipe(startWith(filters.rentals)),
      this.tagsCtrl.valueChanges.pipe(startWith(filters.tags))
    )
      .pipe(
        map((data) => {
          let [codes, rentals, tags] = data;

          this.filters.storeCodes({
            rentals,
            tags
          } as CodeFilters);

          if (isSomething(rentals)) {
            codes = R.filter((code: Code) => R.all(R.equals(true), <any>R.map(rental => R.contains(<any>rental, <any>code.rentals), rentals)), codes);
          }

          if (isSomething(tags)) {
            codes = R.filter((code: Code) => R.any(R.equals(true), <any>R.map(tag => R.contains(<any>tag, <any>code.attributes.tags), tags)), codes);
          }

          return codes;
        }),
        untilComponentDestroyed(this)
      )
      .subscribe((codes) => {
        setTimeout(() => {
          this.isLoaded = true;
          this.paginator.firstPage();
          this.dataSource.data = codes;
          this.selection.deselect(...this.selection.selected);
        }, 0);
      });

    this.store.pipe(select(fromRoot.selectCodesWithCreatorAndRentals), untilComponentDestroyed(this))
      .subscribe((codes) => {
        this.tableData.next(codes);
      });

    this.selection.changed.asObservable().pipe(untilComponentDestroyed(this)).subscribe(change => {
      if (isSomething(change.source)) {
        this.select.emit(change.source);
      }
    });

    this.paginator.page.asObservable().pipe(untilComponentDestroyed(this), startWith()).subscribe(page => {
      if (isSomething(page)) {
        this.page.emit(page);
      }
    });

    this.select.emit(this.selection);
    this.page.emit(this.paginator);
  }

  isAllSelected() {
    return this.tableService.isAllSelected(this.selection, this.renderedData);
  }

  masterToggle() {
    this.tableService.masterToggle(this.selection, this.renderedData, this.dataSource);
  }

  applyFilter(term: string) {
    this.paginator.firstPage();
    this.dataSource.filter = term.trim().toLowerCase();
  }

  onDetails(code: Code) {
    this.dialogService.openSide(CodeDialogComponent, {
      data: {code: code}
    });
  }

  onRemove(code: Code) {
    this.dialogService.openConfirm().subscribe(() => {
      this.store.dispatch(DeleteCode({id: code.id}));
    });
  }

  tableManagement() {
    this.dialogService.openTableManagement(this.tableType)
      .subscribe(columns => {
        this.displayedColumns = columns;
      });
  }
}
