import {NotificationResponse} from '../interfaces/notification-response';
import * as moment from 'moment';
import {Rental} from './rental';
import {GuestResponse} from '../interfaces/guest-response';

export class Notification implements NotificationResponse {

  id: string;
  action: string;
  date: number;
  guest_id: string;
  link_pkey: string;
  link_type: string;
  pkey: string;
  rental_id: string;
  subject: string;
  title: string;

  rental: Rental;
  guest: GuestResponse;
  dateFormatted: string;

  constructor(notification: Partial<Notification>) {
    this.id = notification.pkey;
    this.action = notification.action;
    this.date = notification.date;
    this.guest_id = notification.guest_id;
    this.link_pkey = notification.link_pkey;
    this.link_type = notification.link_type;


    /**
     * Special Case where we change Inquiry Cancelled into Booking Cancelled
     * Special Case where we change Inquiry Booked into Booking Confirmed
     * because that's how trigger event is called
     */
    if (this.link_type === 'inquiry' && this.action === 'cancelled') {
      this.link_type = 'booking';
    }
    if (this.link_type === 'inquiry' && this.action === 'booked') {
      this.link_type = 'booking';
      this.action = 'confirmed';
    }

    this.pkey = notification.pkey;
    this.rental_id = notification.rental_id;
    this.subject = notification.subject;
    this.title = notification.title;

    this.rental = notification.rental;
    this.guest = notification.guest;
    this.dateFormatted = moment.unix(notification.date).format('DD - MMM - YYYY');
  }

}
