import {Component} from '@angular/core';

@Component({
  selector:    'app-activity',
  templateUrl: './activity.component.html',
  styleUrls:   ['./activity.component.scss']
})
export class ActivityComponent {

}
