import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ReplaySubject, Subject} from 'rxjs';
import * as R from 'ramda';
import {takeUntil} from 'rxjs/operators';
import { FloatLabelType } from '@angular/material/core';

@Component({
  selector:    'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls:   ['./select-search.component.scss']
})
export class SelectSearchComponent implements OnInit, OnDestroy, OnChanges {

  @Input() items: any[];
  @Input() placeholder: string;
  @Input() multiple = true;
  @Input() hasBlank = false;
  @Input() ctrl?: FormControl;

  @Input() bindLabel?: string;
  @Input() bindValue?: string;

  @Input() floatLabel: FloatLabelType = 'auto';

  @Output() change = new EventEmitter<string[]>();

  hasCtrl = false;
  isRequired = false;

  public itemFilterCtrl: FormControl = new FormControl();
  public filteredItems: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  private _onDestroy = new Subject<void>();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.items) {
      const items: any[] = R.path(['items', 'currentValue'], changes) || [];
      this.filteredItems.next(items.slice());
    }
  }

  ngOnInit() {

    this.items = this.items || [];

    this.checkIfRequired();

    if (this.ctrl) {
      this.hasCtrl = true;
    } else {
      this.ctrl = new FormControl();
    }

    this.filteredItems.next(this.items.slice());

    this.itemFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterChannels();
      });

    this.ctrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe((items) => {
        this.checkIfRequired();
        if (R.isNil(items)) {
          items = [];
        } else if (!R.is(Array, items)) {
          items = [items];
        }
        if (!this.hasCtrl) {
          this.change.emit(items);
        }
      });
  }

  checkIfRequired() {
    let errors: any = this.ctrl && this.ctrl.validator && (<any>this.ctrl).validator(new FormControl());
    this.isRequired = !R.isNil(errors) && errors.required;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private filterChannels() {
    if (!this.items) {
      return;
    }
    let search = this.itemFilterCtrl.value;
    if (!search) {
      this.filteredItems.next(this.items.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredItems.next(
      this.items.filter(item => {
        return (this.bindLabel ? R.path(R.split('.', this.bindLabel), item) || '' : item.name).toLowerCase().indexOf(search) > -1;
      })
    );
  }

}

