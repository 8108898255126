import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {Observable, Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {TemplateSamplesDialogComponent} from '../../template/template-samples-dialog/template-samples-dialog.component';
import {TriggerSample} from '../../../models/samples/trigger-sample';
import {localeCompareSort} from '@automata/utility/functions/locale-compare-sort';
import {TableUtilityService} from '@automata/services/table-utility.service';

@Component({
  selector:    'app-trigger-samples-dialog',
  templateUrl: './trigger-samples-dialog.component.html',
  styleUrls:   ['./trigger-samples-dialog.component.scss']
})
export class TriggerSamplesDialogComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns = [
    'icon',
    'name',
    'templateDescription'
  ];

  selection = new SelectionModel<TriggerSample>(true, []);
  dataSource = new MatTableDataSource<TriggerSample>();

  source$: Observable<TriggerSample[]>;

  samplesSub: Subscription;

  constructor(private store: Store<fromRoot.State>,
              private tableService: TableUtilityService,
              public dialogRef: MatDialogRef<TemplateSamplesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {workflowAbleOnly: boolean}) {
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortData = localeCompareSort;

    if (this.data.workflowAbleOnly) {
      this.source$ = this.store.pipe(select(fromRoot.selectWorkflowAbleTriggerSamples));
    } else {
      this.source$ = this.store.pipe(select(fromRoot.selectTriggerSamplesWithDescription));
    }

    this.samplesSub = this.source$
      .subscribe(samples => {
        this.dataSource.data = <TriggerSample[]>samples;
      });
  }

  ngOnDestroy() {
    this.samplesSub.unsubscribe();
  }

  openSample(sample: TriggerSample) {
    this.dialogRef.close(sample);
  }

  close() {
    this.dialogRef.close();
  }

}
