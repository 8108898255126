import {createAction, props} from '@ngrx/store';
import {Template} from '@automata/models/template';
import {TemplateAddRequest} from '@automata/interfaces/template/template-add-request';
import {TemplateEditRequest} from '@automata/interfaces/template/template-edit-request';

export const LoadTemplates = createAction('[Templates] Load Start');
export const LoadTemplatesComplete = createAction('[Templates] Load Complete', props<{templates: Template[]}>());

export const DeleteBatchTemplate = createAction('[Templates] Delete Batch Start', props<{ids: string[]}>());
export const DeleteBatchTemplateComplete = createAction('[Templates] Delete Batch Complete', props<{ids: string[]}>());

export const DeleteTemplate = createAction('[Templates] Delete Start', props<{id: string}>());
export const DeleteTemplateComplete = createAction('[Templates] Delete Complete', props<{id: string}>());

export const AddTemplate = createAction('[Templates] Add Start', props<{request: TemplateAddRequest}>());
export const AddTemplateComplete = createAction('[Templates] Add Complete', props<{template: Template}>());

export const AddTemplateSample = createAction('[Templates] Add Sample Start', props<{request: TemplateAddRequest}>());
export const AddTemplateSampleComplete = createAction('[Templates] Add Sample Complete', props<{template: Template}>());

export const EditTemplate = createAction('[Templates] Edit Start', props<{request: TemplateEditRequest}>());
export const EditTemplateComplete = createAction('[Templates] Edit Complete', props<{template: Template}>());

export const ClearLastAddedSample = createAction('[Templates] Clear Last Added Sample');
export const ClearLastAddedTemplate = createAction('[Templates] Clear Last Added Template');
export const ClearLastEditedTemplate = createAction('[Templates] Clear Last Edited Template');
export const CreateTemplatesComplete = createAction('[Templates] Add Multiple', props<{templates: Template[]}>());
