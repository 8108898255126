import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {LoadUsers, LoadUsersComplete} from '../actions/users.actions';
import {UsersService} from '../services/users.service';
import {of} from 'rxjs';
import {catchError, map, switchMapTo} from 'rxjs/operators';
import {ActionFailed} from '../actions/utility.actions';

@Injectable()
export class UsersEffects {

  @Effect()
  loadUsers$ = this.actions$
    .pipe(
      ofType(LoadUsers),
      switchMapTo(
        this.users.all()
          .pipe(
            map(users => LoadUsersComplete({users})),
            catchError(error => of(ActionFailed({error})))
          )
      )
    );

  constructor(private actions$: Actions,
              private users: UsersService) {
  }
}
