import {Component, Inject, OnInit} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import DOMPurify from 'dompurify';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DataDictService} from '@automata/components/template/preview-template/data-dictionary.service';
import {SamplesService} from '@automata/services/samples.service';
import {switchMap} from 'rxjs/operators';
import {SendSampleService} from '@automata/components/template/preview-template/send-sample/send-sample.service';
import {AuthService} from '@automata/services/auth.service';
import {ToastService} from '@automata/services/toast.service';
import {SendSampleDialogService} from '@automata/components/template/preview-template/send-sample/send-sample-dialog.service';
import {TemplateType} from '@automata/enums/template-type';


@Component({
  selector:    'app-preview-template-dialog',
  templateUrl: './preview-template-dialog.component.html',
  styleUrls:   ['./preview-template-dialog.component.scss']
})
export class PreviewTemplateDialogComponent implements OnInit {

  content: SafeHtml
  types = TemplateType
  compiledContent: string

  constructor(public dialogRef: MatDialogRef<PreviewTemplateDialogComponent>,
              private sanitizer: DomSanitizer,
              private toast: ToastService,
              private authService: AuthService,
              private samplesService: SamplesService,
              private sendSampleDialogService: SendSampleDialogService,
              private previewTemplateService: SendSampleService,
              private dataDictService: DataDictService,
              @Inject(MAT_DIALOG_DATA) public data: {content: string, subject: string, type: TemplateType}) {
  }

  ngOnInit() {
    this.samplesService.getAllSamples()
      .pipe(
        switchMap(([rental, guest, inquiry]) => {
          return this.dataDictService
            .resolve(this.data.content, {
              inquiry,
              rental,
              guest,
            })
        }),
      )
      .subscribe((compiledMessage) => {
        const sanitizedContent = DOMPurify.sanitize(compiledMessage || '');
        this.compiledContent = sanitizedContent
        this.content = this.sanitizer.bypassSecurityTrustHtml(sanitizedContent)
      })
  }

  onSendTest() {
    this.sendSampleDialogService.openSendSample(this.compiledContent, this.data.subject, this.data.type)
  }

  close() {
    this.dialogRef.close();
  }

}
