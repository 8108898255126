import {TriggerConditionModel} from '../../../interfaces/trigger/trigger-condition';
import {ConditionType} from '../enums/condition-type';
import * as moment from 'moment';
import * as R from 'ramda';
import {comparatorMapping} from './condition-comparator-mapping';
import {getCountryName} from '../../../models/country-list';

declare const require: any;

const capitalize = require('lodash/capitalize');

export class TriggerCondition implements TriggerConditionModel {
  type: ConditionType;
  comp: string;
  value?: number | string;
  trigger?: string;
  days?: number;
  hours?: number;
  minutes?: number;

  label: string;
  key: string;
  updated: number;
  updatedFormatted: string;

  description: string;

  constructor(condition: Partial<TriggerCondition>) {
    this.key = condition.key;
    this.type = condition.type;
    this.comp = condition.comp;
    if (!R.isNil(condition.value)) {
      this.value = condition.value;
    }
    if (!R.isNil(condition.trigger)) {
      this.trigger = condition.trigger;
    }
    if (!R.isNil(condition.days)) {
      this.days = condition.days;
    }
    if (!R.isNil(condition.hours)) {
      this.hours = condition.hours;
    }
    if (!R.isNil(condition.minutes)) {
      this.minutes = condition.minutes;
    }
    this.updated = condition.updated;
    this.updatedFormatted = moment.unix(this.updated).isValid() ? moment.unix(this.updated).format('DD - MMM - YYYY | h:mm A') : null;

    try {
      let desc = '';
      let daysLabel = '';
      let hoursLabel = '';
      let nightsLabel = '';
      let compModel: any;
      let join = '';

      switch (this.type) {
        case ConditionType.UNTIL_CHECKIN:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.UNTIL_CHECKIN]);
          daysLabel = this.days === 1 ? 'day' : 'days';
          hoursLabel = this.hours === 1 ? 'hour' : 'hours';
          desc = `${capitalize(compModel.label)} ${this.days} ${daysLabel} and ${this.hours} ${hoursLabel} until checkin`;
          break;
        case ConditionType.SINCE_CHECKIN:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.SINCE_CHECKIN]);
          daysLabel = this.days === 1 ? 'day' : 'days';
          hoursLabel = this.hours === 1 ? 'hour' : 'hours';
          desc = `${capitalize(compModel.label)} ${this.days} ${daysLabel} and ${this.hours} ${hoursLabel} since checkin`;
          break;
        case ConditionType.UNTIL_CHECKOUT:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.UNTIL_CHECKOUT]);
          daysLabel = this.days === 1 ? 'day' : 'days';
          hoursLabel = this.hours === 1 ? 'hour' : 'hours';
          desc = `${capitalize(compModel.label)} ${this.days} ${daysLabel} and ${this.hours} ${hoursLabel} until checkout`;
          break;
        case ConditionType.SINCE_CHECKOUT:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.SINCE_CHECKOUT]);
          daysLabel = this.days === 1 ? 'day' : 'days';
          hoursLabel = this.hours === 1 ? 'hour' : 'hours';
          desc = `${capitalize(compModel.label)} ${this.days} ${daysLabel} and ${this.hours} ${hoursLabel} since checkout`;
          break;
        case ConditionType.STAY_LENGTH:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.STAY_LENGTH]);
          nightsLabel = this.value === 1 ? 'night' : 'nights';
          join = compModel.label === 'equals' ? '' : ' is';
          desc = `Length of stay${join} ${compModel.label} ${this.value} ${nightsLabel}`;
          break;
        case ConditionType.GUEST_COUNT:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.GUEST_COUNT]);
          join = compModel.label === 'equals' ? '' : ' is';
          desc = `Number of guests${join} ${compModel.label} ${this.value}`;
          break;
        case ConditionType.GUEST_COUNTRY:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.GUEST_COUNTRY]);
          desc = `Guest country ${compModel.label} ${getCountryName(<string>this.value)}`;
          break;
        case ConditionType.GUEST_EMAIL:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.GUEST_EMAIL]);
          desc = `Guest email ${compModel.label}`;
          break;
        case ConditionType.GUEST_PHONE:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.GUEST_PHONE]);
          desc = `Guest phone ${compModel.label}`;
          break;
        case ConditionType.GUEST_LASTNAME:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.GUEST_LASTNAME]);
          desc = `Guest last name ${compModel.label}`;
          break;
        case ConditionType.BOOKING_TOTAL:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.BOOKING_TOTAL]);
          desc = `Booking total is ${compModel.label} ${this.value}`;
          break;
        case ConditionType.INVOICE_TOTAL:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.INVOICE_TOTAL]);
          desc = `Invoice total is ${compModel.label} ${this.value}`;
          break;
        case ConditionType.BOOKINGS_STATUS:
          compModel = R.find((comp) => comp.comp === this.comp && comp.value === this.value, comparatorMapping[ConditionType.BOOKINGS_STATUS]);
          desc = `Booking ${compModel.label}`;
          break;
        case ConditionType.INQUIRY_STATUS:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.INQUIRY_STATUS]);
          desc = `Inquiry ${compModel.label} ${this.value === 'inquiry' ? 'pending' : this.value}`;
          break;
        case ConditionType.TRIGGER_CONDITION:
          compModel = R.find((comp) => comp.comp === this.comp && comp.value === this.value, comparatorMapping[ConditionType.TRIGGER_CONDITION]);
          desc = `Trigger ${compModel.label}`;
          this.label = compModel.label;
          break;
        case ConditionType.GUEST_BOOKINGS:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.GUEST_BOOKINGS]);
          const guestBookingsLabel = this.value === 1 ? 'booking' : 'bookings';
          desc = `Guest has ${compModel.label} ${this.value} ${guestBookingsLabel}`;
          break;
        case ConditionType.AVAILABLE_DAYS_BEFORE:
          desc = `${this.value} night(s) available before booking`;
          break;
        case ConditionType.AVAILABLE_DAYS_AFTER:
          desc = `${this.value} night(s) available after booking`;
          break;
        case ConditionType.RENTAL_AVAILABILITY:
          compModel = R.find((comp) => comp.comp === this.comp && comp.value === this.value, comparatorMapping[ConditionType.RENTAL_AVAILABILITY]);
          desc = `Rental is ${compModel.label}`;
          break;
        case ConditionType.BOOKING_TAGS:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.BOOKING_TAGS]);
          desc = `Booking tag ${compModel.label} '${this.value}'`;
          break;
        case ConditionType.EARLY_CHECKIN:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.EARLY_CHECKIN]);
          desc = `Checkin is early by ${this.hours} hours and ${this.minutes} minutes or more`;
          break;
        case ConditionType.EARLY_CHECKOUT:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.EARLY_CHECKOUT]);
          desc = `Checkout is early by ${this.hours} hours and ${this.minutes} minutes or more`;
          break;
        case ConditionType.LATE_CHECKIN:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.LATE_CHECKIN]);
          desc = `Checkin is late by ${this.hours} hours and ${this.minutes} minutes or more`;
          break;
        case ConditionType.LATE_CHECKOUT:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.LATE_CHECKOUT]);
          desc = `Checkout is late by ${this.hours} hours and ${this.minutes} minutes or more`;
          break;
      }

      this.description = desc;
    } catch (e) {
      console.log(e);
      this.description = '';
    }
  }
}
