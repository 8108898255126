import {Pipe, PipeTransform} from '@angular/core';
import * as lang from '../models/languages';
import * as R from 'ramda';

@Pipe({
  name: 'languageName'
})
export class LanguageNamePipe implements PipeTransform {

  transform(langCode: any): string {
    const language = R.find(c => c['1'] === langCode, <any>lang.languages);
    if (!R.isNil(language)) {
      return (<any>language).name;
    } else {
      return 'Unknown';
    }
  }
}
