import * as R from 'ramda';
import {ConditionType} from '../enums/condition-type';

export function typeMappings() {
  const typeMapping: {[key: string]: {value: ConditionType, label: string, tooltip: string}} = {
    [ConditionType.UNTIL_CHECKIN]: {
      value: ConditionType.UNTIL_CHECKIN,
      label: 'until checkin',
      tooltip: 'Trigger will fire if the specified amount of time, that is left before checkin, is true at the time of the trigger event.'
    },
    [ConditionType.SINCE_CHECKIN]: {
      value: ConditionType.SINCE_CHECKIN,
      label: 'since checkin',
      tooltip: 'Trigger will fire if the specified amount of time, that has passed since checkin, is true at the time of the trigger event.'
    },
    [ConditionType.UNTIL_CHECKOUT]: {
      value: ConditionType.UNTIL_CHECKOUT,
      label: 'until checkout',
      tooltip: 'Trigger will fire if the specified amount of time, that is left before checkout, is true at the time of the trigger event.'
    },
    [ConditionType.SINCE_CHECKOUT]: {
      value: ConditionType.SINCE_CHECKOUT,
      label: 'since checkout',
      tooltip: 'Trigger will fire if the specified amount of time, that has passed since checkout, is true at the time of the trigger event.'
    },
    [ConditionType.STAY_LENGTH]: {
      value: ConditionType.STAY_LENGTH,
      label: 'length of stay',
      tooltip: 'Trigger will fire based on the number of nights for the booking.'
    },
    [ConditionType.GUEST_COUNT]: {
      value: ConditionType.GUEST_COUNT,
      label: 'number of guests',
      tooltip: 'Trigger will fire based on the number of guests on the booking.'
    },
    [ConditionType.GUEST_COUNTRY]: {
      value: ConditionType.GUEST_COUNTRY,
      label: 'guest country',
      tooltip: 'Trigger will fire based on whether or not the address of the guest contains the specified country.'
    },
    [ConditionType.GUEST_EMAIL]: {
      value: ConditionType.GUEST_EMAIL,
      label: 'guest email',
      tooltip: 'Trigger will fire based on whether or not the email address of the guest exists in the guest details.'
    },
    [ConditionType.GUEST_PHONE]: {
      value: ConditionType.GUEST_PHONE,
      label: 'guest phone',
      tooltip: 'Trigger will fire based on whether or not the phone number of the guest exists in the guest details.'
    },
    [ConditionType.GUEST_LASTNAME]: {
      value: ConditionType.GUEST_LASTNAME,
      label: 'guest last name',
      tooltip: 'Trigger will fire based on whether or not the last name of the guest exists in the guest details.'
    },
    [ConditionType.BOOKING_TOTAL]: {
      value: ConditionType.BOOKING_TOTAL,
      label: 'booking total',
      tooltip: 'Trigger will fire based on the amount of the booking total.'
    },
    [ConditionType.INVOICE_TOTAL]: {
      value: ConditionType.INVOICE_TOTAL,
      label: 'invoice total',
      tooltip: 'Trigger will fire based on the amount of the invoice total.'
    },
    [ConditionType.BOOKINGS_STATUS]: {
      value: ConditionType.BOOKINGS_STATUS,
      label: 'booking status',
      tooltip: 'Trigger will fire based on the paid or unpaid status of the booking.'
    },
    [ConditionType.INQUIRY_STATUS]: {
      value: ConditionType.INQUIRY_STATUS,
      label: 'inquiry status',
      tooltip: 'Trigger will fire based on whether or not the status of the inquiry is Booked, Canceled, or Pending.'
    },
    [ConditionType.TRIGGER_CONDITION]: {
      value: ConditionType.TRIGGER_CONDITION,
      label: 'the specified trigger',
      tooltip: 'Trigger will fire based on whether or not the specified trigger has already fired. This can only be used with triggers that are part of the same workflow.'
    },
    [ConditionType.GUEST_BOOKINGS]: {
      value: ConditionType.GUEST_BOOKINGS,
      label: 'guest bookings',
      tooltip: 'Trigger will fire if the guest has the specified number of bookings in your inquiries inbox.'
    },
    [ConditionType.AVAILABLE_DAYS_AFTER]: {
      value: ConditionType.AVAILABLE_DAYS_AFTER,
      label: 'available nights after',
      tooltip: 'Trigger will fire based on the number of nights, that must be open on the calendar, after the booking ends.'
    },
    [ConditionType.AVAILABLE_DAYS_BEFORE]: {
      value: ConditionType.AVAILABLE_DAYS_BEFORE,
      label: 'available nights before',
      tooltip: 'Trigger will fire based on the number of nights, that must be open on the calendar, before the booking starts.'
    },
    [ConditionType.RENTAL_AVAILABILITY]: {
      value: ConditionType.RENTAL_AVAILABILITY,
      label: 'rental availability',
      tooltip: 'Trigger will fire based on whether or not the calendar is available for the dates requested by the guest.'
    },
    [ConditionType.BOOKING_TAGS]: {
      value: ConditionType.BOOKING_TAGS,
      label: 'booking tags',
      tooltip: 'Trigger will fire based on whether or not the booking contains the specified tag.'
    },
    [ConditionType.EARLY_CHECKIN]: {
      value: ConditionType.EARLY_CHECKIN,
      label: 'early checkin',
      tooltip: 'Trigger will fire if the checkin time on the booking is earlier than the checkin time on the rental by the specified amount of time or more.'
    },
    [ConditionType.EARLY_CHECKOUT]: {
      value: ConditionType.EARLY_CHECKOUT,
      label: 'early checkout',
      tooltip: 'Trigger will fire if the checkout time on the booking is earlier than the checkout time on the rental by the specified amount of time or more.'
    },
    [ConditionType.LATE_CHECKIN]: {
      value: ConditionType.LATE_CHECKIN,
      label: 'late checkin',
      tooltip: 'Trigger will fire if the checkin time on the booking is later than the checkin time on the rental by the specified amount of time or more.'
    },
    [ConditionType.LATE_CHECKOUT]: {
      value: ConditionType.LATE_CHECKOUT,
      label: 'late checkout',
      tooltip: 'Trigger will fire if the checkout time on the booking is later than the checkout time on the rental by the specified amount of time or more.'
    }
  };
  return typeMapping;
}

export const typeMappingValues = R.sort((a, b) => a.label.toString().localeCompare(b.label), R.values(typeMappings()));
