<div class="modal-header">
  <h1>
    <span *ngIf="!isEdit()">{{'pageElements.createTriggerCondition' | translate}}</span>
    <span *ngIf="isEdit()">{{'pageElements.editTriggerCondition' | translate}}</span>
  </h1>

  <button (click)="close()" type="button" class="close">
    <span>&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="description">
    <h6>{{'pageElements.exampleCondition' | translate}}:</h6>
    <h5 [ngClass]="{hidden: !description}">{{description}} <span *ngIf="activeTypeTooltip$ | async as tooltip" [matTooltip]="tooltip" matTooltipPosition="above" matTooltipClass="text-center"><i class="fal fa-question-circle"></i></span></h5>
    <h5 [ngClass]="{hidden: description}">{{'pageElements.conditionDescription' | translate}}</h5>
  </div>

  <form [formGroup]="form">

    <div class="row">

      <div class="col-sm-4" [ngClass]="{'col-sm-push-8': changeColumns()}">
        <mat-form-field>
          <mat-select [matTooltip]="'tooltips.typeOfCondition' | translate" matTooltipPosition="above"
                      matTooltipClass="text-center" formControlName="type"
                      [placeholder]="'formControls.type' | translate">
            <mat-option *ngFor="let t of types" [value]="t.value" [matTooltip]="t.tooltip" matTooltipPosition="right" matTooltipClass="text-center">
              {{ t.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-sm-4" [ngClass]="{'col-sm-pull-4': changeColumns()}">
        <mat-form-field *ngIf="comparators">
          <mat-select formControlName="comp" [placeholder]="'formControls.condition' | translate">
            <mat-option *ngFor="let c of comparators" [value]="c">
              {{ c.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-sm-4" [ngClass]="{'col-sm-pull-4': changeColumns()}">
        <app-condition-value *ngIf="hasType()" [type]="form.get('type')" [form]="valueForm"
                             [triggers]="triggers"></app-condition-value>
      </div>

    </div>
  </form>
</div>

<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-default left-icon text-uppercase">
    <i class="far fa-check-square" aria-hidden="true"></i>
    {{'buttons.cancel' | translate}}
  </button>
  <button (click)="save()" [disabled]="form.invalid || valueForm.invalid" type="button"
          class="btn btn-new left-icon text-uppercase">
    <i class="far fa-plus" aria-hidden="true"></i>
    {{'buttons.save' | translate}}
  </button>
</div>
