import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {LoadRentals, LoadRentalsComplete, UpdateRentalSettings, UpdateRentalSettingsComplete} from '../actions/rentals.actions';
import {RentalsService} from '../services/rentals.service';
import {of} from 'rxjs';
import {catchError, map, switchMap, switchMapTo} from 'rxjs/operators';
import {ActionFailed} from '../actions/utility.actions';

@Injectable()
export class RentalsEffects {

  @Effect()
  loadRentals$ = this.actions$
    .pipe(
      ofType(LoadRentals),
      switchMapTo(
        this.rentals
          .all()
          .pipe(
            map(rentals => LoadRentalsComplete({rentals})),
            catchError(error => of(ActionFailed({error})))
          )
      )
    );

  @Effect()
  updateSettings$ = this.actions$
    .pipe(
      ofType(UpdateRentalSettings),
      switchMap(
        ({settings, rental}) => this.rentals
          .updateSettings(settings, rental)
          .pipe(
            map(rental => UpdateRentalSettingsComplete({rental})),
            catchError(error => of(ActionFailed({error})))
          )
      )
    );

  constructor(private actions$: Actions,
              private rentals: RentalsService) {
  }
}
