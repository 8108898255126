import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, IAppConfig} from '../app.config';
import {combineLatest, from} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {concatMap, flatMap, map, tap, toArray} from 'rxjs/operators';
import {TemplateSample} from '../models/samples/template-sample';
import {TriggerSample} from '../models/samples/trigger-sample';
import {WorkflowSample} from '../models/samples/workflow-sample';
import {GuestSample, InquirySample, RentalSample} from '@automata/components/template/preview-template/data-dictionary.service';

@Injectable()
export class SamplesService {

  constructor(private http: HttpClient,
              private store: Store<fromRoot.State>,
              @Inject(APP_CONFIG) private config: IAppConfig) {
  }

  samples() {
    return from(['template_samples', 'trigger_samples', 'workflow_samples'])
      .pipe(
        concatMap(name => this.http.get<Object[]>(`${this.config.samplesUrl}/${name}.json`)
          .pipe(
            flatMap(response => from(response)),
            map(response => {
              switch (name) {
                case 'template_samples':
                  return TemplateSample.deserialize(response);
                case 'trigger_samples':
                  return TriggerSample.deserialize(response);
                case 'workflow_samples':
                  return WorkflowSample.deserialize(response);
              }
            }),
            toArray()
          ))
      );
  }

  rentalSample() {
    return this.http.get<RentalSample>(`${this.config.samplesUrl}/sample_rental.json`)
  }

  guestSample() {
    return this.http.get<GuestSample>(`${this.config.samplesUrl}/sample_guest.json`)
  }

  inquirySample() {
    return this.http.get<InquirySample>(`${this.config.samplesUrl}/sample_inquiry.json`)
  }

  getAllSamples() {
    return combineLatest([
      this.rentalSample(),
      this.guestSample(),
      this.inquirySample()
    ])
  }
}
