import {
  AddWorkflowsToTriggersComplete,
  ArchiveTriggerComplete,
  CreateConditionComplete,
  CreateTriggerComplete,
  CreateTriggersComplete,
  DeleteBatchTriggerComplete,
  DeleteConditionComplete,
  DeleteTriggerComplete,
  LoadAllTriggersComplete,
  LoadTriggerDetails,
  LoadTriggerDetailsComplete,
  LoadTriggersComplete,
  LoadTriggerSkippedBookingsComplete,
  OpenTriggerComplete,
  PauseTriggerComplete,
  PauseTriggersComplete,
  RemoveFromWorkflowComplete,
  ResumeTriggerComplete,
  ResumeTriggersComplete,
  SaveConditionComplete,
  SaveTriggerComplete,
  TriggerSkipInquiryComplete,
  TriggerUnSkipInquiryComplete,
  UnArchiveTriggerComplete,
  UpdateTriggersComplete
} from '../actions/triggers.actions';
import {Trigger} from '../models/trigger';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as R from 'ramda';
import {TriggerCondition} from '../features/conditions/models/trigger-condition';
import {TriggerSkipsResponse} from '../interfaces/trigger/trigger-skips-response';
import {createReducer, on} from '@ngrx/store';

export interface State extends EntityState<Trigger> {
  // additional entities state properties
  selectedTriggerId: string | null
  isLoaded: boolean
  legacy: Trigger[]
}

export const adapter: EntityAdapter<Trigger> = createEntityAdapter<Trigger>({
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedTriggerId: null,
  isLoaded:          false,
  legacy: []
});


export const reducer = createReducer(
  initialState,
  on(LoadAllTriggersComplete, (state, {automata, legacy}) => adapter.addAll(automata, {
    ...state,
    isLoaded: true,
    legacy
  })),
  on(LoadTriggersComplete, (state, {automata, legacy}) => adapter.addMany(automata, {
    ...state,
    isLoaded: true,
    legacy
  })),
  on(CreateTriggersComplete, (state, {triggers}) => {
    if (triggers.length === 1) {
      return adapter.addMany(triggers, {...state, selectedTriggerId: triggers[0].id});
    } else {
      return adapter.addMany(triggers, state);
    }
  }),
  on(CreateTriggerComplete, (state, {trigger}) => adapter.addOne(trigger, {
    ...state,
    selectedTriggerId: trigger.id
  })),
  on(OpenTriggerComplete, (state) => ({...state, selectedTriggerId: null})),
  on(TriggerSkipInquiryComplete, (state, {response}) => adapter.updateOne({
    id:      response.trigger,
    changes: {
      skippedInquiries: R.append(response.booking, state.entities[response.trigger].skippedInquiries)
    }
  }, state)),
  on(TriggerUnSkipInquiryComplete, (state, {response}) => adapter.updateOne({
    id:      response.trigger,
    changes: {
      skippedInquiries: R.reject(id => id === response.booking, state.entities[response.trigger].skippedInquiries)
    }
  }, state)),
  on(LoadTriggerSkippedBookingsComplete, (state, {responses}) => adapter.updateMany(<any>R.map((p: TriggerSkipsResponse) => ({
    id:      p.trigger,
    changes: {
      skippedInquiries: R.concat(<string[]>p.skips, state.entities[p.trigger].skippedInquiries)
    }
  }), responses), state)),
  on(UpdateTriggersComplete, (state, {triggers}) => adapter.updateMany(R.map((trigger: Trigger) => ({
    id:      trigger.id,
    changes: {...trigger}
  }), <any>triggers), state)),
  on(AddWorkflowsToTriggersComplete, (state, {triggers}) => adapter.updateMany(<any>R.map((p: Trigger) => ({
    id:      p.id,
    changes: {
      attributes: p.attributes
    }
  }), triggers), state)),
  on(PauseTriggersComplete, (state, {triggers}) => adapter.updateMany(<any>R.map(trigger => ({
    id:      trigger.pkey,
    changes: {
      status: 0
    }
  }), triggers), state)),
  on(ResumeTriggersComplete, (state, {triggers}) => adapter.updateMany(<any>R.map(trigger => ({
    id:      trigger.pkey,
    changes: {
      status: 1
    }
  }), triggers), state)),
  on(LoadTriggerDetails, (state, {trigger}) => adapter.updateOne({
    id:      trigger.id,
    changes: {pendingDetails: true}
  }, state)),
  on(LoadTriggerDetailsComplete, (state, {trigger}) => adapter.updateOne({
    id:      trigger.id,
    changes: {
      ...trigger,
      hasDetails:     true,
      pendingDetails: false
    }
  }, state)),
  on(SaveTriggerComplete, (state, {trigger}) => adapter.updateOne({
    id:      trigger.id,
    changes: {
      ...trigger,
      hasDetails:     true,
      pendingDetails: false
    }
  }, state)),
  on(RemoveFromWorkflowComplete, (state, {trigger}) => adapter.updateOne({
    id:      trigger.id,
    changes: {
      ...trigger
    }
  }, state)),
  on(SaveConditionComplete, (state, {response}) => {
    const conditionsWithoutCurrent = R.filter((k) => k.key !== response.condition.key, state.entities[response.triggerId].conditions);

    return adapter.updateOne({
      id:      response.triggerId,
      changes: {
        conditions: <any>R.append(response.condition, <TriggerCondition[]>conditionsWithoutCurrent)
      }
    }, state);
  }),
  on(CreateConditionComplete, (state, {response}) => adapter.updateOne({
    id:      response.triggerId,
    changes: {
      conditions: <any>R.append(response.condition, <TriggerCondition[]>state.entities[response.triggerId].conditions)
    }
  }, state)),
  on(DeleteConditionComplete, (state, {response}) => adapter.updateOne({
    id:      response.triggerId,
    changes: {
      conditions: <any>R.filter((k) => k.key !== response.conditionId, state.entities[response.triggerId].conditions)
    }
  }, state)),
  on(PauseTriggerComplete, (state, {trigger}) => adapter.updateOne({
    id:      trigger.id,
    changes: {status: 0}
  }, state)),
  on(ResumeTriggerComplete, (state, {trigger}) => adapter.updateOne({
    id:      trigger.id,
    changes: {status: 1}
  }, state)),
  on(ArchiveTriggerComplete, (state, {trigger}) => adapter.updateOne({
    id:      trigger.id,
    changes: {archived: 1}
  }, state)),
  on(UnArchiveTriggerComplete, (state, {trigger}) => adapter.updateOne({
    id:      trigger.id,
    changes: {archived: 0}
  }, state)),
  on(DeleteTriggerComplete, (state, {id}) => adapter.removeOne(id, state)),
  on(DeleteBatchTriggerComplete, (state, {ids}) => {
    return adapter.removeMany(ids, {...state, legacy: R.reject(t => ids.includes(t.id), state.legacy)})
  })
);


export const {selectEntities, selectAll} = adapter.getSelectors();
