import {Component} from '@angular/core';
import {DialogService} from '../../services/dialog.service';
import {IntegrationsService} from '../../services/integrations.service';
import {Integration} from '../../enums/integration';

@Component({
  selector:    'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls:   ['./integrations.component.scss']
})
export class IntegrationsComponent {

  integrations = Integration;

  constructor(private dialogService: DialogService,
              private integrationsService: IntegrationsService) {

  }

  openIFTTT() {
    this.dialogService.openIFTTTIntegration();
  }

  openSlackInfo() {
    this.dialogService.openSlackInfo();
  }

  openInfo(integration: Integration) {
    const data = this.integrationsService.getIntegrationMessage(integration);
    this.dialogService.openIntegrationInfo(data);
  }

}
