export class TriggerStatuses {
  static statuses = [
    {
      name: 'Active',
      key:  'active'
    },
    {
      name: 'All Unarchived',
      key:  'unarchived'
    },
    {
      name: 'Archived',
      key:  'archived'
    },
    {
      name: 'Paused',
      key:  'paused'
    }
  ];
}
