import {
  AddTemplateComplete,
  AddTemplateSampleComplete,
  ClearLastAddedSample,
  ClearLastAddedTemplate, ClearLastEditedTemplate,
  CreateTemplatesComplete,
  DeleteBatchTemplateComplete,
  DeleteTemplateComplete,
  EditTemplateComplete,
  LoadTemplatesComplete
} from '../actions/templates.actions';
import {Template} from '../models/template';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';

export interface State extends EntityState<Template> {
  // additional entities state properties
  lastAddedId: string | null
  lastEditedId: string | null
  lastAddedSampleId: string | null
  selectedTemplateId: string | null
  isLoaded: boolean
}

export const adapter: EntityAdapter<Template> = createEntityAdapter<Template>({
  sortComparer: (a: Template, b: Template) => a.name.toString().localeCompare(b.name)
});

export const initialState: State = adapter.getInitialState({
  lastAddedId:        null,
  lastEditedId:       null,
  lastAddedSampleId:  null,
  selectedTemplateId: null,
  isLoaded:           false
});

export const reducer = createReducer(
  initialState,
  on(LoadTemplatesComplete, (state, {templates}) => adapter.addMany(templates, {
    ...state,
    isLoaded: true
  })),
  on(DeleteTemplateComplete, (state, {id}) => adapter.removeOne(id, state)),
  on(AddTemplateComplete, (state, {template}) => adapter.addOne(template, {
    ...state,
    lastAddedId: template.id
  })),
  on(CreateTemplatesComplete, (state, {templates}) => adapter.addMany(templates, {...state, isLoaded: true})),
  on(ClearLastAddedSample, (state) => ({...state, lastAddedSampleId: null})),
  on(ClearLastAddedTemplate, (state) => ({...state, lastAddedId: null})),
  on(ClearLastEditedTemplate, (state) => ({...state, lastEditedId: null})),
  on(AddTemplateSampleComplete, (state, {template}) => adapter.addOne(template, {
    ...state,
    lastAddedSampleId: template.id
  })),
  on(EditTemplateComplete, (state, {template}) => adapter.updateOne({
    id:      template.id,
    changes: template
  }, {
    ...state,
    lastEditedId: template.id
  })),
  on(DeleteBatchTemplateComplete, (state, {ids}) => adapter.removeMany(ids, state))
);

export const {selectEntities, selectAll} = adapter.getSelectors();
