import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {AutomataLogModel} from '../../../models/automata-log-model';
import {TableType} from '../../../enums/table-type';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {LoadBookingMessages, LoadBookingLogs} from '../../../containers/bookings-page/store/booking.actions';
import {DialogService} from '../../../services/dialog.service';
import {Message} from '../../../models/message';
import {TableUtilityService} from '../../../services/table-utility.service';
import * as R from 'ramda';
import {localeCompareSort} from '@automata/utility/functions/locale-compare-sort';
import {TranslateService} from '@ngx-translate/core';
import {BookingService} from '@automata/containers/bookings-page/store/booking.service';
import {getBookingLogs} from '@automata/containers/bookings-page/store/booking.selectors';
import {TriggerHandler} from '@automata/enums/trigger-handler';

@Component({
  selector:    'app-booking-journey',
  templateUrl: './booking-journey.component.html',
  styleUrls:   ['./booking-journey.component.scss']
})
export class BookingJourneyComponent implements OnInit {

  @Input() inquiryId: string;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns = [
    'dateFormatted',
    'eventName',
    'triggerName',
    'handlerName',
    'message.text'
  ];

  dataSource = new MatTableDataSource<AutomataLogModel>();

  tableType = TableType.BookingJourney;

  isEmptyTable$ = this.tableService.isEmptyTable(this.dataSource);

  emailHandler = TriggerHandler.SendEmailMessage

  constructor(private store: Store<fromRoot.State>,
              private translate: TranslateService,
              private inquiriesService: BookingService,
              private tableService: TableUtilityService,
              private dialogService: DialogService) {
  }

  ngOnInit() {
    this.store.dispatch(LoadBookingMessages({id: this.inquiryId}));
    this.store.dispatch(LoadBookingLogs({id: this.inquiryId}));

    this.dataSource.data = [];
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortData = localeCompareSort;

    this.store.pipe(getBookingLogs(this.inquiryId)).subscribe(inquiry => {
      this.dataSource.data = inquiry.automataLogModels || [];
    });
  }

  openMessage(message: Message) {
    if (R.isNil(message)) {
      return;
    }
    this.dialogService.openBookingMessage(message);
  }

}
