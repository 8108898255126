<mat-form-field class="mat-block" [floatLabel]="floatLabel">
  <mat-select [formControl]="ctrl" [placeholder]="placeholder" [multiple]="multiple" [required]="isRequired">
    <mat-option>
      <ngx-mat-select-search noEntriesFoundLabel="No match" [formControl]="itemFilterCtrl"
                             [placeholderLabel]="'pageElements.searchLabel' | translate"></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngIf="!multiple && hasBlank" [value]="null">
      None
    </mat-option>
    <mat-option *ngFor="let item of filteredItems | async" [value]="item | fieldOrPath:bindValue || 'id'">
      {{item | fieldOrPath:bindLabel || 'name'}}
    </mat-option>
  </mat-select>
</mat-form-field>
