import * as R from 'ramda'
import { MatSort } from '@angular/material/sort'
import { get, trim } from 'lodash'

export type DataAccessor<T> = (item: T, property) => any

export const createLocaleCompareSort = <T>(dataAccessor?: DataAccessor<T>) => (data, sort: MatSort) => {
  // custom ordering function; normally, it uses and returns a 'sortedData' array variable where the ordered data will be stored;

  const active = sort.active
  const direction = sort.direction
  if (!active || direction === '') {
    return data
  }

  return data.sort((a, b) => {
    let valueA = sortingDataAccessor(a, active, dataAccessor)
    let valueB = sortingDataAccessor(b, active, dataAccessor)

    // If both valueA and valueB exist (truthy), then compare the two. Otherwise, check if
    // one value exists while the other doesn't. In this case, existing value should come first.
    // This avoids inconsistent results when comparing values to undefined/null.
    // If neither value exists, return 0 (equal).
    let comparatorResult = 0
    if (valueA && valueB) {
      // treat as string
      if (typeof valueA !== typeof valueB) {
        valueA = valueA.toString()
        valueB = valueB.toString()
      }
      // Check if one value is greater than the other; if equal, comparatorResult should remain 0.
      if (R.is(String, valueA) && R.is(String, valueB)) {
        valueA = trim((<string>valueA))
        valueB = trim((<string>valueB))
        comparatorResult = (<string>valueA).localeCompare(<string>valueB, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      } else {
        if (valueA > valueB) {
          comparatorResult = 1
        } else if (valueA < valueB) {
          comparatorResult = -1
        }
      }
    } else if (valueA) {
      comparatorResult = 1
    } else if (valueB) {
      comparatorResult = -1
    }

    return comparatorResult * (direction === 'asc' ? 1 : -1)
  })
}

export const localeCompareSort = createLocaleCompareSort()

export const sortingDataAccessor = <T>(data, property: string, dataAccessor?: DataAccessor<T>): string | number => {
  let value: any = dataAccessor ? dataAccessor(data, property) : data[property]

  if (property && property.indexOf('.') !== -1) {
    value = get(data, property)
  }

  if (R.is(String, value)) {
    value = value.toLocaleLowerCase()
  }

  return value
}
