import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivityEventFilter} from '../../../interfaces/activity-event-filter';
import {TriggerNotificationTableModel} from '../../../models/trigger-notification-table-model';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {ActivitiesService} from '../../../services/activities.service';

@Component({
  selector:    'app-activity-table-controls',
  templateUrl: './activity-table-controls.component.html',
  styleUrls:   ['./activity-table-controls.component.scss']
})
export class ActivityTableControlsComponent implements OnInit, OnDestroy {

  @Input() heading: string;

  @Input() notifications: Observable<TriggerNotificationTableModel[]>;

  @Input() filter: BehaviorSubject<string>;

  @Output() search = new EventEmitter<string>();

  filters: ActivityEventFilter[] = [];
  filterStatusSub: Subscription;

  constructor(private activitiesService: ActivitiesService) {
  }

  ngOnInit() {
    this.filterStatusSub = this.activitiesService
      .sortedFilters(this.notifications)
      .subscribe(filters => {
        this.filters = filters;
      });
  }

  ngOnDestroy() {
    this.filterStatusSub.unsubscribe();
  }
}
