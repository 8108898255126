import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {TutorialType} from '@automata/components/tutorial/tutorial-opener.component';

@Component({
  selector:    'app-tutorial',
  templateUrl: './tutorial-dialog.component.html',
  styleUrls:   ['./tutorial-dialog.component.scss']
})
export class TutorialDialogComponent implements OnInit {

  type: TutorialType;

  constructor(public dialogRef: MatDialogRef<TutorialDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {type: TutorialType}) {
  }

  ngOnInit() {
    this.type = this.data.type;
  }

  close() {
    this.dialogRef.close();
  }
}
