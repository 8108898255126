import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DialogService} from '../../../services/dialog.service';
import * as fromRoot from '../../../reducers';
import {select, Store} from '@ngrx/store';
import {TriggerDialogComponent} from '../../trigger/trigger-dialog/trigger-dialog.component';
import * as R from 'ramda';
import {
  ArchiveTrigger,
  DeleteTrigger,
  LoadTriggerSkippedBookings,
  TriggerSkipInquiry,
  TriggerUnSkipInquiry,
  UnArchiveTrigger
} from '../../../actions/triggers.actions';
import {EditBooking, LoadIsSkipBooking, SkipBooking, UnSkipBooking} from '../../../containers/bookings-page/store/booking.actions';
import {BehaviorSubject} from 'rxjs';
import {TriggerEvents} from '../../../models/trigger-events';
import {Trigger} from '../../../models/trigger';
import {WorkflowDetailType} from '../../../enums/workflow-detail-type';
import {isSomething} from '../../../utility/functions/is-something';
import {TagsType} from '../../../enums/tags-type';
import {getTags} from '../../../utility/functions/get-tags';
import {BookingDialogView} from '../../../enums/booking-dialog-view';
import {delay, filter} from 'rxjs/operators';
import {selectBookingById} from '@automata/containers/bookings-page/store/booking.selectors';
import {BookingView} from '@automata/containers/bookings-page/store/booking.model';
import {AmplitudeService} from '@automata/services/amplitude.service';

@Component({
  selector:    'app-booking-dialog',
  templateUrl: './booking-dialog.component.html',
  styleUrls:   ['./booking-dialog.component.scss']
})
export class BookingDialogComponent implements OnInit {

  triggers: Trigger[];

  tableData$ = new BehaviorSubject<Trigger[]>([]);

  inquiry: BookingView;

  tags: string[] = [];

  type = WorkflowDetailType.Booking;

  view = BookingDialogView.Flow;
  views = BookingDialogView;

  constructor(public dialogRef: MatDialogRef<BookingDialogComponent>,
              private dialogService: DialogService,
              private amplitudeService: AmplitudeService,
              private store: Store<fromRoot.State>,
              @Inject(MAT_DIALOG_DATA) public data: {inquiry: BookingView}) {
  }

  ngOnInit() {

    this.inquiry = this.data.inquiry;

    this.store.pipe(
      select(selectBookingById(this.data.inquiry.pkey)),
      filter(inquiry => isSomething(inquiry)),
      delay(10)
    )
      .subscribe(inquiry => {
        this.inquiry = inquiry;
        this.loadData(inquiry);
      });

    this.loadData(this.data.inquiry);

    const skippableTriggers = R.filter((t: Trigger) => TriggerEvents.isSkippable(t.event), this.data.inquiry.associatedTriggers);
    const skippableTriggerIds = R.map((t: Trigger) => t.id, skippableTriggers);
    this.store.dispatch(LoadTriggerSkippedBookings({ids: <string[]>skippableTriggerIds}));

    this.store.dispatch(LoadIsSkipBooking({id: this.data.inquiry.pkey}));
  }

  loadData(booking: BookingView) {
    this.setTags(booking);

    const sortedTriggers = R.sort((a: Trigger, b: Trigger) => TriggerEvents.getWeight(a) - TriggerEvents.getWeight(b), booking.associatedTriggers);

    this.triggers = sortedTriggers;

    setTimeout(() => {
      this.tableData$.next(sortedTriggers);
    }, 10);
  }

  openView(view: BookingDialogView) {
    switch (view) {
      case BookingDialogView.Flow:
        this.amplitudeService.logEvent('booking-dialog-flow-view-open')
        break;
      case BookingDialogView.List:
        this.amplitudeService.logEvent('booking-dialog-list-view-open')
        break;
      case BookingDialogView.Journey:
        this.amplitudeService.logEvent('booking-dialog-journey-view-open')
        break;
    }
    this.view = view;
  }

  setTags(inquiry: BookingView) {
    this.tags = getTags(inquiry);
  }

  toggleWorkflow() {
    if (this.inquiry.skip) {
      R.forEach(trigger => {
        this.onUnSkip(trigger, true);
      }, this.triggers);
      this.store.dispatch(UnSkipBooking({id: this.inquiry.pkey}));
      this.amplitudeService.logEvent('booking-triggers-activate')
    } else {
      R.forEach(trigger => {
        this.onSkip(trigger, true);
      }, this.triggers);
      this.store.dispatch(SkipBooking({id: this.inquiry.pkey}));
      this.amplitudeService.logEvent('booking-triggers-deactivate')
    }
  }

  close() {
    this.dialogRef.close();
  }

  addTags() {
    this.dialogService.openTags(this.tags, TagsType.Inquiry)
      .subscribe((result) => {
        this.tags = result.tags;
        this.store.dispatch(EditBooking({
          request: {
            id:         this.inquiry.pkey,
            attributes: {...this.inquiry.attributes, tags: this.tags}
          }
        }));
      });
  }

  onOpen(event) {
    console.log(`Editing trigger ${event.trigger.id}`);
    this.dialogService.openSide(TriggerDialogComponent, {
      data: {
        triggerId: event.trigger.id,
        activeTab: event.tab
      }
    });
  }

  confirmRemove(trigger: Trigger) {
    this.dialogService.openConfirm().subscribe(() => {
      this.onConfirmRemove(trigger);
    });
  }

  onConfirmRemove(trigger: Trigger) {
    this.store.dispatch(DeleteTrigger({id: trigger.id}));
  }

  onDuplicate(trigger: Trigger) {
    console.log(`Editing trigger ${trigger.id}`);
    this.dialogService.openSide(TriggerDialogComponent, {
      data: {
        triggerId:   trigger.id,
        isDuplicate: true
      }
    });
  }

  onEdit(trigger: Trigger) {
    console.log(`Editing trigger ${trigger.id}`);
    this.dialogService.openSide(TriggerDialogComponent, {data: {triggerId: trigger.id}});
  }

  onSkip(trigger: Trigger, silent = false) {
    if (!silent) {
      this.amplitudeService.logEvent('booking-trigger-deactivate')
    }
    this.store.dispatch(TriggerSkipInquiry({request: {triggerId: trigger.id, bookingId: this.inquiry.pkey, silent}}));
  }

  onUnSkip(trigger: Trigger, silent = false) {
    if (!silent) {
      this.amplitudeService.logEvent('booking-trigger-activate')
    }
    this.store.dispatch(TriggerUnSkipInquiry({request: {triggerId: trigger.id, bookingId: this.inquiry.pkey, silent}}));
  }

  onArchive(trigger: Trigger) {
    this.store.dispatch(ArchiveTrigger({id: trigger.id}));
  }

  onUnArchive(trigger: Trigger) {
    this.store.dispatch(UnArchiveTrigger({id: trigger.id}));
  }
}
