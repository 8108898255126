import {Component, Input, OnInit} from '@angular/core';
import {DialogService} from '@automata/services/dialog.service';

export type TutorialType = 'activity' | 'workflows' | 'triggers' | 'templates' | 'bookings' | 'codes';

@Component({
  selector: 'app-tutorial-opener',
  template: `
              <span matTooltip="Click here for a video walkthrough of this page."
                    matTooltipPosition="right"
                    matTooltipClass="text-center" (click)="onOpen()">
                  <i class="far info-icon fa-video"></i>
              </span>
              `,
  styles:   [`
        :host {
            margin-left: 6px;
            cursor: pointer;
        }
    `]
})
export class TutorialOpenerComponent implements OnInit {

  @Input() type: TutorialType;

  constructor(private dialogService: DialogService) {
  }

  ngOnInit() {
  }

  onOpen() {
    this.dialogService.openTutorial(this.type);
  }

}
