<div class="clearfix activity-content">

  <div class="panel panel-default">
    <div class="clearfix header-panel">
      <div class="col-sm-6 title">
        {{'pageElements.activityLogs' | translate}}
      </div>
      <div class="col-sm-6 logs-search btn-holder">
        <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)"
                 [placeholder]="'formControls.searchHere' | translate" type="search">
          <span matPrefix>
            <i class="far fa-search"></i>
          </span>
        </mat-form-field>
        <button (click)="refreshLogs()"
                [matTooltip]="'tooltips.updateTriggerLogs' | translate"
                matTooltipPosition="above"
                matTooltipClass="text-center"
                type="button"
                class="btn btn-cstm-info left-icon text-uppercase pull-right">
          <span class="far fa-sync" aria-hidden="true"></span>
          {{'buttons.refreshLogs' | translate}}
        </button>
      </div>
    </div>
    <div class="panel-body">
      <div class="row">

        <mat-table #table matSort [dataSource]="dataSource"
                   class="table-without-select">

          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef
                             mat-sort-header> {{'tableColumns.date' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                            <span *ngIf="!element.guest"
                                  [matTooltip]="element.dateFormattedLong"
                                  matTooltipPosition="above"
                                  matTooltipClass="text-center">{{element.dateFormatted}}</span>
              <ng-template #tolTemplate>
                <div class="tal">
                  <strong>{{'tooltips.sent' | translate}}: </strong> {{element.timeFormatted}}
                  <br>
                  <strong>{{'tooltips.guest' | translate}}: </strong> {{element.guestName}}
                  <br *ngIf="trigger.rentalId">
                  <strong *ngIf="trigger.rentalId">{{'tooltips.rental' | translate}}
                    : </strong> {{trigger.rentalName}}
                </div>
              </ng-template>

              <span [ngClass]="{hidden: !element.guest}" [tooltip]="tolTemplate">
                                {{element.dateFormatted}}
                            </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="guestName">
            <mat-header-cell *matHeaderCellDef
                             mat-sort-header> {{'tableColumns.guest' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span *ngIf="!!element.inquiry" [tooltip]="tolTemplate">
                {{element.guestName || '.....'}}
              </span>
                    <span *ngIf="!element.inquiry">
                {{element.guestName || '.....'}}
              </span>
              <ng-template #tolTemplate>
                <div class="tal arrive-depart-tooltip">
                  <strong>{{'tooltips.arrive' | translate}}</strong> {{element.inquiry?.guest_arrive | epoch:'DD - MMM - YYYY'}}
                  <br>
                  <strong>{{'tooltips.depart' | translate}}</strong> {{element.inquiry?.guest_depart | epoch:'DD - MMM - YYYY'}}
                </div>
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="eventName">
            <mat-header-cell *matHeaderCellDef
                             mat-sort-header> {{'tableColumns.event' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element"><span [ngStyle]="{background: element.eventColor}"
                                                      class="event-color"></span> {{element.eventName}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="templateName">
            <mat-header-cell *matHeaderCellDef
                             mat-sort-header> {{'tableColumns.template' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.templateName}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="channelName">
            <mat-header-cell *matHeaderCellDef
                             mat-sort-header> {{'tableColumns.channel' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.channelName}}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onEventLog(row)"
                   [ngClass]="{'clickable': !!row.eventMessage}"></mat-row>
        </mat-table>

        <app-translated-pagination>
          <mat-paginator #paginator [ngClass]="{hidden: (isEmptyTable$ | asyncSchedule | async)}"
                         [length]="dataSource?.data?.length"
                         [pageSize]="10"
                         [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
                         [showFirstLastButtons]="true">
          </mat-paginator>
        </app-translated-pagination>

        <app-empty-table *ngIf="(isEmptyTable$ | async)" [type]="tableType"></app-empty-table>
      </div>
    </div>
  </div>
</div>
