import {ClearActivities, LoadActivitiesComplete} from '../actions/activities.actions';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {AutomataNotification} from '../models/automata-notification';
import {RequestLimit} from '../enums/request-limit';
import {createReducer, on} from '@ngrx/store';

export interface State extends EntityState<AutomataNotification> {
  hasMore: boolean
  limit: number
  offset: number
  selectedActivityId: string | null
  isLoaded: boolean
}

export const adapter: EntityAdapter<AutomataNotification> = createEntityAdapter<AutomataNotification>({
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  hasMore:            true,
  limit:              0,
  offset:             0,
  selectedActivityId: null,
  isLoaded:           false
});

export const reducer = createReducer(
  initialState,
  on(LoadActivitiesComplete, (state, {response}) => {
    const limit = response.limit;
    const offset = response.offset;

    return adapter.addMany(response.result, {
      ...state,
      isLoaded: true,
      limit:    limit,
      offset:   offset,
      hasMore:  state.ids.length + response.result.length === limit + offset
    });
  }),
  on(ClearActivities, (state) => adapter.removeAll({
    ...state,
    isLoaded: false,
    limit:    RequestLimit.Activities,
    offset:   0,
    hasMore:  true
  }))
);

export const {selectAll, selectIds} = adapter.getSelectors();
