import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {PayloadHeadersDialogComponent} from '../payload-headers-dialog/payload-headers-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, Validators} from '@angular/forms';
import {TemplateLanguageDialogParams} from '../../../interfaces/template/template-language-dialog-params';
import {Template} from '../../../models/template';
import {TemplateTranslation} from '../../../models/template-translation';
import * as R from 'ramda';
import * as langs from '../../../models/languages';
import {TemplatesService} from '../../../services/templates.service';
import {Subscription} from 'rxjs';
import {filter, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {TemplateType} from '../../../enums/template-type';
import {AmplitudeService} from '@automata/services/amplitude.service';

@Component({
  selector:    'app-template-languages-dialog',
  templateUrl: './template-translate-dialog.component.html',
  styleUrls:   ['./template-translate-dialog.component.scss']
})
export class TemplateTranslateDialogComponent implements OnInit, OnDestroy {

  template: Template;
  languages = R.map(l => ({id: l['1'], name: l['name']}), langs.languages);

  form = this.fb.group({
    id:       ['', [Validators.required]],
    language: ['', [Validators.required]],
    subject:  ['', [Validators.maxLength(75)]],
    content:  this.fb.group({
      body: ['', [Validators.required]]
    }),
    type:     ['', [Validators.required]]
  });

  translationSub: Subscription;

  types = TemplateType;

  constructor(public dialogRef: MatDialogRef<PayloadHeadersDialogComponent>,
              private fb: FormBuilder,
              private amplitudeService: AmplitudeService,
              private templatesService: TemplatesService,
              @Inject(MAT_DIALOG_DATA) public data: TemplateLanguageDialogParams) {
  }

  ngOnInit() {

    this.template = this.data.template;

    this.form.setValue({
      id:       this.data.languageModel.id,
      language: this.data.languageModel.language,
      subject:  this.data.languageModel.subject || '',
      content:  {
        body: this.data.languageModel.text || ''
      },
      type:     this.data.templateType
    });

    // observe changes of language select box
    this.translationSub = this.form
      .get('language')
      .valueChanges
      .pipe(
        withLatestFrom(this.form.get('content').get('body').valueChanges), // once language is selected, take latest value from template editor
        filter(([code, content]) => !R.isEmpty(content)), // if editor's content is not empty, proceed
        switchMap(([code, content]) => {
          return this.templatesService.translate(content, code) // call translate service
            .pipe(
              tap((translatedText) => this.template.body = translatedText), // tap into a result without changing it and just assign to template
              filter(() => !R.isEmpty(this.form.get('subject').value)), // check if subject input is empty, proceed if not
              switchMap(() => {
                  return this.templatesService.translate(this.form.get('subject').value, code) // call translate with subject value
                    .pipe(
                      tap((text) => this.form.patchValue({subject: text})) // populate subject with new value
                    );
                }
              )
            );
        })
      )
      .subscribe(() => {
      });
  }

  ngOnDestroy() {
    this.translationSub.unsubscribe();
  }

  submit() {
    const formValue = this.form.getRawValue();
    const result = new TemplateTranslation({
      id:       formValue.id,
      language: formValue.language,
      text:     formValue.content.body,
      subject:  formValue.subject
    });
    this.amplitudeService.logEvent('add-template-translation')
    this.dialogRef.close(result);
  }

  close() {
    this.dialogRef.close();
  }

}
