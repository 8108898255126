<app-activity-table-controls [heading]="'pageTitle.failTriggers' | translate" (search)="applyFilter($event)"
                             [filter]="filter$"
                             [notifications]="notifications$"></app-activity-table-controls>

<!-- Content Holder -->
<div class="content-holder clearfix">

  <mat-table #table matSort [dataSource]="dataSource" class="table-without-select"
             [ngClass]="{minHeight: !(isEmptyTable$ | async)}">
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.date' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
                <span [matTooltip]="element.dateFormattedLong"
                      matTooltipPosition="above"
                      matTooltipClass="text-center">{{element.dateFormatted}}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="guestName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.guest' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element" (click)="openBookingDetails(element);$event.stopPropagation()">
        {{element.guestName || '.....'}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="eventName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.event' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"><span [ngStyle]="{background: element.eventColor}"
                                                class="event-color"></span> {{element.eventName}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="templateName">
      <mat-header-cell *matHeaderCellDef
                       mat-sort-header> {{'tableColumns.template' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.templateName || '.....'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="channelName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.channel' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.channelName || '.....'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="options">
      <mat-header-cell *matHeaderCellDef> {{'tableColumns.options' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div dropdown container="body" placement="bottom">
          <div dropdownToggle (click)="$event.stopPropagation()" class="text-center elipse-btn">
            <i class="far fa-ellipsis-v-alt"></i>
          </div>
          <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
            <li *ngIf="element.eventMessage" role="menuitem">
              <button (click)="onEventLog(element)" class="dropdown-item">
                {{'buttons.eventLog' | translate}}
              </button>
            </li>
            <li role="menuitem">
              <button (click)="onTriggerDetails(element)" class="dropdown-item">
                {{'buttons.triggerDetails' | translate}}
              </button>
            </li>
            <li *ngIf="element.event | isInquiry" role="menuitem">
              <button (click)="openBookingDetails(element)" class="dropdown-item">
                {{'buttons.bookingDetails' | translate}}
              </button>
            </li>
          </ul>

        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openTrigger(row)"
             [ngClass]="{hasNoTrigger: !row.hasTrigger}"></mat-row>
  </mat-table>

  <app-translated-pagination>
    <mat-paginator #paginator
                   [ngClass]="{hidden: (isEmptyTable$ | async)}"
                   [length]="dataSource?.data?.length"
                   [pageSize]="5"
                   [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
                   [showFirstLastButtons]="true">
    </mat-paginator>
  </app-translated-pagination>

  <app-empty-table *ngIf="(isEmptyTable$ | async)" [type]="tableType"></app-empty-table>
</div>
