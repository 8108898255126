import * as moment from 'moment';
import {TriggerEvents} from '@automata/models/trigger-events';
import {TriggerHandlers} from '@automata/models/trigger-handlers';

export function EpochFormatter(format = 'DD - MMM - YYYY | h:mm A') {
  return (epoch) => moment.unix(epoch).format(format);
}

export function EventNameTransformer() {
  return (event) => TriggerEvents.getEventName(event);
}

export function HandlerNameTransformer() {
  return (handler) => TriggerHandlers.getHandlerName(handler);
}
