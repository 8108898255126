<div class="main-body bookings-page clearfix">
  <div class="container-fluid">
    <!-- Title & buttons -->
    <div class="row title-holder">
      <div class="col-xs-3 title">
        {{'pageTitle.bookings' | translate}}
        <span [matTooltip]="'pageTooltip.bookings' | translate"
              matTooltipPosition="above"
              matTooltipClass="text-center">
                    <i class="far info-icon fa-question-circle"></i>
                </span>
        <app-tutorial-opener [type]="'bookings'"></app-tutorial-opener>
      </div>
      <div class="col-xs-6">
        <app-inactive-subscription></app-inactive-subscription>
      </div>
    </div>

    <!-- Content Holder -->
    <div class="content-holder clearfix">
      <!-- input fields -->
      <div class="clearfix input-holder-top">
        <div class="col-sm-9">
          <div class="row">
            <div class="col-sm-2">
              <app-select-search [matTooltip]="'tooltips.bookingsRentalFilter' | translate"
                                 matTooltipPosition="above"
                                 matTooltipClass="text-center"
                                 [items]="rentals$ | async"
                                 [ctrl]="form.get('rentals')"
                                 [placeholder]="'formControls.rentals' | translate"></app-select-search>
            </div>
            <div class="col-sm-2">
              <app-select-search [matTooltip]="'tooltips.bookingsSourceFilter' | translate"
                                 matTooltipPosition="above"
                                 matTooltipClass="text-center"
                                 bindValue="token"
                                 bindLabel="friendlyName"
                                 [items]="channels$ | async"
                                 [ctrl]="form.get('channels')"
                                 [placeholder]="'formControls.channels' | translate"></app-select-search>
            </div>
            <div class="col-sm-2">
              <mat-form-field>
                <mat-select [matTooltip]="'tooltips.bookingsStatusFilter' | translate"
                            matTooltipPosition="above"
                            matTooltipClass="text-center"
                            [formControl]="form.get('status')"
                            [placeholder]="'formControls.status' | translate">
                  <mat-option *ngFor="let status of statuses" [value]="status.key">
                    {{ status.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-sm-2">
              <mat-form-field>
                <mat-select [matTooltip]="'tooltips.bookingsArriveFilter' | translate"
                            matTooltipPosition="above"
                            matTooltipClass="text-center"
                            [formControl]="form.get('arrival')"
                            [placeholder]="'formControls.arrivals' | translate">
                  <mat-option *ngFor="let arrival of arrivals" [value]="arrival.id">
                    {{ arrival.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-sm-2">
              <app-select-search *ngIf="(tags$ | async)?.length"
                                 [matTooltip]="'tooltips.bookingsTagFilter' | translate"
                                 matTooltipPosition="above"
                                 matTooltipClass="text-center"
                                 [items]="tags$ | async"
                                 [ctrl]="form.get('tags')"
                                 [placeholder]="'formControls.tags' | translate"></app-select-search>
            </div>
          </div>
        </div>

        <div class="col-sm-3 edit-align">
          <mat-form-field>
            <input matInput (keyup)="search.emit($event.target.value)"
                   [placeholder]="'formControls.searchHere' | translate"
                   type="search">
            <span matPrefix>
              <i class="far fa-search"></i>
            </span>
          </mat-form-field>
        </div>
      </div>

      <mat-table #table matSort [dataSource]="dataSource" class="withoutSelect">

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
                        <span *ngIf="element.booked > 0"
                              [matTooltip]="'tooltips.bookingConfirm' | translate" matTooltipPosition="right"
                              matTooltipClass="text-center">
                            <i class="far fa-check-square"></i>
                        </span>
            <span *ngIf="element.canceled > 0"
                  [matTooltip]="'tooltips.canceled' | translate" matTooltipPosition="right"
                  matTooltipClass="text-center">
                            <i class="far fa-minus-square"></i>
                        </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="inquiry_source">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.channel' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element">
                        <span [matTooltip]="'Imported: ' + element.createdFormatted"
                              matTooltipPosition="right"
                              matTooltipClass="text-center">
                                {{element.inquiry_source?.toLowerCase()}}
                            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tagsFormatted">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.tags' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.tagsFormatted | truncate:35}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastTriggerName">
          <mat-header-cell *matHeaderCellDef
                           mat-sort-header> {{'tableColumns.lastTrigger' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.lastTriggerName}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="guestName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.guestName' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element" [matTooltip]="element.guestName" matTooltipPosition="above"
                    matTooltipClass="text-center">
            {{element.guestName | truncate:23}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="guestArrive">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.arrive' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element">
                        <span class="text-uppercase">
                            <ng-template #tolTemplate>
                                <div class="tal">
                                    <strong>{{'tooltips.checkIn' | translate}}: </strong>{{element.checkInFormatted}}
                                  <br>
                                    <strong>{{'tooltips.checkOut' | translate}}: </strong>{{element.checkOutFormatted}}
                                </div>
                            </ng-template>
                            <span [tooltip]="tolTemplate">
                                {{element.arriveFormatted}}
                            </span>
                        </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="guestDepart">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.depart' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element" class="text-center">
                        <span class="text-uppercase">
                            <ng-template #tolTemplate>
                                <div class="tal">
                                    <strong>{{'tooltips.checkIn' | translate}}: </strong>{{element.checkInFormatted}}
                                  <br>
                                    <strong>{{'tooltips.checkOut' | translate}}: </strong>{{element.checkOutFormatted}}
                                </div>
                            </ng-template>
                            <span [tooltip]="tolTemplate">
                                {{element.departFormatted}}
                            </span>
                        </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="rentalName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.rental' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element" class="text-center" [matTooltip]="element.rental?.name"
                    matTooltipPosition="above" matTooltipClass="text-center">
            {{element.rental?.name | truncate:23}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="guestsCount">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.guests' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element" class="text-center"> {{element.guestsCount}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef>
            <app-column-edit-control (select)="tableManagement()"></app-column-edit-control>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div dropdown container="body">
              <div dropdownToggle (click)="$event.stopPropagation()" class="text-center elipse-btn">
                <i class="far fa-ellipsis-v-alt"></i>
              </div>
              <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                <li *ngIf="hasWorkflow(element)" role="menuitem">
                  <button class="dropdown-item" (click)="onEdit(element)">
                    {{'buttons.workflowDetail' | translate}}
                  </button>
                </li>
                <li role="menuitem">
                  <button class="dropdown-item"
                          (click)="onManageTags(element)">{{'buttons.manageTags' | translate}}
                  </button>
                </li>
              </ul>

            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'hasWorkflow': row | hasWorkflow}"
                 (click)="onEdit(row)"></mat-row>
      </mat-table>

      <app-translated-pagination>
        <mat-paginator #paginator [ngClass]="{hidden: (isEmptyTable$ | async) && isLoaded}"
                       [length]="dataSource?.data?.length"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20, 50, 75, 100]" [showFirstLastButtons]="true">
        </mat-paginator>
      </app-translated-pagination>

      <app-empty-table *ngIf="(isEmptyTable$ | async) && isLoaded" [type]="tableType"></app-empty-table>
    </div>

  </div>
</div>
