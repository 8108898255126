import {Pipe, PipeTransform} from '@angular/core';
import {isSomething} from '../utility/functions/is-something';

@Pipe({
  name: 'isSomething'
})
export class IsSomethingPipe implements PipeTransform {

  transform(value: any, args?: any): boolean {
    return isSomething(value);
  }

}
