import * as moment from 'moment';
import {CodeResponse} from '../interfaces/code/code-response';
import {CodeAttributes} from '../interfaces/code/code-attributes';
import * as R from 'ramda';
import {Taggable} from '../interfaces/taggable';

export class Code implements Taggable {

  id: string;

  rentals: string[];
  rentalNames: string;

  created: number;
  createdFormatted: string;
  createdFormattedShort: string;

  creator: string;
  creatorName: string;

  account: number;

  name: string;
  nameFormatted: string;

  body: string;

  attributes: CodeAttributes;

  tagsFormatted = '';

  archived: number;

  description: string;

  constructor(code?: Partial<Code>) {
    if (code) {
      this.id = code.id;
      this.rentals = code.rentals || [];
      this.rentalNames = code.rentalNames;

      this.created = code.created;
      this.createdFormatted = moment.unix(code.created).format('DD - MMM - YYYY | h:mm A');
      this.createdFormattedShort = moment.unix(code.created).format('DD - MMM - YYYY');

      this.account = code.account;

      this.creator = code.creator;
      this.creatorName = code.creatorName;

      this.name = R.pipe(
        R.defaultTo(''),
        R.when(R.is(Number), R.toString),
      )(code.name);
      this.nameFormatted = `*|CUSTOM:${this.name.toUpperCase()}|*`;

      this.description = code.description;

      this.body = code.body;

      this.attributes = code.attributes;

      this.tagsFormatted = code.tagsFormatted;

      this.archived = code.archived;
    }
  }

    fromResponse(response: CodeResponse) {
        this.id = response.pkey;
        this.rentals = response.rentals || [];
        this.created = response.created;
        this.createdFormatted = moment.unix(response.created).format('DD - MMM - YYYY | h:mm A');
        this.createdFormattedShort = moment.unix(response.created).format('DD - MMM - YYYY');
        this.creator = response.creator;
        this.name = R.pipe(
          R.defaultTo(''),
          R.when(R.is(Number), R.toString),
        )(response.name);
        this.nameFormatted = `*|CUSTOM:${this.name.toUpperCase()}|*`;
        this.body = response.body;
        this.attributes = response.attributes;
        this.archived = response.archived;
        this.account = response.account;
        this.description = response.description;
        if (!this.attributes) {
            this.attributes = {tags: []} as CodeAttributes;
        }
        if (this.attributes && !this.attributes.tags) {
            this.attributes = {...this.attributes, tags: []};
        }
        if (R.is(Array, this.attributes.tags)) {
            this.attributes = {...this.attributes, tags: R.map(R.pipe(R.when(R.is(Number), R.toString)))(this.attributes.tags)};
        }
        if (R.is(Array, this.attributes.tags)) {
            this.tagsFormatted = this.attributes.tags.join(', ');
        }
    }
}
