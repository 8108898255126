import {Inject, Injectable} from '@angular/core';
import {LoginForm} from '../models/login-form';
import {APP_CONFIG, IAppConfig} from '../app.config';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {LocalStorageKeys} from '../models/local-storage-keys';
import {Observable, of, throwError as _throw} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {Logout} from '../actions/users.actions';
import {User} from '../models/user';
import * as R from 'ramda';
import {catchError, map, tap} from 'rxjs/operators';
import {UserResponse} from '../interfaces/user/user-response';
import {UserRoles} from '../models/user-roles';
import {UserPlanType} from '../types/user-plan-type';
import {UserSubscription} from '../interfaces/user/user-subscription';
import {ToastService} from '@automata/services/toast.service';
import {ToastIconClasses} from '@automata/utility/models/toast-icon-classes';
import {ToastrService} from 'ngx-toastr';
import * as Sentry from '@sentry/browser';
import {environment} from '../../environments/environment';
import {SubscriptionStatus} from '@automata/services/amplitude.service';

declare const window: any;

@Injectable()
export class AuthService {

  user: User;

  constructor(private http: HttpClient,
              private toast: ToastService,
              private toastr: ToastrService,
              private store: Store<fromRoot.State>,
              @Inject(APP_CONFIG) private config: IAppConfig) {
  }

  status() {
    const url = '@api/subscription/status';

    return this.http.get<SubscriptionStatus>(url);
  }

  isAuthenticated(): boolean {
    return !R.isNil(localStorage.getItem(LocalStorageKeys.API_TOKEN));
  }

  isAuthenticatedForce(): Observable<boolean> {
    const user = this.getUser();
    if (R.isNil(user)) {
      return of(false);
    }
    return this.http.get(`@api/user?account=${user.account}`)
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  fetchUser() {
    return this.http.get<User>(`@api/user/`)
      .pipe(
        map(response => {
          const user = User.deserialize(response)
          this.user = user
          return user
        })
      )
  }

  getUser(): User {
    try {
      if (R.isNil(this.user)) {
        return JSON.parse(localStorage.getItem(LocalStorageKeys.USER_INFO));
      } else {
        return this.user;
      }
    } catch (e) {
      return null;
    }
  }

  getIntercom(user: User) {
    return {
      app_id:     this.config.intercomAppId,
      user_id:    user.id,
      account:    user.account,
      email:      user.primaryemail,
      name:       `${user.firstname} ${user.lastname}`,
      created_at: user.created
    };
  }

  getAppcues(user: User) {
    return {
      user_id:    user.id,
      account:    user.account,
      email:      user.primaryemail,
      name:       `${user.firstname} ${user.lastname}`,
      created_at: user.created
    };
  }

  getIntercomUpdate(user: User) {
    return {
      user_id:    user.id,
      account:    user.account,
      email:      user.primaryemail,
      name:       `${user.firstname} ${user.lastname}`,
      created_at: user.created
    };
  }

  isAdmin(): boolean {
    const user = this.getUser();
    if (user) {
      return Math.min(...user.roles) === UserRoles.Admin.value;
    } else {
      return false;
    }
  }

  hasPlan(playType: UserPlanType) {
    const user = this.getUser();
    if (user) {
      return R.filter((sub: UserSubscription) => sub.plan_name === playType, <any>user.subscriptions).length > 0;
    } else {
      return false;
    }
  }

  login(credentials: LoginForm) {
    return this.http.post(`@api/user/login`, {
      username:  credentials.email,
      password:  credentials.password,
      keepalive: credentials.keepAlive
    })
      .pipe(
        map((response: UserResponse) => {
          this.user = User.deserialize(response);
          Sentry.configureScope((scope) => {
            scope.setUser({
              email:    this.user.primaryemail,
              id:       this.user.account,
              username: `${this.user.firstname} ${this.user.lastname}`
            } as any);
          });
          if (environment.environment === 'production') {
            window.Intercom('trackEvent', 'automata-login');
          }
          localStorage.setItem(LocalStorageKeys.API_TOKEN, response.token);
          localStorage.setItem(LocalStorageKeys.USER_INFO, JSON.stringify(this.user));
          localStorage.setItem(LocalStorageKeys.LOGOUT, 'false');
          return response;
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          if (errorResponse && errorResponse.error) {
            this.toastr.show(errorResponse.error.error, 'Error', {toastClass: ToastIconClasses.Warning});
          }
          return _throw('Unauthorized');
        })
      );
  }

  loginByToken(token: string) {
    // auth.interceptor.ts will handle clean up if error happens
    if (environment.environment === 'production') {
      window.Intercom('trackEvent', 'automata-login');
    }
    localStorage.setItem(LocalStorageKeys.API_TOKEN, token);
    return this.http.get(`@api/user/`)
      .pipe(
        tap(response => {
          localStorage.setItem(LocalStorageKeys.LOGOUT, 'false');
          this.user = User.deserialize(response);
          localStorage.setItem(LocalStorageKeys.USER_INFO, JSON.stringify(this.user));
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          if (errorResponse && errorResponse.error) {
            this.toastr.show(errorResponse.error.error, 'Error', {toastClass: ToastIconClasses.Warning});
          }
          return _throw('Unauthorized');
        })
      );
  }

  logout() {
    Sentry.configureScope(scope => scope.clear());
    if (environment.environment === 'production') {
      window.Intercom('shutdown');
    }
    localStorage.removeItem(LocalStorageKeys.USER_INFO);
    localStorage.removeItem(LocalStorageKeys.API_TOKEN);
    localStorage.removeItem(LocalStorageKeys.INQUIRIES_FILTERS);
    localStorage.removeItem(LocalStorageKeys.TRIGGERS_FILTERS);
    localStorage.removeItem(LocalStorageKeys.WORKFLOWS_FILTERS);
    localStorage.removeItem(LocalStorageKeys.CODES_FILTERS);
    localStorage.setItem(LocalStorageKeys.LOGOUT, 'true');
    this.store.dispatch(Logout());
  }
}
