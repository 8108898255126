import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

export interface SendTestEmailPayload {
  message_txt: string
  message_html: string
  to: string
  from: string
  subject: string
}

export interface SendTestSMSPayload {
  body: string
  to: string
}

@Injectable()
export class SendSampleService {

  constructor(private http: HttpClient) {
  }

  sendTestEmail(payload: SendTestEmailPayload) {
    const url = `@api/testmessage/email`


    return this.http.post(url, payload)
  }

  sendTestSMS(payload: SendTestSMSPayload) {
    const url = `@api/testmessage/sms`


    return this.http.post(url, payload)
  }
}
