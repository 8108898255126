<nav class="navbar navbar-default navbar-fixed-top main-nav-holder">
  <div class="container-fluid">
    <div class="collapse navbar-collapse main-nav">
      <div class="row">
        <div class="col-sm-2 col-md-2 col-lg-2 logo hidden-xs">
          <a routerLink="/activity">
            <img src="assets/logo.png" alt="img"/>
          </a>
        </div>
        <div class="col-sm-7 col-md-7 col-lg-7">
          <ul class="nav navbar-nav nav-center">
            <li routerLinkActive="active">
              <a routerLink="/workflows">
                <i class="far fa-sitemap"></i>
                <span>{{'header.workflows' | translate}}</span>
              </a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="/triggers">
                <i class="far fa-bell"></i>
                <span>{{'header.triggers' | translate}}</span>
              </a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="/templates">
                <i class="far fa-file-alt"></i>
                <span>{{'header.templates' | translate}}</span>
              </a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="/bookings">
                <i class="far fa-calendar-alt"></i>
                <span>{{'header.bookings' | translate}}</span>
              </a>
            </li>
            <li dropdown [ngClass]="{active:
                        utility.isRouteActive('integrations') ||
                        utility.isRouteActive('codes') ||
                        utility.isRouteActive('integrations') ||
                        utility.isRouteActive('activity')}" class="dropdown">
              <a dropdownToggle type="button" class="dropdown-toggle">
                <i class="far fa-cog"></i>
                <span>{{'header.settings' | translate}}</span>
                <i class="far dropdown-icon fa-angle-down"></i>
              </a>
              <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                <li>
                  <a class="dropdown-item" routerLink="/codes"><i
                    class="fal fa-file-code"></i>{{'header.customCodes' | translate}}</a>
                </li>
                <li *ngIf="isAdmin">
                  <a class="dropdown-item" routerLink="/integrations"><i
                    class="fal fa-cubes"></i>{{'header.integrations' | translate}}</a>
                </li>
                <li class="divider dropdown-divider"></li>
                <li>
                  <a class="dropdown-item" routerLink="/activity"><i
                    class="fal fa-history"></i>{{'header.recentActivity' | translate}}</a>
                </li>
                <!--<li dropdown triggers="mouseover" placement="right" container="body" class="dropdown">-->
                <!--<a dropdownToggle class="dropdown-item dropdown-toggle" (click)="false">{{'header.language' | translate}}</a>-->
                <!--<ul *dropdownMenu class="dropdown-menu" role="menu">-->
                <!--<li role="menuitem"><a (click)="useLanguage(languages.English)" class="dropdown-item lang-item">{{'header.languageButtonEn' | translate}}</a></li>-->
                <!--<li role="menuitem"><a (click)="useLanguage(languages.Spanish)" class="dropdown-item lang-item">{{'header.languageButtonEs' | translate}}</a></li>-->
                <!--</ul>-->
                <!--</li>-->
              </ul>
            </li>
          </ul>
        </div>

        <!--<div class="col-sm-1">-->
        <!--<div class="nav navbar-nav lang-nav">-->
        <!--<button [class.active]="language === languages.English" type="button" (click)="useLanguage(languages.English)" class="btn-lang">EN</button>-->
        <!--<button [class.active]="language === languages.Spanish" type="button" (click)="useLanguage(languages.Spanish)" class="btn-lang" >ES</button>-->
        <!--</div>-->
        <!--</div>-->

        <div class="col-sm-3">
          <ul class="nav navbar-nav nav-right navbar-right">
            <li dropdown class="dropdown">
              <button dropdownToggle type="button" class="dropdown-toggle">
                <span [matTooltip]="(user?.firstname + ' ' + user?.lastname)"
                      matTooltipClass="text-center"
                      matTooltipPosition="below">{{ (user?.firstname + ' ' + user?.lastname) | truncate:30 }}</span>
                <span class="role">{{role()}}</span>
                <i class="far dropdown-icon fa-angle-down"></i>
              </button>
              <ul *dropdownMenu class="dropdown-menu">
                <li *ngIf="isAdmin">
                  <a class="dropdown-item" routerLink="/account">
                    <i class="fal fa-building"></i> {{'header.accountInfo' | translate}}
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" (click)="changePassword()">
                    <i class="fal fa-user-secret"></i> {{'header.changePassword' | translate}}
                  </a>
                </li>
                <li class="divider dropdown-divider"></li>
                <li>
                  <a class="dropdown-item" (click)="logout()">
                    <i class="fal fa-sign-out"></i> {{'header.logout' | translate}}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>
