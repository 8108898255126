<div class="table-box">
  <mat-table #table matSort [dataSource]="dataSource">

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.name' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="value">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.value' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.value | truncate:38}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef> {{'tableColumns.edit' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div dropdown container="body">
          <div dropdownToggle (click)="$event.stopPropagation()" class="text-center elipse-btn">
            <i class="far fa-ellipsis-v-alt"></i>
          </div>
          <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
            <li role="menuitem">
              <button (click)="onEdit(element)" class="dropdown-item">
                {{'buttons.edit' | translate}}
              </button>
            </li>
            <li role="menuitem">
              <button (click)="onRemove(element)" class="dropdown-item">
                {{'buttons.delete' | translate}}
              </button>
            </li>
          </ul>

        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onEdit(row)"></mat-row>
  </mat-table>

  <app-translated-pagination>
    <mat-paginator #paginator
                   [ngClass]="{hidden: (isEmptyTable$ | asyncSchedule | async)}"
                   [length]="dataSource?.data?.length"
                   [pageSize]="5"
                   [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
                   [showFirstLastButtons]="true">
    </mat-paginator>
  </app-translated-pagination>

  <app-empty-table *ngIf="(isEmptyTable$ | async)" [type]="tableType"></app-empty-table>

</div>
