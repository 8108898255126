import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TriggerResponse} from '../interfaces/trigger/trigger-response';
import {combineLatest, EMPTY, forkJoin, from, Observable, of} from 'rxjs';
import {Trigger} from '../models/trigger';
import * as R from 'ramda';
import {catchError, concatMap, distinctUntilChanged, filter, flatMap, map, mergeMap, switchMap, take, tap, toArray} from 'rxjs/operators';
import {TriggerCondition} from '../features/conditions/models/trigger-condition';
import {TriggerConditionResponse} from '../interfaces/trigger/trigger-condition-response';
import {TriggerConditionCreate} from '../interfaces/trigger/trigger-condition-create';
import {TriggerConditionDeleteRequest} from '../interfaces/trigger/trigger-condition-delete-request';
import {TriggerConditionSave} from '../interfaces/trigger/trigger-condition-save';
import {TriggerHandler} from '../enums/trigger-handler';
import {User} from '../models/user';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {isSomething} from '../utility/functions/is-something';
import {TriggerSkipsResponse} from '../interfaces/trigger/trigger-skips-response';
import {TriggerSaveRequest} from '../interfaces/trigger/trigger-save-request';
import {RemoveFromWorkflowRequest} from '../interfaces/remove-from-workflow-request';
import {TriggerEvent} from '../interfaces/trigger/trigger-event';
import {TriggerEvents} from '../models/trigger-events';
import {sortAscend} from '../utility/functions/sort-by-name';
import {TriggersCreateRequest} from '../interfaces/trigger/triggers-create-request';
import {AddWorkflowsParams} from '../interfaces/add-workflows-params';
import {ToastService} from '@automata/services/toast.service';
import {ToastIconClasses} from '@automata/utility/models/toast-icon-classes';
import {ToastrService} from 'ngx-toastr';
import {TriggerCreateRequest} from '@automata/interfaces/trigger/trigger-create-request';
import { compact } from 'lodash'

@Injectable()
export class TriggersService {

  constructor(private http: HttpClient,
              private toast: ToastService,
              private toastr: ToastrService,
              private store: Store<fromRoot.State>) {

  }

  getAll(): Observable<{automata: Trigger[], legacy: Trigger[]}> {
    return this.http.get<TriggerResponse[]>(`@api/trigger/all`)
      .pipe(
        map((triggers) => {
          let legacyTriggers = R.filter((t: any) => t.service === 'Tokeet Triggers', triggers);
          return R.map(t => {
            let trigger = new Trigger();
            trigger.fromResponse(t);
            return trigger;
          }, legacyTriggers)
        }),
        switchMap(legacy => this.http.get<TriggerResponse[]>('@api/trigger/all?automata=1')
          .pipe(
            map((automataTriggers) => {
              const automata = R.map(t => {
                let trigger = new Trigger();
                trigger.fromResponse(t);
                return trigger;
              }, automataTriggers)
              return {
                automata,
                legacy
              }
            })
          )
        )
      );
  }

  createMany(payload: TriggersCreateRequest): Observable<Trigger[]> {
    return this.http.post<TriggerResponse[]>(`@api/triggers/`, payload)
      .pipe(
        map((response) => R.map(t => {
          let trigger = new Trigger();
          trigger.fromResponse(t);
          return trigger;
        }, response))
      );
  };

  create(payload: TriggerCreateRequest): Observable<Trigger> {
    return this.http.post<TriggerResponse>(`@api/trigger/`, payload)
      .pipe(
        map((response) => {
          let trigger = new Trigger();
          trigger.fromResponse(response);
          return trigger;
        })
      );
  };

  update(request: TriggerSaveRequest): Observable<Trigger> {
    return this.http.put<TriggerResponse>(`@api/trigger/${request.id}`, request)
      .pipe(
        map((response) => {
          let trigger = new Trigger();
          trigger.fromResponse(response);
          return trigger;
        })
      );
  };

  updateBatch(triggers: Trigger[]) {
    let updatedTriggers: Trigger[] = [];

    if (R.isEmpty(triggers)) {
      return of([]);
    }

    return <any>forkJoin(
      from(triggers)
        .pipe(
          concatMap((trigger: Trigger) => {
            return this.update(trigger.toUpdate())
              .pipe(
                map(trigger => {
                  updatedTriggers = R.append(trigger, updatedTriggers);
                  return updatedTriggers;
                })
              );
          })
        )
    )
      .pipe(
        map(triggers => R.flatten(triggers))
      );
  }

  get(pkey): Observable<Trigger> {
    return this.http.get<TriggerResponse>(`@api/trigger/${pkey}`)
      .pipe(
        map((response) => {
          let trigger = new Trigger();
          trigger.fromResponse(response);
          return trigger;
        })
      );
  };

  pause(pkey): Observable<Trigger> {
    return this.http.put<TriggerResponse>(`@api/trigger/pause/${pkey}`, {})
      .pipe(
        map((response) => {
          let trigger = new Trigger();
          trigger.fromResponse(response);
          return trigger;
        }),
        catchError(() => of(null))
      );
  };

  resume(pkey): Observable<Trigger> {
    return this.http.put<TriggerResponse>(`@api/trigger/resume/${pkey}`, {})
      .pipe(
        map((response) => {
          let trigger = new Trigger();
          trigger.fromResponse(response);
          return trigger;
        }),
        catchError(() => of(null))
      );
  };

  archive(pkey): Observable<Trigger> {
    return this.http.put<TriggerResponse>(`@api/trigger/archive/${pkey}`, {})
      .pipe(
        map((response) => {
          let trigger = new Trigger();
          trigger.fromResponse(response);
          return trigger;
        })
      );
  };

  unarchive(pkey): Observable<Trigger> {
    return this.http.put<TriggerResponse>(`@api/trigger/unarchive/${pkey}`, {})
      .pipe(
        map((response) => {
          let trigger = new Trigger();
          trigger.fromResponse(response);
          return trigger;
        })
      );
  };

  delete(pkey) {
    return this.http.delete(`@api/trigger/${pkey}`);
  };

  deleteBatch(ids: string[]): Observable<string[]> {
    if (R.isEmpty(ids)) {
      return EMPTY;
    }
    return this.http.put<string[]>(`@api/trigger/delete/batch`, {ids: ids});
  };

  saveCondition(request: TriggerConditionSave): Observable<TriggerCondition> {
    return this.http.put(`@api/trigger/condition/${request.triggerId}`, {
      ...request.condition,
      key: request.conditionId
    })
      .pipe(
        map((response) => new TriggerCondition(R.merge(response, {key: request.conditionId})))
      );
  }

  createCondition(request: TriggerConditionCreate): Observable<TriggerCondition> {
    return this.http.post<TriggerConditionResponse>(`@api/trigger/condition/${request.triggerId}`, request.condition)
      .pipe(
        map((response) => new TriggerCondition(response))
      );
  }

  deleteCondition(request: TriggerConditionDeleteRequest) {
    return this.http.put(`@api/trigger/condition/remove/${request.triggerId}`, {key: request.conditionId});
  }

  notifyOnMissingPhone(handler$, recipients$, users$) {
    return combineLatest(handler$, recipients$, users$)
      .pipe(
        filter(([handler, recipients, users]) => handler === TriggerHandler.SendSMSMessage && !R.isEmpty(recipients) && !R.isEmpty(users)),
        tap((result: any) => {
          return this.checkAndNotifyMissingPhone(result[0], result[1], result[2])
        })
      );
  }

  checkAndNotifyMissingPhone(handler: TriggerHandler, recipients: string[], users: Partial<User>[]) {
    R.forEach(recipientId => {
      const recipient = R.find(u => u.id === recipientId, users);
      if (!R.isNil(recipient) && (R.isEmpty(recipient.phone) || R.isNil(recipient.phone))) {
        this.toastr.show(`User ${recipient.firstname} is missing phone number.`, 'Error', {toastClass: ToastIconClasses.Warning});
      }
    }, recipients);
  }

  getTemplates() {
    return this.store
      .select(fromRoot.selectTemplates)
      .pipe(
        filter(templates => isSomething(templates)),
        map(templates => R.map(t => ({
          id:   t.id,
          name: t.friendlyName,
          type: t.type
        }), templates)),
        distinctUntilChanged()
      );
  }

  getLastAddedTemplate() {
    return this.store
      .select(fromRoot.selectLastAdded)
      .pipe(
        filter(template => !R.isNil(template)),
        take(1),
      );
  }

  getLastEditedTemplate() {
    return this.store
      .select(fromRoot.selectLastEdited)
      .pipe(
        filter(template => !R.isNil(template)),
        take(1),
      );
  }

  getUsers() {
    return this.store
      .select(fromRoot.selectAllUsers)
      .pipe(
        filter(users => isSomething(users)),
        map(users => R.map((u: User) => ({
          id:        u.id,
          name:      `${u.firstname} ${u.lastname}`,
          firstname: u.firstname,
          detailedEmailName: `${u.firstname} ${u.lastname} - ${u.primaryemail}`,
          detailedPhoneName: `${u.firstname} ${u.lastname} - ${u.phone || 'Not Provided'}`,
          phone:     u.phone
        }), users)),
        distinctUntilChanged()
      );
  }

  loadSkippedBookingTriggers(triggerIds: string[]): Observable<TriggerSkipsResponse[]> {
    if (R.isEmpty(triggerIds)) {
      return EMPTY;
    }
    if (triggerIds.length === 1) {
      return this.http.get<TriggerSkipsResponse>(`@api/trigger/skip/trigger/${triggerIds[0]}`)
        .pipe(
          map(response => [response]),
          map(responses => compact(responses))
        );
    }
    return this.http.get<TriggerSkipsResponse[]>(`@api/triggers/skip/trigger/`, {params: {triggers: triggerIds}})
      .pipe(
        map(responses => compact(responses))
      )
  }

  addWorkflowsToTriggers(request: AddWorkflowsParams) {
    if (R.isEmpty(request.triggers) || R.isEmpty(request.workflows)) {
      return of([]);
    }
    return forkJoin(
      R.map((trigger: Trigger) => {

        let updatedTrigger = new Trigger(trigger);

        let workflows = R.uniq(R.concat(request.workflows, trigger.attributes.workflows));
        workflows = R.map(R.compose(R.toLower, R.join('_'), R.split(' '), R.trim))(workflows);

        let saveRequest: TriggerSaveRequest = {
          id:          updatedTrigger.id,
          event:       updatedTrigger.event,
          timeevent:   updatedTrigger.event,
          days:        updatedTrigger.settings.days,
          hours:       updatedTrigger.settings.hours,
          status:      updatedTrigger.status,
          channel:     updatedTrigger.settings.channel,
          rental:      updatedTrigger.settings.rental,
          not_rentals: updatedTrigger.settings.not_rentals,
          handler:     updatedTrigger.handler,
          service:     'automata',
          attributes:  R.merge(updatedTrigger.attributes, {workflows}),
          users:       <string[]>updatedTrigger.settings.users,
          template:    updatedTrigger.settings.template
        } as TriggerSaveRequest;

        return this.http.put<TriggerResponse>(`@api/trigger/${trigger.id}`, saveRequest)
          .pipe(
            map(response => {
              let trigger = new Trigger();
              trigger.fromResponse(response);
              return trigger;
            })
          );
      }, request.triggers)
    );
  }

  pauseTriggers(triggers: Trigger[]) {
    return forkJoin(R.map(t => this.pause(t.pkey), triggers));
  }

  resumeTriggers(triggers: Trigger[]) {
    return forkJoin(R.map(t => this.resume(t.pkey), triggers));
  }

  removeFromWorkflow(request: RemoveFromWorkflowRequest): Observable<Trigger> {

    let updatedTrigger = new Trigger(request.trigger);

    let workflows = R.reject(workflowName => workflowName === request.workflowName, updatedTrigger.attributes.workflows);

    updatedTrigger.attributes = {
      ...updatedTrigger.attributes,
      workflows: workflows
    };

    let saveRequest: TriggerSaveRequest = {
      id:          updatedTrigger.id,
      pkey:        updatedTrigger.id,
      event:       updatedTrigger.event,
      timeevent:   updatedTrigger.event,
      days:        updatedTrigger.settings.days,
      hours:       updatedTrigger.settings.hours,
      status:      updatedTrigger.status,
      channel:     updatedTrigger.settings.channel,
      rental:      updatedTrigger.settings.rental,
      not_rentals: updatedTrigger.settings.not_rentals,
      handler:     updatedTrigger.handler,
      service:     'automata',
      attributes:  updatedTrigger.attributes,
      users:       <string[]>updatedTrigger.settings.users,
      template:    updatedTrigger.settings.template
    } as TriggerSaveRequest;

    return this.update(saveRequest);
  }

  getEvents(isPartOfWorkflow = false) {

    const events = R.map((e: TriggerEvent) => ({
      id:   e.value,
      name: e.name
    }), sortAscend('name')(TriggerEvents.events));

    return of(events)
      .pipe(
        map(events => {
          if (isPartOfWorkflow) {
            return R.filter(event => TriggerEvents.allowsRental(event.id) && !TriggerEvents.isRate(event.id), events);
          } else {
            return events;
          }
        })
      );
  }

  recreate(param: {triggers: Trigger[]; workflow: string; rental: string}) {

    if (R.isEmpty(param.triggers)) {
      return of([]);
    }

    let triggerRequests: TriggersCreateRequest[] = R.map(trigger => ({
      event:      trigger.event,
      timeevent:  trigger.timeevent,
      days:       trigger.settings.days,
      hours:      trigger.settings.hours,
      status:     1,
      handler:    trigger.handler,
      service:    'automata',
      channels:   [''],
      rentals:    [param.rental],
      conditions: trigger.conditions || [],
      attributes: {
        name:      trigger.name,
        tags:      [],
        delayed:   0,
        workflows: R.map(R.compose(R.toLower, R.join('_'), R.split(' '), R.trim))([param.workflow])
      },
      users:      trigger.users,
      template:   trigger.template
    } as TriggersCreateRequest), param.triggers);

    return of(triggerRequests)
      .pipe(
        flatMap(requests => from(requests)),
        mergeMap(request => this.createMany(request)),
        toArray(),
        map(triggers => R.flatten(triggers))
      );
  }

  duplicateTrigger(trigger: Trigger, workflow: string, rentalId: string): Observable<Trigger> {

    let request = {
      event:      trigger.event,
      timeevent:  trigger.timeevent,
      days:       trigger.settings.days,
      hours:      trigger.settings.hours,
      status:     1,
      handler:    trigger.handler,
      service:    'automata',
      channels:   [trigger.settings.channel],
      rentals:    [rentalId],
      conditions: trigger.conditions || [],
      attributes: {
        name:        trigger.name,
        tags:        trigger.attributes.tags,
        delayed:     trigger.attributes.delayed,
        bookingTags: trigger.attributes.bookingTags,
        workflows:   R.map(R.compose(R.toLower, R.join('_'), R.split(' '), R.trim))([workflow])
      },
      users:      trigger.users,
      template:   trigger.template
    } as any;

    return this.createMany(request).pipe(map(triggers => R.head(triggers)));
  }

  areWorkflowsChanged(oldWorkflows: string[], newWorkflows: string[]) {
    if (oldWorkflows.length !== newWorkflows.length) {
      return true;
    }
    let oldFormatted = R.map(R.compose(R.toLower, R.join('_'), R.split(' '), R.trim))(oldWorkflows);

    return !R.equals(oldFormatted.sort(), newWorkflows.sort());
  }
}

