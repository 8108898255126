import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatRippleModule} from '@angular/material/core';
import {LayoutSidebarButtonComponent} from '@automata/layout/sidebar/sidebar-button/sidebar-button.component';
import {CallPipeModule} from '@automata/pipes/call/call.pipe.module';

const modules = [
  CommonModule,
  MatRippleModule,
  CallPipeModule
];

export const components = [
  LayoutSidebarButtonComponent
];

export const dialogs = [];

export const services = [];

export const pipes = [];

export const directives = [];

export const declarations = [
  ...pipes,
  ...dialogs,
  ...components,
  ...directives
];

@NgModule({
  imports:         [
    ...modules
  ],
  declarations,
  providers:       [
    ...services
  ],
  entryComponents: [
    ...dialogs
  ],
  exports:         [
    ...declarations,
    ...modules
  ]
})
export class LayoutSidebarButtonModule {
}
