import {LoadRentalsComplete, UpdateRentalSettingsComplete} from '../actions/rentals.actions';
import {Rental} from '../models/rental';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {updateOne} from '@automata/utility/serializers/utility.adapter';

export interface State extends EntityState<Rental> {
  // additional entities state properties
  selectedRentalId: string | null
  isLoaded: boolean
}

export const adapter: EntityAdapter<Rental> = createEntityAdapter<Rental>({
  sortComparer: (a: Rental, b: Rental) => a.name.toString().localeCompare(b.name),
  selectId: (r: Rental) => r.pkey
});

export const initialState: State = adapter.getInitialState({
  selectedRentalId: null,
  isLoaded:         false
});

export const reducer = createReducer(
  initialState,
  on(LoadRentalsComplete, (state, {rentals}) => adapter.addMany(rentals, {
    ...state,
    isLoaded: true
  })),
  on(UpdateRentalSettingsComplete, (state, {rental}) => updateOne(adapter, {id: rental.id, changes: rental}, {
    ...state,
    isLoaded: true
  })),
);

export const {selectAll, selectIds, selectEntities} = adapter.getSelectors();
