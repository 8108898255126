import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LocalStorageKeys} from '../models/local-storage-keys';
import {isNil} from 'ramda';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!isNil(localStorage.getItem(LocalStorageKeys.API_TOKEN))) {
      request = request.clone({
        setHeaders: {
          Authorization: localStorage.getItem(LocalStorageKeys.API_TOKEN)
        }
      });
    }

    return next.handle(request);
  }
}
