import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {BehaviorSubject, combineLatest, Observable, Subject, Subscription} from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import {GetSkipTriggers, SkipTriggers, UnSkipTriggers} from '../../actions/accounts.actions';
import {AddTriggerSample} from '../../actions/samples.actions';
import {TriggerEvents} from '../../models/trigger-events';
import {TriggerStatuses} from '../../models/trigger-statuses';
import {FormControl} from '@angular/forms';
import {DialogService} from '../../services/dialog.service';
import {TriggerDialogComponent} from '../../components/trigger/trigger-dialog/trigger-dialog.component';
import {sortAscend} from '../../utility/functions/sort-by-name';
import {FiltersService} from '../../services/filters.service';
import * as R from 'ramda';
import {filter, map, startWith, switchMap, take} from 'rxjs/operators';
import {isSomething} from '../../utility/functions/is-something';
import {TagsType} from '../../enums/tags-type';
import {Trigger} from '../../models/trigger';
import {TriggerSample} from '../../models/samples/trigger-sample';
import {getWorkflows} from '../../utility/functions/get-workflows';
import {ToastService} from '@automata/services/toast.service';
import {
    AddWorkflowsToTriggers,
    ArchiveTrigger,
    DeleteBatchTrigger, DeleteTrigger,
    OpenTrigger,
    PauseTrigger,
    ResumeTrigger,
    UnArchiveTrigger
} from '../../actions/triggers.actions';
import {TriggerHandlers} from '@automata/models/trigger-handlers';
import {AmplitudeService} from '@automata/services/amplitude.service';

@Component({
  selector:    'app-triggers',
  templateUrl: './triggers.component.html',
  styleUrls:   ['./triggers.component.scss']
})
export class TriggersComponent implements OnInit, OnDestroy {

  tableData$ = new BehaviorSubject<Trigger[]>([]);

  events = R.concat(sortAscend('name')(R.filter((e) => e.value !== 'time', TriggerEvents.events)), TriggerEvents.timeevents);
  actions = TriggerHandlers.handlers;
  statuses = TriggerStatuses.statuses;

  templatesCtrl = new FormControl(this.filters.getTriggers().templates);
  rentalsCtrl = new FormControl(this.filters.getTriggers().rentals);
  channelsCtrl = new FormControl(this.filters.getTriggers().channels);
  eventsCtrl = new FormControl(this.filters.getTriggers().events);
  statusCtrl = new FormControl(this.filters.getTriggers().status);
  actionsCtrl = new FormControl(this.filters.getTriggers().actions);
  tagsCtrl = new FormControl(this.filters.getTriggers().tags);

  triggersSub: Subscription;

  filteredTableData$: Observable<Trigger[]>;

  channels$ = this.store.pipe(select(fromRoot.selectChannelsWithDefaults));
  rentals$ = this.store.pipe(select(fromRoot.selectAllRentals));
  templates$ = this.store.pipe(select(fromRoot.selectAllTemplates));

  filter$ = new Subject<string>();

  selected: Trigger[] = [];
  page: PageEvent;

  skippedSub: Subscription;

  triggerOpenSub: Subscription;
  skipped: boolean;

  tags$ = this.filters.registerTagsFilter(TagsType.Trigger, this.tagsCtrl);

  constructor(private auth: AuthService,
              private amplitudeService: AmplitudeService,
              private store: Store<fromRoot.State>,
              private toast: ToastService,
              private filters: FiltersService,
              private dialogService: DialogService) {
  }

  ngOnInit() {

    this.store.dispatch(GetSkipTriggers());

    this.skippedSub = this.store.pipe(select(fromRoot.areTriggersSkipped))
      .subscribe(skipped => {
        this.skipped = skipped;
      });

    this.triggerOpenSub = this.store.pipe(
      select(fromRoot.selectedTriggerId),
      filter(triggerId => !R.isNil(triggerId))
    )
      .subscribe(id => {
        this.store.dispatch(OpenTrigger({id}));
      });

    const filters = this.filters.getTriggers();

    this.filteredTableData$ = combineLatest(
      this.tableData$,
      this.templatesCtrl.valueChanges.pipe(startWith(filters.templates)),
      this.rentalsCtrl.valueChanges.pipe(startWith(filters.rentals)),
      this.channelsCtrl.valueChanges.pipe(startWith(filters.channels)),
      this.eventsCtrl.valueChanges.pipe(startWith(filters.events)),
      this.statusCtrl.valueChanges.pipe(startWith(filters.status)),
      this.actionsCtrl.valueChanges.pipe(startWith(filters.actions)),
      this.tagsCtrl.valueChanges.pipe(startWith(filters.tags))
    )
      .pipe(
        map((data) => {
          let [triggers, templates, rentals, channels, events, status, actions, tags] = data;

          this.filters.storeTriggers(<any>{
            templates,
            rentals,
            channels,
            events,
            status,
            actions,
            tags
          });

          if (isSomething(templates)) {
            triggers = R.filter((t: Trigger) => R.contains(t.templateId, <any>templates), triggers);
          }
          if (isSomething(rentals)) {
            triggers = R.filter((t: Trigger) => R.contains(t.rentalId, <any>rentals), triggers);
          }
          if (isSomething(channels)) {
            triggers = R.filter((t: Trigger) => R.contains(t.channelToken, <any>channels), triggers);
          }
          if (isSomething(events)) {
            triggers = R.filter((t: Trigger) => R.contains(t.eventId, <any>events), triggers);
          }
          if (isSomething(status)) {
            switch (<any>status) {
              case 'active':
                triggers = R.filter((t: Trigger) => t.status === 1, triggers);
                break;
              case 'unarchived':
                triggers = R.filter((t: Trigger) => t.archived === 0, triggers);
                break;
              case 'archived':
                triggers = R.filter((t: Trigger) => t.archived === 1, triggers);
                break;
              case 'paused':
                triggers = R.filter((t: Trigger) => t.status === 0, triggers);
                break;
            }
          }
          if (isSomething(actions)) {
            triggers = R.filter((trigger: Trigger) => R.contains(trigger.handler, actions), triggers)
          }
          if (isSomething(tags)) {
            triggers = R.filter((trigger: Trigger) => R.any(R.equals(true), <any>R.map(tag => R.contains(<any>tag, <any>trigger.attributes.tags), tags)), triggers);
          }
          return triggers;
        })
      );

    this.triggersSub = this.store
      .pipe(
        select(fromRoot.selectTriggersWithTemplates),
        map((triggers) => R.map(t => new Trigger(t), triggers))
      )
      .subscribe((triggers) => {
        this.tableData$.next(triggers);
      });
  }

  ngOnDestroy() {
    this.triggersSub.unsubscribe();
    this.tableData$.unsubscribe();
    this.triggerOpenSub.unsubscribe();
    this.skippedSub.unsubscribe();
  }

  openSamples() {
    this.amplitudeService.logEvent('trigger-sample-start')
    this.dialogService.openTriggerSamples()
      .subscribe((sample: TriggerSample) => {
        this.amplitudeService.logEvent('trigger-sample-complete')
        this.store.dispatch(AddTriggerSample({sample}));
      });
  }

  toggleTriggers() {
    if (this.skipped) {
      this.amplitudeService.logEvent('global-triggers-activate')
      this.store.dispatch(UnSkipTriggers({id: this.auth.getUser().id}));
    } else {
      this.amplitudeService.logEvent('global-triggers-deactivate')
      this.store.dispatch(SkipTriggers({id: this.auth.getUser().id}));
    }
  }

  addToWorkflow() {

    const sharedWorkflows = R.reduce((acc: string[], trigger: Trigger) => {

      return R.intersection(getWorkflows(trigger), acc);

    }, getWorkflows(R.head(this.selected)) || [], this.selected);

    const uniqueRentals = R.uniq(R.map(R.path(['rentalId']), this.selected));
    const uniqueRentalsWithoutNil = R.reject(R.isNil)(uniqueRentals);

    if (uniqueRentalsWithoutNil.length > 1) {
      setTimeout(() => this.toast.info('sameRental'), 500);
      return;
    }

    if (uniqueRentalsWithoutNil.length !== 1 || uniqueRentals.length > uniqueRentalsWithoutNil.length) {
      setTimeout(() => this.toast.info('withoutRental'), 500);
      return;
    }

    this.store.pipe(
      select(fromRoot.rentalWorkflows(R.head(uniqueRentalsWithoutNil))),
      take(1),
      switchMap(workflows => this.dialogService.openWorkflows(sharedWorkflows, workflows))
    )
      .subscribe(result => {
        this.store.dispatch(AddWorkflowsToTriggers({params: {
            triggers:  this.selected,
            workflows: result.workflows
          }}));
      });
  }

  openNew() {
    this.amplitudeService.logEvent('trigger-blank-start')
    this.dialogService.openSide(TriggerDialogComponent, {data: {}});
  }

  onEdit(trigger: Trigger) {
    console.log(`Editing trigger ${trigger.id}`);
    this.dialogService.openSide(TriggerDialogComponent, {data: {triggerId: trigger.id}});
  }

  onDuplicate(trigger: Trigger) {
    console.log(`Duplicating trigger ${trigger.id}`);
    this.dialogService.openSide(TriggerDialogComponent, {
      data: {
        triggerId:   trigger.id,
        isDuplicate: true
      }
    });
  }

  onPagination(pageEvent: PageEvent) {
    this.page = pageEvent;
  }

  onSelection(event: any) {
    this.selected = event.selected;
  }

  isAnySelected() {
    return !R.isEmpty(this.selected) && !R.isNil(this.selected);
  }

  isAllSelected() {
    const numSelected = this.selected.length;
    const numRows = this.page.length;
    return numSelected === numRows;
  }

  onConfirmDelete() {
    let selectedIds: string[] = [];
    if (this.isAllSelected()) {
      const startIndex = this.page.pageIndex * this.page.pageSize;
      const selectedOnCurrentPage = R.slice(startIndex, startIndex + this.page.pageSize, <any>this.selected);
      selectedIds = R.map((trigger: Trigger) => trigger.id, <any>selectedOnCurrentPage);
    } else {
      selectedIds = R.map((trigger: Trigger) => trigger.id, <any>this.selected);
    }
    this.store.dispatch(DeleteBatchTrigger({ids: selectedIds}));
  }

  confirmDelete() {
    this.dialogService.openConfirm().subscribe(() => {
      this.onConfirmDelete();
    });
  }

  confirmRemove(trigger: Trigger) {
    this.dialogService.openConfirm().subscribe(() => {
      this.onConfirmRemove(trigger);
    });
  }

  onConfirmRemove(trigger: Trigger) {
    this.store.dispatch(DeleteTrigger({id: trigger.id}));
  }

  onPause(trigger: Trigger) {
    this.store.dispatch(PauseTrigger({id: trigger.id}));
  }

  onResume(trigger: Trigger) {
    this.store.dispatch(ResumeTrigger({id: trigger.id}));
  }

  onArchive(trigger: Trigger) {
    if (!!trigger.status) {
      this.dialogService.openConfirm({
        title:       'Pause trigger?',
        body:        'Archiving the trigger also pauses it. Are you sure?',
        confirmText: 'Pause & Archive',
        cancelText:  'Cancel'
      })
        .subscribe(() => {
          this.store.dispatch(PauseTrigger({id: trigger.id}));
          this.store.dispatch(ArchiveTrigger({id: trigger.id}));
        });
    } else {
      this.store.dispatch(ArchiveTrigger({id: trigger.id}));
    }
  }

  onUnArchive(trigger: Trigger) {
    this.store.dispatch(UnArchiveTrigger({id: trigger.id}));
  }
}
