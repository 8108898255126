import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {LoginForm} from '../../models/login-form';

@Component({
  selector:    'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls:   ['./login-form.component.scss']
})
export class LoginFormComponent {

  @Output() login = new EventEmitter<LoginForm>();

  loginForm = this.fb.group({
    email:     [
      '',
      [
        Validators.required,
        Validators.email
      ]
    ],
    password:  [
      '',
      [
        Validators.required,
        Validators.minLength(8)
      ]
    ],
    keepAlive: [false]
  });

  constructor(private fb: FormBuilder) {
  }

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }

}
