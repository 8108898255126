<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header btn-holder" [ngClass]="{'tab-nav-holder': !isNew()}">
      <div class="d-flex justify-content-between align-items-center">
        <div [ngClass]="{hidden: isNew()}" class="nav-tab-holder">
          <ul class="nav nav-tabs d-flex" role="tablist">
            <li [ngClass]="{active: activeTab.value === tabTypes.Template}">
              <a (click)="openTab(tabTypes.Template)">
                {{'pageElements.templateTab' | translate}}
              </a>
            </li>
            <li [ngClass]="{active: activeTab.value === tabTypes.Triggers}">
              <a (click)="openTab(tabTypes.Triggers)">
                {{'pageElements.triggerTab' | translate}}
              </a>
            </li>
          </ul>
        </div>
        <div [ngClass]="{hidden: !isNew()}" class="">
          <h5 class="modal-title">
            {{'pageElements.newTemplate' | translate}}
          </h5>
        </div>
        <div [ngClass]="{'dialog-actions': !isNew()}" class="d-flex text-right">

          <button tabindex="-1" (click)="close()" type="button"
                  class="btn btn-default left-icon text-uppercase">
            <i class="far fa-check-square" aria-hidden="true"></i>
            {{'buttons.cancel' | translate}}
          </button>

          <button (click)="addTags()"
                  [matTooltip]="'tooltips.addTagsToTemplate' | translate"
                  matTooltipPosition="above"
                  matTooltipClass="text-center"
                  type="button"
                  class="btn btn-cstm-info left-icon text-uppercase">
            <i class="far fa-plus" aria-hidden="true"></i>
            {{'buttons.tags' | translate}}
          </button>

          <button
            *ngIf="hasTranslations$ | async"
            [matTooltip]="'tooltips.generateLanguageVersions' | translate"
            matTooltipPosition="above"
            matTooltipClass="text-center"
            (click)="translateControl.emit(true)" type="button"
            class="btn btn-cstm-info left-icon text-uppercase">
            <span class="far fa-plus"></span> {{'buttons.translation' | translate}}
          </button>

          <button
            *ngIf="activeTab.value === tabTypes.Template && (templateForm?.type === types.Payload || template?.type === types.Payload)"
            (click)="payloadControl.emit(true)" type="button"
            class="btn btn-cstm-info left-icon text-uppercase">
            <span class="far fa-plus"></span> {{'buttons.header' | translate}}
          </button>

          <button (click)="onTemplateSave()" [disabled]="!isTemplateFormValid" type="button"
                  class="btn btn-new left-icon text-uppercase">
            <span class="far fa-save" aria-hidden="true"></span>
            {{'buttons.save' | translate}}
          </button>
        </div>
      </div>
      <button (click)="close()" class="cstm-close" data-dismiss="modal" aria-label="Close" type="button">
        <span class="far fa-times"></span>
      </button>
    </div>

    <div *ngIf="activeTab.value === tabTypes.Template" class="modal-body">

      <div class="row">
        <div [ngClass]="{hidden: isNew()}" class="col-sm-12 edit-template-col">
          <h4 class="modal-title">
            {{'pageElements.editTemplate' | translate}}
          </h4>
        </div>
      </div>

      <app-template-form [templateId]="template.id"
                         [translateControl]="translateControl"
                         [payloadControl]="payloadControl"
                         (change)="onTemplateChange($event)"
                         (save)="onTemplateSave(false)"
                         (valid)="onTemplateValid($event)"></app-template-form>
    </div>

    <div *ngIf="activeTab.value === tabTypes.Triggers" class="modal-body">

      <div class="content-holder mt20 clearfix">
        <app-trigger-table [data]="tableData$"
                           [mainTable]="false"
                           [hasSelect]="false"
                           [hasTemplate]="false"
                           (remove)="confirmRemove($event)"
                           (duplicate)="onDuplicate($event)"
                           (edit)="onEdit($event)"
                           (pause)="onPause($event)"
                           (resume)="onResume($event)"
                           (archive)="onArchive($event)"
                           (unarchive)="onUnArchive($event)"></app-trigger-table>
      </div>

    </div>
  </div>
</div>
