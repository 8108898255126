import {Observable, of} from 'rxjs';
import {CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {LoadTemplates} from '../actions/templates.actions';
import {catchError, filter, switchMapTo, take, tap} from 'rxjs/operators';

@Injectable()
export class TemplatesGuard implements CanActivate {

  constructor(private store: Store<fromRoot.State>) {
  }

  public canActivate(): Observable<boolean> {
    return this.checkTemplates()
      .pipe(
        switchMapTo(of(true)),
        catchError(() => of(false))
      );
  }

  checkTemplates(): Observable<boolean> {
    return this.store.pipe(
      select(fromRoot.selectTemplatesLoaded),
      tap(isLoaded => {
        if (!isLoaded) {
          this.store.dispatch(LoadTemplates());
        }
      }),
      filter(isLoaded => isLoaded),
      take(1)
    );
  }
}
