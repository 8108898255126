import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserResponse} from '../interfaces/user/user-response';
import {from, Observable} from 'rxjs';
import {User} from '../models/user';
import {flatMap, map, toArray} from 'rxjs/operators';

@Injectable()
export class UsersService {

  constructor(private http: HttpClient) {
  }

  all(): Observable<User[]> {
    return this.http.get<UserResponse[]>('@api/user/all/')
      .pipe(
        flatMap(response => from(response)),
        map(response => User.deserialize(response)),
        toArray()
      );
  }
}
