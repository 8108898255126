import {TriggersHelperService} from './services/triggers-helper.service';
import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, Injectable, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {WorkflowsComponent} from './containers/workflows/workflows.component';
import {routes} from '../routes';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from '../environments/environment';
import {StoreModule} from '@ngrx/store';
import {metaReducers, reducerProvider, reducerToken} from './reducers';
import {TriggersComponent} from './containers/triggers/triggers.component';
import {TemplatesComponent} from './containers/templates/templates.component';
import {BookingsPageComponent} from './containers/bookings-page/bookings-page.component';
import {ToastrModule} from 'ngx-toastr';
import {AutomataToastComponent} from './components/toast/toast.component';
import {LoginComponent} from './containers/login/login.component';
import {UtilityService} from './services/utility.service';
import {AuthService} from './services/auth.service';
import {AuthGuard} from './guards/auth.guard';
import {APP_CONFIG, AppConfig} from './app.config';
import {TokenInterceptor} from './interceptors/token.interceptor';
import {LoginGuard} from './guards/login.guard';
import {LoginFormComponent} from './components/login-form/login-form.component';
import {IntegrationsComponent} from './containers/integrations/integrations.component';
import {ActivityComponent} from './containers/activity/activity.component';
import {TriggersEffects} from './effects/triggers.effects';
import {EffectsModule} from '@ngrx/effects';
import {TriggersService} from './services/triggers.service';
import {UsersService} from './services/users.service';
import {ChannelsService} from './services/channels.service';
import {TemplatesService} from './services/templates.service';
import {RentalsService} from './services/rentals.service';
import {BookingService} from './containers/bookings-page/store/booking.service';
import {ChannelsEffects} from './effects/channels.effects';
import {RentalsEffects} from './effects/rentals.effects';
import {TemplatesEffects} from './effects/templates.effects';
import {UsersEffects} from './effects/users.effects';
import {BookingEffects} from './containers/bookings-page/store/booking.effects';
import {TemplatesGuard} from './guards/templates.guard';
import {TriggersGuard} from './guards/triggers.guard';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {RentalsGuard} from './guards/rentals.guard';
import {ChannelsGuard} from './guards/channels.guard';
import {AttachmentDialogComponent} from './components/template/attachment-dialog/attachment-dialog.component';
import {DictionaryDialogComponent} from './components/template/dictionary-dialog/dictionary-dialog.component';
import {DialogService} from './services/dialog.service';
import {UsersGuard} from './guards/users.guard';
import {TriggerDialogComponent} from './components/trigger/trigger-dialog/trigger-dialog.component';
import {TemplateDialogComponent} from './components/template/template-dialog/template-dialog.component';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {BookingGuard} from './containers/bookings-page/store/booking.guard';
import {WorkflowDialogComponent} from './components/workflow/workflow-dialog/workflow-dialog.component';
import {FiltersService} from './services/filters.service';
import {ActivitiesGuard} from './guards/activities.guard';
import {ActivitiesService} from './services/activities.service';
import {ActivitiesEffects} from './effects/activities.effects';
import {TemplateConfirmDialogComponent} from './components/template/template-confirm-dialog/template-confirm-dialog.component';
import {ActivityChartComponent} from './components/activity/activity-chart/activity-chart.component';
import {TableUtilityService} from './services/table-utility.service';
import {AccountComponent} from './containers/account/account.component';
import {AccountService} from './services/account.service';
import {AccountGuard} from './guards/account.guard';
import {AccountsEffects} from './effects/accounts.effects';
import {ApiKeysGuard} from './guards/api-keys-guard';
import {CreateApiKeyDialogComponent} from './components/account/create-api-key-dialog/create-api-key-dialog.component';
import {AuthorizeComponent} from './containers/authorize/authorize.component';
import {TokeetService} from './services/tokeet.service';
import {NgProgressModule} from '@ngx-progressbar/core';
import {NgProgressHttpModule} from '@ngx-progressbar/http';
import {ApiKeysTableComponent} from './components/account/api-keys-table/api-keys-table.component';
import {ConditionDialogComponent} from './features/conditions/components/condition-dialog/condition-dialog.component';
import {IftttIntegrationDialogComponent} from './components/account/ifttt-integration-dialog/ifttt-integration-dialog.component';
import {IntegrationInfoDialogComponent} from './components/integrations/integration-info-dialog/integration-info-dialog.component';
import {IntegrationsService} from './services/integrations.service';
import {PayloadHeadersDialogComponent} from './components/template/payload-headers-dialog/payload-headers-dialog.component';
import {TemplateTranslateDialogComponent} from './components/template/template-translate-dialog/template-translate-dialog.component';
import {TruncatePipe} from './pipes/truncate.pipe';
import {TriggersSuccessTableComponent} from './components/activity/triggers-success-table/triggers-success-table.component';
import {TriggersFailTableComponent} from './components/activity/triggers-fail-table/triggers-fail-table.component';
import {GuestService} from './services/guest.service';
import {NotificationsService} from './services/notifications.service';
import {NotificationsGuard} from './guards/notifications.guard';
import {NotificationsEffects} from './effects/notifications.effects';
import {StripHtmlPipe} from './pipes/strip-html.pipe';
import {TagsDialogComponent} from './components/tags/tags-dialog/tags-dialog.component';
import {TableManagementDialogComponent} from './components/tables/table-management-dialog/table-management-dialog.component';
import {AdminGuard} from './guards/admin.guard';
import {CodesComponent} from './containers/codes/codes.component';
import {CodeDialogComponent} from './components/codes/code-dialog/code-dialog.component';
import {CodesEffects} from './effects/codes.effects';
import {CodesService} from './services/codes.service';
import {CodesGuard} from './guards/codes.guard';
import {BookingDialogComponent} from './components/bookings/booking-dialog/booking-dialog.component';
import {TemplateSamplesDialogComponent} from './components/template/template-samples-dialog/template-samples-dialog.component';
import {TriggerWorkflowsDialogComponent} from './components/trigger/trigger-workflows-dialog/trigger-workflows-dialog.component';
import {SamplesEffects} from './effects/samples.effects';
import {SampleGuard} from './guards/samples.guard';
import {SamplesService} from './services/samples.service';
import {WorkflowWizardComponent} from './components/workflow/workflow-wizard/workflow-wizard.component';
import {WorkflowDuplicateDialogComponent} from './components/workflow/workflow-duplicate-dialog/workflow-duplicate-dialog.component';
import {WorkflowDeleteConfirmDialogComponent} from './components/workflow/workflow-delete-confirm-dialog/workflow-delete-confirm-dialog.component';
import {WorkflowsEffects} from './effects/workflows.effects';
import {NewWorkflowDialogComponent} from './components/workflow/new-workflow-dialog/new-workflow-dialog.component';
import {ChangePasswordDialogComponent} from './components/account/change-password-dialog/change-password-dialog.component';
import {UtilityEffects} from './effects/utility.effects';
import {TriggerSamplesDialogComponent} from './components/trigger/trigger-samples-dialog/trigger-samples-dialog.component';
import {BookingMessagesDialogComponent} from './components/bookings/booking-messages-dialog/booking-messages-dialog.component';
import {ContractsService} from './services/contracts.service';
import {ContractsEffects} from './effects/contracts.effects';
import {ContractsGuard} from './guards/contracts.guard';
import {WorkflowRenameDialogComponent} from './components/workflow/workflow-rename-dialog/workflow-rename-dialog.component';
import {ApiInterceptor} from './interceptors/api.interceptor';
import {ExistingTriggersDialogComponent} from './components/workflow/existing-triggers-dialog/existing-triggers-dialog.component';
import {SlackInfoDialogComponent} from './components/integrations/slack-info-dialog/slack-info-dialog.component';
import {EpochPipe} from '@automata/pipes/epoch.pipe';
import {FieldOrPathPipe} from './pipes/field-or-path.pipe';
import {IsChannelAirbnbPipe} from './pipes/is-channel-airbnb.pipe';
import {AlertDialogComponent} from './components/alert-dialog/alert-dialog.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TutorialDialogComponent} from './components/tutorial/tutorial-dialog.component';
import {captureException, init} from '@sentry/browser';
import {PlanService} from '@automata/services/plan.service';
import {SharedModule} from '@automata/shared/shared.module';
import {HeaderComponent} from '@automata/components/header/header.component';
import {CallPipe} from '@automata/pipes/call.pipe';
import {WizardLandingGuard} from '@automata/guards/wizard-landing.guard';
import {SubscriptionsEffects} from '@automata/effects/subscriptions.effects';
import {SubscriptionsGuard} from '@automata/guards/subscriptions.guard';
import {BookingStoreModule} from '@automata/containers/bookings-page/store/booking.module';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {VERSION} from '../environments/version';
import {DataDictService} from '@automata/components/template/preview-template/data-dictionary.service';

if (environment.production && environment.sentryDns) {
  init({
    dsn: environment.sentryDns,
    release: `automata@${VERSION.hash}`,
    environment: environment.environment,
    ignoreErrors: ['ResizeObserver loop limit exceeded', new RegExp(/ChunkLoadError/g)],
    beforeSend(event) {
      if (['ProgressEvent', 'XMLHttpRequestProgressEvent'].includes((event?.extra?.__serialized__ as any)?.error)) {
        return null;
      }
      return event;
    },
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
  }

  handleError(error) {
    captureException(error.originalError || error);
    throw error;
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations:    [
    AppComponent,
    WorkflowsComponent,
    TriggersComponent,
    TemplatesComponent,
    HeaderComponent,
    BookingsPageComponent,
    LoginComponent,
    LoginFormComponent,
    IntegrationsComponent,
    ActivityComponent,
    ActivityChartComponent,
    AccountComponent,
    AuthorizeComponent,
    ApiKeysTableComponent,
    TriggersSuccessTableComponent,
    TriggersFailTableComponent,
    CodesComponent,
    CallPipe
  ],
  imports:         [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    BookingStoreModule,
    RouterModule.forRoot(routes),
    EffectsModule.forRoot([
      ChannelsEffects,
      RentalsEffects,
      SubscriptionsEffects,
      TemplatesEffects,
      UsersEffects,
      TriggersEffects,
      UtilityEffects,
      WorkflowsEffects,
      BookingEffects,
      ActivitiesEffects,
      AccountsEffects,
      NotificationsEffects,
      CodesEffects,
      SamplesEffects,
      ContractsEffects
    ]),
    StoreModule.forRoot(reducerToken, {metaReducers}),
    ToastrModule.forRoot({
      toastComponent: AutomataToastComponent
    }),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgProgressModule,
    NgProgressHttpModule,
    TranslateModule.forRoot({
      loader: {
        provide:    TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps:       [HttpClient]
      }
    })
  ],
  providers:       [
    {
      provide:  ErrorHandler,
      useClass: SentryErrorHandler
    },
    reducerProvider,
    {
      provide:  HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi:    true
    },
    {
      provide:  HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi:    true
    },
    {
      provide:  HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi:    true
    },
    {
      provide:  APP_CONFIG,
      useValue: AppConfig
    },
    CallPipe,
    UtilityService,
    DialogService,
    AuthGuard,
    SampleGuard,
    LoginGuard,
    TriggersGuard,
    ApiKeysGuard,
    AdminGuard,
    TemplatesGuard,
    BookingGuard,
    RentalsGuard,
    SubscriptionsGuard,
    UsersGuard,
    AccountGuard,
    WizardLandingGuard,
    ActivitiesGuard,
    ActivitiesService,
    TableUtilityService,
    ChannelsGuard,
    AuthService,
    TriggersService,
    PlanService,
    FiltersService,
    AccountService,
    UsersService,
    ChannelsService,
    TemplatesService,
    CodesService,
    ContractsGuard,
    RentalsService,
    BookingService,
    TokeetService,
    TriggersHelperService,
    IntegrationsService,
    GuestService,
    NotificationsService,
    NotificationsGuard,
    TruncatePipe,
    StripHtmlPipe,
    EpochPipe,
    IsChannelAirbnbPipe,
    FieldOrPathPipe,
    CodesGuard,
    SamplesService,
    DataDictService,
    ContractsService
  ],
  bootstrap:       [AppComponent],
  entryComponents: [
    AlertDialogComponent,
    AutomataToastComponent,
    TemplateConfirmDialogComponent,
    ConfirmDialogComponent,
    WorkflowDialogComponent,
    TemplateDialogComponent,
    TriggerDialogComponent,
    AttachmentDialogComponent,
    DictionaryDialogComponent,
    CreateApiKeyDialogComponent,
    ConditionDialogComponent,
    IftttIntegrationDialogComponent,
    IntegrationInfoDialogComponent,
    PayloadHeadersDialogComponent,
    TemplateTranslateDialogComponent,
    TagsDialogComponent,
    TableManagementDialogComponent,
    CodeDialogComponent,
    BookingDialogComponent,
    TemplateSamplesDialogComponent,
    TriggerWorkflowsDialogComponent,
    WorkflowWizardComponent,
    WorkflowDuplicateDialogComponent,
    WorkflowDeleteConfirmDialogComponent,
    NewWorkflowDialogComponent,
    ChangePasswordDialogComponent,
    TriggerSamplesDialogComponent,
    BookingMessagesDialogComponent,
    WorkflowRenameDialogComponent,
    ExistingTriggersDialogComponent,
    SlackInfoDialogComponent,
    TutorialDialogComponent
  ]
})
export class AppModule {
}
