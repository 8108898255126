import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {LoadActivities, LoadActivitiesComplete} from '../actions/activities.actions';
import {ActivitiesService} from '../services/activities.service';
import {of} from 'rxjs';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {GuestService} from '../services/guest.service';
import * as fromRoot from '../reducers';
import {Store} from '@ngrx/store';
import {RequestLimit} from '../enums/request-limit';
import {ActionFailed} from '../actions/utility.actions';

@Injectable()
export class ActivitiesEffects {

  @Effect()
  loadActivities$ = this.actions$
    .pipe(
      ofType(LoadActivities),
      switchMap(() => this.store
        .select(fromRoot.selectActivityIds)
        .pipe(
          map(ids => ids.length),
          take(1)
        )
      ),
      switchMap(count => this.activities
        .get(RequestLimit.Activities, count)
        .pipe(
          map(notifications => LoadActivitiesComplete({
            response: {
              limit:  RequestLimit.Activities,
              offset: count,
              result: notifications
            }
          })),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  constructor(private actions$: Actions,
              private store: Store<fromRoot.State>,
              private guestService: GuestService,
              private activities: ActivitiesService) {
  }
}
