import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TemplateConfirmDialogResult} from '../../../interfaces/template/template-confirm-dialog-result';
import {SaveForm} from '@automata/decorators/save.decorator';

@Component({
  selector:    'app-template-confirm-dialog',
  templateUrl: './template-confirm-dialog.component.html',
  styleUrls:   ['./template-confirm-dialog.component.scss']
})
export class TemplateConfirmDialogComponent implements OnInit {

  form = this.fb.group({
    name: ['', [
      Validators.required,
      Validators.maxLength(50),
      Validators.pattern(/^([0-9a-zа-яàáâãäåąæçćèéêëęœìíïîłńðòóôõöøśùúûñüýÿżźßÞďđ\s\-])+$/i),
      Validators.pattern(/^((?!\s\-\s).)*$/i)
    ]]
  });

  constructor(public dialogRef: MatDialogRef<TemplateConfirmDialogComponent>,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.form.patchValue({name: this.data.name});
  }

  @SaveForm()
  saveAsNew(form: FormGroup) {
    this.dialogRef.close({
      saveAsNew: true,
      name:      form.get('name').value,
      closed:    false
    } as TemplateConfirmDialogResult);
  }

  @SaveForm()
  update(form: FormGroup) {
    this.dialogRef.close({
      saveAsNew: false,
      name:      form.get('name').value,
      closed:    false
    } as TemplateConfirmDialogResult);
  }

  close() {
    this.dialogRef.close({
      closed: true
    } as TemplateConfirmDialogResult);
  }

}
