import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {Workflow} from '../../models/workflow';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import {FormControl} from '@angular/forms';
import {FiltersService} from '../../services/filters.service';
import {delay, map, startWith, tap} from 'rxjs/operators';
import * as R from 'ramda';
import {WorkflowFilters} from '../../interfaces/workflow-filters';
import {DialogService} from '../../services/dialog.service';
import {Router} from '@angular/router';
import {TableUtilityService} from '../../services/table-utility.service';
import {TableType} from '../../enums/table-type';
import {WorkflowDialogComponent} from '../../components/workflow/workflow-dialog/workflow-dialog.component';
import {WorkflowWizardComponent} from '../../components/workflow/workflow-wizard/workflow-wizard.component';
import {NewWorkflowDialogComponent} from '../../components/workflow/new-workflow-dialog/new-workflow-dialog.component';
import {localeCompareSort} from '@automata/utility/functions/locale-compare-sort';
import {TranslateService} from '@ngx-translate/core';
import {DeleteWorkflow, RemoveWorkflow} from '@automata/actions/workflows.actions';
import {PauseTriggers, ResumeTriggers} from '../../actions/triggers.actions';
import {AmplitudeService} from '@automata/services/amplitude.service';

@Component({
  selector:    'app-workflows',
  templateUrl: './workflows.component.html',
  styleUrls:   ['./workflows.component.scss']
})
export class WorkflowsComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns = this.tableService.getDisplayedColumns(TableType.Workflows);

  dataSource = new MatTableDataSource<Workflow>();

  tableData = new BehaviorSubject<Workflow[]>([]);

  workflowsSub: Subscription;
  filtersSub: Subscription;

  rentalCtrl = new FormControl(this.filters.getWorkflows().rentals);
  eventsCtrl = new FormControl(this.filters.getWorkflows().events);

  tableType = TableType.Workflows;
  isLoaded = false;

  rentals$ = this.store.pipe(select(fromRoot.selectAllRentals));
  isEmptyTable$ = this.tableService.isEmptyTable(this.dataSource);
  triggers$ = this.store.pipe(select(fromRoot.selectTriggers));

  constructor(private auth: AuthService,
              private router: Router,
              private amplitudeService: AmplitudeService,
              private dialogService: DialogService,
              private tableService: TableUtilityService,
              private filters: FiltersService,
              private translate: TranslateService,
              private store: Store<fromRoot.State>) {
  }

  ngOnInit() {

    this.dataSource.data = [];
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortData = localeCompareSort;

    this.filtersSub = combineLatest(
      this.tableData,
      this.rentalCtrl.valueChanges.pipe(startWith(this.rentalCtrl.value)),
      this.eventsCtrl.valueChanges.pipe(startWith(this.eventsCtrl.value))
    )
      .pipe(
        tap(([workflows, rentals, events]) => this.filters.storeWorkflows({rentals, events} as WorkflowFilters)),
        map((data) => {
          let [workflows, rentals] = data;

          if (!R.isEmpty(rentals)) {
            workflows = R.filter((i: Workflow) => R.contains(i.rentalId, rentals), workflows);
          }

          return workflows;
        }),
        delay(10)
      )
      .subscribe((workflows) => {
        this.paginator.firstPage();
        this.isLoaded = true;
        this.dataSource.data = workflows;
      });

    this.workflowsSub = this.store.pipe(select(fromRoot.selectWorkflows))
      .subscribe((workflows) => {
        this.tableData.next(workflows);
      });
  }

  ngOnDestroy() {
    this.workflowsSub.unsubscribe();
    this.filtersSub.unsubscribe();
  }

  openSamples() {
    this.amplitudeService.logEvent('workflow-sample-start')
    this.dialogService.openSide(WorkflowWizardComponent, {data: {}});
  }

  openBlank() {
    this.amplitudeService.logEvent('workflow-blank-start')
    this.dialogService.openSide(NewWorkflowDialogComponent, {data: {}});
  }

  applyFilter(term: string) {
    this.paginator.firstPage();
    this.dataSource.filter = term.trim().toLowerCase();
  }

  onDetails(row) {
    console.log(`Editing workflow ${row.id}`);
    this.dialogService.openSide(WorkflowDialogComponent, {
      data: {
        workflowId: row.id
      }
    });
  }

  onRename(workflow: Workflow) {
    this.dialogService.openWorkflowRename(workflow);
  }

  onDuplicate(workflow: Workflow) {
    this.dialogService.openWorkflowDuplicate(workflow);
  }

  confirmRemove(workflow: Workflow) {
    this.dialogService.openWorkflowDeleteConfirm()
      .subscribe((result) => {
        this.onConfirmRemove(workflow, result.isChecked);
      });
  }

  onConfirmRemove(workflow: Workflow, deleteAssociated) {
    if (deleteAssociated) {
      this.store.dispatch(DeleteWorkflow({workflow}));
    } else {
      this.store.dispatch(RemoveWorkflow({workflow}));
    }
  }

    onStatusChange(workflow: Workflow) {
        if (workflow.status) {
            this.store.dispatch(PauseTriggers({triggers: workflow.triggers}));
        } else {
            this.store.dispatch(ResumeTriggers({triggers: workflow.triggers}));
        }
    }

  tableManagement() {
    this.dialogService.openTableManagement(this.tableType)
      .subscribe(columns => {
        this.displayedColumns = columns;
      });
  }
}
