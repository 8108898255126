import {IWorkflowSample} from '../../interfaces/workflow-sample';
import {SampleType} from '../../enums/sample-type';
import {deserialize, Serializable} from '@automata/utility/serializers/serializers';

declare const require: any;

const uuid = require('uuid/v4');

export class WorkflowSample extends Serializable<WorkflowSample> implements IWorkflowSample {
  id = uuid();
  kind = SampleType.Workflow;

  key: string;
  name: string;
  description: string;
  triggers: string[];

  static deserialize(data: object): WorkflowSample {
    return deserialize(WorkflowSample, data);
  }
}
