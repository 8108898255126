import {Observable, of} from 'rxjs';
import {CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {LoadBookings} from './booking.actions';
import {catchError, filter, switchMapTo, take, tap} from 'rxjs/operators';
import {selectBookingsLoaded} from '@automata/containers/bookings-page/store/booking.selectors';

@Injectable()
export class BookingGuard implements CanActivate {

  constructor(private store: Store<fromRoot.State>) {
  }

  public canActivate(): Observable<boolean> {
    return this.checkInquiries()
      .pipe(
        switchMapTo(of(true)),
        catchError(() => of(false))
      );
  }

  checkInquiries(): Observable<boolean> {
    return this.store.pipe(
      select(selectBookingsLoaded),
      tap(isLoaded => {
        if (!isLoaded) {
          this.store.dispatch(LoadBookings());
        }
      }),
      filter(isLoaded => isLoaded),
      take(1)
    );
  }
}
