import {Pipe, PipeTransform} from '@angular/core';
import {TriggerHandler} from '../../enums/trigger-handler';
import * as R from 'ramda';

@Pipe({
  name: 'allowsRecipients'
})
export class AllowsRecipientsPipe implements PipeTransform {
  transform(handler: string): boolean {
    if (R.isEmpty(handler)) {
      return true;
    }

    return handler === TriggerHandler.SendEmailMessage ||
      handler === TriggerHandler.SendContract ||
      handler === TriggerHandler.SendForm ||
      handler === TriggerHandler.SendDocument ||
      handler === TriggerHandler.SendSMSMessage ||
      handler === TriggerHandler.SendPushNotification;

  }
}
