import {Pipe, PipeTransform} from '@angular/core';
import {TriggerEvents} from '../../models/trigger-events';

@Pipe({
  name: 'allowsRental'
})
export class AllowsRentalPipe implements PipeTransform {
  transform(event: string): boolean {
    return TriggerEvents.allowsRental(event);
  }
}
