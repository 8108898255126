import {InjectionToken} from '@angular/core';
import {environment} from '../environments/environment';

export let APP_CONFIG = new InjectionToken('app.config');

export interface IAppConfig {
  signatureApiUrl: string;
  apiUrl: string
  samplesUrl: string
  iftttUrl: string
  intercomAppId: string
}

export const AppConfig: IAppConfig = {
  signatureApiUrl: environment.signatureApiUrl,
  apiUrl:          environment.apiUrl,
  samplesUrl:      environment.samplesUrl,
  iftttUrl:        environment.iftttUrl,
  intercomAppId:   environment.intercomAppId
};
