import {ActionReducerMap, createFeatureSelector, createSelector, MetaReducer, select, Store} from '@ngrx/store';
import {environment} from '../../environments/environment';
import * as fromChannel from './channels.reducer';
import * as fromRental from './rentals.reducer';
import * as fromCode from './codes.reducer';
import * as fromTemplate from './templates.reducer';
import * as fromUser from './users.reducer';
import * as fromTrigger from './triggers.reducer';
import * as fromActivity from './activities.reducer';
import * as fromNotification from './notifications.reducer';
import * as fromAccount from './accounts.reducer';
import * as fromSample from './samples.reducer';
import * as fromContract from './contracts.reducer';
import * as fromSubscription from './subscriptions.reducer';
import {InjectionToken} from '@angular/core';
import {TemplateType} from '../enums/template-type';
import {storeLogger} from 'ngrx-store-logger';
import {Template} from '../models/template';
import {Trigger} from '../models/trigger';
import {Rental} from '../models/rental';
import * as R from 'ramda';
import {Channel} from '../models/channel';
import {Workflow} from '../models/workflow';
import {User} from '../models/user';
import {TriggerEvents} from '../models/trigger-events';
import {AutomataNotification} from '../models/automata-notification';
import {TriggerNotificationTableModel} from '../models/trigger-notification-table-model';
import {Notification} from '../models/notification';
import {isSomething} from '../utility/functions/is-something';
import {Code} from '../models/code';
import {TriggerCondition} from '../features/conditions/models/trigger-condition';
import {SampleType} from '../enums/sample-type';
import {WorkflowSample} from '../models/samples/workflow-sample';
import {ActivityTypes} from '../models/activity-types';
import {TriggerSample} from '../models/samples/trigger-sample';
import {TemplateSample} from '../models/samples/template-sample';
import {SignatureTemplate} from '../models/signature-template';
import {uniqueProperties} from '../utility/functions/unique-properties';
import {TriggerHandler} from '../enums/trigger-handler';
import {hasGuest} from '@automata/utility/functions/has-guest';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {combineLatest} from 'rxjs';

declare const require: any;

const startsWith = require('lodash/startsWith');

export interface State {
  channels: fromChannel.State;
  rentals: fromRental.State;
  codes: fromCode.State;
  templates: fromTemplate.State;
  users: fromUser.State;
  triggers: fromTrigger.State;
  activities: fromActivity.State;
  notifications: fromNotification.State;
  accounts: fromAccount.State;
  samples: fromSample.State;
  contracts: fromContract.State;
  subscriptions: fromSubscription.State;
}

export const reducers: any = {
  channels:      fromChannel.reducer,
  rentals:       fromRental.reducer,
  codes:         fromCode.reducer,
  templates:     fromTemplate.reducer,
  users:         fromUser.reducer,
  triggers:      fromTrigger.reducer,
  activities:    fromActivity.reducer,
  notifications: fromNotification.reducer,
  accounts:      fromAccount.reducer,
  samples:       fromSample.reducer,
  contracts:     fromContract.reducer,
  subscriptions: fromSubscription.reducer
};

export const reducerToken = new InjectionToken<ActionReducerMap<State>>(
  'Registered Reducers'
);

export const reducerProvider = [
  {
    provide:  reducerToken,
    useValue: reducers
  }
];

export function logout(reducer) {
  return function(state, action) {
    return reducer(action.type === '[Users] Logout' ? undefined : state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [
    <MetaReducer<State>>storeLogger({
      collapsed: true,
      colors:    {
        title:     (action: any) => {
          if (startsWith(action.type, '[Channels]')) {
            return '#f44336';
          } else if (startsWith(action.type, '[Inquiries]')) {
            return '#E91E63';
          } else if (startsWith(action.type, '[Rentals]')) {
            return '#9C27B0';
          } else if (startsWith(action.type, '[Templates]')) {
            return '#673AB7';
          } else if (startsWith(action.type, '[Triggers]')) {
            return '#03A9F4';
          } else if (startsWith(action.type, '[Users]')) {
            return '#007bff';
          } else if (startsWith(action.type, '[Codes]')) {
            return '#ff9908';
          } else if (startsWith(action.type, '[Notifications]')) {
            return '#e900ff';
          } else if (startsWith(action.type, '[Activities]')) {
            return '#ff374e';
          } else if (startsWith(action.type, '[Samples]')) {
            return '#7e8cff';
          }
        },
        prevState: (prevState: Object) => '#000000',
        action:    (action: Object) => '#000000',
        nextState: (nextState: Object) => '#000000',
        error:     (error: any, prevState: Object) => '#000000'
      }
    }),
    <MetaReducer<State>>logout
  ]
  : [<MetaReducer<State>>logout];

export const defaultChannels: Channel[] = [
  Channel.deserialize({
    channelId:    'tokeetwebsite',
    token:        'tokeetwebsite',
    friendlyName: 'Tokeet Websites',
    name:         'Tokeet Websites'
  }),
  Channel.deserialize({
    channelId:    'tokeet',
    token:        'tokeet',
    friendlyName: 'Sympl / Tokeet Dashboard',
    name:         'Sympl / Tokeet Dashboard'
  }),
  Channel.deserialize({
    channelId:    'widget',
    token:        'widget',
    friendlyName: 'Sympl / Tokeet Widgets',
    name:         'Sympl / Tokeet Widgets'
  })
];

//region States
export const channelState = createFeatureSelector<fromChannel.State>('channels');

export const rentalState = createFeatureSelector<fromRental.State>('rentals');

export const codeState = createFeatureSelector<fromCode.State>('codes');

export const templateState = createFeatureSelector<fromTemplate.State>('templates');

export const userState = createFeatureSelector<fromUser.State>('users');

export const triggerState = createFeatureSelector<fromTrigger.State>('triggers');

export const activityState = createFeatureSelector<fromActivity.State>('activities');

export const notificationState = createFeatureSelector<fromNotification.State>('notifications');

export const accountState = createFeatureSelector<fromAccount.State>('accounts');

export const sampleState = createFeatureSelector<fromSample.State>('samples');

export const contractState = createFeatureSelector<fromContract.State>('contracts');

export const subscriptionsState = createFeatureSelector<fromSubscription.State>('subscriptions');
//endregion

//region
export const selectSubscriptions = createSelector(subscriptionsState, state => state.subscriptions);

export const isSignatureActive = (store: Store<any>) => store.pipe(
  select(selectSubscriptions),
  map(subscriptions => !!R.find(p => p.product === 'signature' && (p.status === 'active' || p.status === 'trial' || p.status === 'trialing'), subscriptions) ||
    !!R.find(p => p.product === 'tokeet' && (p.status === 'trial' || p.status === 'trialing'), subscriptions) ||
    !!R.find(p => p.product === 'sympl' && (p.status === 'trial' || p.status === 'trialing'), subscriptions)),
  distinctUntilChanged()
);
export const isAutomataActive = (store: Store<any>) => store.pipe(
  select(selectSubscriptions),
  filter(subscriptions => isSomething(subscriptions)),
  map(subscriptions => {
    return !!R.find(p => p.product === 'automata' && (p.status === 'active' || p.status === 'trial' || p.status === 'trialing'), subscriptions) ||
      !!R.find(p => p.product === 'tokeet' && (p.status === 'trial' || p.status === 'trialing'), subscriptions) ||
      !!R.find(p => p.product === 'sympl' && (p.status === 'trial' || p.status === 'trialing'), subscriptions);
  }),
  distinctUntilChanged()
);
//endregion

//region Contracts
export const selectContractsLoaded = createSelector(contractState, (state: fromContract.State) => state.isLoaded);

export const selectAllContracts = createSelector(contractState, fromContract.selectAll);

export const selectSearchableContracts = createSelector(selectAllContracts, (contracts: SignatureTemplate[]) => {
  return R.map(c => ({
    id:   c.id,
    name: c.name,
    type: c.type
  }), contracts);
});
//endregion

//region Channels
export const selectChannelsLoaded = createSelector(channelState, (state: fromChannel.State) => state.isLoaded);

export const selectAllChannels = createSelector(channelState, fromChannel.selectAll);

export const selectChannelsWithDefaults = createSelector(selectAllChannels, channels => {
  let channelsWithDefaults = R.concat(channels)(defaultChannels);
  if (!environment.features.hometogo) {
    channelsWithDefaults = R.reject(c => c.token === 'hometogo', channelsWithDefaults)
  }
  return R.sort(
    (a: Channel, b: Channel) =>
      R.pathOr('', ['friendlyName'], a)
        .toString()
        .localeCompare(R.pathOr('', ['friendlyName'], b), undefined, {
          numeric:     true,
          sensitivity: 'base'
        }),
    channelsWithDefaults
  );

});
//endregion

//region Rentals
export const selectRentalsLoaded = createSelector(rentalState, (state: fromRental.State) => state.isLoaded);

export const selectAllRentals = createSelector(rentalState, fromRental.selectAll);

export const selectAllRentalIds = createSelector(rentalState, fromRental.selectIds);

export const selectRentalEntities = createSelector(rentalState, fromRental.selectEntities);
//endregion

//region Users
export const selectUsersLoaded = createSelector(userState, (state: fromUser.State) => state.isLoaded);

export const selectUserEntities = createSelector(userState, fromUser.selectEntities);

export const selectAllUsers = createSelector(userState, fromUser.selectAll);

export const selectUsers = userIds =>
  createSelector(selectUserEntities, (entities: {[id: string]: User}) => {
    let users = [];

    R.forEach((userId: string | number) => {
      if (userId === '111' || userId === 111) {
        users.push(new User({id: userId, firstname: 'Guest', lastname: ''} as Partial<User>));
      } else {
        let user = entities[userId];
        if (isSomething(user)) {
          users.push(user);
        }
      }
    }, userIds);

    return users;
  });
//endregion

//region Templates
export const selectTemplatesLoaded = createSelector(templateState, (state: fromTemplate.State) => state.isLoaded);

export const selectTemplateEntities = createSelector(templateState, fromTemplate.selectEntities);

export const selectAllTemplates = createSelector(templateState, fromTemplate.selectAll);

export const selectTemplates = createSelector(selectAllTemplates, templates => R.map((t: Template) => new Template(t), templates));

export const selectTemplatesWithTriggersByType = (templateType: TemplateType) => createSelector(
  selectTriggers, selectTemplates,
  (triggers, templates) => {
    let templatesWithTriggers = R.map(template => {

      let triggersWithTemplate = R.filter(R.propEq('template', template.pkey), triggers);

      return new Template(template, triggersWithTemplate);

    }, templates);

    if (templateType === TemplateType.None) {
      return templatesWithTriggers;
    }
    return R.filter(R.propEq('type', templateType), templatesWithTriggers);
  }
);

export const selectTemplatesByType = (type: TemplateType) => createSelector(selectTemplates, (templates) => R.filter(R.propEq('type', type), templates));

export const selectTemplateById = (id: string) => createSelector(selectTemplates, (templates) => R.find(R.propEq('id', id), templates));

export const selectLastAdded = createSelector(
  templateState, selectTemplateEntities,
  (state: fromTemplate.State, entities: {[id: string]: Template}) => entities[state.lastAddedId]
);

export const selectLastAddedSample = createSelector(
  templateState, selectTemplateEntities,
  (state: fromTemplate.State, entities: {[id: string]: Template}) => entities[state.lastAddedSampleId]
);

export const selectLastEdited = createSelector(
  templateState, selectTemplateEntities,
  (state: fromTemplate.State, entities: {[id: string]: Template}) => entities[state.lastEditedId]
);
//endregion

//region Triggers
export const selectedTriggerId = createSelector(triggerState, (state: fromTrigger.State) => state.selectedTriggerId);

export const selectTriggersLoaded = createSelector(triggerState, (state: fromTrigger.State) => state.isLoaded);

export const selectTriggerEntities = createSelector(triggerState, fromTrigger.selectEntities);

export const selectTriggersById = (ids: string[]) => createSelector(selectTriggerEntities, (entities) => {
  return R.reject(R.isNil)(R.map((id) => entities[id], ids));
});

export const selectAllTriggers = createSelector(triggerState, fromTrigger.selectAll);

export const selectTriggers = createSelector(selectAllTriggers, triggers => R.map((t: Trigger) => new Trigger(t), triggers));

export const selectLegacyTriggers = createSelector(triggerState, (state: fromTrigger.State) => state.legacy)

export const selectWorkflowAbleTriggers = createSelector(selectTriggers, triggers => {
  return R.filter((trigger: Trigger) => TriggerEvents.allowsRental(trigger.event) && !TriggerEvents.isRate(trigger.event), triggers);
});

export const selectTriggersWithoutWorkflow = (workflow: Workflow) => createSelector(selectWorkflowAbleTriggers, triggers => {
  return R.filter((t: Trigger) => !R.contains(workflow.name, t.attributes.workflows), triggers);
});

export const selectTriggersWithTemplates = createSelector(
  selectTemplates,
  selectTriggers,
  selectChannelsWithDefaults,
  selectAllRentals,
  selectAllUsers,
  selectAllContracts,
  (templates, triggers, channels, rentals, users, contracts) => {
    return R.map(trigger => {

      let template: Template | SignatureTemplate;
      let templateName = '';
      let hasNonExistingTemplate = false;
      if (trigger.handler === TriggerHandler.SendContract ||
        trigger.handler === TriggerHandler.SendForm ||
        trigger.handler === TriggerHandler.SendDocument
      ) {
        template = <any>R.find(R.propEq('id', trigger.template), contracts);
        templateName = template ? template.name : null;
      } else {
        template = <any>R.find(R.propEq('pkey', trigger.template), templates);
        templateName = template ? (<Template>template).friendlyName : null;
      }
      hasNonExistingTemplate = !!trigger.template && !template;

      const channel = R.find(R.propEq('token', R.path(['settings', 'channel'], trigger)), channels || []);
      const rental = R.find(R.propEq('id', R.path(['settings', 'rental'], trigger)), rentals || []);
      const filteredUsers = R.filter(user => R.contains(user.id, trigger.settings.users), users);

      let newTrigger = new Trigger(trigger);
      newTrigger.templateDescription = template ? template.description : null;
      newTrigger.templateName = templateName;
      if (hasNonExistingTemplate) {
        newTrigger.template = null;
        newTrigger.templateDescription = null;
        newTrigger.templateName = null;
        console.log('remove template from trigger');
      }
      newTrigger.channelName = channel ? channel.friendlyName : null;
      newTrigger.rentalName = rental ? rental.name : null;
      newTrigger.recipientsNames = R.join(', ', R.map(u => `${u.firstname} ${u.lastname}`, filteredUsers));
      if (hasGuest(trigger.settings.users)) {
        if (filteredUsers.length > 0) {
          newTrigger.recipientsNames = `${newTrigger.recipientsNames}, Guest`;
        } else {
          newTrigger.recipientsNames = 'Guest';
        }
      }
      let conditions = R.map(c => new TriggerCondition(c), trigger.conditions);
      newTrigger.conditionsDescriptions = R.join(', ', R.map(c => c.description, conditions));
      return newTrigger;

    }, triggers);
  }
);
export const selectTriggersByTemplateId = templateId => createSelector(
  selectTriggersWithTemplates,
  (allTriggers) => {
    return R.filter((trigger: Trigger) => trigger.settings.template === templateId, allTriggers);
  }
);

export const selectTriggerById = triggerId =>
  createSelector(
    selectTriggersWithTemplates,
    (triggers) => {
      return R.find(t => t.id === triggerId, triggers);
    }
  );

//endregion

//region Workflows
export const selectWorkflows = createSelector(
  selectAllRentals, selectTriggersWithTemplates,
  (rentals, triggers) => {

    let workflows = [];

    let eligibleTriggers = R.filter((t) => !R.isNil(t.settings.rental) && !TriggerEvents.isRate(t.event), triggers);

    R.forEach(rental => {

      let rentalTriggers = R.filter((t: Trigger) => t.settings.rental === rental.id, eligibleTriggers);

      let rentalWorkflows = R.uniq(R.flatten(R.map(R.path(['attributes', 'workflows']))(rentalTriggers)));

      R.forEach((name: string) => {

        let workflowTriggers = R.filter((t: Trigger) => R.contains(name, t.attributes.workflows), rentalTriggers);

        let lastUpdatedTrigger: Trigger = R.last(R.sort((a: Trigger, b: Trigger) => a.lastupdate - b.lastupdate, workflowTriggers));

        const hasAnyTriggerActive = R.any(R.equals(true), R.map(t => t.status === 1, workflowTriggers));

        let workflow = new Workflow();

        workflow.id = `${rental.id}.${name}`;
        workflow.triggers = workflowTriggers;
        workflow.name = name;
        workflow.rentalId = rental.id;
        workflow.rentalName = rental.name;
        workflow.status = hasAnyTriggerActive ? 1 : 0;
        workflow.templatesCount = R.uniq(R.map(t => t.template, workflowTriggers)).length;
        workflow.templateNames = R.map(t => t.templateName, workflowTriggers);
        if (!R.isNil(lastUpdatedTrigger)) {
          workflow.lastUpdated = lastUpdatedTrigger.lastupdate;
          workflow.lastUpdatedFormatted = lastUpdatedTrigger.lastUpdateFormatted;
        }
        workflows.push(workflow);
      }, rentalWorkflows);

    }, rentals);

    return workflows;
  }
);

export const selectFormWorkflows = createSelector(selectWorkflows, workflows => R.map(w => ({
  id:   w.id,
  name: w.name
}), workflows));

export const rentalWorkflows = (rentalId) => createSelector(selectTriggers, (triggers) => {
  // @ts-ignore
  return R.compose(
    R.map(R.when(R.is(Number), R.toString)),
    R.uniq,
    R.flatten,
    R.map(R.path(['attributes', 'workflows'])),
    R.filter(R.pathEq(['settings', 'rental'], rentalId))
  )(triggers);
});

export const selectWorkflowsAssociatedTriggers = (trigger: Trigger) =>
  createSelector(selectWorkflows, workflows => {
    const rentalId = R.path(['settings', 'rental'], trigger);
    const triggerWorkflowIds = R.map(w => `${rentalId}.${w}`, trigger.attributes.workflows);
    const relatedWorkflows = R.filter(w => R.contains(w.id, triggerWorkflowIds), workflows);
    const triggers = R.flatten(R.map(w => w.triggers, relatedWorkflows));
    return R.uniqBy(t => t.id, triggers);
  });

export const selectWorkflowById = (id) => createSelector(selectWorkflows, workflows => R.find(w => w.id === id, workflows));

export const selectTriggersByWorkflow = (workflow: Workflow) => createSelector(selectTriggers, triggers => {
  return R.filter(t => t.rentalId === workflow.rentalId && R.contains(workflow.name, t.attributes.workflows), triggers);
});
//endregion

//region Activities

export const selectHasMoreActivities = createSelector(activityState, (state: fromActivity.State) => state.hasMore);

export const selectActivitiesLoaded = createSelector(activityState, (state: fromActivity.State) => state.isLoaded);

export const selectAllActivities = createSelector(activityState, fromActivity.selectAll);

export const selectActivityIds = createSelector(activityState, fromActivity.selectIds);

export const attachTemplateAndChannelToActivity = (templates, activities, triggers, contracts) => {

  return <TriggerNotificationTableModel[]>R.map((activity: AutomataNotification) => {
    let tableModel = new TriggerNotificationTableModel(activity);

    let template: Template | SignatureTemplate;
    let templateName = '';
    if (tableModel.handler === TriggerHandler.SendContract ||
      tableModel.handler === TriggerHandler.SendForm ||
      tableModel.handler === TriggerHandler.SendDocument
    ) {
      template = <any>R.find(R.propEq('id', activity.templateId), contracts);
      templateName = template ? template.name : null;
    } else {
      template = <any>R.find(R.propEq('pkey', activity.templateId), templates);
      templateName = template ? (<Template>template).friendlyName : null;
    }

    if (!R.isNil(template) && !R.isNil(templateName)) {
      tableModel.templateName = templateName;
    }

    tableModel.eventName = TriggerEvents.getEventName(activity.event);
    tableModel.eventColor = TriggerEvents.getEventColor(activity.event);
    tableModel.hasTrigger = !R.isNil(R.find(t => t.id === tableModel.triggerId, triggers));
    tableModel.channelName = tableModel.channelName === 'tokeetwebsite' || tableModel.channelName === 'tokeet' ? 'Tokeet' : tableModel.channelName

    return tableModel;
  }, activities);
};

export const selectActivitiesByTriggerId = (triggerId) => createSelector(
  selectTemplates, selectAllActivities, selectTriggers, selectAllContracts,
  (templates, activities, triggers, contracts) => {
    const filteredActivities = R.filter((activity: AutomataNotification) => activity.triggerId === triggerId, activities);
    return attachTemplateAndChannelToActivity(templates, filteredActivities, triggers, contracts);
  });

export const selectTriggerNotificationTableModel = (successful?: boolean) => createSelector(
  selectTemplates, selectAllActivities, selectTriggers, selectAllContracts,
  (templates, activities, triggers, contracts) => {
    const filteredActivities = <any>R.filter((ac: AutomataNotification) => R.isNil(successful) ? true : successful ? ac.success !== 0 : ac.success === 0, <any>activities);
    return attachTemplateAndChannelToActivity(templates, filteredActivities, triggers, contracts);
  }
);

export const selectRecentActivity = createSelector(selectAllActivities, (activities) => {

  let groupedByDate = R.groupBy(R.prop('sentChartFormatted'), R.reverse(activities));
  let data: any[] = [];

  R.forEachObjIndexed((values, date: string) => {

    const split = date.split(' ');
    const day = split[0];
    const month = split[1];

    data = R.append({
      'name':   `${month} ${day}`,
      'series': R.values(
        R.mapObjIndexed(
          (value, code) => ({
            i18n:  ActivityTypes.getHandlerNameByCode(code),
            name:  ActivityTypes.getHandlerNameByCode(code),
            value: R.length(<any>value)
          }), R.groupBy(<any>R.prop('handler'))(values)
        )
      )
    }, <any[]>data);

  }, groupedByDate);

  return R.takeLast(10, data);
});

//endregion

//region Notifications
export const selectNotificationsLoaded = createSelector(notificationState, (state: fromNotification.State) => state.isLoaded);

export const selectAllNotifications = createSelector(notificationState, fromNotification.selectAll);

export const selectNotificationsWithRentals = createSelector(selectAllRentals, selectAllNotifications, (rentals, notifications) => {

  return R.map((notification: Notification) => {

    const rental = R.find((r: Rental) => r.id === notification.rental_id, rentals);

    if (!R.isNil(rental)) {
      let newNotification = new Notification(notification);
      newNotification.rental = rental;
      return newNotification;
    } else {
      return notification;
    }

  }, notifications);

});
//endregion

//region Accounts

export const selectAccountsLoaded = createSelector(accountState, (state: fromAccount.State) => state.isLoaded);

export const selectAccountEntities = createSelector(accountState, fromAccount.selectEntities);

export const selectAccountById = (id) => createSelector(selectAccountEntities, entities => entities[id]);

export const selectApiKeysLoaded = createSelector(accountState, (state: fromAccount.State) => state.isApiKeysLoaded);

export const areTriggersSkipped = createSelector(accountState, (state: fromAccount.State) => state.triggersSkipped);

//endregion

//region Codes
export const selectCodesLoaded = createSelector(codeState, (state: fromCode.State) => state.isLoaded);

export const selectAllCodes = createSelector(codeState, fromCode.selectAll);

export const selectUniqueCodeNames = createSelector(selectAllCodes, uniqueProperties('nameFormatted'));

export const selectCodesWithCreatorAndRentals = createSelector(
  selectAllCodes, selectAllUsers, selectAllRentals,
  (codes, users, rentals) => {
    return <Code[]>R.map((code: Code) => {

      let newCode = new Code(code);

      const creator = R.find((u: User) => u.id == code.creator, users);

      if (!R.isNil(creator)) {
        newCode.creatorName = `${creator.firstname} ${creator.lastname}`;
      }

      R.forEach((rentalId: string) => {
        const rental = R.find((r: Rental) => rentalId === r.pkey, rentals);
        if (!R.isNil(rental)) {
          if (!isSomething(newCode.rentalNames)) {
            newCode.rentalNames = rental.name;
          } else {
            newCode.rentalNames += `, ${rental.name}`;
          }
        }
      }, newCode.rentals);

      return newCode;
    }, codes);
  });

export const selectRentalsForCode = (code: Code) => createSelector(selectCodesWithCreatorAndRentals, selectAllRentalIds, selectRentalEntities, selectAllRentals, (codes, rentalIds, entities, rentals) => {
  if (R.isNil(code.id)) {
    return rentals;
  }
  const allRentalsForCodeName = R.pipe(
    R.filter(R.propEq('name', code.name)),
    R.map(R.view(R.lensProp('rentals'))),
    R.flatten,
    R.uniq
  )(codes);
  const result = R.concat(code.rentals, R.without(allRentalsForCodeName, rentalIds));
  return R.reject(R.isNil)(R.map((id: string) => entities[id], result));
});
//endregion

//region Samples
export const selectSamplesLoaded = createSelector(sampleState, (state: fromSample.State) => state.isLoaded);

export const selectAllSamples = createSelector(sampleState, fromSample.selectAll);

export const selectSamplesByType = (type: SampleType) => createSelector(selectAllSamples, samples => {
  return R.filter(s => s.kind === type, samples);
});

export const selectTemplateSampleByKey = (key: string) => createSelector(selectAllSamples, samples => {
  return R.find((t: TemplateSample) => t.key === key, <any>R.filter(s => s.kind === SampleType.Template, samples));
});

export const selectTemplateSamplesWithIcon = createSelector(selectAllSamples, (samples) => {
  const triggers: TriggerSample[] = <any>R.filter(s => s.kind === SampleType.Trigger, samples);
  const templates: TemplateSample[] = <any>R.filter(s => s.kind === SampleType.Template, samples);
  return R.map((t: TemplateSample) => {
    let newTemplate = new TemplateSample(t);
    const firstTrigger = R.find((trigger: TriggerSample) => trigger.template === t.key, triggers);
    if (!R.isNil(firstTrigger)) {
      newTemplate.icon = firstTrigger.icon;
    }
    return newTemplate;
  }, templates);
});

export const selectTriggerSamplesWithDescription = createSelector(selectAllSamples, samples => {
  const triggers: TriggerSample[] = <any>R.filter(s => s.kind === SampleType.Trigger, samples);
  const templates: TemplateSample[] = <any>R.filter(s => s.kind === SampleType.Template, samples);
  return R.map((t: TriggerSample) => {
    let newTrigger = new TriggerSample(t);
    const template = R.find((template: TemplateSample) => template.key === t.template, templates);
    if (!R.isNil(template)) {
      newTrigger.templateDescription = template.description;
    }
    return newTrigger;
  }, triggers);
});

export const selectWorkflowAbleTriggerSamples = createSelector(selectTriggerSamplesWithDescription, samples => {
  return R.filter((s: TriggerSample) => TriggerEvents.allowsRental(s.event) && !TriggerEvents.isRate(s.event), samples);
});

export const selectTriggerSamplesByWorkflow = (workflow: WorkflowSample) => createSelector(selectAllSamples, samples => {
  const triggerSamples = R.filter(sample => sample.kind === SampleType.Trigger, samples);
  const workflowTriggers: any = R.filter(s => R.contains(s.key, workflow.triggers), triggerSamples);
  return R.map((t: TriggerSample) => {
    const triggerTemplate = <TemplateSample>R.find(sample => sample.kind === SampleType.Template && sample.key === t.template, samples);
    let newTriggerSample = new TriggerSample(t);
    newTriggerSample.templateDescription = triggerTemplate ? triggerTemplate.description : '';
    return newTriggerSample;
  }, workflowTriggers);
});

export const selectTemplateSamples = createSelector(selectAllSamples, samples => {
  return R.filter(s => s.kind === SampleType.Template, samples);
});
//endregion
