<span *ngIf="isBadgeVisible"
      [matTooltip]="'tooltips.customizeTableColumns' | translate"
      matTooltipPosition="left"
      matTooltipClass="text-center"
      matBadge="!"
      matBadgeOverlap="false"
      matBadgeColor="accent"
      matBadgePosition="after"
      (click)="onClick()">
    <i class="far fa-bars"></i>
</span>
<span *ngIf="!isBadgeVisible"
      [matTooltip]="'tooltips.customizeTableColumns' | translate"
      matTooltipPosition="left"
      matTooltipClass="text-center"
      (click)="onClick()">
    <i class="far fa-bars"></i>
</span>
