import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {LoadContracts, LoadContractsComplete} from '../actions/contracts.actions';
import {ContractsService} from '../services/contracts.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import {ActionFailed} from '../actions/utility.actions';

@Injectable()
export class ContractsEffects {

  @Effect()
  loadContracts$ = this.actions$
    .pipe(
      ofType(LoadContracts),
      switchMap(() => this.contracts
        .getContracts()
        .pipe(
          map(templates => LoadContractsComplete({templates})),
          catchError(error => [
            ActionFailed({error}),
            LoadContractsComplete({templates: []})
          ])
        ))
    );

  constructor(private actions$: Actions,
              private contracts: ContractsService) {
  }
}
