import {Component, Inject, OnDestroy} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ChangePasswordDialogParams} from '../../../interfaces/change-password-dialog-params';
import {FormBuilder, Validators} from '@angular/forms';
import {AccountService} from '../../../services/account.service';
import {of, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {ToastService} from '@automata/services/toast.service';

@Component({
  selector:    'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls:   ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnDestroy {

  saveSub: Subscription;

  form = this.fb.group({
    password1: ['', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(32),
      Validators.pattern(/^\S*$/)
    ]],
    password2: ['',
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(32),
        Validators.pattern(/^\S*$/)
      ],
      [
        this.validatePasswordMatch.bind(this)
      ]
    ]
  });

  constructor(private fb: FormBuilder,
              private toast: ToastService,
              private accountService: AccountService,
              public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ChangePasswordDialogParams) {
  }

  ngOnDestroy() {
    if (this.saveSub) {
      this.saveSub.unsubscribe();
    }
  }

  validatePasswordMatch() {
    return of(this.form.controls).pipe(
      map((controls) => ({password1: controls.password1.value, password2: controls.password2.value})),
      map(form => form.password1 === form.password2),
      map(match => match ? null : {passwordMatch: true})
    );
  }

  save() {
    const form = this.form.getRawValue();

    this.saveSub = this.accountService.changePassword(this.data.user.id, form)
      .subscribe(() => {
        this.toast.success('Password changed successfully.');
        this.close();
      }, error => {
        this.toast.error(error);
        this.close();
      });
  }

  close() {
    this.dialogRef.close();
  }
}
