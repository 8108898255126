<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <button (click)="close()" class="cstm-close" data-dismiss="modal" aria-label="Close">
        <span class="far fa-times"></span>
      </button>
      <div class="header-box">
        <div>
          <h5 class="modal-title">
            {{'buttons.workflowDetail' | translate}}
          </h5>
        </div>
        <div>
          <mat-slide-toggle [matTooltip]="workflow?.status | workflowDetailGlobalTooltip"
                            matTooltipPosition="below"
                            matTooltipClass="text-center"
                            [checked]="workflow?.status === 1" (change)="toggleWorkflow()"
                            class="global-switch">
            <span [ngClass]="{hidden: workflow?.status !== 1}">{{'pageElements.active' | translate}}</span>
            <span
              [ngClass]="{hidden: workflow?.status === 1}">{{'pageElements.inactive' | translate}}</span>
          </mat-slide-toggle>
          <button (click)="toggleView()" type="button"
                  class="btn btn-default text-uppercase btn-switcher-wide">
            <span *ngIf="!isList">{{'buttons.listView' | translate}}</span>
            <span *ngIf="isList">{{'buttons.flowView' | translate}}</span>
          </button>
          <div dropdown class="btn-group">
            <button dropdownToggle type="button"
                    class="btn btn-cstm-info left-icon text-uppercase dropdown-toggle">
              <span class="far fa-plus"></span> {{'buttons.newTrigger' | translate}}
            </button>
            <ul *dropdownMenu class="dropdown-menu dropdown-menu-workflow-detail" role="menu">
              <li role="menuitem">
                <a [matTooltip]="'tooltips.triggerCommonSamples' | translate"
                   matTooltipPosition="left"
                   matTooltipClass="text-center"
                   class="dropdown-item" (click)="openSamples()">
                  {{'buttons.startWithSample' | translate}}
                </a>
                <a [matTooltip]="'tooltips.triggerWorkflowFromScratch' | translate"
                   matTooltipPosition="left"
                   matTooltipClass="text-center"
                   class="dropdown-item" (click)="createTrigger()">
                  {{'buttons.startWithBlank' | translate}}
                </a>
                <a [matTooltip]="'tooltips.addExistingTriggerToWorkflow' | translate"
                   matTooltipPosition="left"
                   matTooltipClass="text-center"
                   class="dropdown-item" (click)="addExistingTrigger()">
                  {{'buttons.addExistingTrigger' | translate}}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-body work-detail-modal-content">
      <div class="row">
        <div class="col-sm-12 title" [ngClass]="{addHeight: isList}">
          <app-select-search *ngIf="workflows$ | asyncSchedule | async"
                             [items]="workflows$ | asyncSchedule | async"
                             [ctrl]="workflowsCtrl"
                             [multiple]="false"
                             [matTooltip]="'tooltips.switchBetweenWorkflows' | translate"
                             matTooltipPosition="below"
                             matTooltipClass="text-center"
                             class="workflow-detail-header-select"></app-select-search>
        </div>
      </div>
      <div *ngIf="!isList" class="col-sm-8 col-sm-offset-2 workflow-holder">
        <!--<app-group-view *ngIf="triggers" [triggers]="triggers"></app-group-view>-->
        <app-workflow-detail-trigger *ngFor="let trigger of triggers;let isOdd = odd;"
                                     [type]="type"
                                     [workflowName]="workflow.name"
                                     [trigger]="trigger"
                                     [isOdd]="isOdd"
                                     (open)="onOpen($event)"></app-workflow-detail-trigger>
      </div>
      <div *ngIf="isList" class="content-holder">
        <app-trigger-table [mainTable]="false"
                           [data]="tableData$"
                           [hasTags]="false"
                           [hasSelect]="false"
                           [tableType]="tableType"
                           (remove)="confirmRemove($event)"
                           (duplicate)="onDuplicate($event)"
                           (edit)="onEdit($event)" (pause)="onPause($event)" (resume)="onResume($event)"
                           (archive)="onArchive($event)"
                           (unarchive)="onUnArchive($event)" class="mt15"></app-trigger-table>
      </div>
    </div>
  </div>
</div>
