import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '@automata/reducers';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SaveForm} from '@automata/decorators/save.decorator';
import {Workflow} from '@automata/models/workflow';

@Component({
  selector:    'app-existing-triggers-dialog',
  templateUrl: './existing-triggers-dialog.component.html',
  styleUrls:   ['./existing-triggers-dialog.component.scss']
})
export class ExistingTriggersDialogComponent {

  triggers$ = this.store.pipe(select(fromRoot.selectTriggersWithoutWorkflow(this.data.workflow)));

  form = this.fb.group({
    triggers: [[], [Validators.required]]
  });

  constructor(private fb: FormBuilder,
              public dialogRef: MatDialogRef<ExistingTriggersDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {workflow: Workflow},
              private store: Store<fromRoot.State>) {
  }

  @SaveForm()
  save(form: FormGroup) {
    const value = form.getRawValue();
    this.dialogRef.close(value.triggers);
  }

  close() {
    this.dialogRef.close();
  }
}
