<div class="main-body clearfix">
  <div class="container-fluid">
    <!-- Title & buttons -->
    <div class="row title-holder">
      <div class="col-sm-3 title">
        {{'pageTitle.workflows' | translate}}
        <span [matTooltip]="'pageTooltip.workflows' | translate"
              matTooltipPosition="above"
              matTooltipClass="text-center">
                    <i class="far info-icon fa-question-circle"></i>
                </span>
        <app-tutorial-opener [type]="'workflows'"></app-tutorial-opener>
      </div>
      <div class="col-sm-6">
        <app-inactive-subscription></app-inactive-subscription>
      </div>
      <div class="col-sm-3 btn-holder">
        <div dropdown class="btn-group">
          <button dropdownToggle type="button"
                  class="btn btn-cstm-info left-icon text-uppercase dropdown-toggle">
            <span class="far fa-plus"></span> {{'buttons.new' | translate}}
          </button>
          <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
            <li role="menuitem">
              <a [matTooltip]="'tooltips.workflowSample' | translate"
                 matTooltipPosition="left"
                 matTooltipClass="text-center" class="dropdown-item" (click)="openSamples()">
                {{'buttons.startWithSample' | translate}}
              </a>
              <a *ngIf="(triggers$ | asyncSchedule | async)?.length"
                 [matTooltip]="'tooltips.workflowScratch' | translate"
                 matTooltipPosition="left"
                 matTooltipClass="text-center" class="dropdown-item" (click)="openBlank()">
                {{'buttons.startWithBlank' | translate}}
              </a>
              <div *ngIf="!(triggers$ | asyncSchedule | async)?.length"
                   [matTooltip]="'tooltips.workflowScratchWarning' | translate"
                   matTooltipPosition="left"
                   matTooltipClass="text-center" class="di">
                                <span class="dropdown-item disabled">
                                    {{'buttons.startWithBlank' | translate}}
                                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Content Holder -->
    <div class="content-holder clearfix">
      <!-- input fields -->
      <div class="clearfix workflows-page input-holder-top">
        <div class="col-sm-9">
          <div class="row">
            <div class="col-sm-2">
              <app-select-search *ngIf="rentals$ | async"
                                 [matTooltip]="'tooltips.workflowRentalFilter' | translate"
                                 matTooltipPosition="above"
                                 matTooltipClass="text-center"
                                 [items]="rentals$ | async"
                                 [ctrl]="rentalCtrl"
                                 [placeholder]="'formControls.rentals' | translate"></app-select-search>
            </div>
          </div>
        </div>
        <div class="col-sm-3 edit-align">
          <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)"
                   [placeholder]="'formControls.searchHere' | translate"
                   type="search">
            <span matPrefix>
              <i class="far fa-search"></i>
            </span>
          </mat-form-field>
        </div>
      </div>

      <!-- content tables -->
      <mat-table #table matSort matSortActive="lastUpdated" matSortDirection="desc" [dataSource]="dataSource"
                 class="withoutSelect">

        <ng-container matColumnDef="rentalName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.rental' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.rentalName}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.name' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="triggers">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.triggers' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element">
                        <span [matTooltip]="element.triggers | listOfTriggers | truncate:300"
                              matTooltipPosition="above"
                              matTooltipClass="text-center">{{element.triggers.length}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="templatesCount">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.templates' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element">
                        <span [matTooltip]="element.templateNames | listOfStrings | truncate:300"
                              matTooltipPosition="above"
                              matTooltipClass="text-center">{{element.templatesCount}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastUpdated">
          <mat-header-cell *matHeaderCellDef
                           mat-sort-header> {{'tableColumns.lastTriggered' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.lastUpdatedFormatted}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.status' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-slide-toggle (click)="$event.stopPropagation()" [id]="element.id"
                              [matTooltip]="element.status | workflowStatusTooltip"
                              matTooltipPosition="above"
                              matTooltipClass="text-center"
                              [checked]="element.status === 1"
                              (change)="onStatusChange(element)"></mat-slide-toggle>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef>
            <app-column-edit-control (select)="tableManagement()"></app-column-edit-control>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div dropdown container="body">
              <div dropdownToggle (click)="$event.stopPropagation()" class="text-center elipse-btn">
                <i class="far fa-ellipsis-v-alt"></i>
              </div>
              <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                <li role="menuitem">
                  <button (click)="onStatusChange(element)"
                          class="dropdown-item">
                    <span *ngIf="element.status === 1">{{'buttons.pause' | translate}}</span>
                    <span *ngIf="element.status === 0">{{'buttons.resume' | translate}}</span>
                  </button>
                  <button (click)="onDuplicate(element)" class="dropdown-item">
                    {{'buttons.duplicate' | translate}}
                  </button>
                  <button (click)="onDetails(element)" class="dropdown-item">
                    {{'buttons.edit' | translate}}
                  </button>
                  <button (click)="onRename(element)" class="dropdown-item">
                    {{'buttons.rename' | translate}}
                  </button>
                  <button (click)="confirmRemove(element)" class="dropdown-item">
                    {{'buttons.delete' | translate}}
                  </button>
                </li>
              </ul>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onDetails(row)"></mat-row>
      </mat-table>

      <app-translated-pagination>
        <mat-paginator #paginator [ngClass]="{hidden: (isEmptyTable$ | async) && isLoaded}"
                       [length]="dataSource?.data?.length"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20, 50, 75, 100]" [showFirstLastButtons]="true">
        </mat-paginator>
      </app-translated-pagination>

      <app-empty-table *ngIf="(isEmptyTable$ | async) && isLoaded" [type]="tableType"></app-empty-table>

    </div>
  </div>
</div>
