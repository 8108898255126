<div *ngIf="data.length" class="chart-dsc-top clearfix">
  <ul class="list list-inline">
    <li *ngFor="let type of types">
      <span [ngStyle]="{'background-color': type.color}" class="status-o"></span>
      {{type.i18n | translate}}
    </li>
  </ul>
</div>
<div class="chart clearfix">

  <div *ngIf="!data.length" class="empty-chart">
    <i class="far fa-chart-bar"></i>
    <p>{{'pageElements.chartPlaceholder' | translate}}</p>
  </div>

  <ngx-charts-bar-vertical-stacked
    *ngIf="data.length"
    [customColors]="customColors"
    [results]="data"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [barPadding]="62">
  </ngx-charts-bar-vertical-stacked>

</div>
