import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {ActivityTypes} from '../../../models/activity-types';
import {combineLatest, Subscription} from 'rxjs';
import {delay, map, startWith, tap} from 'rxjs/operators';
import * as R from 'ramda';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector:    'app-activity-chart',
  templateUrl: './activity-chart.component.html',
  styleUrls:   ['./activity-chart.component.scss']
})
export class ActivityChartComponent implements OnInit, OnDestroy {

  data = [];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;

  @HostBinding('class.chart-holder') chartHolder = true;

  types = ActivityTypes.types;

  recentSub: Subscription;

  constructor(private store: Store<fromRoot.State>,
              private translateService: TranslateService) {

  }

  customColors(name: string) {
    return ActivityTypes.getColorByName(name);
  }

  ngOnInit() {

    this.recentSub = combineLatest(
      this.translateService.onLangChange.pipe(startWith(null)),
      this.store.pipe(select(fromRoot.selectRecentActivity))
    )
      .pipe(
        tap(() => this.data = []),
        delay(50),
        map(([lang, activities]) => R.map(a => {
          a.series = R.map(s => {
            s.name = this.translateService.instant(s.i18n);
            return s;
          }, a.series);
          return a;
        }, activities))
      )
      .subscribe(activities => {
        this.data = <any>activities;
      });
  }

  ngOnDestroy() {
    this.recentSub.unsubscribe();
  }
}
