import {Injectable} from '@angular/core';
import {Integration} from '../enums/integration';
import {IntegrationInfo} from '../interfaces/integration-info';

@Injectable()
export class IntegrationsService {

  constructor() {
  }

  getIntegrationMessage(integration: Integration) {
    switch (integration) {
      case Integration.Tokeet:
        return {
          title:    'Automata has a native integration with the Tokeet channel manager. This integration cannot be disconnected.',
          linkText: 'Tokeet',
          link:     'http://tokeet.com/'
        } as IntegrationInfo;
      case Integration.Signature:
        return {
          title:    'Automata has a native integration with the Signature application. This integration cannot be disconnected.',
          linkText: 'Signature',
          link:     'https://www.usesignature.com'
        } as IntegrationInfo;
      case Integration.Webhooks:
        return {
          title: 'Automata has native support for webhooks. You may integrate with third-party APIs without the need for coding by simply using Automata triggers.'
        } as IntegrationInfo;
      case Integration.Email:
        return {
          title: 'Automata has native support for email messaging. We handle all the heavy lifting to ensure your messages get to your guests on time.'
        } as IntegrationInfo;
      case Integration.SMS:
        return {
          title: 'Automata has native support for SMS messaging globally. We\'ll use our own phone numbers and pay the cost of sending the message. Automata even accept replies from your guests and saves it to the guest message thread.'
        } as IntegrationInfo;
    }
  }
}
