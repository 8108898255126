import {Pipe, PipeTransform} from '@angular/core';
import {TriggerHandler} from '../../enums/trigger-handler';

@Pipe({
  name: 'isSignatureHandler'
})
export class IsSignatureHandlerPipe implements PipeTransform {

  transform(handler: string): boolean {
    return handler === TriggerHandler.SendContract ||
      handler === TriggerHandler.SendDocument ||
      handler === TriggerHandler.SendForm;
  }

}
