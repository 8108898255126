import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as R from 'ramda';
import {filter, map} from 'rxjs/operators';
import {AuthService} from '../../../services/auth.service';
import {AccountService} from '../../../services/account.service';
import {Subscription} from 'rxjs';

declare const window: any;

@Component({
  selector:    'app-ifttt-integration-dialog',
  templateUrl: './ifttt-integration-dialog.component.html',
  styleUrls:   ['./ifttt-integration-dialog.component.scss']
})
export class IftttIntegrationDialogComponent implements OnInit, OnDestroy {

  iftttUrl = 'https://ifttt.com/tokeet';

  hasIFTTT = true;

  accountSub: Subscription;

  constructor(private store: Store<fromRoot.State>,
              private accountService: AccountService,
              private auth: AuthService) {

  }

  ngOnInit() {
    this.accountSub = this.store
      .select(fromRoot.selectAccountById(this.auth.getUser().account))
      .pipe(
        filter(account => !R.isNil(account)),
        map(account => this.accountService.hasIFTTTApiKey(account.apiKeys))
      )
      .subscribe(hasIFTTT => {
        this.hasIFTTT = hasIFTTT;
      });
  }

  ngOnDestroy() {
    this.accountSub.unsubscribe();
  }

  goToIFTTT() {
    window.location.href = this.iftttUrl;
  }

}
