import {TriggerHandler} from '../enums/trigger-handler';
import {TemplateType} from '../enums/template-type';

export class HandlerTemplateMap {
  static templateType = {
    [TriggerHandler.SendHTTPPost]:         TemplateType.Payload,
    [TriggerHandler.SendSMSMessage]:       TemplateType.PushNotification,
    [TriggerHandler.SendPushNotification]: TemplateType.PushNotification,
    [TriggerHandler.SendEmailMessage]:     TemplateType.Email,
    [TriggerHandler.SendFollowUp]:         TemplateType.Email
  };
}
