import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

declare const window: any;

@Component({
  selector:    'app-intercom-widget',
  templateUrl: './intercom-widget.component.html',
  styleUrls:   ['./intercom-widget.component.scss']
})
export class IntercomWidgetComponent implements OnInit {

  public open: boolean = false;
  public spin: boolean = true;
  public direction: string = 'up';
  public animationMode: string = 'fling';

  constructor() {
  }

  ngOnInit() {
  }

  goToHelp() {
    window.open('http://help.tokeet.com');
  }

  goToScheduleTraining() {
    window.open('https://calendly.com/tokeet');
  }

  goToPortal() {
    window.open('https://portal.productboard.com/automata-portal/2-product-portal');
  }

  openChat() {
    if (environment.environment === 'production') {
      window.Intercom('show');
    }
    this.open = false;
  }
}
