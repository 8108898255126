import {Injectable} from '@angular/core';

import {Storage} from '../utility/storage';

export const PREFIX = 'automata';

@Injectable({providedIn: 'root'})
export class LocalStorage extends Storage {
  constructor() {
    super(localStorage, PREFIX);
  }
}
