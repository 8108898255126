import {Pipe, PipeTransform} from '@angular/core';
import {TriggerEvents} from '../models/trigger-events';

@Pipe({
  name: 'isTriggerSkippable'
})
export class IsTriggerSkippablePipe implements PipeTransform {

  transform(event: string): boolean {
    return TriggerEvents.isSkippable(event);
  }

}
