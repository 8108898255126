import {createAction, props} from '@ngrx/store';
import {Trigger} from '@automata/models/trigger';
import {TriggerSaveRequest} from '@automata/interfaces/trigger/trigger-save-request';
import {TriggersCreateRequest} from '@automata/interfaces/trigger/triggers-create-request';
import {TriggerCreateRequest} from '@automata/interfaces/trigger/trigger-create-request';
import {TriggerConditionCreate} from '@automata/interfaces/trigger/trigger-condition-create';
import {TriggerConditionCreateComplete} from '@automata/interfaces/trigger/trigger-condition-create-complete';
import {TriggerConditionDeleteRequest} from '@automata/interfaces/trigger/trigger-condition-delete-request';
import {TriggerConditionSave} from '@automata/interfaces/trigger/trigger-condition-save';
import {TriggerConditionSaveComplete} from '@automata/interfaces/trigger/trigger-condition-save-complete';
import {InquirySkipRequest} from '@automata/interfaces/inquiry/inquiry-skip-request';
import {InquirySkipResponse} from '@automata/interfaces/inquiry/inquiry-skip-response';
import {TriggerSkipsResponse} from '@automata/interfaces/trigger/trigger-skips-response';
import {AddWorkflowsParams} from '@automata/interfaces/add-workflows-params';
import {RemoveFromWorkflowRequest} from '@automata/interfaces/remove-from-workflow-request';

export const LoadAllTriggers = createAction('[Triggers] Load All');
export const LoadAllTriggersComplete = createAction('[Triggers] Load All Complete', props<{automata: Trigger[], legacy: Trigger[]}>());

export const OpenTrigger = createAction('[Triggers] Open Start', props<{id: string}>());
export const OpenTriggerComplete = createAction('[Triggers] Open Complete');

export const AddWorkflowsToTriggers = createAction('[Triggers] Add Workflows To Triggers Start', props<{params: AddWorkflowsParams}>());
export const AddWorkflowsToTriggersComplete = createAction('[Triggers] Add Workflows To Triggers Complete', props<{triggers: Trigger[]}>());

export const LoadTriggers = createAction('[Triggers] Load Start');
export const LoadTriggersComplete = createAction('[Triggers] Load Complete', props<{automata: Trigger[], legacy: Trigger[]}>());

export const LoadTriggerSkippedBookings = createAction('[Triggers] Load Skipped Bookings Start', props<{ids: string[]}>());
export const LoadTriggerSkippedBookingsComplete = createAction('[Triggers] Load Skipped Bookings Complete', props<{responses: TriggerSkipsResponse[]}>());

export const LoadTriggerDetails = createAction('[Triggers] Load Details Start', props<{trigger: Trigger}>());
export const LoadTriggerDetailsComplete = createAction('[Triggers] Load Details Complete', props<{trigger: Trigger}>());

export const PauseTrigger = createAction('[Triggers] Pause Start', props<{id: string}>());
export const PauseTriggerComplete = createAction('[Triggers] Pause Complete', props<{trigger: Trigger}>());

export const PauseTriggers = createAction('[Triggers] Pause Multiple Start', props<{triggers: Trigger[]}>());
export const PauseTriggersComplete = createAction('[Triggers] Pause Multiple Complete', props<{triggers: Trigger[]}>());

export const ResumeTriggers = createAction('[Triggers] Resume Multiple Start', props<{triggers: Trigger[]}>());
export const ResumeTriggersComplete = createAction('[Triggers] Resume Multiple Complete', props<{triggers: Trigger[]}>());

export const ArchiveTrigger = createAction('[Triggers] Archive Start', props<{id: string}>());
export const ArchiveTriggerComplete = createAction('[Triggers] Archive Complete', props<{trigger: Trigger}>());

export const UnArchiveTrigger = createAction('[Triggers] UnArchive Start', props<{id: string}>());
export const UnArchiveTriggerComplete = createAction('[Triggers] UnArchive Complete', props<{trigger: Trigger}>());

export const DeleteTrigger = createAction('[Triggers] Delete Start', props<{id: string}>());
export const DeleteTriggerComplete = createAction('[Triggers] Delete Complete', props<{id: string}>());

export const DeleteBatchTrigger = createAction('[Triggers] Delete Batch Start', props<{ids: string[], silent?: boolean}>());
export const DeleteBatchTriggerComplete = createAction('[Triggers] Delete Batch Complete', props<{ids: string[]}>());

export const ResumeTrigger = createAction('[Triggers] Resume Start', props<{id: string}>());
export const ResumeTriggerComplete = createAction('[Triggers] Resume Complete', props<{trigger: Trigger}>());

export const SaveTrigger = createAction('[Triggers] Save Start', props<{request: TriggerSaveRequest}>());
export const SaveTriggerComplete = createAction('[Triggers] Save Complete', props<{trigger: Trigger}>());

export const UpdateTriggersComplete = createAction('[Triggers] Update Complete', props<{triggers: Trigger[]}>());

export const CreateTriggers = createAction('[Triggers] Create Start', props<{request: TriggersCreateRequest}>());
export const CreateTriggersComplete = createAction('[Triggers] Create Complete', props<{triggers: Trigger[]}>());

export const CreateTrigger = createAction('[Trigger] Create Start', props<{request: TriggerCreateRequest}>());
export const CreateTriggerComplete = createAction('[Trigger] Create Complete', props<{trigger: Trigger}>());

export const SaveCondition = createAction('[Trigger] Save Condition Start', props<{request: TriggerConditionSave}>());
export const SaveConditionComplete = createAction('[Trigger] Save Condition Complete', props<{response: TriggerConditionSaveComplete}>());

export const CreateCondition = createAction('[Trigger] Create Condition Start', props<{request: TriggerConditionCreate}>());
export const CreateConditionComplete = createAction('[Trigger] Create Condition Complete', props<{response: TriggerConditionCreateComplete}>());

export const DeleteCondition = createAction('[Trigger] Delete Condition Start', props<{request: TriggerConditionDeleteRequest}>());
export const DeleteConditionComplete = createAction('[Trigger] Delete Condition Complete', props<{response: TriggerConditionDeleteRequest}>());

export const TriggerSkipInquiry = createAction('[Trigger] Skip Start', props<{request: InquirySkipRequest}>());
export const TriggerSkipInquiryComplete = createAction('[Trigger] Skip Complete', props<{response: InquirySkipResponse}>());

export const TriggerUnSkipInquiry = createAction('[Trigger] UnSkip Start', props<{request: InquirySkipRequest}>());
export const TriggerUnSkipInquiryComplete = createAction('[Trigger] UnSkip Complete', props<{response: InquirySkipResponse}>());

export const RemoveFromWorkflow = createAction('[Trigger] Remove From Workflow Start', props<{request: RemoveFromWorkflowRequest}>());
export const RemoveFromWorkflowComplete = createAction('[Trigger] Remove From Workflow Complete', props<{trigger: Trigger}>());
