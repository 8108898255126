import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, Validators} from '@angular/forms';
import {CodeDialogParams} from '../../../interfaces/code/code-dialog-params';
import * as fromRoot from '../../../reducers';
import {select, Store} from '@ngrx/store';
import {Code} from '../../../models/code';
import {isSomething} from '../../../utility/functions/is-something';
import {DialogService} from '../../../services/dialog.service';
import {TagsType} from '../../../enums/tags-type';
import {combineLatest, Observable} from 'rxjs';
import {TriggersService} from '../../../services/triggers.service';
import {validateAlphaNum} from '../../../validators/alpha-num.validator';
import {CodeAddRequest} from '../../../interfaces/code/code-add-request';
import {CodeUpdateRequest} from '../../../interfaces/code/code-update-request';
import {AuthService} from '../../../services/auth.service';
import {distinctUntilChanged, map, startWith} from 'rxjs/operators';
import * as R from 'ramda';
import {getTags} from '../../../utility/functions/get-tags';
import {isEmptyTrimmed} from '../../../validators/is-empty-trimmed.validator';
import {safeLowerCase} from '@automata/utility/functions/safe-lowercase';
import {AddCode, UpdateCode} from '../../../actions/codes.actions';
import {notAllNumbersValidator} from '@automata/validators/not-all-numbers.validator';
import {AmplitudeService} from '@automata/services/amplitude.service';

@Component({
  selector:    'app-code-dialog',
  templateUrl: './code-dialog.component.html',
  styleUrls:   ['./code-dialog.component.scss']
})
export class CodeDialogComponent implements OnInit {

  code: Code;
  tags: string[] = [];

  form = this.fb.group({
    name:        ['', [Validators.required, Validators.maxLength(15), validateAlphaNum(), notAllNumbersValidator]],
    rentals:     [''],
    description: ['', [Validators.maxLength(140)]],
    body:        ['', [Validators.required, isEmptyTrimmed()]]
  });

  rentals$ = this.store.pipe(
    select(fromRoot.selectRentalsForCode(this.data.code)),
    distinctUntilChanged()
  );

  isExisting$: Observable<boolean>;

  constructor(public dialogRef: MatDialogRef<CodeDialogComponent>,
              private fb: FormBuilder,
              private auth: AuthService,
              private store: Store<fromRoot.State>,
              private triggersService: TriggersService,
              private dialogService: DialogService,
              private amplitudeService: AmplitudeService,
              @Inject(MAT_DIALOG_DATA) public data: CodeDialogParams) {
  }

  ngOnInit() {
    this.code = this.data.code;

    this.tags = getTags(this.code);

    if (this.isEdit()) {
      this.form.setValue({
        name:        this.code.name || '',
        rentals:     this.code.rentals || '',
        body:        this.code.body || '',
        description: this.code.description || ''
      });
    }

    this.isExisting$ = combineLatest(
      this.form.get('name').valueChanges.pipe(startWith('')),
      this.form.get('rentals').valueChanges.pipe(startWith([])),
      this.store.pipe(select(fromRoot.selectAllCodes))
    )
      .pipe(
        map(data => {
          let [name, rentals, codes] = data;

          if (
            this.isEdit() &&
            R.equals(safeLowerCase(name), safeLowerCase(this.code.name)) &&
            R.difference(<string[]>rentals, this.code.rentals).length === 0 &&
            R.difference(this.code.rentals, <string[]>rentals).length === 0
          ) {
            return false;
          }

          const sameCodes: any = R.filter((c: Code) => safeLowerCase(c.name) === safeLowerCase(name), codes);

          const filtered: any = R.filter((c: Code) => {
            return (R.isEmpty(c.rentals) && R.isEmpty(rentals)) || R.any(R.equals(true), <any>R.map(r => R.contains(r, <any>c.rentals), <any>rentals));
          }, sameCodes);

          return filtered.length > 0;
        })
      );
  }

  isEdit() {
    return isSomething(this.code.id);
  }

  addTags() {
    this.dialogService.openTags(this.tags, TagsType.Code)
      .subscribe((result) => {
        this.tags = result.tags;
        if (this.isEdit()) {
          this.onSave(false);
        }
      });
  }

  onSave(closeDialog = true) {

    const user = this.auth.getUser();

    const request = {
      ...this.form.getRawValue(),
      pkey:       this.code.id,
      account:    user.account,
      creator:    user.id,
      attributes: {
        tags: this.tags
      }
    };

    if (this.isEdit()) {
      this.store.dispatch(UpdateCode({request: request as CodeUpdateRequest}));
    } else {
      this.amplitudeService.logEvent('custom-code-complete')
      this.store.dispatch(AddCode({request: request as CodeAddRequest}));
    }
    if (closeDialog) {
      this.close();
    }
  }

  close() {
    this.dialogRef.close();
  }
}
