import {Component} from '@angular/core';
import {Toast, ToastPackage, ToastrService} from 'ngx-toastr';
import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';
import {ToastIconClasses} from '../../utility/models/toast-icon-classes';

@Component({
  selector:            '[automata-toast]',
  template:            `
        <div class="alert alert-cstm alert-cstm-warning alert-dismissible" role="alert">
            <button (click)="remove()" type="button" class="close" data-dismiss="alert" aria-label="Close">
                <i class="far fa-times" aria-hidden="true"></i>
            </button>
            <div class="row">
                <div class="alert-icon">
                    <i *ngIf="options.toastClass===ToastIconClasses.Success" class="fal fa-check-circle"></i>
                    <i *ngIf="options.toastClass===ToastIconClasses.Info" class="fal fa-info-circle"></i>
                    <i *ngIf="options.toastClass===ToastIconClasses.Error" class="fal fa-times-circle"></i>
                    <i *ngIf="options.toastClass===ToastIconClasses.Warning" class="fal fa-exclamation-circle"></i>
                </div>
                <div class="alert-content">
                    <h4>
                        {{ title }}
                    </h4>
                    <p>
                        {{ message }}
                    </p>
                </div>
            </div>
        </div>
    `,
  animations:          [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 0
      })),
      transition('inactive => active', animate('300ms ease-out', keyframes([
        style({
          opacity: 0
        }),
        style({
          opacity: 0.8
        }),
        style({
          opacity: 1
        })
      ]))),
      transition('active => removed', animate('300ms ease-out', keyframes([
        style({
          opacity: 0.6,
          bottom:  0
        }),
        style({
          opacity: 0.1
        }),
        style({
          opacity: 0
        })
      ])))
    ])
  ],
  preserveWhitespaces: false
})
export class AutomataToastComponent extends Toast {

  ToastIconClasses = ToastIconClasses;

  constructor(protected toastrService: ToastrService,
              public toastPackage: ToastPackage) {

    super(toastrService, toastPackage);
  }

  action(event: any) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }

}
