import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {TriggerHandler} from '../enums/trigger-handler';
import * as R from 'ramda';
import {TriggerEvents} from '../models/trigger-events';

declare const require: any;

const startsWith = require('lodash/startsWith');

@Injectable()
export class TriggersHelperService {

  form: FormGroup;

  initForm(form: FormGroup) {
    this.form = form;
  }

  getName() {
    return this.form.get('name');
  }

  getDays() {
    return this.form.get('timeevent').get('days');
  }

  getHours() {
    return this.form.get('timeevent').get('hours');
  }

  getTimeEvent() {
    return this.form.get('timeevent').get('event');
  }

  isPostHandler() {
    const handler = this.form.get('handler').value;
    return handler === TriggerHandler.SendHTTPPost;
  }

  isPushOrPostHandler() {
    const handler = this.form.get('handler').value;
    return handler === TriggerHandler.SendPushNotification || handler === TriggerHandler.SendHTTPPost;
  }

  isSignatureHandler() {
    const handler = this.form.get('handler').value;
    return handler === TriggerHandler.SendContract ||
      handler === TriggerHandler.SendDocument ||
      handler === TriggerHandler.SendForm;
  }

  canAddConditions() {
    return this.isInquiry() || this.isInvoice() || this.isTime() || this.isCard();
  }

  allowsChannel() {
    return TriggerEvents.allowsChannel(this.form.get('event').value);
  }

  isInquiry() {
    return TriggerEvents.isInquiry(this.form.get('event').value);
  }

  isInvoice() {
    return TriggerEvents.isInvoice(this.form.get('event').value);
  }

  isTime() {
    return TriggerEvents.isTime(this.form.get('event').value);
  }

  isCard() {
    return TriggerEvents.isCard(this.form.get('event').value);
  }

  isSystem() {
    return startsWith(this.form.get('event').value, 'system');
  }

  isHold() {
    return startsWith(this.form.get('event').value, 'event');
  }

  isRate() {
    return startsWith(this.form.get('event').value, 'rate');
  }

  isGuest() {
    return startsWith(this.form.get('event').value, 'guest') || startsWith(this.form.get('event').value, 'message.create');
  }

  allowsRental() {
    return TriggerEvents.allowsRental(this.form.get('event').value);
  }

  isExpense() {
    return TriggerEvents.isExpense(this.form.get('event').value);
  }

  isFollowUpHandler() {
    const handler = this.form.get('handler').value;
    return handler === TriggerHandler.SendFollowUp || handler === TriggerHandler.RemoveSendFollowUp;
  }

  isBookingTagHandler() {
    const handler = this.form.get('handler').value;
    return handler === TriggerHandler.AddTagHandler || handler === TriggerHandler.RemoveTagHandler;
  }

  isFormOrDocument() {
    const handler = this.form.get('handler').value;
    return handler === TriggerHandler.SendDocument || handler === TriggerHandler.SendForm;
  }

  isContract() {
    const handler = this.form.get('handler').value;
    return handler === TriggerHandler.SendContract;
  }

  hasNoGuestOption() {
    return this.isSystem() ||
      this.isHold() ||
      this.isGuest() ||
      this.isRate() ||
      this.isPushOrPostHandler() ||
      this.isFollowUpHandler() ||
      this.isBookingTagHandler() ||
      this.isExpense() ||
      this.isCard();
  }

  hasNoGuestAndRecipientsOption() {
    return this.isFollowUpHandler() || this.isBookingTagHandler() || this.isPostHandler();
  }

  hasGuestOption() {
    return !this.hasNoGuestOption();
  }

  addGuest(form: any) {
    let recipients = R.clone(form.recipients);
    if (form.guest) {
      if (recipients) {
        if (R.is(Array, recipients)) {
          recipients = R.append('111', recipients);
        } else {
          recipients = [recipients, '111'];
        }
      } else {
        recipients = ['111'];
      }
    }
    return recipients;
  }

  addPostUrl(form: any) {
    let recipients = R.clone(form.recipients);
    if (form.handler === TriggerHandler.SendHTTPPost) {
      recipients = [form.postUrl];
    }
    return recipients;
  }

}
