import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import * as R from 'ramda';

export interface PlanSubscription {
  product: string
  status: string
}

@Injectable()
export class PlanService {

  constructor(private http: HttpClient) {
  }

  isAutomataActive() {
    const url = '@api/subscribe/all';

    return this.http.get<{subscriptions: PlanSubscription[], card: any}>(url)
      .pipe(
        map(response => !!R.find(p => p.product === 'automata' && (p.status === 'active' || p.status === 'trialing'), response.subscriptions))
      );
  }

  all() {
    const url = '@api/subscribe/all';

    return this.http.get<{subscriptions: PlanSubscription[], card: any;}>(url)
      .pipe(
        map(response => response.subscriptions)
      );
  }
}
