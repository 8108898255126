import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {ToastIconClasses} from '../utility/models/toast-icon-classes';
import {LocalStorageKeys} from '../models/local-storage-keys';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup, ValidationErrors} from '@angular/forms';
import * as R from 'ramda';

declare const document: any;
declare const require: any;

const tippy = require('tippy.js');

@Injectable()
export class UtilityService {

  tooltips = {
    'bold':            'Bold (Ctrl+B)',
    'italic':          'Italic (Ctrl+I)',
    'underline':       'Underline (Ctrl+U)',
    'strike':          'Strikethrough',
    'ordered':         'Numbered List',
    'bullet':          'Bulleted List',
    'indent-decrease': 'Decrease Indent',
    'indent-increase': 'Increase Indent',
    'direction':       'Text Direction',
    'clean':           'Clear Formatting',
    'undo':            'Undo',
    'redo':            'Redo',
    'dictionary':      'Data Dictionary',
    'attachment':      'Attach Files',
    'link':            'Insert Link',
    'image':           'Insert Image',
    'imageAttachment': 'Insert Image',
    'align':           'Align Text',
    'header':          'Styles'
  };

  constructor(private router: Router,
              private toastr: ToastrService,
              public dialog: MatDialog) {
  }

  public isRouteActive(route: string): boolean {
    return this.router.isActive(route, false);
  }

  public addClass(element: string, className: string): void {
    document.getElementsByTagName(element)[0].classList.add(className);
  }

  public hasClass(element: string, className: string): boolean {
    return document.getElementsByTagName(element)[0].classList.contains(className);
  }

  public removeClass(element: string, className: string): void {
    if (this.hasClass(element, className)) {
      document.getElementsByTagName(element)[0].classList.remove(className);
    }
  }

  public showToastAfterRefresh(message: string, title: string, icon: ToastIconClasses) {
    localStorage.setItem(LocalStorageKeys.TOAST, JSON.stringify({
      message: message,
      title:   title,
      icon:    icon
    }));
  }

  public showPendingToasts() {
    try {
      let existingToast = JSON.parse(localStorage.getItem(LocalStorageKeys.TOAST));
      if (!R.isNil(existingToast)) {
        setTimeout(() => {
          this.toastr.show(
            existingToast.message,
            existingToast.title, {
              toastClass: existingToast.icon
            });
        }, 0);
      }
      localStorage.removeItem(LocalStorageKeys.TOAST);
    } catch (e) {
      localStorage.removeItem(LocalStorageKeys.TOAST);
    }
  }

  public isRequired(control: any) {
    let errors: any = control.validator && (<any>control).validator(new FormControl());
    return errors !== null && errors.required;
  }

  showTippyTooltipsOnTemplateEditor() {
    let showTooltip = (el, tool) => {
      if (tool === 'indent') {
        if (el.value === '-1') {
          tool = 'indent-decrease';
        } else if (el.value === '+1') {
          tool = 'indent-increase';
        }
      }
      if (tool === 'list') {
        tool = el.value;
      }
      if (this.tooltips[tool]) {
        el.title = this.tooltips[tool];
        setTimeout(() => {
          tippy(el);
        }, 50);
      }
    };

    let toolbarElement = document.querySelector('.ql-toolbar');
    if (toolbarElement) {
      let buttonMatched = toolbarElement.querySelectorAll('button');
      for (let el of buttonMatched) {
        let tool = el.className.replace('ql-', '');
        showTooltip(el, tool);
      }
      let spanAligns = toolbarElement.querySelectorAll('span.ql-align');
      let spanHeaders = toolbarElement.querySelectorAll('span.ql-header');
      for (let el of spanAligns) {
        showTooltip(el, 'align');
      }
      for (let el of spanHeaders) {
        showTooltip(el, 'header');
      }
    }
  }

  getFormValidationErrors(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {

      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log(`Control: ${key}; Error: ${keyError}`);
        });
      }
    });
  }
}
