import {LanguageNamePipe} from '@automata/pipes/language-name.pipe';

declare const require: any;

const uuid = require('uuid/v4');

export class TemplateTranslation {
  id = '';
  language = '';
  languageName = '';
  text = '';
  subject = '';

  constructor(params?: Partial<TemplateTranslation>) {
    this.id = uuid();
    if (params) {
      if (params.id) {
        this.id = params.id;
      }
      this.language = params.language || '';
      this.languageName = new LanguageNamePipe().transform(this.language);
      this.text = params.text || '';
      this.subject = params.subject || '';
    }
  }
}
