import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {Observable, of, Subscription} from 'rxjs';
import * as R from 'ramda';
import {Trigger} from '../../../models/trigger';
import {TableUtilityService} from '../../../services/table-utility.service';
import {TableType} from '../../../enums/table-type';
import {DialogService} from '../../../services/dialog.service';
import {delay} from 'rxjs/operators';
import {localeCompareSort} from '@automata/utility/functions/locale-compare-sort';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector:    'app-trigger-table',
  templateUrl: './trigger-table.component.html',
  styleUrls:   ['./trigger-table.component.scss']
})
export class TriggerTableComponent implements OnInit, OnDestroy {

  @Input() data: Observable<Trigger[]>;
  @Input() filter: Observable<string> = of('');
  @Input() hasSelect = true;
  @Input() hasTemplate = true;
  @Input() hasTags = true;
  @Input() mainTable = true;

  @Output() remove: EventEmitter<Trigger> = new EventEmitter<Trigger>();
  @Output() duplicate: EventEmitter<Trigger> = new EventEmitter<Trigger>();
  @Output() edit: EventEmitter<Trigger> = new EventEmitter<Trigger>();
  @Output() archive: EventEmitter<Trigger> = new EventEmitter<Trigger>();
  @Output() unarchive: EventEmitter<Trigger> = new EventEmitter<Trigger>();
  @Output() page: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output() select: EventEmitter<any> = new EventEmitter<any>();

  @Output() pause: EventEmitter<Trigger> = new EventEmitter<Trigger>();
  @Output() resume: EventEmitter<Trigger> = new EventEmitter<Trigger>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @Input() tableType? = TableType.Triggers;

  displayedColumns: string[];

  dataSource = new MatTableDataSource<Trigger>();
  selection = new SelectionModel<Trigger>(true, []);

  dataSub: Subscription;
  filterSub: Subscription;
  paginatorSub: Subscription;
  selectionSub: Subscription;

  isEmptyTable = false;
  emptyTableSub: Subscription;

  isLoaded = false;

  renderedData: any[];

  constructor(private tableService: TableUtilityService,
              private translate: TranslateService,
              private dialogService: DialogService) {

  }

  ngOnInit() {

    this.displayedColumns = this.tableService.getDisplayedColumns(this.tableType);

    if (!this.hasSelect) {
      this.displayedColumns = R.filter(c => c !== 'select', this.displayedColumns);
    }

    if (!this.hasTemplate) {
      this.displayedColumns = R.filter(c => c !== 'templateName', this.displayedColumns);
    }

    if (!this.hasTags) {
      this.displayedColumns = R.filter(c => c !== 'tagsFormatted', this.displayedColumns);
    }

    this.dataSource.data = [];
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortData = localeCompareSort;

    // material table has no exposed API for getting what's rendered so we access private property
    this.emptyTableSub = this.dataSource['_renderData'].subscribe((rows) => {
      this.renderedData = rows;
      this.isEmptyTable = rows.length === 0;
    });

    this.paginatorSub = this.paginator.page
      .subscribe(pageEvent => {
        this.page.emit(pageEvent);
      });

    this.dataSub = this.data
      .pipe(
        delay(10)
      )
      .subscribe((data) => {
        this.isLoaded = true;
        this.paginator.firstPage();
        this.dataSource.data = data;
        this.initSelection();
      });

    this.filterSub = this.filter
      .subscribe((term) => {
        this.paginator.firstPage();
        this.dataSource.filter = term.trim().toLowerCase();
      });
  }

  ngOnDestroy() {
    this.dataSub.unsubscribe();
    this.filterSub.unsubscribe();
    this.paginatorSub.unsubscribe();
    this.emptyTableSub.unsubscribe();
  }

  initSelection() {
    this.selection = new SelectionModel<Trigger>(true, []);
    if (this.selectionSub) {
      this.selectionSub.unsubscribe();
    }
    this.selectionSub = this.selection.changed
      .subscribe((event) => {
        this.page.emit({
          pageIndex: this.paginator.pageIndex,
          pageSize:  this.paginator.pageSize,
          length:    this.paginator.length
        } as PageEvent);
        this.select.emit(event.source);
      });
    this.select.emit(this.selection);
  }

  trackById(index: number, trigger: Trigger): string {
    return trigger.id;
  }

  isAllSelected() {
    return this.tableService.isAllSelected(this.selection, this.renderedData);
  }

  masterToggle() {
    this.tableService.masterToggle(this.selection, this.renderedData, this.dataSource);
  }

  onToggleArchive(trigger: Trigger) {
    if (trigger.archived === 1) {
      this.unarchive.emit(trigger);
    } else {
      this.archive.emit(trigger);
    }
  }

  onTogglePause(trigger: Trigger) {
    if (trigger.status === 1) {
      this.pause.emit(trigger);
    } else {
      this.resume.emit(trigger);
    }
  }

  tableManagement() {
    if (!this.mainTable) {
      return;
    }
    this.dialogService.openTableManagement(this.tableType)
      .subscribe(columns => {
        this.displayedColumns = columns;
      });
  }
}
