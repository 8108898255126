import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {from as fromPromise, Subscription} from 'rxjs';
import {TokeetService} from '../../services/tokeet.service';
import {switchMap} from 'rxjs/operators';
import {AccountService} from '../../services/account.service';
import {ApiKey} from '../../models/api-key';
import {AuthService} from '../../services/auth.service';
import {UtilityService} from '../../services/utility.service';
import {LoginForm} from '../../models/login-form';

declare const window: any;
declare const require: any;

const uuid = require('uuid/v4');

@Component({
  selector:    'app-authorize',
  templateUrl: './authorize.component.html',
  styleUrls:   ['./authorize.component.scss']
})
export class AuthorizeComponent implements OnInit, OnDestroy {

  isAuthenticated = true;
  secret: string;
  paramsSub: Subscription;
  authenticatedSub: Subscription;
  authSub: Subscription;

  constructor(private route: ActivatedRoute,
              private tokeetService: TokeetService,
              private authService: AuthService,
              private utility: UtilityService,
              private accountService: AccountService) {
    this.utility.addClass('body', 'login');
  }

  ngOnInit() {

    this.authenticatedSub = this.authService
      .isAuthenticatedForce()
      .subscribe((isAuthenticated) => {
        this.isAuthenticated = isAuthenticated;
      });

    this.secret = uuid();
  }

  ngOnDestroy() {
    this.paramsSub.unsubscribe();
    this.authenticatedSub.unsubscribe();
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    this.utility.removeClass('body', 'login');
  }

  onLogin(credentials: LoginForm) {
    this.authSub = this.authService
      .login(credentials)
      .subscribe(() => {
        this.isAuthenticated = true;
      });
  }

  allow() {
    this.paramsSub = fromPromise(this.accountService.generateAPIKey(`IFTTT-${uuid()}`, true))
      .pipe(
        switchMap((apiKey: ApiKey) => {
          return this.tokeetService.createIFTTTUser(this.secret, apiKey);
        }),
        switchMap(() => {
          return this.route.queryParams;
        })
      )
      .subscribe((params) => {
        window.location.href = `${params.redirect_uri}?code=${this.secret}&state=${params.state}`;
      });
  }

  deny() {
    window.location.href = 'https://ifttt.com/channels/tokeet/authorize?error=access_denied';
  }
}
