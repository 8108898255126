import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {Template} from '../../models/template';
import {TemplateType} from '../../enums/template-type';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {AddTemplateSample, ClearLastAddedSample, DeleteBatchTemplate, DeleteTemplate} from '../../actions/templates.actions';
import {DialogService} from '../../services/dialog.service';
import {TemplateDialogComponent} from '../../components/template/template-dialog/template-dialog.component';
import * as R from 'ramda';
import {delay, filter, map, startWith, switchMap} from 'rxjs/operators';
import {TableUtilityService} from '../../services/table-utility.service';
import {TableType} from '../../enums/table-type';
import {localeCompareSort} from '../../utility/functions/locale-compare-sort';
import {FormControl} from '@angular/forms';
import {ITemplateSample} from '../../interfaces/template/template-sample';
import {TemplateAddRequest} from '../../interfaces/template/template-add-request';
import {isSomething} from '../../utility/functions/is-something';
import {TagsType} from '../../enums/tags-type';
import {FiltersService} from '@automata/services/filters.service';
import {TranslateService} from '@ngx-translate/core';
import {RemoveTemplateDialogService} from '@automata/containers/templates/remove-template/remove-template-dialog.service';
import {Trigger} from '@automata/models/trigger';
import {selectLegacyTriggers} from '../../reducers';
import {DeleteBatchTrigger} from '@automata/actions/triggers.actions';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {AmplitudeService} from '@automata/services/amplitude.service';

@Component({
  selector:    'app-old-templates',
  templateUrl: './templates.component.html',
  styleUrls:   ['./templates.component.scss']
})
export class TemplatesComponent extends OnDestroyMixin implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  tabTypes = TemplateType;

  displayedColumns = this.tableService.getDisplayedColumns(TableType.Templates);

  selection = new SelectionModel<Template>(true, []);
  dataSource = new MatTableDataSource<Template>();

  activeType$ = new BehaviorSubject<TemplateType>(TemplateType.None);

  tableType = TableType.Templates;

  isLoaded = false;
  isEmptyTable = false;

  tagsCtrl = new FormControl([]);

  tags$ = this.filters.registerTagsFilter(TagsType.Template, this.tagsCtrl);

  renderedData: any[];

  legacyTriggers: Trigger[]

  constructor(private auth: AuthService,
              private amplitudeService: AmplitudeService,
              private store: Store<fromRoot.State>,
              private dialogService: DialogService,
              private translate: TranslateService,
              private removeTemplateDialogService: RemoveTemplateDialogService,
              private tableService: TableUtilityService,
              private filters: FiltersService,
              public dialog: MatDialog) {
    super()
  }

  ngOnInit() {
    this.store.pipe(select(selectLegacyTriggers), untilComponentDestroyed(this)).subscribe(triggers => {
      this.legacyTriggers = triggers
    })

    this.dataSource.data = [];
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortData = localeCompareSort;

    // material table has no exposed API for getting what's rendered so we access private property
    this.dataSource['_renderData'].pipe(untilComponentDestroyed(this)).subscribe((rows) => {
      this.renderedData = rows;
      this.isEmptyTable = rows.length === 0;
    });

    combineLatest(this.activeType$, this.tagsCtrl.valueChanges.pipe(startWith([])))
      .pipe(
        switchMap(([type, tags]) => this.store.pipe(
          select(fromRoot.selectTemplatesWithTriggersByType(type)),
          map(templates => {
            if (!R.isEmpty(tags)) {
              return R.filter((template: Template) => R.any(R.equals(true), <any>R.map(tag => R.contains(<any>tag, <any>template.attributes.tags), tags)), templates);
            }
            return templates;
          })
        )),
        delay(10),
        untilComponentDestroyed(this)
      )
      .subscribe(templates => {
        this.isLoaded = true;
        this.paginator.firstPage();
        this.dataSource.data = templates;
        this.selection = new SelectionModel<Template>(true, []);
      });

    this.store.pipe(
      select(fromRoot.selectLastAddedSample),
      filter(template => isSomething(template)),
      untilComponentDestroyed(this)
    )
      .subscribe((template) => {
        this.openEdit(template);
        this.store.dispatch(ClearLastAddedSample());
      });
  }

  openNew() {
    this.amplitudeService.logEvent('templates-blank-start')
    this.dialogService.openSide(TemplateDialogComponent, {data: {template: {}}});
  }

  openEdit(template: Template) {
    console.log(`Editing template ${template.id}`);
    this.dialogService.openSide(TemplateDialogComponent, {data: {template: template}});
  }

  isAllSelected() {
    return this.tableService.isAllSelected(this.selection, this.renderedData);
  }

  masterToggle() {
    this.tableService.masterToggle(this.selection, this.renderedData, this.dataSource);
  }

  openSamples() {
    this.amplitudeService.logEvent('templates-sample-start')
    this.dialogService.openTemplateSamples()
      .subscribe((sample: ITemplateSample) => {
        const request = {
          name:        sample.name,
          description: sample.description,
          subject:     sample.subject,
          body:        sample.body,
          type:        sample.type,
          method:      null,
          languages:   [],
          headers:     [],
          attributes:  {tags: []}
        } as TemplateAddRequest;
        this.store.dispatch(AddTemplateSample({request}));
        this.amplitudeService.logEvent('templates-sample-complete')
      });
  }

  isAnySelected() {
    return isSomething(this.selection.selected) && R.any((t: Template) => t.triggers.length === 0, this.selection.selected);
  }

  onConfirmDelete() {
    let selectedTemplatesWithoutTriggers = R.filter((template: Template) => template.triggers.length === 0, this.selection.selected);
    let selectedIds: string[] = R.map((template: Template) => template.id, selectedTemplatesWithoutTriggers);
    this.paginator.firstPage();
    this.store.dispatch(DeleteBatchTemplate({ids: selectedIds}));
  }

  confirmDelete() {
    this.dialogService.openConfirm().subscribe(() => {
      this.onConfirmDelete();
    });
  }

  applyFilter(term: string) {
    this.paginator.firstPage();
    this.dataSource.filter = term.trim().toLowerCase();
  }

  templateAssociatedToLegacy(template: Template, legacyTriggers: Trigger[]) {
    return !!R.find(t => t.template === template.id, legacyTriggers || [])
  }

  onRemove(template: Template, legacyTriggers: Trigger[]) {
    const legacy = R.filter((t: Trigger) => t.template === template.id, legacyTriggers)
    if (legacy.length > 0) {
      this.store.dispatch(DeleteBatchTrigger({ids: R.map((t: Trigger) => t.id, legacy), silent: true}));
    }
    this.removeTemplateDialogService.removeTemplate(template).subscribe(result => {
      console.log(result)
    })
  }

  onConfirmRemove(template: Template, legacyTriggers: Trigger[]) {
    const legacy = R.filter((t: Trigger) => t.template === template.id, legacyTriggers)
    if (legacy.length > 0) {
      this.store.dispatch(DeleteBatchTrigger({ids: R.map((t: Trigger) => t.id, legacy), silent: true}));
    }
    this.dialogService.openConfirm().subscribe(() => {
      this.store.dispatch(DeleteTemplate({id: template.id}));
    });
  }

  tableManagement() {
    this.dialogService.openTableManagement(this.tableType)
      .subscribe(columns => {
        this.displayedColumns = columns;
      });
  }
}
