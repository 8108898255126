<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <button (click)="close()" class="cstm-close" data-dismiss="modal" aria-label="Close">
        <span class="far fa-times"></span>
      </button>

      <div class="header-box">
        <div>
          <h5 class="modal-title">
            {{'pageElements.bookingDetail' | translate}}
          </h5>
        </div>
        <div>
          <mat-slide-toggle [matTooltip]="inquiry.skip | bookingDetailGlobalTooltip"
                            matTooltipPosition="below"
                            matTooltipClass="text-center"
                            [checked]="!!inquiry.skip" (change)="toggleWorkflow()"
                            class="global-switch mat-slide-secondary">
            <span [ngClass]="{hidden: !inquiry.skip}">{{'pageElements.skipped' | translate}}</span>
            <span [ngClass]="{hidden: inquiry.skip}">{{'pageElements.active' | translate}}</span>
          </mat-slide-toggle>
          <button (click)="addTags()"
                  [matTooltip]="'tooltips.addTagsToBooking' | translate"
                  matTooltipPosition="above"
                  matTooltipClass="text-center"
                  type="button" class="btn btn-cstm-info left-icon text-uppercase">
            <i class="far fa-plus" aria-hidden="true"></i>
            {{'buttons.tags' | translate}}
          </button>
          <button *ngIf="view !== views.Flow" (click)="openView(views.Flow)" type="button"
                  class="btn btn-default text-uppercase btn-switcher-wide">
            {{'buttons.flowView' | translate}}
          </button>
          <button *ngIf="view === views.Flow" (click)="openView(views.List)" type="button"
                  class="btn btn-default text-uppercase btn-switcher-wide">
            {{'buttons.listView' | translate}}
          </button>
          <button (click)="openView(views.Journey)"
                  [matTooltip]="'tooltips.clickToViewMessages' | translate"
                  matTooltipPosition="above"
                  matTooltipClass="text-center"
                  type="button" class="btn btn-default text-uppercase btn-switcher">
            {{'buttons.messages' | translate}}
          </button>
        </div>
      </div>
    </div>

    <div class="modal-body work-detail-modal-content">
      <div *ngIf="view === views.Flow" class="row workflow-holder">
        <div class="col-sm-8 col-sm-offset-2">
          <app-workflow-detail-trigger *ngFor="let trigger of triggers;let isOdd = odd;"
                                       [type]="type"
                                       [inquiryId]="inquiry.pkey"
                                       [trigger]="trigger" [isOdd]="isOdd"
                                       (open)="onOpen($event)"></app-workflow-detail-trigger>
        </div>
      </div>
      <div *ngIf="view === views.List" class="content-holder">
        <app-bookings-trigger-table [inquiryId]="inquiry.pkey"
                                    [data]="tableData$"
                                    [hasSelect]="false"
                                    (remove)="confirmRemove($event)"
                                    (duplicate)="onDuplicate($event)"
                                    (edit)="onEdit($event)"
                                    (pause)="onSkip($event)"
                                    (resume)="onUnSkip($event)"
                                    (archive)="onArchive($event)"
                                    (unarchive)="onUnArchive($event)" class="mt25"></app-bookings-trigger-table>

      </div>
      <div *ngIf="view === views.Journey">

        <app-booking-journey [inquiryId]="inquiry.pkey"></app-booking-journey>

      </div>
    </div>
  </div>
</div>
