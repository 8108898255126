<!-- Title & buttons -->
<div class="d-flex  justify-content-between title-holder">
  <div class="title text-nowrap">
    {{heading}}
  </div>
  <div class="btn-holder d-flex">

    <div class="btn-group">
      <mat-form-field class="inline-search">
        <input matInput (keyup)="search.emit($event.target.value)"
               [placeholder]="'formControls.searchHere' | translate" type="search">
        <span matPrefix>
          <i class="far fa-search"></i>
        </span>
      </mat-form-field>
    </div>

    <!-- Single button -->
    <div dropdown class="btn-group">
      <button dropdownToggle type="button"
              class="btn btn-cstm-info right-icon text-uppercase dropdown-toggle">
        {{'buttons.filters' | translate}}
        <i class="fa dropdown-icon fa-angle-down" aria-hidden="true"></i>
      </button>
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right filters-menu" role="menu">
        <li *ngFor="let f of filters" role="menuitem">
          <a [ngClass]="{active: f.value === filter.value}" class="dropdown-item"
             (click)="filter.next(f.value)">
                        <span *ngIf="f.color" [ngStyle]="{background: f.color}"
                              class="event-color-box"></span> {{f.i18n | translate}} <span
            *ngIf="f.status">({{f.status}})</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
