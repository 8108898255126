import {Pipe, PipeTransform} from '@angular/core';
import * as R from 'ramda';

@Pipe({
  name: 'isSkippedForInquiry'
})
export class IsSkippedForInquiryPipe implements PipeTransform {

  transform(inquiryIds: string[], inquiryId: string): any {
    return R.indexOf(inquiryId, inquiryIds) !== -1;
  }
}
