import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TriggerHandler} from '@automata/enums/trigger-handler';

@Pipe({
  name: 'signatureTemplateTooltip'
})
export class SignatureTemplateTooltipPipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }

  transform(handler: string): string {
    switch (handler) {
      case TriggerHandler.SendContract:
        return this.translate.instant('tooltips.signatureTemplateContract');
      case TriggerHandler.SendDocument:
        return this.translate.instant('tooltips.signatureTemplateDocument');
      case TriggerHandler.SendForm:
        return this.translate.instant('tooltips.signatureTemplateForm');
    }
  }
}
