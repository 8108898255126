import {Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Trigger} from '../../../models/trigger';
import {TabTypes} from '../../../enums/tab-types';
import {TriggerEvents} from '../../../models/trigger-events';
import {User} from '../../../models/user';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {Subscription} from 'rxjs';
import {TriggerHandlers} from '../../../models/trigger-handlers';
import * as R from 'ramda';
import {WorkflowDetailType} from '../../../enums/workflow-detail-type';
import {PauseTrigger, RemoveFromWorkflow, ResumeTrigger, TriggerSkipInquiry, TriggerUnSkipInquiry} from '../../../actions/triggers.actions';
import {InquirySkipRequest} from '../../../interfaces/inquiry/inquiry-skip-request';
import {DialogService} from '../../../services/dialog.service';
import {RemoveFromWorkflowRequest} from '../../../interfaces/remove-from-workflow-request';
import {TranslateService} from '@ngx-translate/core';
import {isSomething} from '@automata/utility/functions/is-something';
import {filter} from 'rxjs/operators';
import {AmplitudeService} from '@automata/services/amplitude.service';

@Component({
  selector:    'app-workflow-detail-trigger',
  templateUrl: './workflow-detail-trigger.component.html',
  styleUrls:   ['./workflow-detail-trigger.component.scss']
})
export class WorkflowDetailTriggerComponent implements OnInit, OnDestroy {

  @Input() inquiryId: string;
  @Input() isOdd: boolean;
  @Input() workflowName: string;

  @Input() trigger: Trigger;
  @Input() type: WorkflowDetailType;

  @Output() open = new EventEmitter<any>();

  @HostBinding('class.canRemoveTrigger') canRemoveTrigger = false;

  tabs = TabTypes;

  users: User[];

  usersSub: Subscription;
  triggerSub: Subscription;

  types = WorkflowDetailType;

  constructor(private store: Store<fromRoot.State>,
              private translate: TranslateService,
              private amplitudeService: AmplitudeService,
              private dialogService: DialogService) {
  }

  ngOnInit() {

    this.canRemoveTrigger = this.type === this.types.Workflow;

    this.triggerSub = this.store.pipe(
      select(fromRoot.selectTriggerById(this.trigger.id)),
      filter(trigger => isSomething(trigger))
      )
      .subscribe((trigger: Trigger) => {
        this.trigger = trigger;
      });

    this.usersSub = this.store.pipe(select(fromRoot.selectUsers(this.trigger.settings.users)))
      .subscribe((users) => {
        this.users = users;
      });
  }

  ngOnDestroy() {
    this.usersSub.unsubscribe();
    this.triggerSub.unsubscribe();
  }


  onToggleSkip() {
    if (R.indexOf(this.inquiryId, this.trigger.skippedInquiries) !== -1) {
      this.amplitudeService.logEvent('booking-trigger-activate')
      this.store.dispatch(TriggerUnSkipInquiry({
        request: {
                   triggerId: this.trigger.id,
                   bookingId: this.inquiryId
                 } as InquirySkipRequest
      }));
    } else {
      this.amplitudeService.logEvent('booking-trigger-deactivate')
      this.store.dispatch(TriggerSkipInquiry({
        request: {
                   triggerId: this.trigger.id,
                   bookingId: this.inquiryId
                 } as InquirySkipRequest
      }));
    }
  }

  onToggleStatus() {
    if (this.trigger.status === 1) {
      if (this.trigger.attributes.workflows.length > 1) {
        this.dialogService.openConfirm({
            title: this.translate.instant('dialogs.workflowPauseTitle'),
            body:  this.translate.instant('dialogs.workflowPauseBody')
          })
          .subscribe(() => {
            this.store.dispatch(PauseTrigger({id: this.trigger.id}));
          });
      } else {
        this.store.dispatch(PauseTrigger({id: this.trigger.id}));
      }
    } else {
      if (this.trigger.attributes.workflows.length > 1) {
        this.dialogService.openConfirm({
            title: this.translate.instant('dialogs.workflowResumeTitle'),
            body:  this.translate.instant('dialogs.workflowResumeBody')
          })
          .subscribe(() => {
            this.store.dispatch(ResumeTrigger({id: this.trigger.id}));
          });
      } else {
        this.store.dispatch(ResumeTrigger({id: this.trigger.id}));
      }
    }
  }

  confirmRemove() {
    this.dialogService.openConfirm().subscribe(() => {
      this.onConfirmRemove();
    });
  }

  onConfirmRemove() {
    this.store.dispatch(RemoveFromWorkflow({
      request: {
                 trigger:      this.trigger,
                 workflowName: this.workflowName
               } as RemoveFromWorkflowRequest
    }));
  }

  onOpen(tab: TabTypes) {
    this.open.emit({
      trigger: this.trigger,
      tab:     tab
    });
  }

  getHandlerName() {
    const handlerName = TriggerHandlers.getHandlerName(this.trigger.handler);
    return !R.isNil(handlerName) ? handlerName.replace('Send ', '') : '';
  }

  getEventName(): string {
    if (R.isNil(this.trigger)) {
      return '';
    }
    return TriggerEvents.getEventName(this.trigger.event);
  }

  getEventColor(): string {
    if (R.isNil(this.trigger)) {
      return '';
    }
    return TriggerEvents.getEventColor(this.trigger.event);
  }

  getEventIcon(): string {
    if (R.isNil(this.trigger)) {
      return '';
    }
    return TriggerEvents.getEventIcon(this.trigger.event);
  }

  getBoxClass() {
    return `box-inquiry ${this.isOdd ? 'col-sm-push-6' : ''}`;
  }

  getSideBoxClass() {
    return `${this.isOdd ? 'col-sm-pull-6' : ''}`;
  }

  getBgClass() {
    return `ribbon-box-${this.isOdd ? 'left' : 'right'}`;
  }

  getIconClass() {
    return `section-icon-${this.isOdd ? 'right' : 'left'}`;
  }

}
