import {TriggerEvent} from '../interfaces/trigger/trigger-event';
import * as R from 'ramda';
import {Trigger} from './trigger';

declare const require: any;

const startsWith = require('lodash/startsWith');

/**
 * Weight and colors are taken from this document https://docs.google.com/spreadsheets/d/1leqGdiIUke9qljRWY7Ke8zoXwukz5JmCxape9CzYSvo/edit#gid=0
 */
export class TriggerEvents {
  static events: TriggerEvent[] = [
    {
      name:   'Guest Created',
      i18n:   'triggerEvents.guestCreate',
      value:  'guest.create',
      color:  '#0bab2f',
      icon:   'fal fa-users',
      weight: 1.1
    },
    {
      name:   'Guest Updated',
      i18n:   'triggerEvents.guestUpdate',
      value:  'guest.update',
      color:  '#969696',
      icon:   'fal fa-user-edit',
      weight: 1.7
    },
    {
      name:   'Guest Deleted',
      i18n:   'triggerEvents.guestDelete',
      value:  'guest.delete',
      color:  '#ee3349',
      icon:   'fal fa-user-minus',
      weight: 2.3
    },
    {
      name:   'Guest Message',
      i18n:   'triggerEvents.messageCreate',
      value:  'message.create',
      color:  '#969696',
      icon:   'icon-message-received',
      weight: 1.6
    },

    {
      name:   'Inquiry Created',
      i18n:   'triggerEvents.inquiryCreate',
      value:  'inquiry.create',
      color:  '#0bab2f',
      icon:   'icon-inquiry-created',
      weight: 1
    },
    {
      name:   'Booking Confirmed',
      i18n:   'triggerEvents.inquiryBook',
      value:  'inquiry.book',
      color:  '#0bab2f',
      icon:   'icon-check-circle',
      weight: 3
    },
    {
      name:   'Booking Cancelled',
      i18n:   'triggerEvents.inquiryCancel',
      value:  'inquiry.cancel',
      color:  '#ee3349',
      icon:   'icon-booking-ends',
      weight: 5
    },

    {
      name:   'Hold Created',
      i18n:   'triggerEvents.eventCreate',
      value:  'event.create',
      color:  '#0bab2f',
      icon:   'icon-booking-ends',
      weight: 2
    },
    {
      name:   'Hold Deleted',
      i18n:   'triggerEvents.eventDelete',
      value:  'event.delete',
      color:  '#ee3349',
      icon:   'icon-booking-ends',
      weight: 2.2
    },

    {
      name:   'Invoice Created',
      i18n:   'triggerEvents.invoiceCreate',
      value:  'invoice.create',
      color:  '#0bab2f',
      icon:   'icon-invoice-paid',
      weight: 3.1
    },
    {
      name:   'Invoice Paid',
      i18n:   'triggerEvents.invoicePaid',
      value:  'invoice.paid',
      color:  '#0bab2f',
      icon:   'icon-invoice-paid',
      weight: 5.1
    },
    {
      name:   'Invoice Cancelled',
      i18n:   'triggerEvents.invoiceCancel',
      value:  'invoice.cancel',
      color:  '#ee3349',
      icon:   'icon-booking-ends',
      weight: 3.3
    },
    {
      name:   'Time-based Event',
      i18n:   'triggerEvents.timeEvent',
      value:  'time',
      color:  '#969696',
      icon:   'fal fa-clock',
      weight: 0
    },
    {
      name:   'iCal Import Error',
      i18n:   'triggerEvents.icalRefresh',
      value:  'system.error.ical.refresh',
      icon:   'fal fa-exclamation-triangle',
      color:  '#ee3349',
      weight: 11.2
    },
    {
      name:   'Critical Error',
      i18n:   'triggerEvents.criticalError',
      value:  'system.error',
      icon:   'fal fa-exclamation-triangle',
      color:  '#ee3349',
      weight: 11.3
    },
    {
      name:   'Channel Disabled',
      i18n:   'triggerEvents.channelDisabled',
      value:  'system.error.channel.disabled',
      icon:   'fal fa-exclamation-triangle',
      color:  '#ee3349',
      weight: 11.1
    },
    {
      name:   'Contract Sent',
      i18n:   'triggerEvents.contractSent',
      value:  'contract.sent',
      icon:   'fal fa-paper-plane',
      color:  '#969696',
      weight: 3.5
    },
    {
      name:   'Contract Viewed',
      i18n:   'triggerEvents.contractViewed',
      value:  'contract.viewed',
      icon:   'far fa-eye',
      color:  '#969696',
      weight: 3.53
    },
    {
      name:   'Contract Signed',
      i18n:   'triggerEvents.contractSigned',
      value:  'contract.signed',
      icon:   'fal fa-user-check',
      color:  '#0bab2f',
      weight: 3.52
    },
    {
      name:   'Contract Rejected',
      i18n:   'triggerEvents.contractRejected',
      value:  'contract.rejected',
      icon:   'fal fa-user-minus',
      color:  '#ee3349',
      weight: 3.51
    },
    {
      name:   'Hold Updated',
      i18n:   'triggerEvents.holdUpdated',
      value:  'event.update',
      icon:   'fal fa-pencil',
      color:  '#969696',
      weight: 2.1
    },
    {
      name:   'Invoice Sent',
      i18n:   'triggerEvents.invoiceSent',
      value:  'invoice.sent',
      color:  '#969696',
      icon:   'fal fa-paper-plane',
      weight: 3.2
    },
    {
      name:   'Expense Created',
      i18n:   'triggerEvents.expenseCreated',
      value:  'expense.create',
      color:  '#0bab2f',
      icon:   'icon-invoice-paid',
      weight: 9.1
    },
    {
      name:   'Expense Updated',
      i18n:   'triggerEvents.expenseUpdated',
      value:  'expense.update',
      color:  '#ffc600',
      icon:   'icon-invoice-paid',
      weight: 9.2
    },
    {
      name:   'Expense Deleted',
      i18n:   'triggerEvents.expenseDeleted',
      value:  'expense.delete',
      color:  '#ee3349',
      icon:   'icon-invoice-paid',
      weight: 9.3
    },
    {
      name:   'Task Created',
      i18n:   'triggerEvents.taskCreated',
      value:  'task.create',
      color:  '#0bab2f',
      icon:   'fal fa-terminal',
      weight: 4.1
    },
    {
      name:   'Task Updated',
      i18n:   'triggerEvents.taskUpdated',
      value:  'task.update',
      color:  '#ffc600',
      icon:   'fal fa-terminal',
      weight: 4.3
    },
    {
      name:   'Task Deleted',
      i18n:   'triggerEvents.taskDeleted',
      value:  'task.delete',
      color:  '#ee3349',
      icon:   'fal fa-terminal',
      weight: 4.4
    },
    {
      name:   'Task Completed',
      i18n:   'triggerEvents.taskCompleted',
      value:  'task.complete',
      color:  '#0bab2f',
      icon:   'fal fa-terminal',
      weight: 4.5
    },
    {
      name:   'Rate Created',
      i18n:   'triggerEvents.rateCreated',
      value:  'rate.create',
      color:  '#0bab2f',
      icon:   'fal fa-money-bill-alt',
      weight: 4.5
    },
    {
      name:   'Rate Updated',
      i18n:   'triggerEvents.rateUpdated',
      value:  'rate.update',
      color:  '#ffc600',
      icon:   'fal fa-money-bill-alt',
      weight: 4.5
    },
    {
      name:   'Rate Deleted',
      i18n:   'triggerEvents.rateDeleted',
      value:  'rate.delete',
      color:  '#ee3349',
      icon:   'fal fa-money-bill-alt',
      weight: 4.5
    }
  ];

  static timeevents: TriggerEvent[] = [
    {
      name:   'After Inquiry Created',
      i18n:   'triggerEvents.afterInquiryCreated',
      value:  'time.after.inquiry.created',
      color:  '#0bab2f',
      icon:   'icon-booking-starts',
      weight: 1.01
    },
    {
      name:   'After Booking Confirmed',
      i18n:   'triggerEvents.afterBookingConfirmed',
      value:  'time.after.booking.confirm',
      color:  '#0bab2f',
      icon:   'icon-booking-starts',
      weight: 3.01
    },
    {
      name:   'After Booking Cancelled',
      i18n:   'triggerEvents.afterBookingCanceled',
      value:  'time.after.booking.cancel',
      color:  '#ee3349',
      icon:   'icon-booking-ends',
      weight: 5.01
    },
    {
      name:   'Before Check-in',
      i18n:   'triggerEvents.beforeCheckIn',
      value:  'time.before.booking.start',
      color:  '#0bab2f',
      icon:   'icon-booking-starts',
      weight: 8
    },
    {
      name:   'After Check-in',
      i18n:   'triggerEvents.afterCheckIn',
      value:  'time.after.booking.start',
      color:  '#0bab2f',
      icon:   'icon-booking-starts',
      weight: 9
    },
    {
      name:   'Before Check-out',
      i18n:   'triggerEvents.beforeCheckOut',
      value:  'time.before.booking.end',
      color:  '#ee3349',
      icon:   'icon-booking-ends',
      weight: 10
    },
    {
      name:   'After Check-out',
      i18n:   'triggerEvents.afterCheckOut',
      value:  'time.after.booking.end',
      color:  '#ee3349',
      icon:   'icon-booking-ends',
      weight: 11
    },
    {
      name:   'Before Invoice Due',
      i18n:   'triggerEvents.beforeInvoiceDue',
      value:  'time.before.invoice.due',
      color:  '#ffc600',
      icon:   'icon-invoice-paid',
      weight: 6
    },
    {
      name:   'After Invoice Due',
      i18n:   'triggerEvents.afterInvoiceDue',
      value:  'time.after.invoice.due',
      color:  '#ffc600',
      icon:   'icon-invoice-paid',
      weight: 7
    }
  ];

  static allWeightedEvents(): TriggerEvent[] {
    return R.filter(e => e.weight !== 0, R.concat(TriggerEvents.events, TriggerEvents.timeevents));
  }

  static allSortedEvents(): TriggerEvent[] {
    return R.sort((a, b) => a.weight - b.weight, TriggerEvents.allWeightedEvents());
  }

  static getWeight(trigger: Trigger): number {
    const weightedEvent = R.find(e => e.value === trigger.event, TriggerEvents.allWeightedEvents());
    if (!R.isNil(weightedEvent)) {
      return weightedEvent.weight;
    } else {
      return 0;
    }
  }

  static getEventName(value: string): string | null {
    let event = R.find(R.propEq('value', value), TriggerEvents.events);
    if (R.isNil(event)) {
      event = R.find(R.propEq('value', value), TriggerEvents.timeevents);
    }
    return R.isNil(event) ? null : event.name;
  }

  static getEventColor(value: string): string | null {
    let event = R.find(R.propEq('value', value), TriggerEvents.events);
    if (R.isNil(event)) {
      event = R.find(R.propEq('value', value), TriggerEvents.timeevents);
    }
    return R.isNil(event) ? null : event.color;
  }

  static getEventIcon(value: string): string | null {
    let event = R.find(R.propEq('value', value), TriggerEvents.events);
    if (R.isNil(event)) {
      event = R.find(R.propEq('value', value), TriggerEvents.timeevents);
    }
    return R.isNil(event) ? null : event.icon;
  }

  static isInquiry(event: string): boolean {
    return startsWith(event, 'inquiry');
  }

  static allowsRental(event: string): boolean {
    return /^rate|^task|^inquiry|^time|^expense|^event|^invoice/.test(event);
  }

  static allowsChannel(event: string): boolean {
    return this.isInquiry(event) || this.isTime(event) || this.isTask(event);
  }

  static isTime(event: string): boolean {
    return startsWith(event, 'time');
  }

  static isTask(event: string): boolean {
    return startsWith(event, 'task');
  }

  static isInvoice(event: string): boolean {
    return startsWith(event, 'invoice');
  }

  static isExpense(event: string): boolean {
    return startsWith(event, 'expense');
  }

  static isTimeInvoice(event: string): boolean {
    return event === 'time.before.invoice.due' || event === 'time.after.invoice.due';
  }

  static isSkippable(event: string): boolean {
    return this.isTime(event) || this.isInquiry(event) || this.isInvoice(event);
  }

  static isCard(event: string): boolean {
    return startsWith(event, 'payment');
  }

  static isRate(event: string) {
    return startsWith(event, 'rate');
  }
}
