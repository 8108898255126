import {AbstractControl} from '@angular/forms';

export function validateMinNumber(number) {
  return (control: AbstractControl) => {
    if (control.value < number) {
      return {minNumber: true};
    }
    return null;
  };
}
