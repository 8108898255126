import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {SelectionModel} from '@angular/cdk/collections';
import {TemplateSample} from '../../../models/samples/template-sample';
import {localeCompareSort} from '@automata/utility/functions/locale-compare-sort';
import {TableUtilityService} from '@automata/services/table-utility.service';

@Component({
  selector:    'app-template-samples-dialog',
  templateUrl: './template-samples-dialog.component.html',
  styleUrls:   ['./template-samples-dialog.component.scss']
})
export class TemplateSamplesDialogComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns = [
    'icon',
    'name',
    'description'
  ];

  selection = new SelectionModel<TemplateSample>(true, []);
  dataSource = new MatTableDataSource<TemplateSample>();

  samplesSub: Subscription;

  constructor(private store: Store<fromRoot.State>,
              private tableService: TableUtilityService,
              public dialogRef: MatDialogRef<TemplateSamplesDialogComponent>) {
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortData = localeCompareSort;

    this.samplesSub = this.store.pipe(select(fromRoot.selectTemplateSamplesWithIcon))
      .subscribe(samples => {
        this.dataSource.data = <TemplateSample[]>samples;
      });
  }

  ngOnDestroy() {
    this.samplesSub.unsubscribe();
  }

  openSample(sample: TemplateSample) {
    this.dialogRef.close(sample);
  }

  close() {
    this.dialogRef.close();
  }
}
