import {AbstractControl} from '@angular/forms';
import * as R from 'ramda';

declare const require: any;

const striptags = require('striptags');

export function isEmptyTrimmed() {
  return (control: AbstractControl) => {
    const stripped = striptags(control.value.trim());
    if (R.isEmpty(stripped.trim())) {
      return {isEmptyTrimmed: true};
    }
    return null;
  };
}
