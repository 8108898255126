import {Injectable} from '@angular/core';
import {User} from '@automata/models/user';
import {isSomething} from '@automata/utility/functions/is-something';

declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class DelightedService {

  run(user: User) {
    if (!isSomething(window.delighted) || !isSomething(user)) {
      return;
    }
    window.delighted.survey({
      email:      user.primaryemail,
      name:       `${user.firstname} ${user.lastname}`,
      createdAt:  user.created,
      properties: {
        product_name: 'Automata'
      }
    });
  }
}
