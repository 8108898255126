import {AutomataNotification} from './automata-notification';
import * as moment from 'moment';
import {BookingView} from '@automata/containers/bookings-page/store/booking.model';

export class TriggerNotificationTableModel {
  date: number;
  dateFormatted: string;
  dateFormattedLong: string;
  timeFormatted: string;
  guest: string;
  event: string;
  template: string;
  channel: string;

  handler: string;
  guestName: string;
  eventName: string;
  eventColor: string;
  templateName: string;
  channelName: string;

  triggerId: string;
  hasTrigger = true;

  eventMessage: string;

  inquiryId: string;
  inquiry: BookingView;

  constructor(notification: AutomataNotification, bookings?: BookingView[]) {
    this.date = notification.sent;
    this.dateFormatted = moment.unix(notification.sent).format('DD - MMM - YYYY');
    this.dateFormattedLong = moment.unix(notification.sent).format('DD - MMM - YYYY | h:mm A');
    this.timeFormatted = moment.unix(notification.sent).format('h:mm A');
    this.guest = notification.guestId;
    this.guestName = notification.guestName;
    this.channel = notification.channelToken;
    this.channelName = notification.channelName;
    if (notification.object === 'DBObjects::Inquiry') {
      this.inquiryId = notification.inquiryId;
    }

    this.event = notification.event;
    this.handler = notification.handler;
    this.template = notification.templateId;
    this.triggerId = notification.triggerId;
    this.eventMessage = notification.eventMessage;
  }
}
