<div class="dialog-header">
  <h2 mat-dialog-title>
    {{'pageElements.chooseExistingTriggers' | translate}}
  </h2>
  <button (click)="close()" class="close" aria-label="Close" type="button">
    <span class="far fa-times"></span>
  </button>
</div>
<div class="row center-row">
  <div class="col-sm-12">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-sm-8 col-sm-offset-2 col-xs-12">
          <p class="description">{{'pageElements.selectExistingTriggers' | translate}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-8 col-sm-offset-2 col-xs-12">

          <ng-select
            [items]="triggers$ | async"
            [multiple]="true"
            [closeOnSelect]="false"
            [virtualScroll]="true"
            bindLabel="name"
            [placeholder]="'formControls.selectTriggers' | translate"
            formControlName="triggers"
            appendTo="body">
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
              <div class="ng-value" *ngFor="let item of (items ? items.slice(0,2): [])">
                <span class="ng-value-label">{{item.name}}</span>
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
              </div>
              <div class="ng-value" *ngIf="items.length > 2">
                <span class="ng-value-label">{{items.length - 2}} more...</span>
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <div class="ng-select-hidden-checkbox"></div>
              <mat-checkbox id="item-{{index}}" [checked]="item$.selected"
                            class="ng-select-checkbox"></mat-checkbox>
              {{item.name}}
            </ng-template>
          </ng-select>
          <mat-error *ngIf="form.get('triggers').touched && form.get('triggers').hasError('required')">
            <span [innerHtml]="'validations.valueRequired' | translate"></span>
          </mat-error>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="text-right">
  <button (click)="close()" type="button" class="btn btn-default left-icon text-uppercase mr16">
    <i class="far fa-check-square" aria-hidden="true"></i>
    {{'buttons.done' | translate}}
  </button>
  <button (click)="save(form)" type="button"
          class="btn btn-new left-icon text-uppercase">
    <span class="far fa-save" aria-hidden="true"></span>
    {{'buttons.save' | translate}}
  </button>
</div>
