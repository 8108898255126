import {LoadNotificationsComplete} from '../actions/notifications.actions';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Notification} from '../models/notification';
import {createReducer, on} from '@ngrx/store';

export interface State extends EntityState<Notification> {
  // additional entities state properties
  selectedNotificationId: string | null
  isLoaded: boolean
}

export const adapter: EntityAdapter<Notification> = createEntityAdapter<Notification>({
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedNotificationId: null,
  isLoaded:               false
});

export const reducer = createReducer(
  initialState,
  on(LoadNotificationsComplete, (state, {notifications}) => adapter.addMany(notifications, {
    ...state,
    isLoaded: true
  }))
);

export const {selectAll} = adapter.getSelectors();
