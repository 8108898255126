import {Component, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {TableUtilityService} from '../../services/table-utility.service';
import {Code} from '../../models/code';
import * as R from 'ramda';
import {DialogService} from '../../services/dialog.service';
import {CodeDialogComponent} from '../../components/codes/code-dialog/code-dialog.component';
import {DeleteBatchCode} from '../../actions/codes.actions';
import {SelectionModel} from '@angular/cdk/collections';
import {PageEvent} from '@angular/material/paginator';
import {isSomething} from '@automata/utility/functions/is-something';
import {AmplitudeService} from '@automata/services/amplitude.service';


@Component({
  selector:    'app-codes',
  templateUrl: './codes.component.html',
  styleUrls:   ['./codes.component.scss']
})
export class CodesComponent implements OnDestroy {

  renderedData: any[] = [];
  selection: SelectionModel<Code> = {} as any;
  paginator: PageEvent = {} as any;

  constructor(private store: Store<fromRoot.State>,
              private amplitudeService: AmplitudeService,
              private tableService: TableUtilityService,
              private dialogService: DialogService) {
  }

  ngOnDestroy() {
  }

  onSelectChange(model: SelectionModel<Code>) {
    this.selection = model;
  }

  onPageChange(page: PageEvent) {
    this.paginator = page;
  }

  onRenderedData(data) {
    this.renderedData = data;
  }

  openNew() {
    this.amplitudeService.logEvent('custom-code-start')
    this.dialogService.openSide(CodeDialogComponent, {
      data: {code: {}}
    });
  }

  isAnySelected() {
    return isSomething(this.selection) && isSomething(this.selection.selected);
  }

  isAllSelected() {
    return this.tableService.isAllSelected(this.selection, this.renderedData);
  }

  onConfirmDelete() {
    let selectedIds: string[] = [];
    if (this.isAllSelected()) {
      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      const selectedOnCurrentPage = R.slice(startIndex, startIndex + this.paginator.pageSize, <any>this.selection.selected);
      selectedIds = R.map((code: Code) => code.id, <any>selectedOnCurrentPage);
    } else {
      selectedIds = R.map((code: Code) => code.id, <any>this.selection.selected);
    }
    this.store.dispatch(DeleteBatchCode({ids: selectedIds}));
  }

  confirmDelete() {
    this.dialogService.openConfirm().subscribe(() => {
      this.onConfirmDelete();
    });
  }
}
