import {TagsType} from '@automata/enums/tags-type';
import {createSelector} from '@ngrx/store';
import {selectAllBookings} from '@automata/containers/bookings-page/store/booking.selectors';
import {Taggable} from '@automata/interfaces/taggable';
import * as R from 'ramda';
import {selectAllCodes, selectTemplates, selectTriggers} from '@automata/reducers';

export const allTags = (type: TagsType, includeTriggerBookingTags = false) => createSelector(
  selectTriggers, selectAllCodes, selectTemplates, selectAllBookings,
  (triggers, codes, templates, inquiries) => {

    let allTags: any = [];

    let taggables: Taggable[] = [];
    if (type === TagsType.Trigger) {
      taggables = triggers;
    } else if (type === TagsType.Code) {
      taggables = codes;
    } else if (type === TagsType.Template) {
      taggables = templates;
    } else if (type === TagsType.Inquiry) {
      taggables = inquiries;
    }

    R.forEach(taggable => {
      const tags: any = R.pathOr([], ['attributes', 'tags'], taggable);
      allTags = R.concat(<any>tags, <any>allTags);

      if (type === TagsType.Trigger && includeTriggerBookingTags) {
        const bookingTags = R.pathOr([], ['attributes', 'bookingTags'], taggable);
        allTags = R.concat(bookingTags, allTags);
      }

    }, taggables);

    return R.sort((a: string, b: string) => a.localeCompare(b))(R.uniq(R.map(R.pipe(R.when(R.is(Number), R.toString)))(allTags)));
  });
