import {createReducer, on} from '@ngrx/store';
import {PlanSubscription} from '@automata/services/plan.service';
import {LoadSubscriptionsComplete} from '@automata/actions/subscription.actions';

export interface State {
  subscriptions: PlanSubscription[]
}

export const initialState: State = {
  subscriptions: []
};

export const reducer = createReducer(
  initialState,
  on(LoadSubscriptionsComplete, (state, {subscriptions}) => ({...state, subscriptions}))
);
