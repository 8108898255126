<div class="row-flex">
  <div class="flex-col login-holder">
    <div class="login-content">
      <div class="login-form-layout">
        <h1 class="logo text-center">
          <a href="#">
            <img src="assets/logo.png">
          </a>
        </h1>
        <app-login-form (login)="onLogin($event)"></app-login-form>
        <p class="terms-service text-center">
          <a href="https://www.tokeet.com/terms.html" target="_blank">Terms of Service</a> |
          <a href="https://www.tokeet.com/privacy.html" target="_blank">Privacy Policy</a>
        </p>
      </div>
    </div>
  </div>
  <div class="flex-col marketing-section">
    <div class="marketing-content clearfix">
      <div class="content">
        <h2 class="signin-header">Access the Tokeet suite of products with a single username and password.</h2>
        <p>
          Tokeet is on a mission to simplify short-term rental management. With the most powerful platform on
          the market and a suite of robust tools for every step of the guest experience. Grow your business
          with Tokeet by doing more with less.
        </p>
      </div>
      <div class="logos">
        <a href="//www.tokeet.com">
          <img src="assets/market-place/tokeet.png" alt="Tokeet Logo">
        </a>
        <a href="//www.usesignature.com">
          <img src="assets/market-place/signature.png" alt="Signature Logo">
        </a>
        <a href="//useautomata.com">
          <img src="assets/market-place/automata.png" alt="Automata Logo">
        </a>
        <a href="//rategenie.io">
          <img src="assets/market-place/rategenie.png" alt="Rate Genie Logo">
        </a>
        <a href="//www.ownercenter.net">
          <img src="//cdn.tokeet.com/images/logos/owners_right_sm.png" alt="Owner Center Logo">
        </a>
        <a href="//app.usemargins.com">
          <img src="//cdn.tokeet.com/images/logos/margin-white.png" alt="Margins Logo">
        </a>
        <a href="//usewebready.com/">
          <img src="assets/webready-light.png" alt="Webready Logo">
        </a>
        <a href="//sympl.cm">
          <img src="assets/sympl-light.png" alt="Sympl Logo">
        </a>
        <a href="//usechecklist.com">
          <img src="assets/checklist-light.png" alt="Checklist Logo">
        </a>
      </div>
    </div>
  </div>
</div>
