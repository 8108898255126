import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {APP_CONFIG, IAppConfig} from '../app.config';
import {AuthService} from '../services/auth.service';
import {ToastIconClasses} from '../utility/models/toast-icon-classes';
import {UtilityService} from '../services/utility.service';
import {catchError, tap} from 'rxjs/operators';

declare const require: any;
declare const window: any;

const endsWith = require('lodash/endsWith');

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  isRedirecting = false;

  constructor(private router: Router,
              private auth: AuthService,
              private utility: UtilityService,
              @Inject(APP_CONFIG) private config: IAppConfig) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        tap((response: any) => {
          if ((response.status === 401 || response.status === 403 || response.status === 407) && !endsWith(response.url, '/user/login')) {
            if (!this.isRedirecting) {
              this.auth.logout();
              this.utility.showToastAfterRefresh('Your session has expired', 'Error', ToastIconClasses.Error);
              window.location.href = '/login';
              this.isRedirecting = true;
            }
            return <Observable<HttpEvent<any>>>of({});
          }
        }),
        catchError((err) => {
          if ((err.status === 401 || err.status === 403 || err.status === 407) && !endsWith(err.url, '/user/login')) {
            if (!this.isRedirecting) {
              this.auth.logout();
              this.utility.showToastAfterRefresh('Your session has expired', 'Error', ToastIconClasses.Error);
              window.location.href = '/login';
              this.isRedirecting = true;
            }
            return <Observable<HttpEvent<any>>>of({});
          } else {
            return throwError(err);
          }
        })
      );
  }
}
