import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Message} from '../../../models/message';

@Component({
  selector:    'app-booking-messages-dialog',
  templateUrl: './booking-messages-dialog.component.html',
  styleUrls:   ['./booking-messages-dialog.component.scss']
})
export class BookingMessagesDialogComponent implements OnInit {

  message: Message;

  constructor(public dialogRef: MatDialogRef<BookingMessagesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.message = this.data.message;
  }

  close() {
    this.dialogRef.close();
  }
}
