<div class="modal-header">
  <h5 class="modal-title">Send Sample</h5>
  <button (click)="close()" type="button" class="close">
    <span><i class="fal fa-times"></i></span>
  </button>
</div>
<div class="modal-body">

  <p>Select a user from your account you wish to send sample <span *ngIf="data.type === types.Email">email</span><span *ngIf="data.type === types.PushNotification">sms</span> to:</p>
  <app-select-search
    *ngIf="data.type === types.Email"
    [multiple]="false"
    [items]="usersEmail$ | async"
    [ctrl]="form.get('user')"
    bindLabel="formattedName"
    [placeholder]="'formControls.selectUser' | translate"></app-select-search>
  <app-select-search
    *ngIf="data.type === types.PushNotification"
    [multiple]="false"
    [items]="usersPhone$ | async"
    [ctrl]="form.get('user')"
    bindLabel="formattedName"
    [placeholder]="'formControls.selectUser' | translate"></app-select-search>

  <mat-error *ngIf="form.get('user').touched && form.get('user').hasError('required')">
    <span [innerHtml]="'validations.userRequired' | translate"></span>
  </mat-error>

  <p *ngIf="data.type === types.PushNotification">The sample SMS will be limited to 160 characters or less</p>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-default left-icon text-uppercase">
    <i class="far fa-check-square" aria-hidden="true"></i> Cancel
  </button>
  <button (click)="onSendSample(form)" [matTooltip]="form.get('user').touched && form.get('user').hasError('required') ? 'Please select a user' : ''" type="button" class="btn btn-cstm-info left-icon text-uppercase">
    <span *ngIf="!sending"><i class="fal fa-paper-plane"></i></span>
    <span *ngIf="sending"><i class="fas fa-spinner fa-spin"></i></span> Send
  </button>
</div>
