export class SimpleCounter {

    quill: any;
    options: any;

    charLabel: any;
    charCount: any;

    wordLabel: any;
    wordCount: any;

    limit: number;

    constructor(quill, options) {
        this.quill = quill;
        this.options = options;

        this.charLabel = document.querySelector(options.charLabel);
        this.charCount = document.querySelector(options.charCount);

        this.wordLabel = document.querySelector(options.wordLabel);
        this.wordCount = document.querySelector(options.wordCount);

        quill.on('text-change', this.update.bind(this));
        this.update();  // Account for initial contents
    }

    calculateWordCount() {
        let text = this.quill.getText();
        text = text.trim();
        // Splitting empty text returns a non-empty array
        return text.length > 0 ? text.split(/\s+/).length : 0;
    }

    calculateCharacterCount() {
        let text = this.quill.getText();
        return text.length - 1;
    }

    update() {
        let wordCount = this.calculateWordCount();
        let charCount = this.calculateCharacterCount();

        let wordLabel = ' Word';
        let charLabel = ' Character';

        if (Math.abs(charCount) !== 1) {
            charLabel += 's';
        }

        if (Math.abs(wordCount) !== 1) {
            wordLabel += 's';
        }

        this.charCount.innerText = Math.abs(charCount);
        this.charLabel.innerText = charLabel;

        this.wordCount.innerText = Math.abs(wordCount);
        this.wordLabel.innerText = wordLabel;
    }
}
