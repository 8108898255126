import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {TemplateDataDictionary} from '../../../models/template-data-dictionary';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-dictionary-dialog',
  templateUrl: './dictionary-dialog.component.html',
  styleUrls: ['./dictionary-dialog.component.scss']
})
export class DictionaryDialogComponent implements OnInit, OnDestroy {

  codeSub: Subscription;
  codes: string[];

  dictionary = {
    contracts: TemplateDataDictionary.contracts,
    guests: TemplateDataDictionary.guests,
    inquiries: TemplateDataDictionary.inquiries,
    rentals: TemplateDataDictionary.rentals,
    discounts: TemplateDataDictionary.discounts
  };

  discountTooltips = {
    '*|DISCOUNT:5|*': 'Inserting this field data token into a template will generate a discount code that can be used for a 5% reduction of the booking total.',
    '*|DISCOUNT:10|*': 'Inserting this field data token into a template will generate a discount code that can be used for a 10% reduction of the booking total.',
    '*|DISCOUNT:15|*': 'Inserting this field data token into a template will generate a discount code that can be used for a 15% reduction of the booking total.',
    '*|DISCOUNT:20|*': 'Inserting this field data token into a template will generate a discount code that can be used for a 20% reduction of the booking total.'
  };

  constructor(public dialogRef: MatDialogRef<DictionaryDialogComponent>,
              private store: Store<fromRoot.State>) {
  }

  ngOnInit() {
    this.codeSub = this.store.pipe(select(fromRoot.selectUniqueCodeNames))
      .subscribe(codes => {
        this.codes = codes;
      });
  }

  ngOnDestroy() {
    this.codeSub.unsubscribe();
  }

  addTag(tag: string) {
    this.dialogRef.close(tag);
  }

  close() {
    this.dialogRef.close();
  }

}
