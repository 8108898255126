import {Component, Inject} from '@angular/core';
import {ConfirmDialogStatus} from '../../../enums/confirm-dialog-status';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector:    'app-workflow-delete-confirm-dialog',
  templateUrl: './workflow-delete-confirm-dialog.component.html',
  styleUrls:   ['./workflow-delete-confirm-dialog.component.scss']
})
export class WorkflowDeleteConfirmDialogComponent {

  isChecked = true;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  toggleChecked() {
    this.isChecked = !this.isChecked;
  }

  confirm() {
    this.dialogRef.close({status: ConfirmDialogStatus.Confirmed, isChecked: this.isChecked});
  }

  cancel() {
    this.dialogRef.close({status: ConfirmDialogStatus.Canceled});
  }

  close() {
    this.dialogRef.close({status: ConfirmDialogStatus.Closed});
  }
}
