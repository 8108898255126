<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header btn-holder">
      <div class="col-sm-5">
        <h5 class="modal-title">
          <span *ngIf="!isEdit()">{{'pageElements.newCode' | translate}}</span>
          <span *ngIf="isEdit()">{{'pageElements.editCode' | translate}}</span>
        </h5>
      </div>
      <div class="col-sm-7 text-right">

        <button tabindex="-1" (click)="close()" type="button" class="btn btn-default left-icon text-uppercase">
          <i class="far fa-check-square" aria-hidden="true"></i>
          {{'buttons.done' | translate}}
        </button>

        <button (click)="addTags()"
                [matTooltip]="'tooltips.addTagsToDataCode' | translate"
                matTooltipPosition="below"
                matTooltipClass="text-center" type="button" class="btn btn-cstm-info left-icon text-uppercase">
          <i class="far fa-plus" aria-hidden="true"></i>
          {{'buttons.tags' | translate}}
        </button>

        <div *ngIf="!(isExisting$ | async) && form.valid" class="di">
          <button (click)="onSave()" type="button"
                  class="btn btn-new left-icon text-uppercase">
            <span class="far fa-save" aria-hidden="true"></span>
            {{'buttons.save' | translate}}
          </button>
        </div>
        <div *ngIf="(isExisting$ | async) || form.invalid" class="di"
             [matTooltip]="'tooltips.fillRequiredFields' | translate"
             matTooltipPosition="above"
             matTooltipClass="text-center">
          <button [disabled]="true" type="button"
                  class="btn btn-new left-icon text-uppercase">
            <span class="far fa-save" aria-hidden="true"></span>
            {{'buttons.save' | translate}}
          </button>
        </div>
      </div>
      <button (click)="close()" class="cstm-close" data-dismiss="modal" aria-label="Close" type="button">
        <span class="far fa-times"></span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="form">

        <div class="row">
          <div class="col-sm-5">
            <mat-form-field [matTooltip]="'tooltips.enterCodeName' | translate"
                            matTooltipPosition="right"
                            matTooltipClass="text-center">
              <input matInput
                     [placeholder]="'formControls.name' | translate" formControlName="name"
                     [required]="true">
              <mat-error *ngIf="form.get('name').hasError('required')">
                <span [innerHtml]="'validations.nameRequired' | translate"></span>
              </mat-error>
              <mat-error *ngIf="form.get('name').hasError('maxlength')">
                <span [innerHtml]="'validations.nameAtMost15' | translate"></span>
              </mat-error>
              <mat-error *ngIf="form.get('name').hasError('alphaNum')">
                <span [innerHtml]="'validations.nameAlphaNum' | translate"></span>
              </mat-error>
              <mat-error *ngIf="form.get('name').hasError('allNumbers')">
                <span [innerHtml]="'validations.nameAllNumbers' | translate"></span>
              </mat-error>
              <mat-hint align="end">{{form.get('name').value.length || 0}}/15</mat-hint>
            </mat-form-field>
          </div>

          <div class="col-sm-7">
            <alert *ngIf="isExisting$ | async" type="danger">
                            <span
                              *ngIf="form.get('rentals').value.length === 0">{{'pageElements.codeNameAlreadyInUse' | translate}}</span>
              <span
                *ngIf="form.get('rentals').value.length > 0">{{'pageElements.codeNameAlreadyInUse2' | translate}}</span>
            </alert>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-5">
            <mat-form-field [matTooltip]="'tooltips.enterCodeDescription' | translate"
                            matTooltipPosition="right"
                            matTooltipClass="text-center">
              <input matInput
                     [placeholder]="'formControls.description' | translate"
                     formControlName="description">
              <mat-error *ngIf="form.get('description').hasError('maxlength')">
                <span [innerHtml]="'validations.descriptionAtMost140' | translate"></span>
              </mat-error>
              <mat-hint align="end">{{form.get('description').value.length || 0}}/140</mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-5">
            <app-select-search [items]="rentals$ | async"
                               [ctrl]="form.get('rentals')"
                               [multiple]="true"
                               [matTooltip]="'tooltips.codeRentals' | translate"
                               matTooltipPosition="right"
                               matTooltipClass="text-center"
                               [hasBlank]="true"
                               [placeholder]="'formControls.forThisRental' | translate"></app-select-search>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <app-editor [content]="code.body" [control]="form.get('body')"
                        [customCodes]="true"></app-editor>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
