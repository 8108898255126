<button (click)="close()" class="close" aria-label="Close" type="button">
  <span class="far fa-times"></span>
</button>

<div class="row">
  <div class="col-sm-12">
    <form [formGroup]="form" (ngSubmit)="submit()">

      <mat-form-field>
        <input matInput [placeholder]="'formControls.name' | translate" formControlName="name"
               [required]="true">
        <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')">
          <span [innerHtml]="'validations.nameRequired' | translate"></span>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="'formControls.value' | translate" formControlName="value"
               [required]="true">
        <mat-error *ngIf="form.get('value').touched && form.get('value').hasError('required')">
          <span [innerHtml]="'validations.valueRequired' | translate"></span>
        </mat-error>
      </mat-form-field>

      <div class="text-right">
        <button [disabled]="form.invalid" class="btn btn-new left-icon text-uppercase" type="submit">
          <i class="far fa-save"></i> {{'buttons.save' | translate}}
        </button>
      </div>

    </form>
  </div>
</div>
