import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {from, Observable, of} from 'rxjs';
import {Booking, BookingView} from './booking.model';
import * as R from 'ramda';
import {flatMap, map, toArray} from 'rxjs/operators';
import {InquirySkipRequest} from '../../../interfaces/inquiry/inquiry-skip-request';
import {InquirySkipResponse} from '../../../interfaces/inquiry/inquiry-skip-response';
import {InquiryEditRequest} from '../../../interfaces/inquiry/inquiry-edit-request';
import {Message} from '../../../models/message';
import {MessageResponse} from '@automata/interfaces/message-response';
import {InquiriesFilters} from '@automata/interfaces/inquiry/inquiries-filters';
import {AutomataLogModel} from '@automata/models/automata-log-model';
import {isSomething} from '@automata/utility/functions/is-something';
import * as moment from 'moment';
import {InquiryAttributes} from '@automata/interfaces/inquiry-attributes';

@Injectable()
export class BookingService {

  getInquiriesSkipCount = 0;

  constructor(private http: HttpClient) {
  }

  getLogs(inquiryId: string) {
    const url = `@api/inquiry/automata_logs/${inquiryId}`;

    return this.http.get<{automata_logs: any}>(url)
      .pipe(
        flatMap(response => from(response.automata_logs)),
        map((response: any) => AutomataLogModel.deserialize(response)),
        toArray(),
      );
  }

  getMessages(inquiryId: string): Observable<Message[]> {
    return this.http.get<MessageResponse[]>(`@api/message/inquiry/${inquiryId}`)
      .pipe(
        flatMap(response => from(response)),
        map(response => Message.deserialize(response)),
        toArray()
      );
  }

  searchInquires(filters: InquiriesFilters, search: string): Observable<BookingView[]> {

    let query = `?`; // &name=&

    if (!R.isEmpty(filters.rentals)) {
      query += `rental_id=${R.last(filters.rentals)}&`;
    }

    if (!R.isEmpty(filters.channels)) {
      query += `source=${R.last(filters.channels)}&`;
    }

    if (!R.isEmpty(search)) {
      query += `name=${search}&`;
    }

    switch (filters.status) {
      case 'booked':
        query += `booked=1&`;
        break;
      case 'followup':
        query += `followup=1&`;
        break;
    }

    query = query.slice(0, -1);

    if (R.isEmpty(query)) {
      return of([]);
    }

    return this.http.get<Booking[]>(`@api/inquiry/all/${query}`)
      .pipe(
        map(bookings => this.mapBookings(bookings))
      );
  }

  getInquires(limit = 100): Observable<{inquiries: BookingView[], skip: number}> {
    const skip = this.getInquiriesSkipCount++ * 100;
    return this.http.get<Booking[]>(`@api/inquiry/all/?limit=${limit + 1}&skip=${skip}`)
      .pipe(
        map((response) => ({
          inquiries:          this.mapBookings(response),
          skip
        }))
      );
  }

  update(inquiry: InquiryEditRequest): Observable<BookingView> {
    return this.http.put<Booking>(`@api/inquiry/update/${inquiry.id}`, inquiry)
      .pipe(
        map(booking => this.convertBookingToView(booking))
      );
  }

  skipTrigger(skipRequest: InquirySkipRequest): Observable<InquirySkipResponse> {
    return this.http.put<InquirySkipResponse>(`@api/trigger/skip/${skipRequest.triggerId}/${skipRequest.bookingId}`, {});
  }

  unSkipTrigger(skipRequest: InquirySkipRequest) {
    return this.http.put<InquirySkipResponse>(`@api/trigger/unskip/${skipRequest.triggerId}/${skipRequest.bookingId}`, {});
  }

  skipInquiry(inquiryId: string) {
    return this.http.put(`@api/trigger/skip/${inquiryId}`, {});
  }

  unSkipInquiry(inquiryId: string) {
    return this.http.put(`@api/trigger/unskip/${inquiryId}`, {});
  }

  getInquirySkip(inquiryId: string): Observable<boolean> {
    return this.http.get(`@api/trigger/skip/booking/${inquiryId}`)
      .pipe(
        map((response: any) => response.skipped === '1')
      );
  }

  private mapBookings(bookings: Booking[]): BookingView[] {
    return bookings.map(booking => this.convertBookingToView(booking))
  }

  private convertBookingToView(booking: Booking): BookingView {
    let attributes = booking.attributes;
    if (!attributes) {
      attributes = {tags: []} as InquiryAttributes;
    }
    if (attributes && !attributes.tags) {
      attributes = {...attributes, tags: []};
    }
    if (attributes && attributes.tags) {
      attributes = {...attributes, tags: R.map(R.pipe(R.when(R.is(Number), R.toString)))(attributes.tags)};
    }

    return {
      ...booking,
      guestsCount: booking.num_child + booking.num_adults,
      skip: isSomething(booking.skip) ? booking.skip !== 0 : false,
      arriveMonth: (moment.unix(booking.guest_arrive).month() + 1).toString(),
      arriveFormatted: booking.guest_arrive ? moment.unix(booking.guest_arrive).format('DD - MMM - YYYY') : '',
      departFormatted: booking.guest_depart ? moment.unix(booking.guest_depart).format('DD - MMM - YYYY') : '',
      checkInFormatted: booking.check_in ? moment.unix(booking.check_in).format('h:mm A') : '',
      checkOutFormatted: booking.check_out ? moment.unix(booking.check_out).format('h:mm A') : '',
      touchFormatted: booking.touch ? moment.unix(booking.touch).format('DD - MMM - YYYY | h:mm A') : '',
      guestName: !R.isNil(booking.guest_details) ? booking.guest_details.name : booking.guest_name,
      createdFormatted: booking.created ? moment.unix(booking.created).format('DD - MMM - YYYY | h:mm A') : '',
      attributes,
      tagsFormatted: attributes.tags.join(', '),
    } as BookingView
  }
}
