export enum ActivityType {
  Email,
  SMS,
  Http,
  Notifications,
  FollowUp,
  CreditCardForm,
  GuestForm,
  Contracts,
  BookingTags,
}
