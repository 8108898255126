import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ApiKey} from '@automata/models/api-key';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {localeCompareSort} from '@automata/utility/functions/locale-compare-sort';
import {TableType} from '@automata/enums/table-type';
import {DialogService} from '@automata/services/dialog.service';
import {AccountDetails} from '@automata/models/account-details';
import {path} from 'ramda';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {TableUtilityService} from '@automata/services/table-utility.service';
import {DeleteApiKey, LoadApiKeys} from '@automata/actions/accounts.actions';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector:    'app-api-keys-table',
  templateUrl: './api-keys-table.component.html',
  styleUrls:   ['./api-keys-table.component.scss']
})
export class ApiKeysTableComponent implements OnInit, OnChanges {

  @Input() account: AccountDetails;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns = [
    'name',
    'key',
    'access',
    'created',
    'edit'
  ];

  dataSource = new MatTableDataSource<ApiKey>();

  tableType = TableType.ApiKeys;

  isEmptyTable$ = this.tableService.isEmptyTable(this.dataSource);

  isLoaded = false;

  constructor(private dialogService: DialogService,
              private translate: TranslateService,
              private tableService: TableUtilityService,
              private store: Store<fromRoot.State>) {
  }

  ngOnInit() {
    this.store.dispatch(LoadApiKeys());

    this.dataSource.data = [];
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortData = localeCompareSort;
  }

  ngOnChanges(changes: SimpleChanges) {

    const account: AccountDetails = path(['account', 'currentValue'], changes);

    setTimeout(() => this.dataSource.data = account.apiKeys || [], 0);
    setTimeout(() => this.isLoaded = true, 100);
  }

  createKey() {
    this.dialogService.openCreateApiKey();
  }

  confirmRemove(apiKey: ApiKey) {
    this.dialogService.openConfirm()
      .subscribe(() => {
        this.store.dispatch(DeleteApiKey({key: apiKey}));
      });
  }

}
