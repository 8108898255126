import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {TriggerCondition} from '../../models/trigger-condition';
import {pathOr} from 'ramda';
import {TableType} from '@automata/enums/table-type';
import {DialogService} from '@automata/services/dialog.service';
import {TableUtilityService} from '@automata/services/table-utility.service';
import {localeCompareSort} from '@automata/utility/functions/locale-compare-sort';
import {TranslateService} from '@ngx-translate/core';
import {TriggerConditionModel} from '@automata/interfaces/trigger/trigger-condition';
import * as moment from 'moment';
import {Trigger} from '@automata/models/trigger';

@Component({
  selector:    'app-conditions-table',
  templateUrl: './conditions-table.component.html',
  styleUrls:   ['./conditions-table.component.scss']
})
export class ConditionsTableComponent implements OnInit, OnChanges {

  @Input() event: string;
  @Input() trigger: Trigger;
  @Input() conditions: TriggerCondition[];

  @Output() remove = new EventEmitter<string>();
  @Output() update = new EventEmitter<{conditionId: string, condition: TriggerConditionModel}>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns = [
    'updated',
    'description',
    'edit'
  ];

  dataSource = new MatTableDataSource<TriggerCondition>();

  isEmptyTable$ = this.tableService.isEmptyTable(this.dataSource);

  tableType = TableType.Conditions;

  constructor(private dialogService: DialogService,
              private translate: TranslateService,
              private tableService: TableUtilityService) {
  }

  ngOnInit() {
    this.dataSource.data = [];
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortData = localeCompareSort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.dataSource.data = pathOr([], ['conditions', 'currentValue'], changes);
    }, 50);
  }

  onEdit(condition: TriggerCondition) {
    const updated = moment.utc().unix();
    this.dialogService.openTriggerCondition(this.event, condition, this.trigger, this.dataSource.data)
      .subscribe(({condition, conditionId}) => {
        this.update.emit({conditionId, condition: {...condition, updated}});
      });
  }

  confirmRemove(condition: TriggerCondition) {
    this.dialogService.openConfirm().subscribe(() => {
      this.onConfirmRemove(condition);
    });
  }

  onConfirmRemove(condition: TriggerCondition) {
    this.remove.emit(condition.key);
  }
}
