import {Pipe, PipeTransform} from '@angular/core';

declare const require: any;

const striptags = require('striptags');

@Pipe({
  name: 'stripHtml'
})
export class StripHtmlPipe implements PipeTransform {

  transform(html: string): string {
    return striptags(html);
  }

}
