import {TriggerEvent} from '../interfaces/trigger/trigger-event';
import {find, isNil, propEq} from 'ramda';
import {TriggerHandler} from '../enums/trigger-handler';

export class TriggerHandlers {
  static handlers: TriggerEvent[] = [
    {
      name:  'Send Email Message',
      value: TriggerHandler.SendEmailMessage
    },
    {
      name:    'Send Signature Contract',
      value:   TriggerHandler.SendContract,
      tooltip: 'Only Signature contracts with Guest and Tokeet user roles assigned can be selected.'
    },
    {
      name:    'Send Signature Document',
      value:   TriggerHandler.SendDocument,
      tooltip: 'Only Signature documents with the Guest role assigned can be selected. This can only be sent to your guest.'
    },
    {
      name:    'Send Signature Form',
      value:   TriggerHandler.SendForm,
      tooltip: 'Only Signature forms with the Guest role assigned can be selected. This can only be sent to your guest.'
    },
    {
      name:  'Send HTTP Request',
      value: TriggerHandler.SendHTTPPost
    },
    {
      name:  'Send Push Notification',
      value: TriggerHandler.SendPushNotification
    },
    {
      name:  'Send SMS Message',
      value: TriggerHandler.SendSMSMessage
    },
    {
      name:  'Mark For Follow-up',
      value: TriggerHandler.SendFollowUp
    },
    {
      name:  'Remove Follow-up Flag',
      value: TriggerHandler.RemoveSendFollowUp
    },
    {
      name:  'Add Booking Tag',
      value: TriggerHandler.AddTagHandler
    },
    {
      name:  'Remove Booking Tag',
      value: TriggerHandler.RemoveTagHandler
    }
  ];

  static getHandlerName(value: string): string | null {
    const handler = find(propEq('value', value), <any>this.handlers);
    return isNil(handler) ? null : handler.name;
  }
}
