import {Pipe, PipeTransform} from '@angular/core';

declare const require: any;

const truncate = require('lodash/truncate');

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return truncate(value, {
      length: args
    });
  }

}
