import {
  AddCodeComplete,
  DeleteBatchCodeComplete,
  DeleteCodeComplete,
  LoadCodesComplete,
  UpdateCodeComplete
} from '../actions/codes.actions';
import {Code} from '../models/code';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';

export interface State extends EntityState<Code> {
  // additional entities state properties
  selectedCodeId: string | null
  isLoaded: boolean
}

export const adapter: EntityAdapter<Code> = createEntityAdapter<Code>({
  sortComparer: (a: Code, b: Code) => a.name.toString().localeCompare(b.name)
});

export const initialState: State = adapter.getInitialState({
  selectedCodeId: null,
  isLoaded:       false
});

export const reducer = createReducer(
  initialState,
  on(LoadCodesComplete, (state, {codes}) => adapter.addMany(codes, {
    ...state,
    isLoaded: true
  })),
  on(AddCodeComplete, (state, {code}) => adapter.addOne(code, state)),
  on(UpdateCodeComplete, (state, {code}) => adapter.updateOne({
    id:      code.id,
    changes: code
  }, state)),
  on(DeleteCodeComplete, (state, {id}) => adapter.removeOne(id, state)),
  on(DeleteBatchCodeComplete, (state, {ids}) => adapter.removeMany(ids, state))
);

export const {selectAll} = adapter.getSelectors();
