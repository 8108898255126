import {Component, OnDestroy, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {TokeetService} from '@automata/services/tokeet.service';
import {Subscription} from 'rxjs';

@Component({
  selector:    'app-slack-info-dialog',
  templateUrl: './slack-info-dialog.component.html',
  styleUrls:   ['./slack-info-dialog.component.scss']
})
export class SlackInfoDialogComponent implements OnInit, OnDestroy {

  isConnectedSub: Subscription;
  isLoading = true;
  isConnected: boolean;

  constructor(public dialogRef: MatDialogRef<SlackInfoDialogComponent>,
              private tokeetService: TokeetService) {
  }

  ngOnInit() {
    this.isConnectedSub = this.tokeetService.isSlackConnected()
      .subscribe(isConnected => {
        this.isConnected = isConnected;
        this.isLoading = false;
      });
  }

  ngOnDestroy() {
    this.isConnectedSub.unsubscribe();
  }

  close() {
    this.dialogRef.close();
  }
}
