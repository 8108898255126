<div class="header-block">
  <h2 mat-dialog-title><i class="fas fa-book"></i> {{'pageElements.triggerSamples' | translate}}</h2>
  <button (click)="close()" class="close" aria-label="Close" type="button">
    <span class="far fa-times"></span>
  </button>
</div>

<mat-dialog-content>

  <mat-table #table matSort matSortActive="name" matSortDirection="asc" [dataSource]="dataSource">

    <ng-container matColumnDef="icon">
      <mat-header-cell *matHeaderCellDef> {{'tableColumns.icon' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"><i [ngClass]="element.icon"></i></mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tableColumns.name' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.name | truncate:35}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="templateDescription">
      <mat-header-cell *matHeaderCellDef
                       mat-sort-header> {{'tableColumns.description' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.templateDescription | truncate:100}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openSample(row)"></mat-row>
  </mat-table>

  <app-translated-pagination>
    <mat-paginator #paginator
                   [length]="dataSource?.data?.length"
                   [pageSize]="5"
                   [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
                   [showFirstLastButtons]="true">
    </mat-paginator>
  </app-translated-pagination>

</mat-dialog-content>
