import {NgModule} from '@angular/core';
import {CallPipe} from '@automata/pipes/call/call.pipe';

@NgModule({
  declarations: [
    CallPipe
  ],
  exports: [
    CallPipe
  ]
})
export class CallPipeModule {
}
