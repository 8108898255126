import {catchError, map, switchMap, take} from 'rxjs/operators';
import {isSomething} from '@automata/utility/functions/is-something';
import {EMPTY} from 'rxjs';
import {AuthService} from '@automata/services/auth.service';
import {SendSampleService} from '@automata/components/template/preview-template/send-sample/send-sample.service';
import {ToastService} from '@automata/services/toast.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Component, Inject, OnInit} from '@angular/core';
import {UsersService} from '@automata/services/users.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TemplateType} from '@automata/enums/template-type';
import {SaveForm} from '@automata/decorators/save.decorator';

@Component({
  selector: 'app-send-sample-dialog',
  templateUrl: './send-sample-dialog.component.html',
  styleUrls: ['./send-sample-dialog.component.scss']
})
export class SendSampleDialogComponent implements OnInit {

  users$ = this.usersService.all();

  usersEmail$ = this.users$
    .pipe(
      map(users => users.map(u => ({...u, formattedName: `${u.firstname} ${u.lastname} - ${u.primaryemail}`})))
    );
  usersPhone$ = this.users$
    .pipe(
      map(users => users.filter(u => !!u.phone).map(u => ({...u, formattedName: `${u.firstname} ${u.lastname} - ${u.phone}`})))
    );

  form = this.fb.group({
    user: ['', [Validators.required]]
  })
  types = TemplateType;

  sending = false

  constructor(private authService: AuthService,
              private fb: FormBuilder,
              private usersService: UsersService,
              public dialogRef: MatDialogRef<SendSampleDialogComponent>,
              private sendSampleService: SendSampleService,
              @Inject(MAT_DIALOG_DATA) public data: {content: string, subject: string, type: TemplateType},
              private toast: ToastService) {
  }

  ngOnInit() {
    console.log(this.data);
  }

  @SaveForm()
  onSendSample(form: FormGroup) {
    if (this.sending) {
      return
    }
    this.sending = true
    const {user} = form.getRawValue()
    return this.users$.pipe(
      take(1),
      switchMap(users => {
        const receiver = users.find(u => u.id === user)
        return this.authService.fetchUser()
          .pipe(
            switchMap(user => {
              let name = '';
              if (isSomething(user.firstname) && user.lastname) {
                name = `${this.authService.user.firstname} ${this.authService.user.lastname}`;
              }
              if (this.data.type === TemplateType.PushNotification) {
                const payload = {
                  to: receiver.phone,
                  body: this.data.content,
                }
                return this.sendSampleService.sendTestSMS(payload);
              } else if (this.data.type === TemplateType.Email) {
                const payload = {
                  message_txt: this.data.content,
                  message_html: this.data.content,
                  to: receiver.primaryemail,
                  from: this.authService.user.primaryemail,
                  subject: this.data.subject || ` from ${name}`,
                };
                return this.sendSampleService.sendTestEmail(payload);
              } else {
                return EMPTY
              }
            }),
            catchError((response) => {
              this.toast.error(null, response.error);
              this.sending = false
              return EMPTY;
            })
          );

      })
    ).subscribe(() => {
      this.sending = false
      if (this.data.type === this.types.Email) {
        this.toast.success('testEmailSent');
      } else {
        this.toast.success('testSMSSent');
      }
      this.close()
    })
  }

  close() {
    this.dialogRef.close();
  }
}
