<ng-template #tolTemplate>
  <div class="tal">
    <strong>{{'tooltips.channels' | translate}}: </strong><span
    *ngIf="trigger.channelName">{{trigger.channelName}}</span><span *ngIf="!trigger.channelName">All</span>
    <br>
    <strong>{{'tooltips.rentals' | translate}}: </strong><span
    *ngIf="trigger.rentalName">{{trigger.rentalName}}</span> <span *ngIf="!trigger.rentalName">All</span>
    <br>
    <strong *ngIf="trigger.recipientsNames">{{'tooltips.recipients' | translate}}
      : </strong>{{trigger.recipientsNames | truncate:100}}
    <br *ngIf="trigger.recipientsNames">
    <strong *ngIf="trigger.conditionsDescriptions">{{'tooltips.conditions' | translate}}
      : </strong>{{trigger.conditionsDescriptions | truncate:170}}
  </div>
</ng-template>
<span [tooltip]="tolTemplate">
    <ng-content></ng-content>
</span>
