import {createAction, props} from '@ngrx/store';
import {Workflow} from '@automata/models/workflow';
import {CreateBlankWorkflowPayload} from '@automata/interfaces/payloads/create-blank-workflow-payload';
import {DuplicateWorkflowPayload} from '@automata/interfaces/duplicate-workflow-payload';
import {Trigger} from '@automata/models/trigger';

export const DeleteWorkflow = createAction('[Workflow] Delete', props<{workflow: Workflow}>());

export const RemoveWorkflow = createAction('[Workflow] Remove', props<{workflow: Workflow}>());

export const CreateWorkflow = createAction('[Workflow] Create', props<CreateBlankWorkflowPayload>());

export const DuplicateWorkflow = createAction('[Workflow] Duplicate', props<{payload: DuplicateWorkflowPayload}>());

export const RenameWorkflow = createAction('[Workflow] Rename', props<{workflow: Workflow, name: string}>());

export const CreateTriggersForWorkflow = createAction('[Workflow] Create Triggers For Workflow', props<{triggers: Trigger[], workflow: Workflow}>());
