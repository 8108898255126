<div class="modal-header">
  <button (click)="close()" type="button" class="close" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">{{'pageElements.createApiKey' | translate}}</h4>
</div>
<div class="modal-body">
  <mat-form-field>
    <input matInput [placeholder]="'formControls.name' | translate" [formControl]="name" [required]="true">
    <mat-error *ngIf="name?.errors?.required">
      <span [innerHtml]="'validations.nameRequired' | translate"></span>
    </mat-error>
  </mat-form-field>

  <p [ngClass]="{hidden: !hasApiKey()}" class="api-key-box">* {{'pageElements.copyApiKey' | translate}}</p>

  <div [ngClass]="{hidden: !hasApiKey()}" class="input-group">
    <p>
      <strong>{{'pageElements.apiKeyLabel' | translate}}:</strong> {{apiKey?.apikey}}</p>
    <span class="input-group-btn">
            <button [ngClass]="{'btn-success': isCopied, 'btn-default': !isCopied}"
                    class="btn left-icon btn-default"
                    type="button"
                    ngxClipboard
                    [cbContent]="apiKey?.apikey"
                    (cbOnSuccess)="isCopied = true">
                <i class="fas fa-copy"></i> <span *ngIf="isCopied">{{'pageElements.copied' | translate}}</span><span
              *ngIf="!isCopied">{{'pageElements.copy' | translate}}</span>
            </button>
        </span>
  </div>
</div>
<div class="modal-footer">
  <button (click)="cancel()" type="button" class="btn btn-default left-icon text-uppercase">
    <i class="far fa-check-square" aria-hidden="true"></i>
    {{'buttons.cancel' | translate}}
  </button>
  <button [ngClass]="{hidden: !isCreated}" (click)="confirm()" class="btn btn-cstm-info left-icon text-uppercase"
          type="button">
    <i class="fas fa-times"></i> {{'buttons.ok' | translate}}
  </button>
  <button [ngClass]="{hidden: isCreated}" [disabled]="name.invalid" (click)="create(name.value)" type="button"
          class="btn btn-cstm-info left-icon text-uppercase">
    <i class="far fa-plus"></i> {{'buttons.create' | translate}}
  </button>
</div>
