import {Injectable} from '@angular/core';
import {ConditionType} from '../enums/condition-type';
import {ConditionValueFormType} from '../enums/condition-value-form-type';

@Injectable({
  providedIn: 'root'
})
export class ConditionService {

  constructor() {
  }

  resolveFormType(type: ConditionType): ConditionValueFormType {
    switch (type) {
      case ConditionType.UNTIL_CHECKIN:
      case ConditionType.SINCE_CHECKIN:
      case ConditionType.UNTIL_CHECKOUT:
      case ConditionType.SINCE_CHECKOUT:
        return ConditionValueFormType.DaysHours;
      case ConditionType.EARLY_CHECKIN:
      case ConditionType.EARLY_CHECKOUT:
      case ConditionType.LATE_CHECKIN:
      case ConditionType.LATE_CHECKOUT:
        return ConditionValueFormType.HoursMinutes;
      case ConditionType.STAY_LENGTH:
        return ConditionValueFormType.Nights;
      case ConditionType.GUEST_COUNT:
      case ConditionType.BOOKING_TOTAL:
      case ConditionType.INVOICE_TOTAL:
      case ConditionType.GUEST_BOOKINGS:
      case ConditionType.AVAILABLE_DAYS_BEFORE:
      case ConditionType.AVAILABLE_DAYS_AFTER:
        return ConditionValueFormType.Number;
      case ConditionType.BOOKINGS_STATUS:
      case ConditionType.GUEST_PHONE:
      case ConditionType.GUEST_EMAIL:
      case ConditionType.GUEST_LASTNAME:
      case ConditionType.RENTAL_AVAILABILITY:
        return ConditionValueFormType.None;
      case ConditionType.GUEST_COUNTRY:
        return ConditionValueFormType.Country;
      case ConditionType.TRIGGER_CONDITION:
        return ConditionValueFormType.Trigger;
      case ConditionType.BOOKING_TAGS:
        return ConditionValueFormType.TriggerTag;
      case ConditionType.INQUIRY_STATUS:
        return ConditionValueFormType.InquiryStatus;
    }

  }
}
