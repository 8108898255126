<div>
  <h2 mat-dialog-title>
    <i class="fal fa-sitemap"></i> {{'pageElements.triggerWorkflows' | translate}}
  </h2>
  <button (click)="close()" class="close" aria-label="Close" type="button">
    <span class="far fa-times"></span>
  </button>
</div>

<mat-dialog-content>
  <form [formGroup]="form">
    <div class="row center-row">

      <div class="col-sm-12">
        <p class="guide-text">{{'pageElements.workflowsGuide' | translate}}</p>
      </div>

      <div class="col-sm-6">
        <app-select-search [items]="rentalWorkflows"
                           [ctrl]="form.get('workflows')"
                           [multiple]="true"
                           [hasBlank]="true"
                           [placeholder]="'formControls.selectWorkflows' | translate"></app-select-search>
      </div>

      <div class="col-sm-6">
        <mat-form-field [matTooltip]="'tooltips.newWorkflowName' | translate"
                        matTooltipPosition="above"
                        matTooltipClass="text-center">
          <input matInput
                 [placeholder]="'formControls.name' | translate"
                 formControlName="name" type="text">
          <mat-error *ngIf="form.get('name').hasError('maxlength')">
            <span [innerHtml]="'validations.nameAtMost45' | translate"></span>
          </mat-error>
          <mat-error *ngIf="form.get('name').hasError('minlength')">
            <span [innerHtml]="'validations.nameAtLeast3' | translate"></span>
          </mat-error>
          <mat-hint align="end">{{form.get('name').value.length || 0}}/45</mat-hint>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>

<div class="row bottom-row">
  <div class="col-sm-12 text-right">
    <button (click)="close()" class="btn btn-default left-icon mr10 text-uppercase" type="button">
      <i class="far fa-check-square"></i> {{'buttons.cancel' | translate}}
    </button>
    <button (click)="save()" [disabled]="form.invalid" class="btn btn-new left-icon text-uppercase" type="button">
      <i class="far fa-save"></i> {{'buttons.select' | translate}}
    </button>
  </div>
</div>
