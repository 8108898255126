import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {from, Observable, of} from 'rxjs';
import {TemplateResponse} from '../interfaces/template/template-response';
import {Template} from '../models/template';
import {TemplateAddRequest} from '../interfaces/template/template-add-request';
import {TemplateEditRequest} from '../interfaces/template/template-edit-request';
import {TemplateForm} from '../interfaces/template/template-form';
import {AddTemplate, EditTemplate} from '../actions/templates.actions';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import * as R from 'ramda';
import {catchError, flatMap, map, mergeMap, switchMap, toArray} from 'rxjs/operators';
import {TemplateType} from '../enums/template-type';
import {TranslateResponse} from '../interfaces/translate-response';
import {TemplateDataDictionary} from '../models/template-data-dictionary';
import {replaceAll} from 'voca';
import {isSomething} from '../utility/functions/is-something';
import {TemplateSample} from '../models/samples/template-sample';
import {ToastService} from '@automata/services/toast.service';
import {retryEntity} from '@automata/utility/operators/retry-entity';

@Injectable()
export class TemplatesService {

  constructor(private http: HttpClient,
              private toast: ToastService,
              private store: Store<fromRoot.State>) {
  }

  all(): Observable<Template[]> {
    return this.http.get<TemplateResponse[]>('@api/template/all/')
      .pipe(
        flatMap(response => from(response)),
        map(response => new Template(response)),
        toArray(),
        retryEntity('templates', this.toast),
        map(templates => {
          return R.filter((t: Template) => t.type !== TemplateType.InvoiceTemplate && t.type !== TemplateType.CustomBranding, templates)
        })
      );
  }

  get(id: string): Observable<Template> {
    return this.http.get<TemplateResponse>(`@api/template/${id}`)
      .pipe(
        map(response => new Template(response))
      );
  };

  add(template: TemplateAddRequest): Observable<Template> {
    return this.http.post<TemplateResponse>(`@api/template/`, template)
      .pipe(
        map(response => new Template(response))
      );
  };

  addSample(templateSample: TemplateSample): Observable<Template> {
    const addRequest = {
      name:        templateSample.name,
      description: templateSample.description,
      subject:     templateSample.subject,
      body:        templateSample.body,
      type:        templateSample.type,
      method:      null,
      languages:   [],
      headers:     [],
      attributes:  {tags: []}
    } as TemplateAddRequest;

    return this.http.post<TemplateResponse>(`@api/template/`, addRequest)
      .pipe(
        map((response) => new Template(response))
      );
  };

  update(request: TemplateEditRequest): Observable<Template> {
    return this.http.put(`@api/template/update/${request.id}`, request)
      .pipe(
        map((response) => new Template(response))
      );
  };

  delete(id) {
    return this.http.delete(`@api/template/delete/${id}`);
  };

  deleteBatch(ids: string[]) {
    if (R.isEmpty(ids)) {
      return of([]);
    }
    return of(ids)
      .pipe(
        flatMap(ids => from(ids)),
        mergeMap(id => this.delete(id)),
        toArray(),
        map(templates => R.flatten(templates))
      );
  };

  save(form: TemplateForm, tags: string[] = []) {

    let languages: any = R.map(l => ({language: l.language, text: l.text, subject: l.subject}), form.languages || []); // any because Partial<TemplateTranslation>[] shows red in webstorm for some reason

    if (form.type !== TemplateType.Email) {
      languages = R.map(l => ({language: l.language, text: l.text}), form.languages || []);
    }

    if (isSomething(form.id)) {
      const editRequest = {
        id:          form.id,
        name:        form.name,
        description: form.description,
        subject:     form.subject,
        body:        form.content.body,
        type:        form.type,
        method:      form.method,
        languages:   languages,
        headers:     form.headers,
        attributes:  {tags}
      } as TemplateEditRequest;
      this.store.dispatch(EditTemplate({request: editRequest}));
    } else {
      const addRequest = {
        name:        form.name,
        description: form.description,
        subject:     form.subject,
        body:        form.content.body,
        type:        form.type,
        method:      form.method,
        languages:   languages,
        headers:     form.headers,
        attributes:  {tags}
      } as TemplateAddRequest;
      this.store.dispatch(AddTemplate({request: addRequest}));
    }
  }

  translate(text: string, language: string) {

    const googleTranslate = (text, codes) => this.http.post(`@api/translate/`, {
      source: text,
      target: language,
      f:      'html'
    })
      .pipe(
        map((response: TranslateResponse) => {
          const translationModel = R.head(response.data.translations);
          let translatedText = '';
          if (!R.isNil(translationModel)) {
            translatedText = translationModel.translatedText;
          }
          R.forEach((code: string) => {
            translatedText = replaceAll(translatedText, `<i data-code="${code}"></i>`, code);
          }, codes);
          translatedText = replaceAll(translatedText, '<code>0</code>', '\r\n');
          return translatedText;
        }),
        catchError(() => of(''))
      );

    return this.store.pipe(
      select(fromRoot.selectUniqueCodeNames),
      map(codes => codes.concat(TemplateDataDictionary.all)),
      switchMap((codes) => {
        R.forEach((code: string) => {
          text = replaceAll(text, code, `<i data-code="${code}"></i>`);
        }, codes);
        text = replaceAll(text, /\r\n|\r|\n/, '<code>0</code>');
        return googleTranslate(text, codes);
      })
    );
  }
}
