import {createAction, props} from '@ngrx/store';
import {Sample} from '@automata/interfaces/sample';
import {CreateWorkflowPayload} from '@automata/interfaces/payloads/create-workflow-payload';
import {TriggerSample} from '@automata/models/samples/trigger-sample';

export const LoadSamples = createAction('[Samples] Load Start');
export const LoadSamplesComplete = createAction('[Samples] Load Complete', props<{samples: Sample[]}>());

export const CreateWorkflow = createAction('[Samples] Create Workflow', props<{payload: CreateWorkflowPayload}>());

export const AddTriggerSample = createAction('[Samples] Add Sample Start', props<{sample: TriggerSample}>());
