import {UserRole} from '../interfaces/user/user-role';

export class UserRoles {

  static Admin: UserRole = {
    name:  'admin',
    value: 1
  };

  static Manager: UserRole = {
    name:  'manager',
    value: 1.1
  };

  static Accounting: UserRole = {
    name:  'accounting',
    value: 1.2
  };

  static Staff: UserRole = {
    name:  'staff',
    value: 1.5
  };

  static Owner: UserRole = {
    name:  'owner',
    value: 1.6
  };

  static Guest: UserRole = {
    name:  'guest',
    value: 2
  };


}
