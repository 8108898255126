import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GuestResponse} from '../interfaces/guest-response';
import {Observable} from 'rxjs';

@Injectable()
export class GuestService {

  constructor(private http: HttpClient) {
  }

  getMany(ids: string[]): Observable<GuestResponse[]> {
    return this.http.post<GuestResponse[]>(`@api/guest/many/`, {pkey: ids});
  }
}
