import {Pipe, PipeTransform} from '@angular/core';
import {TriggerHandler} from '../../enums/trigger-handler';

@Pipe({
  name: 'isFollowUpHandler'
})
export class IsFollowUpHandlerPipe implements PipeTransform {

  transform(handler: string): boolean {
    return handler === TriggerHandler.SendFollowUp || handler === TriggerHandler.RemoveSendFollowUp;
  }

}
