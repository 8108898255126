import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {TagsDialogComponent} from '../../tags/tags-dialog/tags-dialog.component';
import {FormBuilder, Validators} from '@angular/forms';
import * as R from 'ramda';

@Component({
  selector:    'app-trigger-workflows-dialog',
  templateUrl: './trigger-workflows-dialog.component.html',
  styleUrls:   ['./trigger-workflows-dialog.component.scss']
})
export class TriggerWorkflowsDialogComponent implements OnInit {

  triggerWorkflows: string[];
  rentalWorkflows: any[];

  form = this.fb.group({
    name:      ['', [Validators.minLength(3), Validators.maxLength(45)]],
    workflows: ['']
  });

  constructor(private fb: FormBuilder,
              public dialogRef: MatDialogRef<TagsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.triggerWorkflows = this.data.triggerWorkflows;
    this.rentalWorkflows = R.map((workflow) => ({id: workflow, name: workflow}), this.data.rentalWorkflows);
    this.form.patchValue({workflows: this.triggerWorkflows});
  }

  save() {
    const form = this.form.getRawValue();
    let workflows: string[] = form.workflows;
    if (R.is(String, form.name) && !R.isEmpty(form.name.trim())) {
      workflows = R.append(form.name, workflows);
    }
    workflows = R.map(R.compose(R.toLower, R.join('_'), R.split(' '), R.trim))(workflows);
    this.dialogRef.close({submit: true, workflows});
  }

  close() {
    this.dialogRef.close();
  }

}
