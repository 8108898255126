declare const require: any;

const uuid = require('uuid/v4');

export class PayloadHttpHeader {
  id = '';
  name = '';
  value = '';

  constructor(params?: Partial<PayloadHttpHeader>) {
    this.id = uuid();
    if (params) {
      this.name = params.name;
      this.value = params.value;
    }
  }
}
