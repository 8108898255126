import {Observable, of} from 'rxjs';
import {CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {LoadActivities} from '../actions/activities.actions';
import {catchError, filter, switchMapTo, take, tap} from 'rxjs/operators';

@Injectable()
export class ActivitiesGuard implements CanActivate {

  constructor(private store: Store<fromRoot.State>) {
  }

  public canActivate(): Observable<boolean> {
    return this.checkActivities()
      .pipe(
        switchMapTo(of(true)),
        catchError(() => of(false))
      );
  }

  checkActivities(): Observable<boolean> {
    return this.store.pipe(
      select(fromRoot.selectActivitiesLoaded),
      tap(isLoaded => {
        if (!isLoaded) {
          this.store.dispatch(LoadActivities());
        }
      }),
      filter(isLoaded => isLoaded),
      take(1)
    );
  }
}
