import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'bookingDetailGlobalTooltip'
})
export class BookingDetailGlobalTooltipPipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }

  transform(isSkipped: boolean): string {
    if (isSkipped) {
      return this.translate.instant('tooltips.triggersAreSkippedForBooking');
    } else {
      return this.translate.instant('tooltips.triggersAreEnabledForBooking');
    }
  }

}
