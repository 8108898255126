import {Pipe, PipeTransform} from '@angular/core';
import * as R from 'ramda';

@Pipe({
  name: 'hasWorkflow'
})
export class BookingHasWorkflowPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return !R.isNil(value.workflow);
  }

}
