import {NgModule} from '@angular/core';
import {LayoutMainComponent} from '@automata/layout/main/main.component';

const modules = [];

export const components = [
  LayoutMainComponent
];

export const dialogs = [];

export const services = [];

export const pipes = [];
export const directives = [];

export const declarations = [
  ...pipes,
  ...dialogs,
  ...components,
  ...directives
];

@NgModule({
  imports:         [
    ...modules
  ],
  declarations,
  providers:       [
    ...services
  ],
  entryComponents: [
    ...dialogs
  ],
  exports:         [
    ...declarations,
    ...modules
  ]
})
export class LayoutMainModule {
}
