import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {typeMappings, typeMappingValues} from '../../models/condition-type-mapping';
import {comparatorMapping, typeDescriptionMapping} from '../../models/condition-comparator-mapping';
import * as R from 'ramda';
import {ConditionType} from '../../enums/condition-type';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConditionService} from '../../services/condition.service';
import {TriggerConditionModel} from '@automata/interfaces/trigger/trigger-condition';
import {ConditionValueFormType} from '../../enums/condition-value-form-type';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../reducers/index';
import {Trigger} from '@automata/models/trigger';
import {TriggerCondition} from '../../models/trigger-condition';
import {TriggerEvents} from '@automata/models/trigger-events';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {isSomething} from '@automata/utility/functions/is-something';

@Component({
  selector:    'app-condition-dialog',
  templateUrl: './condition-dialog.component.html',
  styleUrls:   ['./condition-dialog.component.scss']
})
export class ConditionDialogComponent extends OnDestroyMixin implements OnInit {

  description: string;

  types = typeMappingValues;

  comparators;

  form = this.fb.group({
    type: ['', [Validators.required]],
    comp: ['', [Validators.required]]
  });

  valueForm = this.fb.group({
    country:       [''],
    number:        [0],
    nights:        [0],
    days:          [0],
    hours:         [0],
    minutes:       [0],
    trigger:       [''],
    bookingTag:    [''],
    inquiryStatus: ['']
  });

  triggers: Trigger[] = [];
  activeTypeTooltip$: Observable<string>

  constructor(private fb: FormBuilder,
              public dialogRef: MatDialogRef<ConditionDialogComponent>,
              private store: Store<fromRoot.State>,
              private conditionService: ConditionService,
              @Inject(MAT_DIALOG_DATA) public data: {event: string, trigger: Trigger, condition: TriggerCondition, existing?: TriggerCondition[]}) {
    super()
  }

  ngOnInit() {
    this.removeExisting()
    this.removeInvoiceTotal();
    this.removeBookingTags();
    this.removeGuestEmailCondition();

    if (this.isEdit()) {
      this.load(this.data.condition);
    }

    if (!R.isNil(this.data.trigger)) {
      this.store.pipe(
        select(fromRoot.selectWorkflowsAssociatedTriggers(this.data.trigger)),
        untilComponentDestroyed(this)
      )
        .subscribe((triggers: Trigger[]) => {
          this.triggers = triggers;
          this.addRemoveTriggerOptions(this.triggers);
        });
    }

    this.form.get('type')
      .valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(type => {
        this.description = typeDescriptionMapping[type];
        this.comparators = comparatorMapping[type];
        this.form.patchValue({
          comp: this.comparators[0]
        });
      });

    this.activeTypeTooltip$ = this.form.get('type').valueChanges.pipe(map(type => {
      const active = this.types.find(t => t.value === type)
      if (active) {
        return active.tooltip
      }
    }))
  }

  removeGuestEmailCondition() {
    if (!this.isEdit()) {
      this.types = R.filter(t => t.value !== ConditionType.GUEST_EMAIL, this.types)
    }
  }

  removeBookingTags() {
    if (!TriggerEvents.isInvoice(this.data.event) && !TriggerEvents.isTimeInvoice(this.data.event) && !TriggerEvents.isInquiry(this.data.event)) {
      this.types = R.filter(t => t.value !== ConditionType.BOOKING_TAGS, this.types);
    }
  }

  removeInvoiceTotal() {
    if (!TriggerEvents.isInvoice(this.data.event) && !TriggerEvents.isTimeInvoice(this.data.event)) {
      this.types = R.filter(t => t.value !== ConditionType.INVOICE_TOTAL, this.types);
    }
  }

  removeExisting() {
    const allowsMulti = [
      ConditionType.UNTIL_CHECKIN,
      ConditionType.SINCE_CHECKIN,
      ConditionType.UNTIL_CHECKOUT,
      ConditionType.SINCE_CHECKOUT,
      ConditionType.STAY_LENGTH,
      ConditionType.GUEST_COUNT,
      ConditionType.BOOKING_TOTAL,
      ConditionType.INVOICE_TOTAL,
      ConditionType.GUEST_BOOKINGS,
      ConditionType.BOOKING_TAGS,
    ]

    if (isSomething(this.data.existing)) {
      const existingTypes = R.map(t => t.type, this.data.existing)
      this.types = R.filter(t => {
        if (R.contains(t.value, existingTypes) && (R.contains(t.value, allowsMulti) || this.data.condition?.type === t.value)) {
          return true
        } else return !R.contains(t.value, existingTypes);
      }, this.types)
    }
  }

  addRemoveTriggerOptions(triggers: Trigger[]) {
    if (R.isEmpty(triggers)) {
      this.types = R.filter(t => t.value !== ConditionType.TRIGGER_CONDITION, this.types);
    } else {
      const typeInTypes = R.find(t => t.value === ConditionType.TRIGGER_CONDITION, this.types);
      if (R.isNil(typeInTypes)) {
        this.types = R.append(typeMappings()[ConditionType.TRIGGER_CONDITION], this.types);
      }
    }
  }

  isEdit() {
    return !R.isNil(this.data.condition);
  }

  hasType(): boolean {
    return !R.isNil(this.form.get('type').value);
  }

  changeColumns() {
    switch (this.form.get('type').value) {
      case ConditionType.UNTIL_CHECKIN:
      case ConditionType.SINCE_CHECKIN:
      case ConditionType.UNTIL_CHECKOUT:
      case ConditionType.SINCE_CHECKOUT:
        return true;
    }
    return false;
  }

  save() {
    let valueType = this.conditionService.resolveFormType(this.form.get('type').value);
    let form = this.form.getRawValue();
    let condition: TriggerConditionModel = {
      type: form.type,
      comp: form.comp.comp
    };
    switch (valueType) {
      case ConditionValueFormType.HoursMinutes:
        condition.hours = this.valueForm.get('hours').value;
        condition.minutes = this.valueForm.get('minutes').value;
        break;
      case ConditionValueFormType.DaysHours:
        condition.days = this.valueForm.get('days').value;
        condition.hours = this.valueForm.get('hours').value;
        break;
      case ConditionValueFormType.Nights:
        condition.value = this.valueForm.get('nights').value;
        break;
      case ConditionValueFormType.Number:
        condition.value = this.valueForm.get('number').value;
        break;
      case ConditionValueFormType.Country:
        condition.value = this.valueForm.get('country').value;
        break;
      case ConditionValueFormType.TriggerTag:
        condition.value = this.valueForm.get('bookingTag').value;
        break;
      case ConditionValueFormType.InquiryStatus:
        condition.value = this.valueForm.get('inquiryStatus').value;
        break;
      case ConditionValueFormType.Trigger:
        condition.value = form.comp.value;
        condition.trigger = this.valueForm.get('trigger').value;
        break;
      default:
        condition.value = form.comp.value;
        break;
    }
    if (this.isEdit()) {
      this.dialogRef.close({
        condition:   condition as TriggerConditionModel,
        conditionId: this.data.condition.key
      });
    } else {
      this.dialogRef.close({
        condition: condition as TriggerConditionModel
      });
    }
  }

  load(condition: TriggerCondition) {
    let valueType = this.conditionService.resolveFormType(condition.type);

    this.description = typeDescriptionMapping[condition.type];

    this.comparators = comparatorMapping[condition.type];

    this.form.patchValue({comp: R.find((c) => c.comp === condition.comp && (R.isNil(c.value) || c.value === condition.value), this.comparators)});
    this.form.patchValue({type: condition.type});

    switch (valueType) {
      case ConditionValueFormType.HoursMinutes:
        this.valueForm.patchValue({hours: condition.hours});
        this.valueForm.patchValue({minutes: condition.minutes});
        break;
      case ConditionValueFormType.DaysHours:
        this.valueForm.patchValue({days: condition.days});
        this.valueForm.patchValue({hours: condition.hours});
        break;
      case ConditionValueFormType.Nights:
        this.valueForm.patchValue({nights: condition.value});
        break;
      case ConditionValueFormType.Number:
        this.valueForm.patchValue({number: condition.value});
        break;
      case ConditionValueFormType.Country:
        this.valueForm.patchValue({country: condition.value});
        break;
      case ConditionValueFormType.Trigger:
        this.valueForm.patchValue({trigger: condition.trigger});
        break;
      case ConditionValueFormType.TriggerTag:
        this.valueForm.patchValue({bookingTag: condition.value});
        break;
      case ConditionValueFormType.InquiryStatus:
        this.valueForm.patchValue({inquiryStatus: condition.value});
        break;
      default:
        this.valueForm.patchValue({value: condition.value});
        break;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
