import {Injectable} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import {TableType} from '../enums/table-type';
import {LocalStorageKeys} from '../models/local-storage-keys';
import * as R from 'ramda';
import {TableColumn} from '../interfaces/table-column';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, Observable} from 'rxjs';

@Injectable()
export class TableUtilityService {

  tableColumns = {
    [TableType.Templates]:        [],
    [TableType.Workflows]:        [],
    [TableType.Bookings]:         [],
    [TableType.Triggers]:         [],
    [TableType.WorkflowTriggers]: [],
    [TableType.Codes]:            []
  };

  availableColumns: {[id: string]: TableColumn[]} = {
    [TableType.Templates]:        [
      {name: 'select', i18n: 'columnSettings.select', restricted: true},
      {name: 'name', i18n: 'columnSettings.name'},
      {name: 'tagsFormatted', i18n: 'columnSettings.tags'},
      {name: 'typeName', i18n: 'columnSettings.type'},
      {name: 'created', i18n: 'columnSettings.created'},
      {name: 'lastused', i18n: 'columnSettings.lastUsed'},
      {name: 'triggers', i18n: 'columnSettings.triggers'},
      {name: 'edit', i18n: 'columnSettings.edit', restricted: true}
    ],
    [TableType.Workflows]:        [
      {name: 'name', i18n: 'columnSettings.name'},
      {name: 'rentalName', i18n: 'columnSettings.rental'},
      {name: 'triggers', i18n: 'columnSettings.triggers'},
      {name: 'templatesCount', i18n: 'columnSettings.templates'},
      {name: 'lastUpdated', i18n: 'columnSettings.lastTriggered'},
      {name: 'status', i18n: 'columnSettings.status'},
      {name: 'edit', i18n: 'columnSettings.edit', restricted: true}
    ],
    [TableType.Bookings]:         [
      {name: 'status', i18n: 'columnSettings.status'},
      {name: 'inquiry_source', i18n: 'columnSettings.channel'},
      {name: 'tagsFormatted', i18n: 'columnSettings.tags', excluded: true},
      {name: 'lastTriggerName', i18n: 'columnSettings.lastTrigger'},
      {name: 'guestName', i18n: 'columnSettings.guestName'},
      {name: 'guestArrive', i18n: 'columnSettings.arrive'},
      {name: 'guestDepart', i18n: 'columnSettings.depart'},
      {name: 'rentalName', i18n: 'columnSettings.rental'},
      {name: 'guestsCount', i18n: 'columnSettings.guests'},
      {name: 'edit', i18n: 'columnSettings.edit', restricted: true}
    ],
    [TableType.Triggers]:         [
      {name: 'select', i18n: 'columnSettings.select', restricted: true},
      {name: 'name', i18n: 'columnSettings.name'},
      {name: 'tagsFormatted', i18n: 'columnSettings.tags', excluded: true},
      {name: 'created', i18n: 'columnSettings.created'},
      {name: 'eventName', i18n: 'columnSettings.event'},
      {name: 'rentalName', i18n: 'columnSettings.rental', excluded: true},
      {name: 'templateName', i18n: 'columnSettings.template'},
      {name: 'handlerName', i18n: 'columnSettings.action'},
      {name: 'lastUpdate', i18n: 'columnSettings.lastTriggered'},
      {name: 'status', i18n: 'columnSettings.status'},
      {name: 'edit', i18n: 'columnSettings.edit', restricted: true}
    ],
    [TableType.WorkflowTriggers]: [
      {name: 'select', i18n: 'columnSettings.select', restricted: true, excluded: true},
      {name: 'name', i18n: 'columnSettings.name'},
      {name: 'eventName', i18n: 'columnSettings.event'},
      {name: 'handlerName', i18n: 'columnSettings.action'},
      {name: 'templateName', i18n: 'columnSettings.template'},
      {name: 'created', i18n: 'columnSettings.created'},
      {name: 'lastUpdate', i18n: 'columnSettings.lastTriggered'},
      {name: 'tagsFormatted', i18n: 'columnSettings.tags', excluded: true},
      {name: 'rentalName', i18n: 'columnSettings.rental', excluded: true},
      {name: 'status', i18n: 'columnSettings.status'},
      {name: 'edit', i18n: 'columnSettings.edit', restricted: true}
    ],
    [TableType.Codes]:            [
      {name: 'select', i18n: 'columnSettings.select', restricted: true},
      {name: 'name', i18n: 'columnSettings.name'},
      {name: 'description', i18n: 'columnSettings.description', excluded: true},
      {name: 'body', i18n: 'columnSettings.value'},
      {name: 'creatorName', i18n: 'columnSettings.creator'},
      {name: 'created', i18n: 'columnSettings.created'},
      {name: 'rentalNames', i18n: 'columnSettings.rentals'},
      {name: 'tagsFormatted', i18n: 'columnSettings.tags'},
      {name: 'edit', i18n: 'columnSettings.edit', restricted: true}
    ]
  };

  constructor(private translate: TranslateService) {
  }


  masterToggle(selection: SelectionModel<any>, renderedData: any[], source: MatTableDataSource<any>) {
    if (this.isAllSelected(selection, renderedData)) {
      selection.clear();
    } else {
      source.data.forEach(row => {
        if (!R.isNil(R.find(rendered => rendered.id === row.id, renderedData))) {
          selection.select(row);
        }
      });
    }
  }

  isAllSelected(selection: SelectionModel<any>, renderedData: any[]) {
    const numSelected = selection.selected.length;
    const numRows = renderedData.length;
    return numSelected === numRows;
  }

  saveColumns(type: TableType, columns: TableColumn[]) {

    let existing;
    try {
      existing = JSON.parse(localStorage.getItem(LocalStorageKeys.TABLE_COLUMNS)) || this.tableColumns;
    } catch (e) {
      console.log('error saving table columns');
    }

    localStorage.setItem(LocalStorageKeys.TABLE_COLUMNS, JSON.stringify(R.merge(existing, {[type]: columns})));
  }

  getColumns(type: TableType) {
    let data;
    try {
      data = JSON.parse(localStorage.getItem(LocalStorageKeys.TABLE_COLUMNS)) || this.tableColumns;
    } catch (e) {
      console.log('error loading table columns');
    }
    return data[type] || this.tableColumns[type];
  }

  getAvailableColumns(type: TableType): TableColumn[] {
    const currentColumns = this.getColumns(type);

    const sorted = R.sort((a: TableColumn, b: TableColumn) => {

      const firstIndex = R.findIndex(R.propEq('name', a.name))(currentColumns);
      const secondIndex = R.findIndex(R.propEq('name', b.name))(currentColumns);

      // when new item is added but not present in local storage
      // we want to display it after Select column, not before
      if (a.name === 'select' && secondIndex === -1) {
        return -1;
      } else {
        return firstIndex - secondIndex;
      }

    }, this.availableColumns[type]);

    const excluded: any = R.map((x: TableColumn) => x.name, R.filter((a: TableColumn) => a.excluded, currentColumns));
    const included: any = R.map((x: TableColumn) => x.name, R.filter((a: TableColumn) => !a.excluded, currentColumns));


    return <TableColumn[]>R.map(c => {
      if (R.contains(c.name, excluded)) {
        return {...c, excluded: true};
      } else if (R.contains(c.name, included)) {
        return {...c, excluded: false};
      } else {
        return c;
      }
    }, sorted);
  }

  getDisplayedColumns(type: TableType): string[] {
    return <string[]>R.pipe(
      R.filter((c: TableColumn) => !c.excluded),
      <any>R.map((c: TableColumn) => c.name),
      <any>R.filter((c: string) => c !== 'edit'),
      <any>R.append('edit')
    )(<any>this.getAvailableColumns(type));
  }

  isCustomizationBadgeVisible() {
    let isVisible = true;
    try {
      isVisible = R.isNil(localStorage.getItem(LocalStorageKeys.TABLE_CUSTOMIZATION_BADGE));
    } catch (e) {
      console.log('error');
    }
    return isVisible;
  }

  hideCustomizationBadge() {
    localStorage.setItem(LocalStorageKeys.TABLE_CUSTOMIZATION_BADGE, 'hidden');
  }

  isEmptyTable(source: any) {
    return source['_renderData'].pipe(
      map((rows: any) => rows.length === 0)
    );
  }

  paginationTranslations(): Observable<{
    perPage: string
    nextPage: string
    prevPage: string
    firstPage: string
    lastPage: string
  }> {
    return combineLatest(
      this.translate.stream('pageElements.pagination'),
      this.translate.stream('pageElements.nextPage'),
      this.translate.stream('pageElements.prevPage'),
      this.translate.stream('pageElements.firstPage'),
      this.translate.stream('pageElements.lastPage')
    )
      .pipe(map(([perPage, nextPage, prevPage, firstPage, lastPage]) => {
        return {
          perPage,
          nextPage,
          prevPage,
          firstPage,
          lastPage
        };
      }));
  }
}
