import {Injectable, TemplateRef} from '@angular/core';
import * as R from 'ramda';
import {merge} from 'ramda';
import {ConfirmDialogComponent} from '../components/confirm-dialog/confirm-dialog.component';
import {ConfirmDialogParams} from '../interfaces/confirm-dialog-params';
import {TemplateConfirmDialogComponent} from '../components/template/template-confirm-dialog/template-confirm-dialog.component';
import {CreateApiKeyDialogComponent} from '../components/account/create-api-key-dialog/create-api-key-dialog.component';
import {ConditionDialogComponent} from '../features/conditions/components/condition-dialog/condition-dialog.component';
import {IftttIntegrationDialogComponent} from '../components/account/ifttt-integration-dialog/ifttt-integration-dialog.component';
import {Trigger} from '../models/trigger';
import {TriggerCondition} from '../features/conditions/models/trigger-condition';
import {IntegrationInfoDialogComponent} from '../components/integrations/integration-info-dialog/integration-info-dialog.component';
import {IntegrationInfo} from '../interfaces/integration-info';
import {PayloadHeadersDialogComponent} from '../components/template/payload-headers-dialog/payload-headers-dialog.component';
import {PayloadHttpHeader} from '../models/payload-http-header';
import {TagsDialogComponent} from '../components/tags/tags-dialog/tags-dialog.component';
import {TableManagementDialogComponent} from '../components/tables/table-management-dialog/table-management-dialog.component';
import {TableType} from '../enums/table-type';
import {TagsType} from '../enums/tags-type';
import {TemplateSamplesDialogComponent} from '../components/template/template-samples-dialog/template-samples-dialog.component';
import {TriggerWorkflowsDialogComponent} from '../components/trigger/trigger-workflows-dialog/trigger-workflows-dialog.component';
import {WorkflowDuplicateDialogComponent} from '../components/workflow/workflow-duplicate-dialog/workflow-duplicate-dialog.component';
import {Workflow} from '../models/workflow';
import {WorkflowDeleteConfirmDialogComponent} from '../components/workflow/workflow-delete-confirm-dialog/workflow-delete-confirm-dialog.component';
import {User} from '../models/user';
import {ChangePasswordDialogComponent} from '../components/account/change-password-dialog/change-password-dialog.component';
import {TriggerSamplesDialogComponent} from '../components/trigger/trigger-samples-dialog/trigger-samples-dialog.component';
import {BookingMessagesDialogComponent} from '../components/bookings/booking-messages-dialog/booking-messages-dialog.component';
import {Message} from '../models/message';
import {WorkflowRenameDialogComponent} from '../components/workflow/workflow-rename-dialog/workflow-rename-dialog.component';
import {filter, map, take, tap} from 'rxjs/operators';
import {TableUtilityService} from './table-utility.service';
import {TableManagementResult} from '../interfaces/table-management-result';
import {ConfirmDialogStatus} from '../enums/confirm-dialog-status';
import {TriggerSample} from '@automata/models/samples/trigger-sample';
import {ExistingTriggersDialogComponent} from '@automata/components/workflow/existing-triggers-dialog/existing-triggers-dialog.component';
import {isSomething} from '@automata/utility/functions/is-something';
import {ITemplateSample} from '@automata/interfaces/template/template-sample';
import {SlackInfoDialogComponent} from '@automata/components/integrations/slack-info-dialog/slack-info-dialog.component';
import {AlertDialogComponent} from '@automata/components/alert-dialog/alert-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {TutorialDialogComponent} from '@automata/components/tutorial/tutorial-dialog.component';
import {Store} from '@ngrx/store';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ComponentType} from '@angular/cdk/overlay';

@Injectable()
export class DialogService {

  constructor(private dialog: MatDialog,
              private store: Store<any>,
              private translate: TranslateService,
              private tableService: TableUtilityService) {
  }

  public openSide<T, D = any>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, config?: MatDialogConfig<D>): MatDialogRef<T> {

    let defaultConfig: MatDialogConfig<D> = {
      width:         '100%',
      height:        '100%',
      maxWidth:      'auto',
      backdropClass: '',
      position:      {
        right:  '0px',
        bottom: '0px'
      },
      panelClass:    [
        'modal',
        'side-modal-lg',
        'right'
      ]
    };

    let dialogRef = this.dialog.open(componentOrTemplateRef, merge(defaultConfig, config));

    if (dialogRef['_overlayRef'].overlayElement) {
      dialogRef['_overlayRef'].overlayElement.parentElement.className += ' dialog-wrapper';
    }

    let beforeClose = dialogRef.beforeClosed().subscribe(() => {
      dialogRef['_containerInstance']['_elementRef'].nativeElement.classList.add('side-closing');
      // dialogRef['_containerInstance']['_state'] = 'void';
      beforeClose.unsubscribe();
    });

    return dialogRef;
  }

  public openAlert(data: {title: string, body: string}, height = 230) {

    let defaultConfig: MatDialogConfig = {
      width:      '500px',
      height:     `${height}px`,
      data:       data,
      panelClass: [
        'confirm-modal',
        'modal-confirmation'
      ]
    };

    return this.dialog.open(AlertDialogComponent, defaultConfig).afterClosed()
      .pipe(
        take(1)
      )
      .subscribe();
  }

  public openConfirm(data?: ConfirmDialogParams) {

    let defaultData = {
      title:       this.translate.instant('dialogs.deleteTitle'),
      body:        this.translate.instant('dialogs.deleteBody'),
      confirmText: this.translate.instant('dialogs.deleteConfirm'),
      cancelText:  this.translate.instant('dialogs.deleteAbort')
    };

    let defaultConfig: MatDialogConfig = {
      width:      '500px',
      height:     '230px',
      data:       {...defaultData, ...data},
      panelClass: [
        'confirm-modal',
        'modal-confirmation'
      ]
    };

    return this.dialog.open(ConfirmDialogComponent, defaultConfig).afterClosed()
      .pipe(
        take(1),
        filter(status => status === ConfirmDialogStatus.Confirmed)
      );
  }

  public openWorkflowDeleteConfirm() {
    let defaultConfig: MatDialogConfig = {
      width:      '600px',
      height:     '330px',
      data:       {
        title:       this.translate.instant('dialogs.deleteTitle'),
        body:        this.translate.instant('dialogs.deleteBody'),
        confirmText: this.translate.instant('dialogs.deleteConfirm'),
        cancelText:  this.translate.instant('dialogs.deleteAbort')
      },
      panelClass: [
        'confirm-modal',
        'modal-confirmation'
      ]
    };

    return this.dialog.open(WorkflowDeleteConfirmDialogComponent, defaultConfig).afterClosed()
      .pipe(
        take(1),
        filter(result => result && result.status === ConfirmDialogStatus.Confirmed)
      );
  }

  public openTemplateConfirm(templateName: string) {

    let defaultConfig: MatDialogConfig = {
      width:      '500px',
      height:     '260px',
      data:       {name: templateName},
      panelClass: [
        'confirm-modal',
        'modal-confirmation'
      ]
    };

    return this.dialog.open(TemplateConfirmDialogComponent, defaultConfig).afterClosed()
      .pipe(
        take(1),
        filter(result => result && !result.closed)
      );
  }

  public openCreateApiKey() {

    let defaultConfig: MatDialogConfig = {
      width:      '500px',
      height:     '315px',
      panelClass: [
        'confirm-modal',
        'modal-confirmation'
      ]
    };

    return this.dialog.open(CreateApiKeyDialogComponent, defaultConfig);
  }

  public openChangePassword(user: User) {
    let defaultConfig: MatDialogConfig = {
      width:  '520px',
      height: '350px',
      data:   {user}
    };

    return this.dialog.open(ChangePasswordDialogComponent, defaultConfig);
  }

  public openTutorial(type: string) {
    let defaultConfig: MatDialogConfig = {
      width:  '800px',
      height: '570px',
      data:   {type}
    };

    return this.dialog.open(TutorialDialogComponent, defaultConfig);
  }

  public openTriggerCondition(event: string, condition?: TriggerCondition, trigger?: Trigger, existing?: TriggerCondition[]) {
    let defaultConfig: MatDialogConfig = {
      width:      '650px',
      height:     '350px',
      data:       {event, condition, trigger, existing},
      panelClass: [
        'trigger-condition'
      ]
    };

    return this.dialog.open(ConditionDialogComponent, defaultConfig).afterClosed()
      .pipe(
        filter(result => isSomething(result))
      );
  }

  public openIFTTTIntegration() {
    let defaultConfig: MatDialogConfig = {
      width:      '600px',
      height:     '400px',
      panelClass: [
        'ifttt-integration'
      ]
    };

    return this.dialog.open(IftttIntegrationDialogComponent, defaultConfig);
  }

  public openIntegrationInfo(data: IntegrationInfo) {
    let defaultConfig: MatDialogConfig = {
      width:      '450px',
      height:     '250px',
      data:       data,
      panelClass: [
        'integration-info'
      ]
    };

    return this.dialog.open(IntegrationInfoDialogComponent, defaultConfig);
  }

  public openSlackInfo() {
    let defaultConfig: MatDialogConfig = {
      width:      '450px',
      height:     '250px',
      panelClass: [
        'integration-info'
      ]
    };

    return this.dialog.open(SlackInfoDialogComponent, defaultConfig);
  }

  public openPayloadHeaders(data: PayloadHttpHeader) {
    let defaultConfig: MatDialogConfig = {
      width:      '500px',
      height:     '240px',
      data:       data,
      panelClass: [
        'payload-headers-dialog'
      ]
    };

    return this.dialog.open(PayloadHeadersDialogComponent, defaultConfig).afterClosed()
      .pipe(
        take(1),
        filter(header => !R.isNil(header))
      );
  }

  public openTags(tags: string[], type: TagsType) {
    let defaultConfig: MatDialogConfig = {
      width:  '600px',
      height: '450px',
      data:   {
        tags,
        type
      }
    };

    return this.dialog.open(TagsDialogComponent, defaultConfig)
      .afterClosed()
      .pipe(
        take(1),
        filter(result => result && result.submit)
      );
  }

  public openTableManagement<T>(type: TableType) {
    let defaultConfig: MatDialogConfig = {
      width:  '850px',
      height: '350px',
      data:   {
        columns: this.tableService.getAvailableColumns(type),
        type:    type
      }
    };

    return this.dialog.open(TableManagementDialogComponent, defaultConfig).afterClosed()
      .pipe(
        take(1),
        filter(result => result),
        tap((result) => {
          this.tableService.saveColumns(type, result.available);
        }),
        map((result: TableManagementResult) => result.displayed)
      );
  }

  public openTemplateSamples() {
    let defaultConfig: MatDialogConfig = {
      width:  '768px',
      height: '460px'
    };

    return this.dialog.open(TemplateSamplesDialogComponent, defaultConfig).afterClosed()
      .pipe(
        take(1),
        filter((sample: ITemplateSample) => !R.isNil(sample))
      );
  }

  public openTriggerSamples(workflowAbleOnly = false) {
    let defaultConfig: MatDialogConfig = {
      width:  '768px',
      height: '460px',
      data:   {workflowAbleOnly}
    };

    return this.dialog.open(TriggerSamplesDialogComponent, defaultConfig).afterClosed()
      .pipe(
        filter((sample: TriggerSample) => !R.isNil(sample))
      );
  }

  public openBookingMessage(message: Message) {
    let defaultConfig: MatDialogConfig = {
      width:  '600px',
      height: '500px',
      data:   {message}
    };

    return this.dialog.open(BookingMessagesDialogComponent, defaultConfig);
  }

  public openWorkflows(triggerWorkflows: string[], rentalWorkflows: string[]) {
    let defaultConfig: MatDialogConfig = {
      width:  '768px',
      height: '400px',
      data:   {
        triggerWorkflows,
        rentalWorkflows
      }
    };

    return this.dialog.open(TriggerWorkflowsDialogComponent, defaultConfig).afterClosed()
      .pipe(
        take(1),
        filter(result => result && result.submit)
      );
  }

  public openWorkflowDuplicate(workflow: Workflow) {
    let defaultConfig: MatDialogConfig = {
      width:  '600px',
      height: '350px',
      data:   {
        workflow
      }
    };

    return this.dialog.open(WorkflowDuplicateDialogComponent, defaultConfig);
  }

  public openWorkflowRename(workflow: Workflow) {
    let defaultConfig: MatDialogConfig = {
      width:  '600px',
      height: '300px',
      data:   {
        workflow
      }
    };

    return this.dialog.open(WorkflowRenameDialogComponent, defaultConfig);
  }

  public openExistingTriggers(workflow: Workflow) {
    let defaultConfig: MatDialogConfig = {
      width:  '600px',
      height: '400px',
      data:   {workflow}
    };

    return this.dialog.open(ExistingTriggersDialogComponent, defaultConfig).afterClosed()
      .pipe(
        take(1),
        filter(triggers => isSomething(triggers))
      );
  }
}
