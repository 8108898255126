import {classToClass, classToPlain, plainToClass} from 'class-transformer';
import {assign, merge} from 'lodash';

export function deserialize<T>(Class: any, data: object, options?: object): T {
  return plainToClass(Class, data, options);
}

export function serialize(data: object, options?: object): object {
  return classToPlain(data, options);
}

export type SerializableData<T> = T | Partial<T>;

export class Serializable<T> {

  constructor(data?: SerializableData<T>) {
    if (data) this.merge(data);
  }

  serialize() {
    return serialize(this);
  }

  merge(data: T | Partial<T>, useMerge = true) {
    if (useMerge) {
      merge(this, data);
    } else {
      assign(this, data);
    }
  }

  clone(data?: T | Partial<T>, useMerge = false): T {
    const clone = classToClass(this, {
      ignoreDecorators: true
    });

    if (data) {
      clone.merge(data, useMerge);
    }

    return clone as any;
  }

}
