import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilityService} from '../../services/utility.service';
import {LoginForm} from '../../models/login-form';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';
import {isSomething} from '../../utility/functions/is-something';

@Component({
  selector:    'app-login',
  templateUrl: './login.component.html',
  styleUrls:   ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  authSub: Subscription;
  routeSub: Subscription;

  constructor(private utility: UtilityService,
              private router: Router,
              private route: ActivatedRoute,
              private auth: AuthService) {

    this.utility.addClass('body', 'login');
  }

  ngOnInit() {

    this.routeSub = this.route
      .queryParams
      .pipe(
        filter(params => isSomething(params.token)),
        switchMap(params => this.auth.loginByToken(params.token))
      )
      .subscribe(() => {
        this.router.navigate(['activity']);
      });
  }

  onLogin(credentials: LoginForm) {
    this.authSub = this.auth
      .login(credentials)
      .subscribe(() => {
        this.router.navigate(['activity']);
      });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    this.utility.removeClass('body', 'login');
  }
}
