import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {WorkflowWizardComponent} from '../workflow-wizard/workflow-wizard.component';
import {select, Store} from '@ngrx/store';
import {DialogService} from '../../../services/dialog.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as fromRoot from '../../../reducers';
import {FormBuilder, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {Trigger} from '../../../models/trigger';
import {map, switchMap} from 'rxjs/operators';
import {TriggerDialogComponent} from '../../trigger/trigger-dialog/trigger-dialog.component';
import {TabTypes} from '../../../enums/tab-types';
import {TriggerEvents} from '../../../models/trigger-events';
import * as R from 'ramda';
import {Rental} from '../../../models/rental';
import {CreateWorkflow} from '../../../actions/workflows.actions';
import {ToastService} from '@automata/services/toast.service';
import {WorkflowDetailType} from '@automata/enums/workflow-detail-type';
import {AmplitudeService} from '@automata/services/amplitude.service';

@Component({
  selector:    'app-new-workflow-dialog',
  templateUrl: './new-workflow-dialog.component.html',
  styleUrls:   ['./new-workflow-dialog.component.scss']
})
export class NewWorkflowDialogComponent implements OnInit, OnDestroy {

  triggers$: Observable<any>;

  triggersCtrlSub: Subscription;

  selected: Trigger[];

  rentals$: Observable<Rental[]>;

  form = this.fb.group({
    triggers: ['', [Validators.required]],
    rental:   ['', [Validators.required]],
    name:     ['', [Validators.required, Validators.maxLength(50)]]
  });

  type = WorkflowDetailType.Workflow;

  constructor(public dialogRef: MatDialogRef<WorkflowWizardComponent>,
              private dialogService: DialogService,
              private fb: FormBuilder,
              private amplitudeService: AmplitudeService,
              private toast: ToastService,
              private store: Store<fromRoot.State>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {

    this.rentals$ = this.store.pipe(select(fromRoot.selectAllRentals));

    this.triggers$ = this.store.pipe(
      select(fromRoot.selectWorkflowAbleTriggers),
      map(triggers => R.map((trigger: Trigger) => ({
        id:   trigger.id,
        name: trigger.name || TriggerEvents.getEventName(trigger.event)
      }), triggers))
    );

    this.triggersCtrlSub = this.form.get('triggers').valueChanges
      .pipe(
        switchMap(ids => this.store.pipe(select(fromRoot.selectTriggersById(ids))))
      )
      .subscribe(triggers => {
        this.selected = triggers;
      });
  }

  ngOnDestroy() {
    this.triggersCtrlSub.unsubscribe();
  }

  createWorkflow() {
    const payload = this.form.getRawValue();

    if (R.isEmpty(payload.name.trim())) {
      this.toast.error('toast.error.workflowNameEmpty');
      return;
    }
    this.store.dispatch(CreateWorkflow({
      triggers: this.selected,
      rental:   payload.rental,
      name:     payload.name.trim()
    }));
    this.amplitudeService.logEvent('workflow-blank-complete')
    this.close();
  }

  onOpen(event) {
    console.log(`Editing trigger ${event.trigger.id}`);
    this.dialogService.openSide(TriggerDialogComponent, {
      data: {
        triggerId: event.trigger.id,
        activeTab: TabTypes.Overview
      }
    });
  }

  close() {
    this.dialogRef.close();
  }
}
